import React from 'react';
import clsx from 'clsx';

import { ReactComponent as IconErrorOutline } from '../../assets/icons/16-error-outline.svg';

import styles from './FormError.module.scss';

function FormError({
  className,
  error,
  ...props
}) {
  return (
    <div className={clsx(className, styles.error, error && styles.visible)} aria-hidden={!error}>
      <IconErrorOutline role="presentation" />
      <span>{error}</span>
    </div>
  );
}

export default FormError;
