import React, { forwardRef } from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';

import LoadingSpinner from '../LoadingSpinner';
import Tooltip from '../Tooltip';

import styles from './Button.module.scss';

export const BUTTON_COLOR_OUTLINE = 'outline';
export const BUTTON_COLOR_FILL = 'fill';
export const BUTTON_COLOR_CATEGORY = 'category';

export const BUTTON_BORDER_STYLE_ROUND = 'round';
export const BUTTON_BORDER_STYLE_SQUARE = 'square';

export const BUTTON_SIZE_XSMALL = 'xsmall';
export const BUTTON_SIZE_SMALL = 'small';
export const BUTTON_SIZE_REGULAR = 'regular';
export const BUTTON_SIZE_LARGE = 'large';

const Button = forwardRef(({
  className,
  children,
  color = BUTTON_COLOR_OUTLINE,
  borderStyle = BUTTON_BORDER_STYLE_ROUND,
  iconOnly,
  block,
  linkTo,
  loading,
  negative,
  tag,
  size = BUTTON_SIZE_REGULAR,
  tooltip,
  ...props
}, ref) => {
  const ButtonTag = tag || (linkTo ? Link : 'button');

  props.to = linkTo;
  props.className = clsx(
    className,
    styles.button,
    color && styles[color],
    iconOnly && styles.iconOnly,
    loading && styles.loading,
    negative && styles.negative,
    size && styles[size],
    borderStyle && styles[borderStyle],
    block && styles.block,
  );

  const tooltipTitle = tooltip && props['aria-label'];

  if (tooltipTitle) {
    return (
      <Tooltip title={tooltipTitle}>
        <ButtonTag ref={ref} {...props}>
          <span className={styles.children}>{children}</span>
          {loading && <LoadingSpinner className={styles.loader} absolute />}
        </ButtonTag>
      </Tooltip>
    );
  }

  return (
    <ButtonTag ref={ref} {...props}>
      <span className={styles.children}>{children}</span>
      {loading && <LoadingSpinner className={styles.loader} absolute />}
    </ButtonTag>
  );
});

Button.displayName = 'Button';

export default Button;
