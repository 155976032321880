import React, { useState, createContext, useEffect, useMemo } from "react";

import { getUser, watchUser, isReady } from "../services/AuthService";

const MILLISECONDS_IN_MINUTE = 1000 * 60;
const AUTH_POLL_INTERVAL = 5 * MILLISECONDS_IN_MINUTE;

export const AuthContext = createContext();

export function AuthContextProvider({ children }) {
  const [user, setUser] = useState(null);
  const [authReady, setAuthReady] = useState(false);

  useEffect(() => {
    return watchUser(newUser => {
      setUser(newUser);
      setAuthReady(isReady());
    });
  }, []);

  // Cognito has no onSnapshot equivalent to easily watch for changes, so a background
  // poll at 5 minutes is used to keep user data up to date. It is also refreshed in instances
  // where up to date info is needed – profile sidebar for example.
  useEffect(() => {
    if (!user) return;

    const pollUser = setInterval(getUser, AUTH_POLL_INTERVAL);

    return () => clearInterval(pollUser);
  }, [user]);

  const value = useMemo(() => ({
    user,
    authReady,
  }), [user, authReady]);

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
}
