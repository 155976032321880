import React, { useState, useMemo, useEffect, useContext, useCallback, useRef } from 'react';
import { getDate, addHours, addDays, addWeeks, subWeeks, startOfToday, isToday, isThisWeek, isSameWeek, isPast, format, addSeconds, endOfWeek, areIntervalsOverlapping, addMinutes, subSeconds, endOfDay, isEqual, compareAsc, differenceInMinutes, startOfDay, differenceInSeconds, isSameDay } from 'date-fns';
import clsx from 'clsx';

import { mapById } from '../../utils';

import { watchBlock } from '../../services/DbService/blocks';
import { watchAction } from '../../services/DbService/actions';
import { Event, createEvent } from '../../services/DbService/events';
import { WEEKS_AROUND, ExternalEvent, setCurrentWeek, watchExternalEvents } from "../../services/ExternalEventsService";
import { setEventDuration, setEventStartDate, watchEvents } from '../../services/DbService/events';

import { useCategories } from '../CategoriesContext';
import Dropzone from '../DragAndDrop/Dropzone';
import NowIndicator from './NowIndicator';
import ScheduledEventCard from './ScheduledEventCard';
import ExternalEventCard from './ExternalEventCard';
import NewEvent from './NewEvent';
import Heading, { HEADING_LEVEL_3, HEADING_LEVEL_4 } from './../Heading';

import { ActionDialogContext } from '../ActionDialog';

import styles from './CalendarPanel.module.scss';

// MIN_DURATION is used only at display time to ensure that events have at
// least enough height for one line of text. It's also used to compute
// overlapping events, and this is why it has to be expressed in time and not
// in px/rem
const MIN_DURATION = 30 * 60; // in seconds
const DURATION_INCREMENT = 15; // in minutes
const NEW_EVENT_ID = 'new_event_placeholder';
const NEW_EVENT_DURATION = 60 * 60; // one hour

const DAY_WIDTH = 1 / 7;
const ALL_DAY_EVENT_HEIGHT = 30; // Makes the all day events the equivalent height of a 30 minute event
const MINUTES_IN_DAY = 60 * 24;

// With mouse button pressed, if you move the mouse more than this then it's
// a drag event, otherwise it's a click
const DRAG_DISTANCE_THRESHOLD = 5;
const AUTOSCROLL_AREA_HEIGHT = 25;
const AUTOSCROLL_STEP = 50;
const AUTOSCROLL_INTERVAL = 150;

// These layouts match those in EventCard.module.scss
const BOX_CLASS_INLINE_SMALL = 'inlineSmall';
const BOX_CLASS_INLINE_REGULAR = 'inlineRegular';
const BOX_CLASS_STACKED = 'stacked';

function interval(event) {
  return {
    start: event.startDate,
    end: addSeconds(event.startDate, event.duration),
  }
}

function intersection(a, b) {
  return {
    start: new Date(Math.max(a.start, b.start)),
    end: new Date(Math.min(a.end, b.end)),
  }
}

function roundDate(date, fn = Math.round) {
  let rounded = new Date(date.getTime());
  const minutes =
    fn(rounded.getMinutes() / DURATION_INCREMENT) * DURATION_INCREMENT;
  rounded.setMinutes(minutes, 0);
  // When the rounded date goes to midnight it changes day (midnight is the
  // first second of the day after), better to bring it back to the last second
  // of the same initial day.
  if (!isSameDay(date, rounded)) rounded = endOfDay(date);
  return rounded;
}

function splitDays(event, week) {
  const { id } = event;
  let { start, end } = intersection(interval(event), week);
  start = roundDate(start);
  end = roundDate(end);

  const intervals = [];

  let cursor = start;
  while (!isSameDay(cursor, end)) {
    const endDay = endOfDay(cursor);
    // Skip intervals smaller than DURATION_INCREMENT
    if (differenceInMinutes(endDay, cursor) >= DURATION_INCREMENT) {
      intervals.push({ id, start: cursor, end: endDay });
    }
    cursor = startOfDay(addDays(cursor, 1));
  }
  if (differenceInMinutes(end, cursor) >= DURATION_INCREMENT) {
    intervals.push({ id, start: cursor, end });
  }

  return intervals;
}

function coordsToDate(gridRef, weekStart, clientX, clientY) {
  const elem = gridRef.current;
  const rect = elem.getBoundingClientRect();
  // Convert coords to proportions on the entire width and height of the grid
  let percX = (clientX - rect.left + elem.scrollLeft) / elem.scrollWidth;
  let percY = (clientY - rect.top + elem.scrollTop) / elem.scrollHeight;
  // Important: ensure proportions always stay in the interval [0,1]
  percX = Math.min(Math.max(percX, 0), 1);
  percY = Math.min(Math.max(percY, 0), 1);
  // Use proportions to generate the date
  const day = Math.min(Math.floor(percX * 7), 6);
  let date = weekStart;
  date = addDays(date, day);
  if (percY === 1) {
    // Doing the regular math the date would be moved to the first second of the
    // day after, but it's more robust if y=100% means last second of the day,
    // and not first second of the day after
    date = endOfDay(date);
  } else {
    const minuteInDay = percY * MINUTES_IN_DAY;
    date = addMinutes(date, minuteInDay);
  }
  return date;
}

export function computeBox(start, end, sameTimeCount, sameTimeIndex) {
  const startMinuteInDay = start.getHours() * 60 + start.getMinutes();

  let minutes = differenceInMinutes(end, start);

  // End of the day is set to 23:59:59 so for events that are across multiple
  // days that start for example at 23:00:00, the box would be 59 minutes long
  // and appear as the shorter DURATION_INCREMENT (in this case 45) which breaks
  // the styling for the size of the event. Checking if the end date is equal
  // to the end of the day allows us to add a minute to round it up to 00:00:59
  // so it can be treated as a whole. Note that the seconds are ignored in these
  // calculations so it is safe to add a single minute.
  if (isEqual(end, endOfDay(end))) {
    minutes += 1;
  }

  const width = DAY_WIDTH / sameTimeCount;
  const height = minutes / MINUTES_IN_DAY;
  const left = DAY_WIDTH * start.getDay() + width * sameTimeIndex;
  const top = startMinuteInDay / MINUTES_IN_DAY;

  let className, durationIncrements;

  if (minutes < 30) {
    className = BOX_CLASS_INLINE_SMALL;
  } else if (minutes < 45) {
    className = BOX_CLASS_INLINE_REGULAR;
  } else {
    className = BOX_CLASS_STACKED;

    durationIncrements = Math.min(Math.floor(minutes / DURATION_INCREMENT), 6);
  }

  const css = {
    position: 'absolute',
    width: `${width * 100}%`,
    height: `${height * 100}%`,
    left: `${left * 100}%`,
    top: `${top * 100}%`,
  };
  const narrow = sameTimeCount > 1;

  return { top, css, narrow, className, durationIncrements };
}

function getNewEventTemplate(startDate) {
  return {
    id: NEW_EVENT_ID,
    startDate,
    duration: NEW_EVENT_DURATION,
    creationDate: new Date(),
  };
}

function replaceEventInList(events, replacingEvent) {
  return events.map(event =>
    event.id === replacingEvent.id ? replacingEvent : event);
}

// Return the distance between two points
function distance(x1, y1, x2, y2) {
  const distanceX = Math.abs(x1 - x2);
  const distanceY = Math.abs(y1 - y2);
  return Math.hypot(distanceX, distanceY);
}

// Adds a quick global click listener that suppresses the next click.
// Used to avoid triggering a click event when the mouse is released after an
// event drag or resize.
// This function is needed only by Firefox. In Chrome and Safari the
// setPointerCapture called on the events area does the job already (as it should)
function suppressClick() {
  const handler = e => e.stopPropagation();
  // Add the listner in the capture phase, so it's the first to be called
  window.addEventListener('click', handler, true);
  // Remove it quickly, just the time to avoid that a pointerUp becomes a click
  setTimeout(() => window.removeEventListener('click', handler, true), 50);
}

// Combines multiple watchers together, one for each id in the ids array.
// When one of the watchers receives a value, setState is called with a new
// map object in the shape { entityId: entity }, containing all the entities
// watched. Returns an unsubscribe function that releases all the watchers installed.
function watchMultipleEntities(ids, watcherFn, setState) {
  const unsubscribers = [];
  for (const entityId of ids) {
    const unsubscriber = watcherFn(entityId, newEntity => {
      setState(oldState => ({ ...oldState, [entityId]: newEntity }));
    });
    unsubscribers.push(unsubscriber);
  }
  return () => unsubscribers.forEach(unsubscriber => unsubscriber());
}

export default function WeekView({
  weekStart,
  createEventClicked,
  setCreateEventClicked,
}) {
  const { captureListExpanded, newActionEvent } = useContext(ActionDialogContext);

  const weekEnd = useMemo(() => endOfWeek(weekStart), [weekStart]);
  const week = useMemo(() =>
    ({ start: weekStart, end: weekEnd }), [weekStart, weekEnd]);

  const scrollableRef = useRef();
  const gridRef = useRef();

  const [autoScroll, setAutoScroll] = useState(null);
  const [openEventDialog, setOpenEventDialog] = useState(null);

  // Keep newEvent always filled with a valid value so the "new event dialog"
  // can be preloaded and kept in memory even if newEvent is not visible
  const [newEvent, setNewEvent] = useState(null);
  // This holds the temporary duration change while resizing events
  const [resizedEvent, setResizedEvent] = useState(null);
  // This is the event placeholder moved around during the dragging
  const [draggedEvent, setDraggedEvent] = useState(null);
  // These refs are used in an unusual way, like mutable state objects.
  // This because these states are changed at very high frequency during the
  // pointerMove event, and since the pointerMove handler is dependant from
  // them, using regular states React would have to detach/reattach the handler
  // at every state change, affecting performance and UX. Mutable states solve
  // the issue.
  // More info on the technique:
  // https://reactjs.org/docs/hooks-faq.html#is-there-something-like-instance-variables
  const draggedEventRef = useRef();
  const resizedEventRef = useRef();
  const pointerRef = useRef();

  // Array of the hours in the day: ['12 AM', '1 AM', '2 AM', ...]
  const hours = useMemo(() => {
    const res = [];
    let curTime = startOfToday();
    do {
      res.push(format(curTime, 'h a'));
      curTime = addHours(curTime, 1);
    } while (isToday(curTime));
    return res;
  }, []);

  // Array with the days of the current week and metadata and a percentage
  // width for the size of the past day hatching
  const { days, pastDaysWidth } = useMemo(() => {
    const days = [];
    let curDay = weekStart;
    let pastDays = 0;

    do {
      const isPastDay = isPast(curDay) && !isToday(curDay);
      if (isPastDay) pastDays++;
      days.push({
        day: getDate(curDay),
        shortName: format(curDay, 'EEE'),
        isToday: isToday(curDay),
        isPast: isPastDay,
      });
      curDay = addDays(curDay, 1);
    } while (isSameWeek(curDay, weekStart));

    return {
      days,
      pastDaysWidth: pastDays / days.length * 100,
    };
  }, [weekStart]);

  // Prefetch events from previous/following weeks to speed up UI
  const [eventsBuffer, setEventsBuffer] = useState([]);
  useEffect(() => {
    const startDate = subWeeks(weekStart, WEEKS_AROUND);
    const endDate = addWeeks(weekEnd, WEEKS_AROUND);
    return watchEvents(startDate, endDate, setEventsBuffer);
  }, [weekStart, weekEnd]);

  const [externalEventsBuffer, setExternalEventsBuffer] = useState([]);
  useEffect(() => watchExternalEvents(setExternalEventsBuffer), []);
  useEffect(() => setCurrentWeek(weekStart), [weekStart]);

  // Fetch all entities related to the events in the buffer and keep them cached
  // in order to make the events' rendering instantaneous

  const { activeCategories, hiddenCategories } = useCategories();

  // Fetch and cache categories in a map like { categoryId: category }
  const categoriesById = useMemo(() => {
    if (hiddenCategories === null || activeCategories === null) return {};

    return {
      ...mapById(activeCategories),
      ...mapById(hiddenCategories),
    }
  }, [activeCategories, hiddenCategories]);

  // Fetch and cache blocks in a map like { blockId: block }
  const [blocksById, setBlocksById] = useState({});
  useEffect(() => {
    const blocksIds = new Set();
    for (const event of eventsBuffer) {
      blocksIds.add(event.blockId);
    }
    return watchMultipleEntities(blocksIds, watchBlock, setBlocksById);
  }, [eventsBuffer]);

  // Fetch and cache actions in a map like { actionId: action }
  const [actionsById, setActionsById] = useState({});
  useEffect(() => {
    const actionsIds = new Set();
    for (const event of eventsBuffer) {
      for (const actionId of event.actionsIds) {
        actionsIds.add(actionId);
      }
    }
    return watchMultipleEntities(actionsIds, watchAction, setActionsById);
  }, [eventsBuffer]);

  // Memoize events' actions in a map like { eventId: [action1, ...] }
  const eventsActionsById = useMemo(() => {
    const map = {};
    for (const event of eventsBuffer) {
      const actions = [];
      for (const actionId of event.actionsIds) {
        // Skip actions not present in the map because not loaded yet
        if (!actionsById[actionId]) continue;
        actions.push(actionsById[actionId]);
      }
      map[event.id] = actions;
    }
    return map;
  }, [eventsBuffer, actionsById]);

  // Extract the events to be rendered in this week
  const [events, allDayEvents] = useMemo(() => {
    // Pick only the events of the current week
    const weekEvents = eventsBuffer.filter(event =>
      areIntervalsOverlapping(interval(event), week));

      // Add the newEvent template object
    if (newEvent) weekEvents.push(newEvent);

    // If there is a resized event, apply its new duration
    if (resizedEvent) {
      const i = weekEvents.findIndex(event => event.id === resizedEvent.id);
      if (i > -1) weekEvents[i].duration = resizedEvent.duration;
    }

    // Add events from external calendars
    const externalEvents = externalEventsBuffer.filter(event =>
      areIntervalsOverlapping(interval(event), week));
    weekEvents.push(...externalEvents);

    // Sort events by startDate (with fallback on creationDate) for better
    // accessibility (listed in DOM in ascending order) and to make the overlap
    // algorithm below work
    weekEvents.sort((a, b) => {
      const comparison = compareAsc(a.startDate, b.startDate);
      if (comparison !== 0) return comparison;
      return compareAsc(a.creationDate, b.creationDate);
    });

    // Separate all-day events from timed ones
    const allDayEvents = [];
    const events = [];
    for (const event of weekEvents) {
      // TODO: add support for all-day internal events
      if (event instanceof ExternalEvent && event.duration >= MINUTES_IN_DAY * 60) {
        // Here we copy the behavior of Google Calendar, which renders as all-day
        // each event lasting more than 24 hours, even if for example it starts
        // at 23:50 of day 1 and ends at 7:30 of day 3.
        // Note that Google Calendar displays OOO (Out of Office) slightly differently
        // – it wraps the events even when they are longer than 24h in some instances,
        // but as Kloudless doesn't distinguish the two, we treat that event as a
        // multi day event all the same.
        allDayEvents.push(event);
      } else {
        events.push(event);
      }
    }
    return [events, allDayEvents];
  }, [week, eventsBuffer, externalEventsBuffer, newEvent, resizedEvent]);

  // Complexity: O(n), with n being the number of all-day events
  const [allDayEventsBoxes, allDayEventsRows] = useMemo(() => {
    // filledSlots[day][row] = true if that "slot" is already occupied
    const filledSlots = [];
    // initialize filledSlots
    for (let i = 0; i < 7; i++) filledSlots[i] = [];
    let usedRows = 0;
    const boxes = {};

    // Greedy algorithm to place all-day events.
    // NOTE: allDayEvents MUST be sorted in order to make this algorithm work.
    for (const event of allDayEvents) {
      // Events can start/end outside the week, so pick the intersection
      let { start, end } = intersection(interval(event), week);
      // All-day events often end at midnight, that is the first second of the
      // following day, but for this algorithm it's better to consider the last
      // second of the day before instead.
      end = roundDate(end);
      if (isEqual(end, startOfDay(end))) end.setSeconds(-1);

      const firstDay = start.getDay();
      const days = end.getDay() - firstDay + 1;

      // Find the first available row where this event can be placed
      let row = 0;
      while (filledSlots[firstDay][row] === true) row++;

      // Now that we found the row, mark it as filled in filledSlots
      for (let day = firstDay; day < firstDay + days; day++) {
        filledSlots[day][row] = true;
      }

      usedRows = Math.max(usedRows, row + 1);

      const left = DAY_WIDTH * firstDay;
      const top = row * ALL_DAY_EVENT_HEIGHT;
      const width = DAY_WIDTH * days;
      const height = ALL_DAY_EVENT_HEIGHT;

      boxes[event.id] = [{
        css: {
          position: 'absolute',
          left: `${left * 100}%`,
          top: `${top}rem`,
          width: `${width * 100}%`,
          height: `${height}rem`,
        },
        className: BOX_CLASS_INLINE_REGULAR,
      }];
    }
    return [boxes, usedRows];
  }, [week, allDayEvents]);

  // Complexity: O(n) with n being the number of timed events
  const eventsBoxes = useMemo(() => {
    // First split all the events in intervals starting and ending in the same day
    const intervals = [];
    for (let i = 0; i < events.length; i++) {
      intervals.push(...splitDays(events[i], week));
    }

    // Sort the intervals, it's mandatory to make the algorithm below work
    intervals.sort((a, b) => compareAsc(a.start, b.start));

    // Divide the entire week by time slots (one for each DURATION_INCREMENT),
    // and use them to place events. Each time slot contains one or more columns
    // to accomodate simultaneous events.
    const slots = [];
    // All the slots begin as an empty list of columns
    for (let i = 0; i < 7 * MINUTES_IN_DAY / DURATION_INCREMENT; i++) slots[i] = [];

    for (let i = 0; i < intervals.length; i++) {
      const interval = intervals[i];
      const startSlot = differenceInMinutes(interval.start, week.start) / DURATION_INCREMENT;
      const endSlot = differenceInMinutes(interval.end, week.start) / DURATION_INCREMENT;

      // Find the first available column where this interval can be placed
      let col = 0;
      while (slots[startSlot][col] !== undefined) col++;

      // Now that we have found the column mark it as filled in each slot
      // interested, storing a reference to this interval
      for (let i = startSlot; i < endSlot; i++) {
        slots[i][col] = interval;
      }

      // Store in the interval the index of the column
      interval.sameTimeIndex = col;
    }

    // We have found the sameTimeIndex for each interval, now we have to
    // compute the sameTimeCount
    let prevSlot = new Set();
    let cols = 0;
    // Loop through all the slots
    for (const slot of slots) {
      let connectedToPrevSlot = false;
      for (const interval of slot) {
        if (prevSlot.has(interval)) {
          connectedToPrevSlot = true;
          break;
        }
      }
      if (connectedToPrevSlot === false) {
        // This slot has no events overlapping the previous slot, so it divides
        // two independent groups of intervals. The maximum number of columns
        // we found in the previous group is the sameTimeCount we were looking for
        for (const interval of prevSlot) {
          interval.sameTimeCount = cols;
        }
        // Start a new group of intervals
        prevSlot = new Set();
        cols = 0;
      }
      // Incrementally measure the max number of columns
      cols = Math.max(cols, slot.length);
      // Add these intervals to the group
      for (const interval of slot) {
        if (!interval) continue; // Some columns are empty, skip them
        prevSlot.add(interval);
      }
    }
    // Write sameTimeCount for the very last group of intervals
    for (const interval of prevSlot) {
      interval.sameTimeCount = cols;
    }

    // Generate the boxes data and return it
    const boxes = {};
    for (const { id, start, end, sameTimeCount, sameTimeIndex } of intervals) {
      if (!boxes[id]) boxes[id] = [];
      boxes[id].push(computeBox(start, end, sameTimeCount, sameTimeIndex));
    }
    return boxes;
  }, [week, events]);

  const draggedEventBoxes = useMemo(() => {
    if (!draggedEvent) return null;
    const intervals = splitDays(draggedEvent, week);
    return intervals.map(
      ({ start, end }) =>  computeBox(start, end, 1, 0));
  }, [week, draggedEvent]);

  const coordsToEventStart = useCallback((clientX, clientY) => {
    // Using Math.floor() for the rounding ensures that the newEvent always
    // contains the point clicked, giving a better UX. The normal Math.round()
    // instead would sometimes place newEvent slightly after the point clicked.
    let clickedDate =
      roundDate(coordsToDate(gridRef, weekStart, clientX, clientY), Math.floor);
    const maxStartDate = subSeconds(endOfDay(clickedDate), NEW_EVENT_DURATION);
    if (clickedDate > maxStartDate) clickedDate = maxStartDate;

    return clickedDate;
  }, [weekStart]);

  const handleGridClick = useCallback(e => {
    const clickedDate = coordsToEventStart(e.clientX, e.clientY);
    setNewEvent(getNewEventTemplate(clickedDate));
  }, [coordsToEventStart]);

  useEffect(() => {
    if (!createEventClicked) return;

    const newStartDate = roundDate(new Date(), Math.ceil);
    setNewEvent(getNewEventTemplate(newStartDate));
  }, [createEventClicked]);

  useEffect(() => {
    if (newEvent) return;

    setCreateEventClicked(false);
  }, [newEvent, setCreateEventClicked]);

  const handleNewEventClose = useCallback(() => setNewEvent(null), []);

  useEffect(() => {
    if (newActionEvent === null) {
      handleNewEventClose();
    }
  }, [newActionEvent, handleNewEventClose]);

  const handleEventDrag = useCallback(() => {
    const { clientX, clientY } = pointerRef.current;
    const { initialStartDate, dragStartDate, event } = draggedEventRef.current;

    const curDate = roundDate(coordsToDate(gridRef, weekStart, clientX, clientY));
    const diff = differenceInSeconds(curDate, dragStartDate);
    const newStartDate = addSeconds(initialStartDate, diff);

    // If the new computed date is no different from the old one, don't change
    // state and save some expensive renderings
    if (isEqual(newStartDate, event.startDate)) return;

    // Generate the new dragged event
    const newDraggedEvent = { ...event, startDate: newStartDate };
    // Maintain the original event prototype
    Object.setPrototypeOf(newDraggedEvent, event);
    // Store it in the mutable state (to avoid having React recreating this
    // function too often) and in the regular one, that will render the change
    // in the DOM
    draggedEventRef.current.event = newDraggedEvent;
    setDraggedEvent(newDraggedEvent);
  }, [weekStart]);

  const handleEventResize = useCallback(() => {
    const { clientY } = pointerRef.current;
    const { event, startClientX } = resizedEventRef.current;
    // coordsToDate() is called with startClientX instead of the current clientX
    // because we don't want it to return a different end day for the event
    const curDate = roundDate(coordsToDate(gridRef, weekStart, startClientX, clientY));
    let newDuration = differenceInSeconds(curDate, event.startDate);
    newDuration = Math.max(MIN_DURATION, newDuration);

    // If the new computed duration is no different from the old, one don't
    // change state and save some expensive renderings
    if (newDuration === event.duration) return;

    const resizedEvent = { ...event, duration: newDuration };
    // Maintain the original event prototype
    Object.setPrototypeOf(resizedEvent, event);
    // Store it in the mutable state (to avoid having React recreating this
    // function too often) and in the regular one, that will render the change
    // in the DOM
    resizedEventRef.current.event = resizedEvent;
    setResizedEvent(resizedEvent);
  }, [weekStart]);

  const handlePointerDown = useCallback(e => {
    const id = e.target.closest('[data-event-id]')?.dataset.eventId;
    if (!id) return; // Nothing to do if the click is not on an EventCard

    const event = events.find(event => event.id === id);

    if (e.target.closest('[data-resize-handle]')) {
      // The click happened on a resize handle
      e.currentTarget.setPointerCapture(e.pointerId);
      resizedEventRef.current = {
        startClientX: e.clientX,
        event,
      };
      setResizedEvent(event);

    } else if (e.target.closest('[data-draggable]')) {
      // We have a potential drag initiation
      if (id === NEW_EVENT_ID) return; // Drag is disabled for the new event placeholder
      draggedEventRef.current = {
        startClientX: e.clientX,
        startClientY: e.clientY,
        // We are still not sure if this is a click or a drag, it has to be
        // confirmed in pointerMove
        dragConfirmed: false,
        initialStartDate: event.startDate,
        dragStartDate: roundDate(coordsToDate(gridRef, weekStart, e.clientX, e.clientY)),
        event,
      };
    }
  }, [weekStart, events]);

  const handlePointerMove = useCallback(e => {
    // To make drag/resize operations fluid during the auto-scroll we need
    // to update components coordinates during the scroll event, but inside
    // that event the mouse coordinates are not available. This is why we store
    // them in this mutable state
    pointerRef.current = {
      clientX: e.clientX,
      clientY: e.clientY,
    };

    if (resizedEventRef.current || draggedEventRef.current?.dragConfirmed) {
      // If resizing or dragging check if we are close to the borders and need
      // to start an auto-scroll
      const rect = scrollableRef.current.getBoundingClientRect();
      if (e.clientY < rect.top + AUTOSCROLL_AREA_HEIGHT) {
        setAutoScroll('up');
      } else if (e.clientY > rect.bottom - AUTOSCROLL_AREA_HEIGHT) {
        setAutoScroll('down');
      } else {
        setAutoScroll(null);
      }
    }

    if (resizedEventRef.current) {
      handleEventResize();
    }

    if (draggedEventRef.current) {
      if (!draggedEventRef.current.dragConfirmed) {
        // We are still not sure if the user is clicking or dragging: check
        // if the user has moved the mouse at least DRAG_DISTANCE_THRESHOLD,
        // if yes then we can confirm he has the intent to drag
        const { startClientX, startClientY } = draggedEventRef.current;
        const dist = distance(e.clientX, e.clientY, startClientX, startClientY);
        if (dist > DRAG_DISTANCE_THRESHOLD) {
          e.currentTarget.setPointerCapture(e.pointerId);
          draggedEventRef.current.dragConfirmed = true;
        }
      }

      if (draggedEventRef.current.dragConfirmed) handleEventDrag();
    }
  }, [handleEventResize, handleEventDrag]);

  const handlePointerUp = useCallback(e => {
    if (resizedEventRef.current) {
      const resizedEvent = resizedEventRef.current.event;
      // Apply the resized duration to the original event
      if (resizedEvent.id === NEW_EVENT_ID) {
        setNewEvent(resizedEvent);
      } else {
        setEventDuration(resizedEvent.id, resizedEvent.duration);
        // Apply the change to the events list wihout waiting the server for a
        // more fluid UX
        setEventsBuffer(eventsBuffer => replaceEventInList(eventsBuffer, resizedEvent));
      }
      suppressClick();
    }

    if (draggedEventRef.current?.dragConfirmed) {
      const draggedEvent = draggedEventRef.current.event;
      setEventStartDate(draggedEvent.id, draggedEvent.startDate);
      // Apply the change to the events list wihout waiting the server for a
      // more fluid UX
      setEventsBuffer(eventsBuffer => replaceEventInList(eventsBuffer, draggedEvent));
      suppressClick();
    }

    // Do cleanup, regardless the current state
    e.currentTarget.releasePointerCapture(e.pointerId);
    // Resize states cleanup
    resizedEventRef.current = null;
    setResizedEvent(null);
    // Drag states cleanup
    draggedEventRef.current = null;
    setDraggedEvent(null);
    // Autoscroll cleanup
    setAutoScroll(null);
  }, []);

  const handleEventDialogOpen = useCallback(eventId => setOpenEventDialog(eventId), []);
  const handleEventDialogClose = useCallback(() => setOpenEventDialog(null), []);

  const handleScroll = useCallback(e => {
    // This event is necessary to update the drag/resize during the auto-scroll
    if (resizedEventRef.current) handleEventResize();
    if (draggedEventRef.current?.dragConfirmed) handleEventDrag();
  }, [handleEventResize, handleEventDrag]);

  const handleDropzoneAdd = useCallback((e) => {
    const { type, id, categoryId } = e.item.dataset;

    const blockId = type === 'block' ? id : null;
    const actionsIds = type === 'block' ? [] : [id];

    const duration = NEW_EVENT_DURATION;

    const { clientX, clientY } = e.originalEvent;
    const startDate = coordsToEventStart(clientX, clientY);

    createEvent(
      startDate,
      duration,
      categoryId,
      blockId,
      actionsIds,
    );
  }, [coordsToEventStart]);

  // Manage the auto-scroll
  useEffect(() => {
    if (!scrollableRef.current || !autoScroll) return;
    const interval = setInterval(() => {
      scrollableRef.current.scrollBy({
        top: autoScroll === 'down' ? +AUTOSCROLL_STEP : -AUTOSCROLL_STEP,
        behavior: 'smooth',
      });
    }, AUTOSCROLL_INTERVAL);
    return () => clearInterval(interval);
  }, [autoScroll]);

  return (
    <>
      <div className={styles.daysRow} role='presentation'>
        <div className={styles.leftMargin}/>
        {days.map(({shortName, day, isToday, isPast}) =>
          <div key={day} className={clsx(styles.dayHeader, isToday && styles.today, isPast && styles.past)}>
            <Heading tag="span" level={HEADING_LEVEL_4} className={styles.dayName}>{shortName.toUpperCase()}</Heading>
            <Heading tag="span" level={HEADING_LEVEL_3} className={styles.dayNumber}>{day}</Heading>
          </div>
        )}
        <div className={styles.rightMargin}/>
      </div>
      {allDayEvents.length > 0 &&
        <div
          className={styles.allDayEvents}
          style={{
            height: `${ALL_DAY_EVENT_HEIGHT * allDayEventsRows}rem`
          }}
        >
          <div className={styles.leftMargin}/>
          <div className={styles.grid}>
            <div className={styles.gridPast} style={{ width: `${pastDaysWidth}%` }} />
            {days.map(day =>
              <div key={day.day} className={styles.column} />
            )}
            {allDayEvents.map(event =>
              <ExternalEventCard
                key={event.id}
                allDay
                event={event}
                boxes={allDayEventsBoxes[event.id]}
                onOpen={handleEventDialogOpen}
                onClose={handleEventDialogClose}
                open={openEventDialog === event.id}
              />
            )}
          </div>
          <div className={styles.rightMargin}/>
        </div>
      }
      <div
        ref={scrollableRef}
        className={styles.scrollable}
        onScroll={handleScroll}
      >
        <div className={styles.scrollableContent}>
          <div className={styles.hours} role='presentation'>
            {hours.map(hour =>
              <div key={hour}>{hour}</div>
            )}
          </div>
          <div
            className={clsx(
              styles.eventsArea,
              draggedEvent && styles.dragging,
              resizedEvent && styles.resizing,
            )}
            onPointerDown={handlePointerDown}
            onPointerMove={handlePointerMove}
            onPointerUp={handlePointerUp}
          >
            {/* This "eventsArea" is a uniform rectangle representing
                the entire timespan of the week. This makes it easy to
                position events in time just using percentages, since
                (0%, 0%) = start of the week, and (100%, 100%) = end
                of the week. */}
            <div
              ref={gridRef}
              className={styles.grid}
              onClick={handleGridClick}
            >
              <div className={styles.gridPast} style={{ width: `${pastDaysWidth}%` }} />
              {days.map(day =>
                <div key={day.day} className={styles.column} />
              )}
            </div>
            {events.map(event => <React.Fragment key={event.id}>
              {/* The new event placeholder is part of this list, but having no
                  type it's skipped by this loop. */}
              {event instanceof Event &&
                <ScheduledEventCard
                  event={event}
                  category={categoriesById[event.categoryId]}
                  block={blocksById[event.blockId]}
                  actions={eventsActionsById[event.id]}
                  dragging={event.id === draggedEvent?.id}
                  boxes={eventsBoxes[event.id]}
                  onOpen={handleEventDialogOpen}
                  onClose={handleEventDialogClose}
                  open={openEventDialog === event.id}
                />
              }
              {event instanceof ExternalEvent &&
                <ExternalEventCard
                  event={event}
                  boxes={eventsBoxes[event.id]}
                  onOpen={handleEventDialogOpen}
                  onClose={handleEventDialogClose}
                  open={openEventDialog === event.id}
                />
              }
            </React.Fragment>)}
            {draggedEvent &&
              <ScheduledEventCard
                event={draggedEvent}
                category={categoriesById[draggedEvent.categoryId]}
                block={blocksById[draggedEvent.blockId]}
                actions={eventsActionsById[draggedEvent.id]}
                boxes={draggedEventBoxes}
              />
            }
            <NewEvent
              event={newEvent}
              boxes={eventsBoxes[NEW_EVENT_ID]}
              onClose={handleNewEventClose}
            />
            <NowIndicator visible={isThisWeek(weekStart)}/>
          </div>
        </div>
      </div>
      <Dropzone
        className={clsx(styles.dropzone, captureListExpanded && styles.dropzoneCollapsed)}
        allowDrop={['actions', 'blocks']}
        onAdd={handleDropzoneAdd}
        clone
      />
    </>
  );
}
