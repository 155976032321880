import React, { forwardRef } from 'react';
import clsx from 'clsx';

import { CATEGORY_COLORS } from '../../services/DbService/constants';
import { composeCategoryColors } from '../../services/DbService/categories';

import useUniqueId from '../../hooks/useUniqueId';

import Tooltip from '../Tooltip';

import { ReactComponent as IconCheck } from './../../assets/icons/16-check.svg';

import styles from './CategoryRadioGroupColor.module.scss';

const CategoryRadioGroupColor = forwardRef(({
  className,
  color,
  setColor,
  ...props
}, ref) => {
  const colorName = useUniqueId();

  return (
    <div className={clsx(className, styles.colors)} role="radiogroup" {...props}>
      {Object.keys(CATEGORY_COLORS).map(colorSlug => {
        const checked = color === colorSlug;
        const label = CATEGORY_COLORS[colorSlug]?.label;

        return (
          <Tooltip title={label} key={colorSlug}>
            <div className={styles.color} style={composeCategoryColors(colorSlug)}>
              <input
                type="radio"
                name={colorName}
                value={colorSlug}
                checked={checked}
                onChange={e => setColor(e.target.value)}
                aria-label={label}
              />
              <span className={styles.focusRing} aria-hidden="true" />
              <IconCheck role="presentation" className={clsx(checked && styles.active)} />
            </div>
          </Tooltip>
        )
      })}
    </div>
  );
});

CategoryRadioGroupColor.displayName = 'CategoryRadioGroupColor';

export default CategoryRadioGroupColor;
