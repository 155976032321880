import firebase from 'firebase/app';
import isUUID from 'validator/es/lib/isUUID';
import { mapById, replaceMentions } from '../../utils';
import FirestoreRollingBatch from './FirestoreRollingBatch';
import { STARRED, UNSTARRED, ACTIVE, COMPLETED, SNOOZED, ACTION_DESCRIPTION_MAX_LENGTH } from './constants';
import { firestore, collection, document, createOrderedIdsPath, composeUnsubscribers, actionParentDocument, getSortedDocuments, ParameterError, generateId, fieldArrayPush, fieldArrayRemove, CacheDelayer } from './general';
import { tickActionsCompleted,  tickActionsCompletedStarred, tickActionsCompletedLeveraged } from './user';
import { deleteEvent, createEventHelper, Event } from './events';
import { trackEvent } from '../AnalyticsService';

export class Action {
  static fromFirestore(snapshot, options) {
    const data = snapshot.data(options);
    if (!data) return undefined;
    return Object.assign(new Action(), {
      id: snapshot.id,
      categoryId: data.parent_category_id,
      blockId: data.parent_block_id,
      description: data.description,
      time: data.time,
      starred: data.is_starred,
      unnecessary: data.is_unnecessary,
      state: data.state,
      mentionsPeopleIds: data.mentions,
      leveragedPersonId: data.leveraged_to,
      fromEmail: data.is_email_captured,
      emailBody: data.email_body,
      completionDate:
        data.date_of_completion && new Date(data.date_of_completion),
      starringDate:
        data.date_of_starring && new Date(data.date_of_starring),
    });
  }
}

export async function getAction(actionId) {
  const docSnapshot = await document(`actions/${actionId}`).get();
  return Action.fromFirestore(docSnapshot);
}

export function watchAction(actionId, callback) {
  return document(`actions/${actionId}`).onSnapshot(snapshot => {
    callback(Action.fromFirestore(snapshot));
  });
}

/**
 * Watches all the actions in a category or in a block in a specific state.
 * Important: when fetching completed actions in categories use the limit param
 * to paginate the results, they could be several thousands.
 * The limit parameter is ignored when watching non-completed actions.
 */
export function watchActions(categoryId, blockId, state, limit, callback) {
  if (state === COMPLETED && blockId === null && !limit)
    // Completed actions in categories can be many thousands, pagination is required
    throw new ParameterError({limit}, 'must be > 0 when querying completed actions in categories');

  let actionsQuery = collection('actions')
    .where('parent_category_id' , '==', categoryId)
    .where('parent_block_id' , '==', blockId || null)
    .where('state', '==', state)

  if (state === COMPLETED) {

    actionsQuery = actionsQuery
      .orderBy('is_starred', 'desc')
      .orderBy('date_of_completion', 'desc');

    if (limit) {
      actionsQuery = actionsQuery
        // The extra action is used only to peek above the limit to see if there
        // are more actions
        .limit(limit + 1);
    }

    const cacheDelayer = new CacheDelayer();
    const unsubscribe = actionsQuery.onSnapshot(snapshot => {
      const actions = {
        [STARRED]: [],
        [UNSTARRED]: []
      };
      let hasMore = false;
      snapshot.docs.forEach((docSnapshot, i) => {
        if (limit && i === limit) {
          hasMore = true;
          // Skip this extra action that we used only to peek above the limit
          return;
        }
        const action = Action.fromFirestore(docSnapshot);
        actions[action.starred ? STARRED : UNSTARRED].push(action);
      });

      cacheDelayer.delayIfCached(() => callback(actions, hasMore), snapshot);
    });
    return () => {
      unsubscribe();
      cacheDelayer.dispose();
    };

  } else {

    let actionsById, orders;

    function handleChange() {
      if (actionsById === undefined || orders === undefined) return;
      const orderedActions = {};

      [STARRED, UNSTARRED].forEach(starredStatus => {
        const order = orders[state]?.[starredStatus]?.ordered_ids || [];
        orderedActions[starredStatus] =
          getSortedDocuments(actionsById, order);
      });

      callback(orderedActions, false);
    }

    return composeUnsubscribers(
      actionParentDocument(categoryId, blockId)
        .onSnapshot(snapshot => {
          orders = snapshot.get('actions_lists') || {};
          handleChange();
        }),

      actionsQuery
        .onSnapshot(snapshot => {
          const actions = snapshot.docs.map(Action.fromFirestore);
          actionsById = mapById(actions);
          handleChange();
        }),
    );

  }
}

export function watchActionsById(actionsIds, callback) {
  if (actionsIds.length === 0) return;

  // A temporary fix to ensure that this doesn't fail if 10+ actions have been scheduled
  if (actionsIds.length > 10) {
    actionsIds = actionsIds.slice(0, 10);
  }

  return collection('actions')
    .where('id', 'in', actionsIds)
    .onSnapshot(snapshot => {
      callback(snapshot.docs.map(Action.fromFirestore));
    });
}

export function createAction(description, categoryId, blockId, eventStartDate, eventDuration) {
  if (!isUUID(categoryId))
    throw new ParameterError({categoryId}, 'not a valid UUID');
  if (blockId !== null && !isUUID(blockId))
    throw new ParameterError({blockId}, 'must be "null" or a valid UUID');

  if (typeof description !== 'string')
    throw new ParameterError({description}, 'not a string');
  const descriptionTrim = description.trim();
  if (descriptionTrim === '')
    throw new ParameterError({description}, 'empty after trim');

  const mentions = new Set(); // Set gets rid of duplicates automatically
  const descriptionWithoutMentions = replaceMentions(descriptionTrim, personId => {
    mentions.add(personId);
    return '';
  });
  if (descriptionWithoutMentions.length > ACTION_DESCRIPTION_MAX_LENGTH)
    throw new ParameterError({description}, `length without mentions must be <= ${ACTION_DESCRIPTION_MAX_LENGTH}`);

  if (eventStartDate && eventDuration === null)
    throw new ParameterError({eventDuration}, 'must not be "null" when there is an eventStartDate');
  if (eventDuration && eventStartDate === null)
    throw new ParameterError({eventStartDate}, 'must not be "null" when there is an eventDuration');

  const batch = firestore().batch();

  const actionId = generateId();

  const newAction = {
    id: actionId,
    description: descriptionTrim,
    time: null,
    is_starred: false,
    is_unnecessary: false,
    parent_category_id: categoryId,
    parent_block_id: blockId,
    date_of_completion: null,
    date_of_starring: null,
    state: ACTIVE,
    snoozed_to: null,
    mentions: [...mentions],
  };

  batch.set(document(`actions/${actionId}`), newAction);
  batch.update(actionParentDocument(categoryId, blockId), {
    [`actions_lists.${ACTIVE}.${UNSTARRED}.ordered_ids`]: fieldArrayPush(actionId),
  });

  let newEvent;
  if (eventStartDate) {
    newEvent = createEventHelper(batch, eventStartDate, eventDuration, categoryId, blockId, [actionId]);
  }

  batch.commit();

  let trackingEventName = 'User created an Action';
  const trackingProperties = {
    id: actionId,
    categoryId,
    blockId,
    descriptionLength: descriptionWithoutMentions.length,
    mentionsCount: mentions.length,
  };

  if (newEvent) {
    trackingEventName += ' with an Event';
    trackingProperties.eventId = newEvent.id;
  }

  trackEvent(trackingEventName, trackingProperties);
}

export async function actionHasEvents(actionId) {
  const eventsSnapshot = await collection('events')
    .where('actions_ids', 'array-contains', actionId)
    .limit(1)
    .get();

  return eventsSnapshot.size > 0;
}

export async function deleteAction(actionId) {
  const batch = firestore().batch();
  const action = await getAction(actionId);
  await deleteActionHelper(batch, action);
  batch.commit();
}

export async function deleteActionHelper(batch, action, updateEvents = true) {
  const {
    id: actionId,
    categoryId,
    blockId,
    leveragedPersonId,
    starred,
    state,
  } = action;

  batch.delete(document(`actions/${actionId}`));
  batch.update(actionParentDocument(categoryId, blockId), {
    [createOrderedIdsPath(state, starred)]: fieldArrayRemove(actionId),
  });

  if (state === COMPLETED) {
    tickActionsCompleted(batch, -1);
    if (starred) tickActionsCompletedStarred(batch, -1);
    if (leveragedPersonId) tickActionsCompletedLeveraged(batch, -1);
  }

  if (updateEvents) {
    const eventsSnap = await collection('events')
      .where('actions_ids', 'array-contains', actionId)
      .get();
    eventsSnap.docs.forEach(eventSnap => {
      const { id: eventId, actionsIds } = Event.fromFirestore(eventSnap);
      if (actionsIds.length > 1) {
        // Remove this action from the event
        batch.update(eventSnap.ref, {
          actions_ids: actionsIds.filter(id => id !== actionId),
        });
      } else {
        // There is only one action in this event, and it's this one we are
        // deleting: delete the event too.
        deleteEvent(eventId, batch);
      }
    });
  }
}

export function setActionDescription(id, newDescription) {
  if (typeof newDescription !== 'string')
    throw new ParameterError({newDescription}, 'not a string');
  const descriptionTrim = newDescription.trim();
  if (descriptionTrim === '')
    throw new ParameterError({newDescription}, 'empty after trim');

  const mentions = new Set(); // Set gets rid of duplicates automatically
  const descriptionWithoutMentions = replaceMentions(descriptionTrim, personId => {
    mentions.add(personId);
    return '';
  });
  if (descriptionWithoutMentions.length > ACTION_DESCRIPTION_MAX_LENGTH)
    throw new ParameterError({newDescription}, `length without mentions must be <= ${ACTION_DESCRIPTION_MAX_LENGTH}`);

  document(`actions/${id}`).update({
    description: descriptionTrim,
    mentions: [...mentions],
  });
}

export function setActionTime(id, newTime) {
  if (newTime !== null && typeof newTime !== 'number')
    throw new ParameterError({newTime}, 'can be only null or a number');

  document(`actions/${id}`).update({ time: newTime });
}

export async function setActionStarred(actionId, newStarred) {
  if (typeof newStarred !== 'boolean')
    throw new ParameterError({newStarred}, 'must be a boolean');

  const {
    categoryId,
    blockId,
    starred: oldStarred,
    state,
  } = await getAction(actionId);

  if (oldStarred === newStarred) return;

  const batch = firestore().batch();

  batch.update(document(`actions/${actionId}`), {
    is_starred: newStarred,
    date_of_starring: newStarred ? new Date().toISOString() : null,
  });

  if (state === COMPLETED) {
    tickActionsCompletedStarred(batch, newStarred ? +1 : -1);
  }

  const parentDocument = actionParentDocument(categoryId, blockId);

  batch.update(parentDocument, {
    [createOrderedIdsPath(state, oldStarred)]: firestore.FieldValue.arrayRemove(actionId),
  });

  // Manual ordering is ignored for COMPLETED states so no change is required to
  // the ordered_ids array
  if (state !== COMPLETED) {
    // Actions being starred are appended to the bottom so don't need the extra look up to get
    // the current list.
    if (newStarred === true) {
      batch.update(parentDocument, {
        [createOrderedIdsPath(state, newStarred)]: firestore.FieldValue.arrayUnion(actionId),
      });
    }

    // Actions being unstarred are removed from their parent, but then added to the top
    // of the list. Firestore doesn't have an inbuilt FieldValue function for prepending
    // an array, so the existing UNSTARRED ordered_ids must be fetched, the new value prepended,
    // and then updated as an array.
    else {
      const parentDocumentSnapshot = await parentDocument.get();

      if (!parentDocumentSnapshot?.exists) return;

      const parentDocumentData = parentDocumentSnapshot.data();

      const newOrderedIds = [
        actionId,
        ...parentDocumentData?.actions_lists?.[state]?.[UNSTARRED]?.ordered_ids || [],
      ];

      batch.update(parentDocument, {
        // A Set is used here to remove any chance of duplicates (however unlikely) to ensure
        // that the lists aren't compromised for any reason.
        [createOrderedIdsPath(state, newStarred)]: [...new Set(newOrderedIds)],
      });
    }
  }

  batch.commit();
}

export function setActionStateHelper(batch, action, newState, newUnnecessary) {
  if (newState !== ACTIVE && newState !== SNOOZED && newState !== COMPLETED)
    throw new ParameterError({newState}, `must be "${ACTIVE}", "${SNOOZED}" or "${COMPLETED}"`);
  if (typeof newUnnecessary !== 'boolean')
    throw new ParameterError({newUnnecessary}, 'must be a boolean');
  if (newState !== COMPLETED && newUnnecessary === true)
    throw new ParameterError({newUnnecessary}, `can't be true if newState is not COMPLETED`);

  const {
    id: actionId,
    categoryId,
    blockId,
    state: oldState,
    starred,
    leveragedPersonId,
  } = action;

  batch.update(document(`actions/${actionId}`), {
    is_unnecessary: newUnnecessary,
  });

  // This check is necessary in case someone calls this function only to change
  // from "completed - necessary" to "completed - unnecessary", or vice versa.
  if (oldState !== newState) {
    batch.update(document(`actions/${actionId}`), {
      state: newState,
      date_of_completion: newState === COMPLETED ? new Date().toISOString() : null,
    });

    if (oldState === COMPLETED || newState === COMPLETED) {
      // If action is being set to COMPLETED then counters must be incremented,
      // otherwise the action is losing the COMPLETED state and they have to be
      // decremented
      const increment = newState === COMPLETED ? +1 : -1;
      tickActionsCompleted(batch, increment);
      if (starred) tickActionsCompletedStarred(batch, increment)
      if (leveragedPersonId) tickActionsCompletedLeveraged(batch, increment);
    }

    batch.update(actionParentDocument(categoryId, blockId), {
      [createOrderedIdsPath(oldState, starred)]: fieldArrayRemove(actionId),
      [createOrderedIdsPath(newState, starred)]: fieldArrayPush(actionId),
    });
  }
}

export async function setActionState(actionId, newState, newUnnecessary = false) {
  const batch = firestore().batch();
  const action = await getAction(actionId);
  setActionStateHelper(batch, action, newState, newUnnecessary);
  batch.commit();
}

export async function setActionLeveraged(actionId, newPersonId) {
  const { state, leveragedPersonId: oldPersonId } = await getAction(actionId);

  const batch = firestore().batch();

  batch.update(document(`actions/${actionId}`), {
    leveraged_to: newPersonId,
  });

  const oldLeveraged = oldPersonId !== null;
  const newLeveraged = newPersonId !== null;
  if (state === COMPLETED && oldLeveraged !== newLeveraged) {
    // The action is completed and its transitioning from non-leveraged to
    // leveraged, or viceversa: update the counters
    tickActionsCompletedLeveraged(batch, newLeveraged ? +1 : -1);
  }

  batch.commit();
}

export async function setActionList(actionId, newCategoryId, newBlockId, newState, newStarred, newOrderedActionsIds = null) {
  if (newState !== ACTIVE && newState !== SNOOZED && newState !== COMPLETED)
    throw new ParameterError({newState}, `must be "${ACTIVE}", "${SNOOZED}" or "${COMPLETED}"`);

  if (typeof newStarred !== 'boolean')
    throw new ParameterError({newStarred}, 'not boolean');

  if (newOrderedActionsIds !== null && !Array.isArray(newOrderedActionsIds))
    throw new ParameterError({newOrderedActionsIds}, 'not an array');

  const actionDocument = document(`actions/${actionId}`);
  // Using cache to increase performances (noticeable when drag & drop in UI)
  const action = await actionDocument.get({source: 'cache'});
  if (!action.exists)
    throw new ParameterError({actionId}, 'action does not exist');

  const {
    categoryId: oldCategoryId,
    blockId: oldBlockId,
    starred: oldStarred,
    state: oldState,
    leveragedPersonId,
  } = Action.fromFirestore(action);

  const batch = new FirestoreRollingBatch();

  batch.update(actionDocument, {
    parent_category_id: newCategoryId,
    parent_block_id: newBlockId,
  });

  const now = new Date().toISOString();

  if (newState !== oldState) {
    batch.update(actionDocument, {
      state: newState,
      date_of_completion: newState === COMPLETED ? now : null,
    });

    if (newState === COMPLETED) {
      // The action is transitioning to the completed state
      tickActionsCompleted(batch, +1);
      if (leveragedPersonId) tickActionsCompletedLeveraged(batch, +1);

    } else if (oldState === COMPLETED) {
      // The action is transitioning from completed to non-completed
      tickActionsCompleted(batch, -1);
      if (leveragedPersonId) tickActionsCompletedLeveraged(batch, -1);
    }
  }

  if (newState !== COMPLETED) {
    batch.update(actionDocument, {
      is_unnecessary: false,
    });
  }

  if (newStarred !== oldStarred) {
    batch.update(actionDocument, {
      is_starred: newStarred,
      date_of_starring: newStarred ? now : null,
    });
  }

  const oldCompletedAndStarred = oldState === COMPLETED && oldStarred === true;
  const newCompletedAndStarred = newState === COMPLETED && newStarred === true;
  if (oldCompletedAndStarred !== newCompletedAndStarred) {
    tickActionsCompletedStarred(batch, newCompletedAndStarred ? +1 : -1);
  }

  batch.update(actionParentDocument(oldCategoryId, oldBlockId), {
    [createOrderedIdsPath(oldState, oldStarred)]: fieldArrayRemove(actionId),
  });

  batch.update(actionParentDocument(newCategoryId, newBlockId), {
    [createOrderedIdsPath(newState, newStarred)]: newOrderedActionsIds || fieldArrayPush(actionId),
  });

  if (oldCategoryId !== newCategoryId || oldBlockId !== newBlockId) {
    // The action changed parent, update linked events
    const eventsSnap = await collection('events')
      .where('actions_ids', 'array-contains', actionId)
      .get();
    eventsSnap.docs.forEach(eventSnap => {
      const { actionsIds } = Event.fromFirestore(eventSnap);
      if (actionsIds.length > 1) {
        // The event contained more than one action, and they had all the same
        // parent (it's mandatory), but now this action has not the same
        // parent of its peers, so it cannot be part of the group anymore
        batch.update(eventSnap.ref, {
          actions_ids: actionsIds.filter(id => id !== actionId),
        });
      } else {
        // Since the event is made of this action alone, let's change its
        // parent too
        batch.update(eventSnap.ref, {
          category_id: newCategoryId,
          block_id: newBlockId,
        });
      }
    });
  }

  if (newCategoryId === oldCategoryId) {
    batch.update(actionParentDocument(newCategoryId, newBlockId), {
      [`actions_lists.${newState}.${newStarred ? STARRED : UNSTARRED}.force`]:
        firebase.firestore.FieldValue.increment(1),
    });
  }

  batch.commit();
}
