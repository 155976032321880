import imageCompression from 'browser-image-compression';

import { api, ApiError } from './ApiService';
import { getAuthTokens, NotAuthorizedError, validateAccessToken } from './AuthService';

export async function uploadPhoto(file, { maxSizeMB, maxWidthOrHeight }, revalidateToken = true) {
  const compressedFile = await imageCompression(file, {
    maxSizeMB,
    maxWidthOrHeight,
  });

  const formData = new FormData();
  formData.append('photo', compressedFile);

  const { accessToken } = getAuthTokens();

  const response = await api('/api/photos/upload', formData, {
    accessToken,
    contentType: 'multipart/form-data',
  });

  if (response.errors) {
    const {
      code: errorCode,
      name: errorName,
    } = response.errors[0];

    // If the JWT token has expired, try revalidating it, and if no errors are thrown allow
    // that error to be ignored and for setUser to be recalled
    if (errorName === 'TokenExpiredError' && revalidateToken) {
      await validateAccessToken();
      return uploadPhoto(file, { maxSizeMB, maxWidthOrHeight }, false);
    }

    if (errorCode === 'NotAuthorizedException') {
      throw new NotAuthorizedError(response.errors[0].message);
    } else {
      throw new ApiError(response);
    }
  }

  return response.location;
}
