import React from 'react';
import clsx from 'clsx';

import styles from './Avatar.module.scss';

import { ReactComponent as IconProfile } from './../../assets/icons/24-profile.svg';

function Avatar({
  className,
  src,
  ...props
}) {
  return (
    <div className={clsx(className, styles.avatar)}>
      {src ?
        <img className={styles.image} src={src} alt="User Avatar" width="40" height="40" /> :
        <IconProfile className={styles.icon} />
      }
    </div>
  );
}

export default Avatar;
