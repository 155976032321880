import React, { useRef, useState, useEffect, useCallback, forwardRef } from 'react';
import { createPortal } from 'react-dom';
import clsx from 'clsx';

import useClickOutside from '../../hooks/useClickOutside';
import useFocusTrap from '../../hooks/useFocusTrap';

import { useIsTopmostDialog } from '../Dialog';
import Heading, { HEADING_LEVEL_5 } from '../Heading';
import SmartLink from '../SmartLink';

import { ReactComponent as ArrowRight } from './ArrowRight.svg';

import styles from './ContextualMenu.module.scss';

export const MENU_POSITION_HORIZONTAL = 'horizontal';
export const MENU_POSITION_VERTICAL = 'vertical';

export function ContextualMenuHeading({
  className,
  hasButton,
  children,
  ...props
}) {
  return (
    <Heading
      className={clsx(className, styles.heading, hasButton && styles.headingHasButton)}
      tag="span"
      level={HEADING_LEVEL_5}
      {...props}
    >
      {children}
    </Heading>
  );
}

export const ContextualMenuTrigger = forwardRef(({
  // See React.Children.only implementation below for explanation on single child approach
  children,
  submenu,
  visible,
  menuId,
  setVisible,
  ...props
}, ref) => {
  const handleClick = useCallback((e) => {
    if (!setVisible) return;

    setVisible(visible => !visible);
  }, [setVisible]);

  const handleKeyDown = useCallback((e) => {
    if (!setVisible) return;

    // TODO: The full a11y implementation should select either the first or last
    // item in the list of focusable elements based on the users arrow key, but
    // for the MVP, simply opening the menu is sufficient
    if (
      (submenu && e.key === 'ArrowRight') ||
      (!submenu && (e.key === 'ArrowUp' || e.key === 'ArrowDown'))
    ) {
      e.preventDefault();
      setVisible(true);
    }
  }, [submenu, setVisible]);

  if (!children) return null;

  // Typically we would support any number of children, but as this is a specific trigger
  // that takes aria-* values, it is only the first item that is shown here.
  const child = React.Children.only(children);

  return React.cloneElement(child, {
    onClick: (e) => {
      child.onClick && child.onClick(e);
      handleClick(e);
    },
    onKeyDown: (e) => {
      child.onKeyDown && child.onKeydown(e);
      handleKeyDown(e);
    },
    'aria-haspopup': true,
    'aria-expanded': visible,
    id: menuId,
    ...props,
  });
});

export const ContextualMenuLink = forwardRef(({
  className,
  negative,
  children,
  submenu,
  category,
  ...props
}, ref) => {
  return (
    <SmartLink
      ref={ref}
      className={clsx(
        className,
        styles.link,
        negative && styles.negative,
        submenu && styles.linkSubmenu,
        category && styles.linkCategory,
      )}
      role="menuitem"
      {...props}
    >
      {children}
      {submenu && <ArrowRight role="presentation" />}
    </SmartLink>
  );
});

function ContextualMenu({
  className,
  children,
  buttonRef,
  contextRef,
  mouseCoords,
  onClose,
  position = MENU_POSITION_VERTICAL,
  submenu,
  ...props
}) {
  const wrapperRef = useRef();
  const menuRef = useRef();

  const [css, setCss] = useState(null);

  // Even though contextual menus are not dialogs, it is important that they are registered
  // to the dialog layers so that if a menu is opened within a dialog, pressing Escape or
  // clicking elsewhere doesn't shut the menu and the dialog
  const isTopmostDialog = useIsTopmostDialog();

  useEffect(() => {
    if (!buttonRef?.current) return;

    const wrapperWidth = wrapperRef.current.clientWidth;
    const wrapperHeight = wrapperRef.current.clientHeight;

    const buttonRect = buttonRef.current.getBoundingClientRect();

    const mouseX = mouseCoords?.x;
    const mouseY = mouseCoords?.y;

    // These are the edges of the button that was clicked (or if present the
    // mouseX and mouseY) which are used to calculate where the menu should be placed.
    const buttonTopEdge = mouseY || buttonRect.top;
    const buttonBottomEdge = mouseY || buttonRect.bottom;
    const buttonLeftEdge = mouseX || buttonRect.left;
    const buttonRightEdge = mouseX || buttonRect.right;

    // These constants are only used within this function so are only created here, but
    // are written like constants to make the later code clear
    const MENU_MARGIN = +styles.contextualMenuMargin;
    const SUBMENU_MARGIN = +styles.contextualSubmenuMargin;
    // The offset is used to vertically align the submenu first item (where possible) with
    // the menu item that opened it
    const SUBMENU_OFFSET = +styles.contextualSubmenuOffset;

    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;

    let top = 0;
    let left = 0;

    // Submenus are always horizontal and require a bit of extra space for the padding
    // of the link items. Rather than complicate the HORIZONTAL condition with conditional
    // spacing/padding, it is easiest written as a separate condition.
    if (submenu) {
      left = buttonRightEdge + SUBMENU_MARGIN;
      top = buttonTopEdge - SUBMENU_OFFSET;

      const wrapperRightEdge = left + wrapperWidth + SUBMENU_MARGIN;
      const wrapperBottomEdge = top + wrapperHeight + (MENU_MARGIN * 2);

      if (wrapperRightEdge > windowWidth) {
        left = buttonLeftEdge - wrapperWidth - SUBMENU_MARGIN;
      }
      if (wrapperBottomEdge > windowHeight) {
        top = buttonBottomEdge - wrapperHeight + SUBMENU_OFFSET;
      }
    }

    else if (position === MENU_POSITION_HORIZONTAL) {
      left = buttonRightEdge + MENU_MARGIN;
      top = buttonTopEdge;

      const wrapperRightEdge = left + wrapperWidth + MENU_MARGIN;
      const wrapperBottomEdge = top + wrapperHeight + (MENU_MARGIN * 2);

      if (wrapperRightEdge > windowWidth) {
        left = buttonLeftEdge - wrapperWidth - MENU_MARGIN;
      }
      if (wrapperBottomEdge > windowHeight) {
        top = buttonBottomEdge - wrapperHeight;
      }
    }

    else if (position === MENU_POSITION_VERTICAL) {
      top = buttonBottomEdge + MENU_MARGIN;
      left = buttonLeftEdge;

      const wrapperRightEdge = left + wrapperWidth + (MENU_MARGIN * 2);
      const wrapperBottomEdge = top + wrapperHeight + MENU_MARGIN;

      if (wrapperRightEdge > windowWidth) {
        left = buttonRightEdge - wrapperWidth;
      }
      if (wrapperBottomEdge > windowHeight) {
        top = buttonTopEdge - wrapperHeight - MENU_MARGIN;
      }
    }

    // Final checks are made to be sure that the values used don't fall outside
    // the bounds of the viewport (CSS controls the maximum width to ensure that it
    // doesn't fall outside the bounds on the bottom/right)
    setCss({
      top: `${Math.max(top, MENU_MARGIN)}rem`,
      left: `${Math.max(left, MENU_MARGIN)}rem`,
    });
  }, [buttonRef, mouseCoords, position, submenu]);

  // Transfers focus to the menu when it is opened.
  useEffect(() => {
    if (!css?.top) return;

    menuRef.current && menuRef.current.focus();
  }, [css]);

  // Contextual menus are treated differently to dialogs – when a submenu is visible and the user
  // clicks outside, the whole menu and its subtree is closed – so we need separate close
  // handlers to distinguish the functionsality of the two
  const handleCloseClick = useCallback(e => {
    if (!onClose) return;

    // useClickOutside is triggered by left and right clicks, so to ignore just the
    // right click, it must be allowed to trigger handleClose, but then be filtered
    // out here if the context matches the target and it is a right click.
    // The parent context event must be a toggle (e.g. setMenuVisible(visible => !visible))
    const isRightClick = e.button === 2;
    if (isRightClick && contextRef?.current?.contains(e.target)) return;

    // Submenus are hidden through the useEffect cleanup below as they are children
    // of their parent menu
    if (!submenu) {
      // When the menu closes, it transfers the focus back to the button
      buttonRef.current && buttonRef.current.focus();

      onClose();
    }
  }, [onClose, buttonRef, contextRef, submenu]);

  useClickOutside(handleCloseClick, wrapperRef, buttonRef);

  const handleCloseKeydown = useCallback((e) => {
    if (!isTopmostDialog || !onClose) return;

    // When the menu closes, it transfers the focus back to the button
    buttonRef.current && buttonRef.current.focus();

    onClose();
  }, [isTopmostDialog, onClose, buttonRef]);

  // When a submenu is closed without onClose having been called (i.e. the parent
  // menu is closed) this effect cleanup ensures that the close function is called
  useEffect(() => {
    if (!submenu || !onClose) return;

    return () => {
      onClose();
    };
  }, [submenu, onClose]);

  const handleMenuKeyDown = useCallback((e) => {
    if (e.key === 'ArrowLeft' && submenu) {
      e.preventDefault();

      handleCloseKeydown();
    }
  }, [submenu, handleCloseKeydown]);

  useFocusTrap(menuRef, {
    buttonRef,
    onClose: handleCloseKeydown,
    transferFocusWithArrowKeys: true,
    isTopmostDialog,
  });

  const menuElement = (
    <div
      ref={wrapperRef}
      className={styles.wrapper}
      style={css}
      // The menu is hidden until css has a value to avoid the jump
      // when the component first renders, but the coordinates haven't
      // been calculated yet.
      hidden={!css}
    >
      <div
        ref={menuRef}
        className={clsx(className, styles.menu)}
        tabIndex={-1}
        role="menu"
        onKeyDown={handleMenuKeyDown}
        {...props}
      >
        {children}
      </div>
    </div>
  );

  // If the menu is a submenu it has to live within the node of the parent rather than at
  // the root of the body so that clicks in the parent don't trigger it to close itself
  // (and thus close the submenu) unintentionally.
  return submenu ? menuElement : createPortal(menuElement, document.body);
}

export default ContextualMenu;
