import React, { useMemo } from 'react';
import clsx from 'clsx';

import { timeToText } from "../../utils";

import { ReactComponent as IconStarOutline } from '../../assets/icons/16-star-outline.svg';
// import { ReactComponent as IconCalendar } from '../../assets/icons/16-calendar.svg';
import { ReactComponent as IconScheduled } from '../../assets/icons/16-scheduled.svg';

import Counter from './Counter';

import styles from './Counters.module.scss';

function computeActionsCounters(actions) {
  let totalSeconds = 0;
  let starredSeconds = 0;

  for (let {time, starred, leveragedPersonId} of actions) {
    if (!time || leveragedPersonId) continue;
    // Ensure everything works even if time is corrupted
    time = parseInt(time);
    if (isNaN(time) || time <= 0) continue;
    totalSeconds += time;
    if (starred) starredSeconds += time;
  }

  return {
    total: timeToText(totalSeconds),
    starred: timeToText(starredSeconds),
  };
}

function ActionsCounters({
  actions,
  className,
}) {
  const timeAggregates = useMemo(() => {
    // Conversion done here rather than at prop to allow for nullish values
    return computeActionsCounters(actions || []);
  }, [actions]);

  return (
    <div className={clsx(styles.wrapper, className)}>
      {/* Scheduled actions not implemented yet */}
      {/*<Counter
        icon={IconCalendar}
        short='0h'
        long='0 hours scheduled actions'
      />*/}
      <Counter
        icon={IconStarOutline}
        short={timeAggregates.starred.shortText}
        long={`${timeAggregates.starred.longText} starred actions`}
      />
      <Counter
        icon={IconScheduled}
        short={timeAggregates.total.shortText}
        long={`${timeAggregates.total.longText} total actions`}
      />
    </div>
  );
}

export default ActionsCounters;
