import React, { useMemo} from 'react';
import clsx from 'clsx';

import { CATEGORY_ICONS, CATEGORY_DEFAULT_ICON } from '../../services/DbService/constants';

import styles from './ItemIcon.module.scss';

function ItemIcon({
  className,
  icon,
  initials,
  active,
  image,
  ...props
}) {
  const Icon = useMemo(() => {

    if (initials) return null;

    if (icon && typeof(icon) !== 'string') return icon;

    // [category.icon || CATEGORY_DEFAULT_ICON] doesn't work here in instances where old icon names were
    // used. As this change was after the cut off for stable data, we have to safely degrade to using
    // the CATEGORY_DEFAULT_ICON if category.icon is truthy, but invalid (i.e. an old icon slug).
    const { icon: Icon } = CATEGORY_ICONS[icon] || CATEGORY_ICONS[CATEGORY_DEFAULT_ICON];

    return Icon;
  }, [initials, icon]);

  return (
    <div
      {...props}
      className={clsx(
        className,
        styles.icon,
        active && styles.iconActive,
        image && styles.iconImage
      )}
    >
      <span
        aria-hidden="true"
        className={styles.background}
        style={{
          backgroundImage: image && `url(${image})`,
        }}
      />
      {initials && <span className={styles.initials}>{initials}</span>}
      {Icon && <Icon role="presentation"/>}
    </div>
  );
}

export default ItemIcon;
