import { useEffect, useState } from "react";
import { watchPeople } from "../services/DbService/people";
import { mapById } from "../utils";

let peopleByIdCache = {};
let peopleCache = [];
let peopleInitCache = false;

export default function usePeopleById() {
  // The cache provides a valid init state without waiting for the effect to run
  const [peopleById, setPeopleById] = useState(peopleByIdCache);
  const [people, setPeople] = useState(peopleCache);
  const [peopleInit, setPeopleInit] = useState(peopleInitCache);

  useEffect(() => {
    return watchPeople(newPeople => {
      const newPeopleById = mapById(newPeople);
      setPeopleById(newPeopleById);
      setPeople(newPeople);
      setPeopleInit(true);
      peopleByIdCache = newPeopleById;
      peopleCache = newPeople;
      peopleInitCache = true;
    });
  }, []);

  return { people, peopleById, peopleInit };
}
