import React, { useState, useCallback } from 'react';

import {
  CATEGORY_DEFAULT_ICON,
  CATEGORY_DEFAULT_COLOR,
  CATEGORY_NAME_MAX_LENGTH,
} from '../../services/DbService/constants';
import { createCategory } from '../../services/DbService/categories';

import useUniqueId from '../../hooks/useUniqueId';

import CreateButton from '../CreateButton';
import { BUTTON_COLOR_FILL } from '../Button';
import CreateDialog from '../CreateDialog';
import CharacterCounter from '../CharacterCounter';
import { InputLabel, INPUT_SIZE_SMALL } from '../Input';
import Toast from '../Toast';
import Divider from '../Divider';
import CategoryRadioGroupColor from './../CategoryRadioGroup/CategoryRadioGroupColor';
import CategoryRadioGroupIcon from './../CategoryRadioGroup/CategoryRadioGroupIcon';

import styles from './CreateCategoryDialog.module.scss';

function CreateCategoryDialog({
  className,
  children,
  onCreate,
  onClose,
  ...props
}) {
  const errorToastId = useUniqueId();
  const iconLabelId = useUniqueId();
  const colorLabelId = useUniqueId();

  const [newName, setNewName] = useState('');
  const [newIcon, setNewIcon] = useState(CATEGORY_DEFAULT_ICON);
  const [newColor, setNewColor] = useState(CATEGORY_DEFAULT_COLOR);

  const handleSubmit = useCallback((e) => {
    e.preventDefault();

    const newNameTrim = newName.trim();
    if (!newNameTrim) return;

    const onSuccess = (categoryId) => {
      onCreate && onCreate(categoryId);
    };

    createCategory(newNameTrim, newIcon, newColor, onSuccess);

    onClose();
  }, [newName, newColor, newIcon, onClose, onCreate]);

  const nameErrorMessage = newName.length > CATEGORY_NAME_MAX_LENGTH ?
    'Too many characters' :
    null;

  return (
    <CreateDialog className={styles.dialog} onClose={onClose}>
      <form className={styles.form} onSubmit={handleSubmit}>
        <label className={styles.label}>
          <InputLabel tag="span">Enter new Category name</InputLabel>
          {/* The max length here is longer to allow a little bit of overflow, but the form will be
            invalid when it is over so won't be submittable. */}
          <input
            className={styles.input}
            value={newName}
            aria-errormessage={nameErrorMessage ? errorToastId : null}
            onChange={e => setNewName(e.target.value)}
            autoFocus
            maxLength={CATEGORY_NAME_MAX_LENGTH + 5}
            placeholder='e.g. Health and Vitality'
          />
          <span aria-hidden="true" className={styles.focusRing} />
        </label>

        <div className={styles.extras}>
          <InputLabel tag="span" size={INPUT_SIZE_SMALL} id={colorLabelId}>Color</InputLabel>
          <CategoryRadioGroupColor
            className={styles.label}
            color={newColor}
            setColor={setNewColor}
            aria-labelledby={colorLabelId}
          />

          <Divider />

          <InputLabel tag="span" size={INPUT_SIZE_SMALL} id={iconLabelId}>Icon</InputLabel>
          <CategoryRadioGroupIcon
            className={styles.label}
            icon={newIcon}
            setIcon={setNewIcon}
            color={newColor}
            aria-labelledby={iconLabelId}
          />
        </div>

        <CharacterCounter
          className={styles.counter}
          count={newName.length}
          max={CATEGORY_NAME_MAX_LENGTH}
        />

        <Divider vertical />

        <CreateButton
          aria-label='Add Category'
          error={nameErrorMessage}
          disabled={!newName}
          type="submit"
          color={BUTTON_COLOR_FILL}
        />
      </form>

      {nameErrorMessage &&
        <Toast error role="alert" id={errorToastId}>{nameErrorMessage}</Toast>}
    </CreateDialog>
  );
}

export default CreateCategoryDialog;
