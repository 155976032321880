import { document, collection, firestore, composeUnsubscribers, ParameterError } from './general';

import { Action } from "./actions";

export class User {
  static fromFirestore(snapshot, options) {
    const data = snapshot.data(options);
    if (!data) return undefined;
    const { counters, goals } = data;
    return Object.assign(new User(), {
      id: data.id,
      orderedCategoryIds: data.categories_list?.ordered_ids || [],
      orderedPeopleGroupIds: data.people_groups_list?.ordered_ids || [],
      counters: {
        actionsCompleted: counters?.actions_completed || 0,
        actionsCompletedLeveraged: counters?.actions_completed_leveraged || 0,
        actionsCompletedStarred: counters?.actions_completed_starred || 0,
        blocksCompleted: counters?.blocks_completed || 0,
      },
      goals: {
        vision: goals?.vision,
        purpose: goals?.purpose,
      },
    });
  }
}

export function tickBlocksCompleted(batch, increment) {
  batch.update(document(), {
    'counters.blocks_completed': firestore.FieldValue.increment(increment),
  });
}

export function tickActionsCompleted(batch, increment) {
  batch.update(document(), {
    'counters.actions_completed': firestore.FieldValue.increment(increment),
  });
}

export function tickActionsCompletedStarred(batch, increment) {
  batch.update(document(), {
    'counters.actions_completed_starred': firestore.FieldValue.increment(increment),
  });
}

export function tickActionsCompletedLeveraged(batch, increment) {
  batch.update(document(), {
    'counters.actions_completed_leveraged': firestore.FieldValue.increment(increment),
  });
}

export function watchUserCounters(startDate, callback) {
  // This watcher is very expensive and should be used only for short periods
  // of time. Remember that Firestore downloads all the documents in the
  // resultset of a query, and you are billed for a read operation for each one
  // of them. This is why this function can't be used to compute the all-time
  // counters.

  const startDateISOString = startDate.toISOString();
  let blocksStats, actionsStats;

  function handleChange() {
    // Wait all the counters before calling callback
    if (blocksStats === undefined || actionsStats === undefined) return;
    // Ensure to return a new object every time so React knows that the state
    // has changed
    callback({
      ...blocksStats,
      ...actionsStats,
    });
  }

  return composeUnsubscribers(
    // For these 2 watchers we could add the condition "state == COMPLETED"
    // to add some robustness, but adding it requires creating a custom index on
    // Firestore, and since we are just computing analytics we decided to avoid
    // adding this overhead

    collection('blocks')
      .where('date_of_completion', '>=', startDateISOString)
      .onSnapshot(snapshot => {
        blocksStats = { blocksCompleted: snapshot.size };
        handleChange();
      }),

    collection('actions')
      .where('date_of_completion', '>=', startDateISOString)
      .onSnapshot(snapshot => {
        actionsStats = {
          actionsCompleted: snapshot.size,
          actionsCompletedStarred: 0,
          actionsCompletedLeveraged: 0,
        }
        snapshot.docs.forEach(docSnapshot => {
          const {
            starred,
            leveragedPersonId,
          } = Action.fromFirestore(docSnapshot);
          if (starred) actionsStats.actionsCompletedStarred++;
          if (leveragedPersonId) actionsStats.actionsCompletedLeveraged++;
        });
        handleChange();
      }),
  );
}

export function watchUserAllTimeCounters(callback) {
  // TODO: create a cloud function that regularly checks these counters and
  // rebuilds them if necessary. We could check for example if these all-time
  // counters are always >= of the corresponding last-month counters. If not
  // there is a clear consistency error, and the counters should be rebuilt.
  // Running this function on the client is dangerous because it would download
  // all the completed actions, that can be many thousands.
  return document().onSnapshot(snapshot => {
    const { counters } = User.fromFirestore(snapshot) || {};
    callback(counters);
  });
}

export function watchUserGoals(callback) {
  return document().onSnapshot(snapshot => {
    const { goals } = User.fromFirestore(snapshot) || {};
    callback(goals);
  });
}

export function setUserGoals(newVision, newPurpose) {
  if (typeof newVision !== 'string')
    throw new ParameterError({newVision}, 'must be a string');
  if (typeof newPurpose !== 'string')
    throw new ParameterError({newPurpose}, 'must be a string');

  const documentUpdate = {
    'goals.vision': newVision,
    'goals.purpose': newPurpose,
  };

  const batch = firestore().batch();

  batch.update(document(), documentUpdate);

  batch.commit();
}
