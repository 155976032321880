import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import { getEnvVar, api, ApiError, ApiParameterError } from './ApiService';
import { ENABLE_FIRESTORE_PERSISTENCE } from "./DbService/constants";

const USER_TOKENS = 'userTokens';

export const USER_PROFILE_IMAGE_MAX_FILE_SIZE_MB = 2;
export const USER_PROFILE_IMAGE_MAX_LENGTH = 800;

const subscribers = new Set();
export function watchUser(callback) {
  subscribers.add(callback);
  return () => {
    subscribers.delete(callback);
  };
}
function notifySubscribers(newUser) {
  subscribers.forEach(callback => callback(newUser));
}

firebase.initializeApp({
  apiKey: getEnvVar('FIREBASE_API_KEY'),
  authDomain: getEnvVar('FIREBASE_AUTH_DOMAIN'),
  databaseURL: getEnvVar('FIREBASE_DATABASE_URL'),
  projectId: getEnvVar('FIREBASE_PROJECT_ID'),
  storageBucket: getEnvVar('FIREBASE_STORAGE_BUCKET'),
  messagingSenderId: getEnvVar('FIREBASE_MESSAGING_SENDER_ID'),
  appId: getEnvVar('FIREBASE_APP_ID'),
  measurementId: getEnvVar('FIREBASE_MEASUREMENT_ID'),
});
if (ENABLE_FIRESTORE_PERSISTENCE) firebase.firestore().enablePersistence();

// We must wait Firebase initialization to know if the user has
// access to the app or not
let ready = false;

export function isReady() {
  return ready;
}

firebase.auth().onAuthStateChanged(firebaseUser => {
  // The first time this function is called Firebase has verified
  // if the user can access the app or must login again
  ready = true;

  // If the user has signed out from Firebase make sure to remove
  // also its other credentials
  if (firebaseUser === null) {
    removeAuthTokens();
    notifySubscribers(null);
  }

  const { accessToken } = getAuthTokens();

  if (accessToken) {
    getUser();
  }
});


export class InvalidEmailError extends Error {}
export class InvalidParameterError extends Error {}
export class InvalidPasswordError extends Error {}
export class AlreadyRegisteredEmailError extends Error {}
export class AlreadyRegisteredUsernameError extends Error {}
export class NotAuthorizedError extends Error {}
export class NotVerifiedError extends Error {}
export class UnauthorizedEmailError extends Error {}
export class CognitoJwtExpiredError extends Error {}

function setAuthTokens(response) {
  const {
    idToken: IDToken,
    accessToken,
    refreshToken,
    user: {
      username,
      uid,
      calendarID,
    },
  } = response;

  localStorage.setItem(USER_TOKENS, JSON.stringify({
    IDToken,
    accessToken,
    refreshToken,
    username,
    uid,
    calendarID,
  }));
}

export function getAuthTokens() {
  return JSON.parse(localStorage.getItem(USER_TOKENS)) || {};
}

function removeAuthTokens() {
  localStorage.removeItem(USER_TOKENS);
}

function setCalendarId(calendarID) {
  localStorage.setItem(USER_TOKENS, JSON.stringify({
    ...getAuthTokens(),
    calendarID,
  }));
}

export async function getRpmCalendarUrl() {
  let { calendarID, username, refreshToken } = getAuthTokens();
  if (!calendarID) {
    // Users who logged in with an old version of the application don't have
    // the calendarID saved in the localstorage, so it has to be retrieved
    // from the servers. This code will be no more needed in future.
    const response = await api(`/api/users?username=${username}&cognitoRefreshToken=${refreshToken}`, null, { method: 'GET' });
    if (response.errors) {
      throw new ApiError(response);
    }
    calendarID = response.calendarID;
    setCalendarId(calendarID);
  }
  return new URL(`/api/calendar?calendarID=${calendarID}`, getEnvVar('BACKEND_API_URL'));
}

export async function signIn(usernameOrEmail, password) {
  // Username or email can be used to sign in
  const response = await api('/api/auth/login', {username: usernameOrEmail, password});

  if (response.errors) {
    const errorCode = response.errors[0].code;
    if (response.error_type === 'ValidationError'
        || errorCode === 'NotAuthorizedException') {
      // ValidationError is triggered when the username is not valid or there
      // is an empty field, not meaningful enough to require a separate
      // error handling
      throw new NotAuthorizedError(response.errors[0].message);
    } else if (errorCode === 'UserNotConfirmedException') {
      throw new NotVerifiedError(response.errors[0].message);
    } else {
      throw new ApiError(response);
    }
  }

  await firebase.auth().signInWithCustomToken(response.firebaseToken);

  setAuthTokens(response);
  notifySubscribers(response.user);
}

export async function signUp(email, password, username) {
  const response = await api('/api/auth/register', {email, username, password});
  if (response.errors) {
    // Unfortunately some errors don't have the field 'code', so I have
    // to filter them using the less robust 'message'
    const {
      message,
      code: errorCode,
    } = response.errors[0];

    if (message === 'username is a required field'
        || message === 'username must be a valid email') {
      throw new InvalidEmailError(message);
    } else if (
      message === 'password is a required field'
      || (errorCode === 'min' && message === 'password must be at least 6 characters')
    ) {
      throw new InvalidPasswordError(message);
    } else if (errorCode === 'UsernameExistsException') {
      throw new AlreadyRegisteredUsernameError(message);
    } else if (errorCode === 'EmailExistsException') {
      throw new AlreadyRegisteredEmailError(message);
    } else if (errorCode === 'UnauthorizedEmail'
      && message === 'The email is not authorized to register') {
      throw new UnauthorizedEmailError(message);
    } else if (errorCode === 'InvalidParameterException') {
      throw new InvalidParameterError(message);
    } else {
      throw new ApiError(response);
    }
  }
}

export async function verifyEmail(username, code) {
  const response = await api('/api/auth/verify', {username, confirmationCode: code});
  if (response.errors) {
    const message = response.errors[0].message;
    const errorCode = response.errors[0].code;
    if (errorCode === 'ExpiredCodeException'
        || errorCode === 'CodeMismatchException'
        || message === 'confirmationCode is a required field') {
      throw new NotAuthorizedError(message);
    } else {
      throw new ApiError(response);
    }
  }
}

export async function checkAccess(email) {
  const response = await api('/api/auth/check-registration-access', { email });
  if (response.errors) {
    const { message } = response.errors[0];

    if (message === 'email must be a valid email') {
      throw new InvalidEmailError(message);
    } else {
      throw new ApiError(response);
    }
  }

  return response;
}

export async function forgotPassword(username) {
  const response = await api('/api/auth/forgot-password', { username });
  if (response.errors) {
    throw new ApiError(response);
  }

  return response;
}

export async function resetPassword(username, confirmationCode, newPassword) {
  const response = await api('/api/auth/confirm-forgot-password', {
    username,
    confirmationCode,
    password: newPassword,
  });

  if (response.errors) {
    // Unfortunately some errors don't have the field 'code', so I have
    // to filter them using the less robust 'message'
    const {
      message,
      code: errorCode,
    } = response.errors[0];

    if (
      message === 'password is a required field' ||
      (errorCode === 'min' && message === 'password must be at least 6 characters')
    ) {
      throw new InvalidPasswordError(message);
    } else if (
      errorCode === 'ExpiredCodeException' ||
      errorCode === 'CodeMismatchException' ||
      message === 'confirmationCode is a required field'
    ) {
      throw new NotAuthorizedError(message);
    }

    throw new ApiError(response);
  }

  return response;
}

export async function getUser() {
  const { accessToken } = getAuthTokens();

  const response = await api('/api/users/me', null, {
    method: 'GET',
    accessToken,
  });

  if (response.errors) {
    const {
      name: errorName,
    } = response.errors[0];

    // If the JWT token has expired, try revalidating it, and if no errors are thrown allow
    // that error to be ignored and for setUser to be recalled
    if (errorName === 'TokenExpiredError') {
      await validateAccessToken();
      return getUser();
    }

    throw new ApiError(response);
  }

  notifySubscribers(response);

  return response;
}

export async function setUser(body, revalidateToken = true) {
  const { accessToken } = getAuthTokens();

  const response = await api('/api/users/me', body, {
    method: 'PATCH',
    accessToken,
  });

  if (response.errors) {
    const {
      name: errorName,
      code: errorCode,
      message: errorMessage,
    } = response.errors[0];

    // If the JWT token has expired, try revalidating it, and if no errors are thrown allow
    // that error to be ignored and for setUser to be recalled
    if (errorName === 'TokenExpiredError' && revalidateToken) {
      await validateAccessToken();
      return setUser(body, false);
    }

    if (response.error_type === 'ValidationError') {
      throw new NotAuthorizedError(errorMessage);
    } else if (errorCode === 'UserNotConfirmedException') {
      throw new NotVerifiedError(errorMessage);
    } else if (errorCode === 'AliasExistsException') {
      throw new AlreadyRegisteredUsernameError(errorMessage);
    } else {
      throw new ApiError(response);
    }
  }

  const updatedUserResponse = await getUser();

  if (updatedUserResponse.errors) {
    const {
      code: errorCode,
      message: errorMessage,
    } = updatedUserResponse.errors[0];

    if (response.error_type === 'ValidationError') {
      throw new NotAuthorizedError(errorMessage);
    } else if (errorCode === 'UserNotConfirmedException') {
      throw new NotVerifiedError(errorMessage);
    } else if (errorCode === 'AliasExistsException') {
      throw new AlreadyRegisteredUsernameError(errorMessage);
    } else {
      throw new ApiError(response);
    }
  }

  return updatedUserResponse;
}

export async function signOut() {
  await firebase.auth().signOut();
}

export async function validateAccessToken() {
  const {username, accessToken, refreshToken} = getAuthTokens();
  let response = await api('/api/auth/validate', {token: accessToken});
  // If API says 'Valid token' there's nothing else to do
  if (response.message === 'Valid token') return;

  if (response.errors) {
    // The error 'jwt expired' is accepted at this stage, it means
    // that the token expired and needs a refresh. If all the errors returned are
    // different from this one then something unexpected is happening.
    if (response.errors.every(({message}) => message !== 'jwt expired')) {
      throw new ApiError(response);
    }
  }

  console.log('Current Access Token is invalid, attempting refresh...');
  response = await api('/api/auth/refresh', {username, refreshToken});
  if (response.errors) {
    if (response.errors.some(({code}) => code === 'NotAuthorizedException')) {
      console.log('Refresh Token is invalid too, signing out');
      signOut();
      return;
    } else {
      // We received unexpected errors
      throw new ApiError(response);
    }
  }

  // If execution gets here we have fresh tokens to store
  setAuthTokens(response);
  notifySubscribers(response.user);
  console.log('Access Token refreshed successfully');
}

export async function registerForUpdates(email) {
  const response = await api('/api/auth/register-for-updates', { email });

  if (response.errors) {
    const errorMessage = response.errors[0].message;

    if (
      response.error_type === 'ValidationError' &&
      errorMessage === 'email must be a valid email'
    ) {
      throw new ApiParameterError(errorMessage);
    } else {
      throw new ApiError(response);
    }
  }

  // An extra check to make sure that the email was successfuly added, if not
  // throw a generic error.
  if (!response.success) {
    throw new ApiError(response);
  }

  return response;
}
