import React, { useRef, useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';

import {
  forgotPassword,
  resetPassword,
  NotAuthorizedError,
  InvalidPasswordError,
} from '../../services/AuthService';
import { NetworkError } from '../../services/ApiService';

import { SIGN_IN_URL } from '../App';

import Button, { BUTTON_COLOR_FILL } from '../Button';
import Input from '../Input';
import PageNoUi from '../PageNoUi';
import SmartLink from '../SmartLink';
import AuthForm from './AuthForm';

function ForgotPassword() {
  const history = useHistory();

  // username persists across both steps so useState is preferred to using Ref.current.value
  const [username, setUsername] = useState(history?.location?.state?.username || '');
  const [email, setEmail] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [step, setStep] = useState('request');

  const usernameRef = useRef();
  const verificationCodeRef = useRef();
  const newPasswordRef = useRef();

  const handleSignIn = useCallback(e => {
    e.preventDefault();
    history.push(SIGN_IN_URL, {
      // email is used as the key for the first field as it is passed originally
      // from /access which only accepts email
      email: username,
    });
  }, [history, username]);

  const onSubmitRequest = useCallback(async e => {
    e.preventDefault();
    setLoading(true);

    if (!username) {
      setLoading(false);
      setError('Username/email is required');
      usernameRef.current.focus();
      return;
    }

    let response;

    try {
      response = await forgotPassword(username);
    } catch (e) {
      setLoading(false);
      if (e instanceof NetworkError) {
        setError('Network error, please check your internet connection and try again');
      } else {
        setError('Unexpected error, please try again');
        console.error(e);
      }
      // Move the focus on username so the user can make corrections or just
      // press Enter to try again
      usernameRef.current.focus();
      return;
    }

    setEmail(response?.CodeDeliveryDetails?.Destination);

    setLoading(false);
    setError('');
    setStep('reset');
  }, [username]);

  const onSubmitReset = useCallback(async e => {
    e.preventDefault();
    setLoading(true);

    if (!newPassword) {
      setLoading(false);
      setError('New password required');
      newPasswordRef.current.focus();
      return;
    }

    if (!verificationCode) {
      setLoading(false);
      setError('Verification code is required');
      verificationCodeRef.current.focus();
      return;
    }

    try {
      await resetPassword(username, verificationCode, newPassword);
    } catch (e) {
      setLoading(false);

      if (e instanceof NetworkError) {
        setError('Network error, please check your internet connection and try again');
      } else if (e instanceof NotAuthorizedError) {
        setError('Invalid verification code, please try again');
      } else if (e instanceof InvalidPasswordError) {
        setError('Password must be at least 6 characters long');
        newPasswordRef.current.focus();
        return;
      } else {
        setError('Unexpected error, please try again');
        console.error(e);
      }

      // Move the focus to the verification code field so the user can make
      // corrections or just press Enter to try again
      verificationCodeRef.current.focus();
      return;
    }

    history.push(SIGN_IN_URL, { prev: 'reset', email: username });
  }, [history, username, verificationCode, newPassword]);

  const onSubmit = useCallback(e => {
    if (step === 'request'){
      onSubmitRequest(e);
    } else {
      onSubmitReset(e);
    }
  }, [step, onSubmitRequest, onSubmitReset]);

  const text = step === 'request' ?
    'Enter your email/username below. Ensure you have the correct information.' :
    `We’ve sent your activation code to ${email}. Please enter it below.`;

  return (
    <PageNoUi
      pageTitle="Forgot Password"
      text={text}
    >
      <AuthForm
        error={error}
        loading={loading}
        buttons={
          <Button
            type='submit'
            loading={loading}
            onClick={onSubmit}
            block
            color={BUTTON_COLOR_FILL}
          >
            {step === 'request' ? 'Request code' : 'Reset password'}
          </Button>
        }
        bottomText={<>
          Remembered your password? <SmartLink to={SIGN_IN_URL} onClick={handleSignIn}>Sign in</SmartLink>
        </>}
      >
        {step === 'request' &&
          <Input
            label="Your username/email"
            type="text"
            autoFocus
            autoComplete="username"
            autoCorrect="off"
            autoCapitalize="off"
            spellCheck="false"
            value={username}
            onChange={e => setUsername(e.target.value)}
            ref={usernameRef}
            initialValue={username}
          />
        }
        {step !== 'request' &&
          <>
            <Input
              label="Verification code"
              type="text"
              autoFocus
              spellCheck="false"
              value={verificationCode}
              onChange={e => setVerificationCode(e.target.value)}
              ref={verificationCodeRef}
            />
            <Input
              label="New password"
              type="password"
              autoComplete="new-password"
              value={newPassword}
              onChange={e => setNewPassword(e.target.value)}
              ref={newPasswordRef}
            />
          </>
        }
      </AuthForm>
    </PageNoUi>
  );
}

export default ForgotPassword;
