import React, { useMemo, useState, useCallback, useRef, useEffect, useContext } from 'react';
import clsx from 'clsx';
// import isEmail from 'validator/es/lib/isEmail';

import Avatar from '../Avatar';
import Button from '../Button';
import Sidebar from '../Sidebar';
import Heading, { HEADING_LEVEL_2, HEADING_LEVEL_5 } from '../Heading';
import Divider from '../Divider';
import Input, { InputLabel, INPUT_SIZE_SMALL } from '../Input';
import ProfileSidebarCalendars from "./ProfileSidebarCalendars";
import { NotificationsContext, getNotificationPreferences } from '../NotificationsContext';
import { AuthContext } from '../AuthContext';

import { ReactComponent as IconExit } from '../../assets/icons/16-exit.svg';
import { ReactComponent as IconMediaCamera } from '../../assets/icons/16-media-camera.svg';

import {
  signOut,
  getUser,
  setUser,
  USER_PROFILE_IMAGE_MAX_FILE_SIZE_MB,
  USER_PROFILE_IMAGE_MAX_LENGTH,
  AlreadyRegisteredUsernameError,
} from '../../services/AuthService';
import { uploadPhoto } from '../../services/PhotoService';

import styles from './ProfileSidebar.module.scss';

export const LOCALSTORAGE_COLOR_SCHEME = 'RPM_COLOR_SCHEME';

function ProfileSidebar({ ...props }) {
  const [formEditing, setFormEditing] = useState(false);
  const [formLoading, setFormLoading] = useState(false);
  const [error, setError] = useState(null);
  const [newAvatarUrl, setNewAvatarUrl] = useState(null);
  const [colorScheme, setColorScheme] = useState(localStorage.getItem('RPM_COLOR_SCHEME') || 'auto');
  const [firstNameValue, setFirstNameValue] = useState('');
  const [lastNameValue, setLastNameValue] = useState('');
  const [emailValue, setEmailValue] = useState('');
  const [usernameValue, setUsernameValue] = useState('');
  // Suffixed with State to avoid clash with setState
  const [userState, setUserState] = useState({});

  const { notifications, setNotifications } = useContext(NotificationsContext);
  const { user } = useContext(AuthContext);

  const firstNameRef = useRef();
  const avatarRef = useRef();

  const fullName = useMemo(() => {
    if (!userState) return null;

    return `${userState.firstName || ''} ${userState.lastName || ''}`.trim();
  }, [userState]);

  useEffect(() => {
    document.documentElement.dataset.colorPreference = colorScheme;
    localStorage.setItem(LOCALSTORAGE_COLOR_SCHEME, colorScheme);
  }, [colorScheme]);

  useEffect(() => {
    setUserState(user || {});
  }, [user]);

  // Update the user information when a user accesses the sidebar to be sure it's up to date
  useEffect(() => {
    (async function getUserAsync() {
      await getUser();
    })();
  }, []);

  useEffect(() => {
    if (!formEditing) return;

    setFirstNameValue(userState.firstName || '');
    setLastNameValue(userState.lastName || '');
    setEmailValue(userState.email);
    setUsernameValue(userState.username);

    avatarRef.current.value = null;

    firstNameRef.current.focus();

    setNewAvatarUrl(null);
  }, [userState, formEditing]);

  const handleEditProfile = useCallback(async () => {
    await getUser();
    setFormEditing(true);
  }, []);

  const handleNotificationToggle = useCallback(() => {
    // Rather than rely on the state (from the context) it is important that checks are made
    // against localstorage and Notification.permission to be sure that the user hasn't changed
    // a setting elsewhere that is undetectable (without continually polling a setInterval) that
    // would affect the assumed state of the toggle.
    const currentState = getNotificationPreferences();

    if (currentState === 'default') {
      Notification.requestPermission((permission) => {
        setNotifications(permission);
      });
    } else {
      // There is no way to set Notification.permission (nor would it be desirable) for when a user
      // wants to opt out of notifications so in the NotificationsContext the users' preference is
      // stored as default (which translates to off in the UI) so that we can dutifully not send
      // them notifications until/if they turn the notifications back on – at this point the user is
      // treated as having no preference, but the browser treats it as granted so the local state
      // is updated, notifications are reenabled on the frontend and the user is shown no extra popup.
      setNotifications('default');
    }
  }, [setNotifications]);

  const handleImageUpload = useCallback(async e => {
    setFormLoading(true);

    const [file] = e.target.files;

    if (!file) {
      setNewAvatarUrl(null);
      setFormLoading(false);
      return;
    }

    let backgroundImage;

    try {
      backgroundImage = await uploadPhoto(file, {
        maxSizeMB: USER_PROFILE_IMAGE_MAX_FILE_SIZE_MB,
        maxWidthOrHeight: USER_PROFILE_IMAGE_MAX_LENGTH,
      });
    } catch(e) {
      setFormLoading(false);
      setError('Image upload failed. Please try again.');
      return;
    }

    setNewAvatarUrl(backgroundImage);
    setFormLoading(false);
  }, []);

  const handleSubmit = useCallback(async e => {
    if (formLoading) return;

    e.preventDefault();

    setFormLoading(true);

    let error;

    if (!usernameValue) error = 'Username is required';
    // else if (!emailValue) error = 'Email is required';
    // else if (!isEmail(emailValue)) error = 'Invalid email address';

    if (error) {
      setError(error);
      setFormLoading(false);
      return;
    }

    const patch = {};

    if (userState.firstName !== firstNameValue) patch.firstName = firstNameValue;
    if (userState.lastName !== lastNameValue) patch.lastName = lastNameValue;
    // if (userState.email !== emailValue) patch.email = emailValue;
    if (userState.username !== usernameValue) patch.username = usernameValue;

    if (newAvatarUrl !== null) patch.photoURL = newAvatarUrl;

    if (!Object.keys(patch).length) {
      // There are no changes, so just switch out of editing mode
      setFormEditing(false);
      setFormLoading(false);

      return;
    }

    try {
      await setUser(patch);
    } catch(e) {
      if (e instanceof AlreadyRegisteredUsernameError) {
        setError('Username is taken');
      } else {
        setError('Something went wrong, please try again');
        console.error(e);
      }

      setFormLoading(false);
      return;
    }

    setFormLoading(false);
    setFormEditing(false);
  }, [userState, formLoading, newAvatarUrl, firstNameValue, lastNameValue, usernameValue]);

  return (
    <Sidebar
      className={styles.sidebar}
      headerChildren={
        <>
          <div className={styles.info}>
            <div className={clsx(styles.picWrapper, formEditing && styles.picWrapperEditing)}>
              <Avatar
                className={styles.pic}
                src={formEditing ?
                  newAvatarUrl :
                  user?.photoURL}
              />
              {formEditing && <>
                <input
                  aria-label="Change avatar"
                  className={styles.input}
                  ref={avatarRef}
                  onInput={() => setError('')}
                  onChange={handleImageUpload}
                  type="file"
                  accept="image/png,image/jpeg,image/gif"
                />
                <span className={styles.focusRing} aria-hidden="true" />
                <IconMediaCamera />
              </>}
            </div>
            <div>
              <Heading tag="span" level={HEADING_LEVEL_2} className={styles.name}>{fullName || userState.username}</Heading>
              {!formEditing && <Button onClick={handleEditProfile}>Edit Profile</Button>}
            </div>
          </div>
        </>
      }
      footerChildren={!formEditing && (
        <Button className={styles.signOut} negative onClick={signOut}>
          <IconExit/>Sign Out
        </Button>
      )}
    >

      {!formEditing &&
        <>
          <dl>
            <div className={styles.detail}>
              <InputLabel tag="dt" size={INPUT_SIZE_SMALL}>Email</InputLabel>
              <dd>{userState?.email?.replace('@','\u200B@')}</dd>
            </div>

            <div className={styles.detail}>
              <InputLabel tag="dt" size={INPUT_SIZE_SMALL}>Username</InputLabel>
              <dd>{userState.username}</dd>
            </div>
          </dl>

          <Divider className={styles.divider} />

          <Heading className={styles.subheading} tag="span" level={HEADING_LEVEL_5}>Preferences</Heading>

          {notifications !== 'unsupported' && <>
            <button
              className={styles.notifications}
              aria-label={notifications === 'granted' ? 'Disable notifications' : 'Enable notifications'}
              aria-checked={notifications === 'granted'}
              role="switch"
              onClick={handleNotificationToggle}
              disabled={notifications === 'denied'}
            >
              <InputLabel tag="span" size={INPUT_SIZE_SMALL} className={styles.notificationsLabel} aria-hidden="true">Notifications</InputLabel>
              <span
                className={clsx(styles.toggle, notifications === 'granted' && styles.active)}
                aria-hidden="true"
              />
            </button>

            {notifications === 'denied' &&
              <p className={styles.denied}>You have disabled notifications. To enable them, update the notification settings in your browser and restart the&nbsp;app.</p>
            }
          </>}

          <Input
            tag="select"
            className={styles.colorPreference}
            value={colorScheme}
            onChange={e => setColorScheme(e.target.value)}
            label="Color Mode"
            inlineLabel
            size={INPUT_SIZE_SMALL}
          >
            <option value="auto">Auto</option>
            <option value="light">Light</option>
            <option value="dark">Dark</option>
          </Input>

          <Divider className={styles.divider} />

          <ProfileSidebarCalendars/>
        </>
      }

      {formEditing &&
        <form
          className={styles.editForm}
          onSubmit={handleSubmit}
          noValidate
        >
          <Input
            label="First Name"
            value={firstNameValue}
            onInput={() => setError('')}
            onChange={e => setFirstNameValue(e.target.value)}
            ref={firstNameRef}
          />

          <Input
            label="Last Name"
            value={lastNameValue}
            onInput={() => setError('')}
            onChange={e => setLastNameValue(e.target.value)}
          />

          <Input
            label="Email"
            value={emailValue}
            readOnly
          />

          <Input
            label="Username"
            value={usernameValue}
            onInput={() => setError('')}
            onChange={e => setUsernameValue(e.target.value)}
          />

          {error && <span className={styles.error}>{error}</span>}

          <Button type="submit" loading={formLoading}>Save Changes</Button>
          <Button type="cancel" negative onClick={() => setFormEditing(false)}>Cancel</Button>
        </form>
      }
    </Sidebar>
  );
}

export default ProfileSidebar;
