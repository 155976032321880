import React, { useMemo, useCallback, useRef } from 'react';

import { composeEventDescription } from '../../../services/DbService/events';

import useUniqueId from '../../../hooks/useUniqueId';
import useReplaceMentions from '../../../hooks/useReplaceMentions';

import EventCard from './../EventCard';
import ScheduledEventDialog from './ScheduledEventDialog';

function ScheduledEvent({
  event,
  category,
  block,
  actions,
  dragging = false,
  boxes,
  onOpen,
  onClose,
  open,
}) {
  const {
    id: eventId,
    startDate,
    duration,
  } = event;

  const dialogId = useUniqueId();

  const eventRef = useRef();

  const handleDialogClose = useCallback(() => {
    if (!open) return;

    onClose();
    eventRef.current.focus();
  }, [onClose, open]);

  const handleClick = useCallback(e => {
    if (open) {
      handleDialogClose();
    } else {
      onOpen(eventId);
    }
  }, [eventId, open, onOpen, handleDialogClose]);

  const replaceMentions = useReplaceMentions();

  const description = useMemo(() => {
    return composeEventDescription(block, actions, replaceMentions);
  }, [block, actions, replaceMentions]);

  return (
    <>
      <EventCard
        ref={eventRef}
        eventId={eventId}
        color={category?.color}
        resizable
        draggable
        dragging={dragging}
        description={description}
        startDate={startDate}
        duration={duration}
        boxes={boxes}
        onClick={handleClick}
        aria-expanded={open}
        id={dialogId}
      />

      {open &&
        <ScheduledEventDialog
          eventId={eventId}
          startDate={startDate}
          eventRef={eventRef}
          category={category}
          block={block}
          actions={actions}
          onClose={handleDialogClose}
          aria-labelledby={dialogId}
        />
      }
    </>
  );
}

export default ScheduledEvent;
