import React from 'react';
import clsx from 'clsx';

import styles from './Dialog.module.scss';

function DialogFooter({
  className,
  children,
  ...props
}) {
  return (
    <footer {...props} className={clsx(styles.footer, className)}>
      {children}
    </footer>
  );
}

export default DialogFooter;
