import React, { useRef, useState } from 'react';

import { composeCategoryColors } from '../../services/DbService/categories.js';

import useUniqueId from '../../hooks/useUniqueId';

import { BUTTON_SIZE_XSMALL } from '../Button';
import { useCategories, useGetCategoryColors } from '../CategoriesContext';
import ContextualMenu, { ContextualMenuTrigger, ContextualMenuLink, ContextualMenuHeading } from '../ContextualMenu';
import CreateButton from '../CreateButton';

export default function ContextualMenuCategories({
  onCategoryClick,
  onCreateClick,
}) {
  const { activeCategories, hiddenCategories } = useCategories();
  const getCategoryColors = useGetCategoryColors();

  const hiddenCategoriesMenuButtonRef = useRef();
  const hiddenCategoriesMenuButtonId = useUniqueId();
  const [hiddenCategoriesMenuVisible, setHiddenCategoriesMenuVisible] = useState(false);

  return (
    <React.Fragment>
      <ContextualMenuHeading hasButton={!!onCreateClick}>
        Categories
        {onCreateClick &&
          <CreateButton
            onClick={onCreateClick}
            size={BUTTON_SIZE_XSMALL}
            aria-label="New Category"
          />
        }
      </ContextualMenuHeading>

      {activeCategories && activeCategories.map(category => (
        <ContextualMenuLink
          key={category.id}
          onClick={() => onCategoryClick && onCategoryClick(category.id)}
          category
          style={getCategoryColors(category.id)}
        >
          {category.name}
        </ContextualMenuLink>
      ))}

      <ContextualMenuTrigger
        visible={hiddenCategoriesMenuVisible}
        setVisible={setHiddenCategoriesMenuVisible}
        menuId={hiddenCategoriesMenuButtonId}
        submenu
      >
        <ContextualMenuLink
          ref={hiddenCategoriesMenuButtonRef}
          submenu
          disabled={!hiddenCategories?.length}
        >
          Hidden Categories
        </ContextualMenuLink>
      </ContextualMenuTrigger>

      {hiddenCategoriesMenuVisible &&
        <ContextualMenu
          buttonRef={hiddenCategoriesMenuButtonRef}
          onClose={() => setHiddenCategoriesMenuVisible(false)}
          submenu
          aria-labelledby={hiddenCategoriesMenuButtonId}
        >
          {hiddenCategories && hiddenCategories.map(category => (
            <ContextualMenuLink
              key={category.id}
              onClick={() => onCategoryClick && onCategoryClick(category.id)}
              category
              style={composeCategoryColors(category.color)}
            >
              {category.name}
            </ContextualMenuLink>
          ))}
        </ContextualMenu>
      }
    </React.Fragment>
  );
}
