import React from 'react';

import { ACTIVE, STARRED, UNSTARRED, UNCATEGORIZED_ID } from '../../../services/DbService/constants';

import { useGetCategoryColors } from '../../CategoriesContext';
import Heading, { HEADING_LEVEL_4 } from '../../Heading';
import LoadingSpinner from '../../LoadingSpinner';
import EmptyStateIcons from '../../EmptyState/EmptyStateIcons';
import DashboardSidebarList from './DashboardSidebarList';

import styles from './DashboardSidebarThisWeek.module.scss';

function DashboardSidebarThisWeek({
  categories = null,
}) {
  const getCategoryColors = useGetCategoryColors();

  if (categories === null) {
    return (
      <LoadingSpinner absolute />
    );
  }

  const filteredCategories = categories.filter(category => {
    const {
      [STARRED]: starredActionsIds,
      [UNSTARRED]: unstarredActionsIds,
    } = category.orderedActionsIds[ACTIVE];

    const blocksIds = category.orderedBlocksIds[ACTIVE];

    // Uncategorized block doesn't show actions so shouldn't show here if there are only actions
    if (category.id === UNCATEGORIZED_ID) {
      return blocksIds.length;
    }

    return starredActionsIds.length + unstarredActionsIds.length + blocksIds.length;
  });

  if (filteredCategories.length === 0) {
    return (
      <EmptyStateIcons>
        You don’t have any blocks or actions in any categories. Switch to a category to create&nbsp;some.
      </EmptyStateIcons>
    );
  }

  return (
    <>
      {filteredCategories.map(category => (
        <React.Fragment key={category.id}>
          <Heading className={styles.title} level={HEADING_LEVEL_4}>{category.name}</Heading>
          <DashboardSidebarList
            state={ACTIVE}
            categoryId={category.id}
            style={getCategoryColors(category.id)}
            enableEmptyState={false}
          />
        </React.Fragment>
      ))}
    </>
  );
}
export default DashboardSidebarThisWeek;
