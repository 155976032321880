import React, { useEffect, useState } from 'react';
import { startOfDay, subDays } from 'date-fns';

import { watchUserCounters } from '../../services/DbService/general';

import Page from '../Page';
import ProfileSidebar from './ProfileSidebar';
import Divider from '../Divider';
import LoadingSpinner from '../LoadingSpinner';
import Heading from '../Heading';

import { DialogContextProvider } from '../Dialog';

import { ReactComponent as IconBlocks } from '../../assets/icons/16-blocks.svg';
import { ReactComponent as IconActions } from '../../assets/icons/16-actions.svg';
import { ReactComponent as IconLeveraged } from '../../assets/icons/16-leveraged.svg';
import { ReactComponent as IconStarOutline } from '../../assets/icons/16-star-outline.svg';

import styles from './Profile.module.scss';

function PeopleMainSection({
  subtitle,
  blocksCompleted,
  actionsCompleted,
  actionsCompletedStarred,
  actionsCompletedLeveraged,
  loading,
  ...props
}) {
  return (
    <div>
      <Divider className={styles.topLine} />
      <Heading level="h4" className={styles.subtitle}>{subtitle}</Heading>
      {loading ?
        <LoadingSpinner className={styles.loader} /> :
        <ul className={styles.sectionList}>
          <li>
            <IconBlocks role="presentation" />
            <span><span className={styles.count}>{blocksCompleted}</span> blocks completed</span>
          </li>
          <li>
            <IconActions role="presentation" />
            <span><span className={styles.count}>{actionsCompleted}</span> actions completed</span>
          </li>
          <li>
            <IconLeveraged role="presentation" />
            <span><span className={styles.count}>{actionsCompletedLeveraged}</span> actions leveraged</span>
          </li>
          <li>
            <IconStarOutline role="presentation" />
            <span><span className={styles.count}>{actionsCompletedStarred}</span> actions starred</span>
          </li>
        </ul>}
    </div>
  );
}

function Profile({
  ...props
}) {
  const [weekCounters, setWeekCounters] = useState(null);
  const [monthCounters, setMonthCounters] = useState(null);
  const [allCounters, setAllCounters] = useState(null);

  useEffect(() => {
    const startDate = startOfDay(subDays(new Date(), 7));
    return watchUserCounters(startDate, setWeekCounters);
  }, []);

  useEffect(() => {
    const startDate = startOfDay(subDays(new Date(), 30));
    return watchUserCounters(startDate, setMonthCounters);
  }, []);

  // TODO: restore incremental counters when they are implemented on mobile too

  // useEffect(() => watchUserAllTimeCounters(setAllCounters), []);
  useEffect(() => watchUserCounters(new Date(0), setAllCounters), []);

  return (
    <Page pageTitle="Profile">
      <ProfileSidebar />
      <div className={styles.main}>
        <Heading level="h1" className={styles.title}>Progress</Heading>
        <PeopleMainSection subtitle="Last Week" {...weekCounters} loading={weekCounters === null} />
        <PeopleMainSection subtitle="Last Month" {...monthCounters} loading={monthCounters === null} />
        <PeopleMainSection subtitle="All Time" {...allCounters} loading={allCounters === null} />
      </div>
    </Page>
  );
}

const ProfileWithContext = (props) => (
  <DialogContextProvider>
    <Profile {...props} />
  </DialogContextProvider>
);

export default ProfileWithContext;
