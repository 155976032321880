import isUUID from 'validator/es/lib/isUUID';
import { generateId, document, collection, ParameterError, firestore, CacheDelayer } from './general';
import { trackEvent } from '../AnalyticsService';

export class Event {
  static fromFirestore(snapshot, options) {
    const data = snapshot.data(options);
    if (!data) return undefined;
    return Object.assign(new Event(), {
      id: snapshot.id,
      categoryId: data.category_id,
      blockId: data.block_id,
      startDate: data.start_date && new Date(data.start_date),
      duration: data.duration, // in seconds
      actionsIds: data.actions_ids || [],
      creationDate: data.date_of_creation && new Date(data.date_of_creation),
    });
  }
}

export function watchEvents(startDate, endDate, callback) {
  const cacheDelayer = new CacheDelayer();
  const unsubscribe = collection('events')
    .where('start_date', '>=', startDate.toISOString())
    .where('start_date', '<=', endDate.toISOString())
    .onSnapshot(snapshot => {
      const docs = snapshot.docs.map(Event.fromFirestore);
      cacheDelayer.delayIfCached(() => callback(docs), snapshot);
    });
  return () => {
    unsubscribe();
    cacheDelayer.dispose();
  }
}

export function createEventHelper(batch, startDate, duration, categoryId, blockId, actionsIds) {
  if (!startDate instanceof Date)
    throw new ParameterError({startDate}, 'not a Date object');
  if (isNaN(startDate))
    throw new ParameterError({startDate}, 'invalid date');
  if (!Number.isInteger(duration))
    throw new ParameterError({duration}, 'not an integer number');
  if (duration <= 0)
    throw new ParameterError({duration}, 'can\'t be <= 0' );
  if (!isUUID(categoryId))
    throw new ParameterError({categoryId}, 'not a valid UUID');
  if (blockId !== null && !isUUID(blockId))
    throw new ParameterError({blockId}, 'can be only null or a valid UUID');
  if (!Array.isArray(actionsIds))
    throw new ParameterError({actionsIds}, 'not an array');
  if (actionsIds.some(id => !isUUID(id)))
    throw new ParameterError({actionsIds}, 'must contain only UUIDs');

  const id = generateId();

  const newEvent = {
    id,
    date_of_creation: new Date().toISOString(),
    start_date: startDate.toISOString(),
    duration,
    category_id: categoryId,
    block_id: blockId,
    actions_ids: actionsIds,
  };

  batch.set(document(`events/${id}`), newEvent);

  return newEvent;
}

export function createEvent(startDate, duration, categoryId, blockId, actionsIds) {
  const batch = firestore().batch();
  const newEvent = createEventHelper(batch, startDate, duration, categoryId, blockId, actionsIds);
  batch.commit();

  trackEvent('User created an Event', {
    id: newEvent.id,
    duration,
    categoryId,
    blockId,
    actionsIds,
    startDate,
  });
}

export function deleteEvent(eventId, externalBatch = null) {
  const batch = externalBatch || firestore().batch();
  batch.delete(document(`events/${eventId}`));
  if (!externalBatch) batch.commit();
}


export async function setEventStartDate(eventId, startDate) {
  if (!startDate instanceof Date)
    throw new ParameterError({startDate}, 'not a Date object');
  if (isNaN(startDate))
    throw new ParameterError({startDate}, 'invalid date');

  document(`events/${eventId}`).update({
    start_date: startDate.toISOString(),
  });
}

export async function setEventDuration(eventId, duration) {
  if (!Number.isInteger(duration))
    throw new ParameterError({duration}, 'not an integer number');
  if (duration <= 0)
    throw new ParameterError({duration}, 'can\'t be <= 0' );

  document(`events/${eventId}`).update({
    duration,
  });
}

export function composeEventDescription(block, actions, replaceMentions) {
  let eventDescription = '';
  if (block) eventDescription = block.result;
  if (actions?.length) {
    if (eventDescription !== '') eventDescription += ': ';
    actions.forEach((action, i) => {
      if (i > 0) eventDescription += ', ';
      eventDescription += replaceMentions(action.description);
    });
  }
  return eventDescription;
}
