import React, { useState, useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import clsx from 'clsx';

import usePreviousValue from '../../../hooks/usePreviousValue';

import { ACTIVE, COMPLETED, SNOOZED, BLOCK_ACTIONS_COMPLETION_THRESHOLD } from '../../../services/DbService/constants';
import { watchCategory } from '../../../services/DbService/categories';
import { watchBlock, watchBlockCompletionProgress } from '../../../services/DbService/blocks';
import fireConfetti from '../../../services/ConfettiService';

import { useShowCreateActionDialog, useSetQuickCaptureParent } from '../../ActionDialog';

import { useCategoryColors } from '../../CategoriesContext';
import Accordion from '../../Accordion';
import CreateButton from '../../CreateButton';
import ActionsList from '../../ActionsList';
import Head from '../../Head';
import LoadingSpinner from '../../LoadingSpinner';
import BlockDetailHeader from './BlockDetailHeader';
import BlockDetailToast from './BlockDetailToast';
import Dialog from '../../Dialog';
import Heading from '../../Heading';
import Button from '../../Button';
import FloatingButton from '../../FloatingButton';
import { BlockItemCompleteDialog, tryCompleteBlock } from '../../BlockItem';
import CelebrationDialog from '../../BlockItem/CelebrationDialog';

import { ReactComponent as IconSnoozed } from '../../../assets/icons/16-snoozed.svg';
import { ReactComponent as IconCompleted } from '../../../assets/icons/16-completed.svg';
import { ReactComponent as IconCompletedInverted } from '../../../assets/icons/16-completed-inverted.svg';

import styles from './BlockDetail.module.scss';

function BlockDetail({
  className,
  ...props
}) {
  const { blockId } = useParams();

  const [editing, setEditing] = useState(null);
  const [block, setBlock] = useState({});
  const [category, setCategory] = useState({});
  const [showCompleted, setShowCompleted] = useState(false);
  const [totalCompletedActions, setTotalCompletedActions] = useState(null);
  const prevState = usePreviousValue(block.state);

  const categoryColor = useCategoryColors(category?.id);

  const [pdfExporting, setPdfExporting] = useState(false);
  const [pdfVisible, setPdfVisible] = useState(false);

  const setQuickCaptureParent = useSetQuickCaptureParent();
  const showCreateActionDialog = useShowCreateActionDialog();

  const [completionDialogVisible, setCompleteDialogVisible] = useState(false);

  const [celebrationDialogVisible, setCelebrationDialogVisible] = useState(false);

  useEffect(() => watchBlock(blockId, setBlock), [blockId]);

  // Rather than throwing the celebration modal onComplete and limiting it to just the
  // BlockDetailHeader menu, this uses the previous block state and watches for changes.
  // When it detects a change to a completion, it throws the celebration dialog and
  // fires the confetti cannons!
  useEffect(() => {
    if (!prevState) return;

    if (block.state === COMPLETED && prevState !== COMPLETED) {
      setCelebrationDialogVisible(true);
      fireConfetti();
    }
  }, [block.state, prevState]);

  useEffect(() => {
    // If the block is completed the quick capture parent should be left as quick capture
    if (!block.id || block.state === COMPLETED) return;
    setQuickCaptureParent(block.id);
    return () => setQuickCaptureParent(null);
  }, [block.id, block.state, setQuickCaptureParent]);

  useEffect(() => {
    if (!block.categoryId) return;

    return watchCategory(block.categoryId, setCategory);
  }, [block.categoryId]);

  const handleCompleteClick = useCallback(async () => {
    await tryCompleteBlock(blockId, () => setCompleteDialogVisible(true));
  }, [blockId]);

  const handlePdfExport = useCallback(async () => {
    setPdfExporting(true);

    // This is downloaded asyncronously to avoid lumping all of the Pdf bundlers in
    // with the page load when most users won't use it
    const { generateBlockPdf } = await import('../../../services/PdfService');

    await generateBlockPdf(block.id);

    setPdfExporting(false);
    setPdfVisible(false);
  }, [block.id]);

  const [completionProgress, setCompletionProgress] = useState(null);
  useEffect(() => {
    if (!block.id) return;
    if (block.state !== ACTIVE) {
      setCompletionProgress(null);
      return;
    }
    return watchBlockCompletionProgress(block.id, block.state, setCompletionProgress);
  }, [block.id, block.state]);

  const readonly = block.state === COMPLETED;

  const handleFetch = useCallback((actions = {}) => {
    // actions is an object of starred and unstarred actions, so this is flattened
    // to get the total number of completed actions to display the accordion.
    setTotalCompletedActions(Object.values(actions).flat().length);
  }, []);

  if (!category.id || !block.id) return (
    <article {...props} className={clsx(className, styles.wrapper)}>
      <LoadingSpinner absolute={true} />
    </article>
  );

  return (
    <>
      <Head title={block?.result} />
      <article
        {...props}
        className={className}
        style={categoryColor}
      >
        <BlockDetailHeader
          block={block}
          category={category}
          onExportPdf={() => setPdfVisible(true)}
          onEditingToggle={setEditing}
        />

        {!editing &&
          <div className={styles.group}>
            <Heading tag="h2" level="h5">Actions</Heading>
            <ActionsList
              categoryId={category.id}
              blockId={blockId}
              state={ACTIVE}
              readonly={readonly}
              showCounter
              showNumbers
            />

            {!readonly &&
              <CreateButton
                onClick={() => showCreateActionDialog(blockId)}
                aria-label='Add action'
                tooltip
              />
            }

            <div className={clsx(!totalCompletedActions && styles.completedHidden)}>
              <Accordion
                open={showCompleted}
                onToggle={setShowCompleted}
                heading={`Completed (${totalCompletedActions})`}
              >
                <ActionsList
                  categoryId={category.id}
                  blockId={blockId}
                  state={COMPLETED}
                  readonly={readonly}
                  onFetch={handleFetch}
                />
              </Accordion>
            </div>

         </div>
        }
      </article>

      {block.state === SNOOZED &&
        <BlockDetailToast
          blockId={blockId}
          icon={IconSnoozed}
          title='Block Snoozed'
          dialogMessage={'This block is currently snoozed. You can make this active, which will move it to the active\xa0tab.'}
        />
      }
      {block.state === COMPLETED &&
        <BlockDetailToast
          blockId={blockId}
          style={categoryColor}
          icon={IconCompletedInverted}
          title='Block Completed'
          dialogMessage={'This block is uneditable as it has been completed. To make changes, please make the block\xa0active.'}
        />
      }

      {completionProgress &&
        <FloatingButton
          className={styles.floatingButton}
          onClick={handleCompleteClick}
          aria-label={completionProgress === 'threshold' ?
            `You have completed more than ${BLOCK_ACTIONS_COMPLETION_THRESHOLD * 100}% of actions` :
            'You have completed all starred actions'
          }
          tooltip
        >
          <IconCompleted role="presentation" />
          Complete Block
        </FloatingButton>}

      {completionDialogVisible &&
        <BlockItemCompleteDialog
          blockId={blockId}
          onClose={() => setCompleteDialogVisible(false)}
        />
      }

      {celebrationDialogVisible &&
        <CelebrationDialog
          title="Block completed"
          description={block.result}
          categoryId={category?.id}
          image={block.backgroundImage}
          onClose={() => setCelebrationDialogVisible(false)}
        />
      }

      {pdfVisible &&
        <Dialog
          headerTitle={block?.result}
          onClose={() => setPdfVisible(false)}
          footer={(
            <Button block onClick={handlePdfExport} loading={pdfExporting}>Export</Button>
          )}
        >
          Generate a PDF from this&nbsp;block.
        </Dialog>
      }
    </>
  );
}

export default BlockDetail;
