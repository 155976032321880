import React from 'react';
import clsx from 'clsx';

import styles from './EmptyState.module.scss';

function EmptyState({
  className,
  headerChildren,
  children,
  ...props
}) {
  return (
    <div {...props} className={clsx(className, styles.container)}>
      {headerChildren}

      <p className={styles.message}>
        {children}
      </p>
    </div>
  );
}

export default EmptyState;
