import React, { forwardRef } from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';

import styles from './Link.module.scss';

export const SMART_LINK_STYLE_LINK = 'link';
export const SMART_LINK_STYLE_PLACEHOLDER = 'placeholder';

const SmartLink = forwardRef(({
  className,
  children,
  to,
  href,
  linkStyle = SMART_LINK_STYLE_LINK,
  negative,
  ...props
}, ref) => {
  let LinkTag;

  if (to) {
    LinkTag = Link;
    props.to = to;
  } else if (href) {
    LinkTag = 'a';
    props.href = href;
  } else {
    LinkTag = 'button';
  }

  return (
    <LinkTag
      ref={ref}
      className={clsx(
        className,
        styles.base,
        styles[linkStyle],
        negative && styles.negative,
      )}
      {...props}
    >
      {children}
    </LinkTag>
  );
});

export default SmartLink;
