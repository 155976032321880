import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import clsx from 'clsx';

import {
  ACTIVE, COMPLETED, SNOOZED, UNCATEGORIZED_ID,
} from '../../services/DbService/constants';
import {
  actionHasEvents,
  deleteAction,
  setActionTime,
  setActionStarred,
  setActionState,
  setActionLeveraged,
  setActionList,
} from '../../services/DbService/actions';

import usePeople from '../../hooks/usePeople';
import useReplaceMentions, { mentionToText } from '../../hooks/useReplaceMentions';
import useUniqueId from '../../hooks/useUniqueId';

import { useShowEditActionDialog } from '../ActionDialog';
import Button from '../Button';
import { CardButton, CardCategoryMenu } from '../Card';
import ContextualMenu, {
  ContextualMenuLink, ContextualMenuTrigger, MENU_POSITION_HORIZONTAL,
} from '../ContextualMenu';
import Dialog from '../Dialog';
import LeverageDialog from './LeverageDialog';
import Tooltip from '../Tooltip';

import { ReactComponent as IconLeveraged } from '../../assets/icons/16-leveraged.svg';
import { ReactComponent as IconCheck } from '../../assets/icons/16-check.svg';
import { ReactComponent as IconUnneccessary } from '../../assets/icons/16-unnecessary.svg';
import { ReactComponent as IconStar } from '../../assets/icons/16-star.svg';
import { ReactComponent as IconStarOutline } from '../../assets/icons/16-star-outline.svg';
import { ReactComponent as IconMore } from '../../assets/icons/16-more.svg';
// This uses the same dropdown arrows as <Input tag="select" /> but otherwise has the visual
// styles of a Button so only the icon is imported
import { ReactComponent as SelectArrow } from '../Input/select-arrow.svg';

import styles from './ActionItem.module.scss';

export const TIME_VALUES = {
  // 0: '0m',
  300: '5m',
  600: '10m',
  900: '15m',
  1800: '30m',
  2700: '45m',
  3600: '1h',
  5400: '1.5h',
  7200: '2h',
  10800: '3h',
  18000: '5h',
  28800: '8h',
  43200: '12h',
};

const UNSET = 'unset';

// To allow time to be set to zero we need to override the falsey time === 0 check to
// default to UNSET. This is currently unsed, but is likely to be used in the future, and
// is a non-breaking change.
const getTimeValue = time => time || time === 0 ? time : UNSET;

const mentionToTag = person => `<span>${mentionToText(person)}</span>`;

function ActionItem({
  className,
  children,
  action,
  readonly,
  cardWithColor,
  ...props
}) {
  const actionRef = useRef();

  const {
    id,
    categoryId,
    blockId,
    description,
    starred,
    unnecessary,
    fromEmail,
    leveragedPersonId,
    time,
    state,
  } = action;

  const showEditActionDialog = useShowEditActionDialog();

  const [timeSelectValue, setTimeSelectValue] = useState(getTimeValue(time));
  const { peopleById } = usePeople();

  const [leverageDialogVisible, setLeverageDialogVisible] = useState(false);
  const [deleteDialogVisible, setDeleteDialogVisible] = useState(false);

  const optionsMenuButtonRef = useRef();
  const optionsMenuButtonId = useUniqueId();
  const [rightClickMenuCoords, setRightClickMenuCoords] = useState(null);
  const [optionsMenuVisible, setOptionsMenuVisible] = useState(null);

  const leveragedPerson = useMemo(() => peopleById[leveragedPersonId], [peopleById, leveragedPersonId]);

  const newState = (unnecessary || state === SNOOZED || state === ACTIVE) ? COMPLETED : ACTIVE;

  const completed = state === COMPLETED;
  // You can't snooze actions in blocks or in the capture list
  const snoozable = state === ACTIVE && blockId === null && categoryId !== UNCATEGORIZED_ID;
  const snoozed = state === SNOOZED;

  useEffect(() => setTimeSelectValue(getTimeValue(time)), [time]);

  const replaceMentions = useReplaceMentions();
  const replaceMentionsWithTags = useReplaceMentions(mentionToTag);

  const [descriptionText, descriptionHtml] = useMemo(() => {
    return [
      replaceMentions(description),
      replaceMentionsWithTags(description),
    ];
  }, [replaceMentions, replaceMentionsWithTags, description]);

  const handleLeverageToggle = useCallback(() => {
    if (leveragedPersonId) {
      setActionLeveraged(id, null);
    } else {
      setLeverageDialogVisible(true);
    }
    setOptionsMenuVisible(false);
  }, [leveragedPersonId, id]);

  const handleLeverageDialogConfirm = useCallback(person => {
    setActionLeveraged(id, person.id);
  }, [id]);

  const handleLeverageDialogClose = useCallback(() => {
    setLeverageDialogVisible(false);
    optionsMenuButtonRef?.current && optionsMenuButtonRef.current.focus();
  }, []);

  const onTimeSelectChange = useCallback((e) => {
    const time = e.target.value === UNSET ? null : parseInt(e.target.value);
    setActionTime(id, time);
  }, [id]);

  const onToggleStarred = useCallback(() => {
    setActionStarred(id, !starred);
  }, [id, starred]);

  const onToggleCompletionButton = useCallback((e, key) => {
    e.currentTarget.blur();

    if (key === 'state') {
      setActionState(id, newState);
    } else if (key === 'unnecessary') {
      setActionState(id, COMPLETED, !unnecessary);
    }

    // onToggleCompletionButton is called outside the menu too, but given
    // that any mouse clicks outside the menu would also close the menu
    // this is safe to call any time this function is called.
    setOptionsMenuVisible(false);
  }, [id, newState, unnecessary, setOptionsMenuVisible]);

  const handleCategoryClick = useCallback((newCategoryId) => {
    if (categoryId === newCategoryId) return;

    setActionList(id, newCategoryId, null, state, starred);
  }, [categoryId, id, state, starred]);

  const handleEdit = useCallback(() => {
    showEditActionDialog(action);
  }, [showEditActionDialog, action]);

  const handleRightClick = useCallback((e) => {
    if (readonly) return;
    e.preventDefault();

    setRightClickMenuCoords({ x: e.clientX, y: e.clientY });
    setOptionsMenuVisible(visible => !visible);
  }, [readonly]);

  // When the menu is closed reset the right click coordinates so that if it is opened
  // using the ellipsis, it uses the buttonRef rather than the mouseCoords
  useEffect(() => {
    if (optionsMenuVisible) return;

    setRightClickMenuCoords(null);
  }, [optionsMenuVisible]);

  const performDelete = useCallback(() => {
    deleteAction(id);
  }, [id]);

  const handleDelete = useCallback(async () => {
    if (await actionHasEvents(id)) {
      setDeleteDialogVisible(true);
    } else {
      performDelete();
    }
    setOptionsMenuVisible(false);
  }, [id, performDelete]);

  const handleSnoozeClick = useCallback(() => setActionState(id, SNOOZED), [id]);
  const handleMakeActiveClick = useCallback(() => setActionState(id, ACTIVE), [id]);

  const LabelTag = readonly ? 'span' : 'button';

  if (peopleById === null) return null;

  return (
    <li
      ref={actionRef}
      className={clsx(
        className,
        styles.card,
        cardWithColor && styles.cardWithColor,
      )}
      onContextMenu={handleRightClick}
      {...props}
    >
      <Tooltip
        title={!readonly ? `Edit Action: ${descriptionText}` : descriptionText}
        disabled={optionsMenuVisible}
      >
        <LabelTag
          className={styles.label}
          onClick={!readonly ? handleEdit : undefined}
          aria-label={!readonly ? `Edit Action: ${descriptionText}` : descriptionText}
        >
          {fromEmail &&
            <span className={styles.emailLabel}>Email Capture</span>}
          <span
            className={clsx(styles.description, completed && styles.descriptionCompleted)}
            dangerouslySetInnerHTML={{__html: descriptionHtml}}
          />
          {leveragedPerson && <span className={clsx(styles.leveraged, completed && styles.leveragedCompleted)}>
            <IconLeveraged role="presentation" />
            <span className={styles.leveragedLabel}>
              <span>Leveraged to&nbsp;</span>
              <span className={styles.leveragedName}>@{leveragedPerson.nickname}</span>
            </span>
          </span>}
        </LabelTag>
      </Tooltip>

      <div className={clsx(styles.buttons, 'sortable-ignore')}>
        <CardCategoryMenu
          categoryId={categoryId}
          cardWithColor={cardWithColor}
          onCategoryClick={handleCategoryClick}
        />

        <CardButton
          className={styles.buttonTime}
          aria-label="Set time"
          tag="label"
          disabled={readonly}
          iconOnly={false}
          cardWithColor={cardWithColor}
        >
          <select
            value={timeSelectValue}
            onChange={onTimeSelectChange}
            disabled={readonly}
          >
            <option value={UNSET}>–</option>
            {Object.keys(TIME_VALUES).map(value => (
              <option key={value} value={value}>{TIME_VALUES[value]}</option>
            ))}
          </select>
          <span className={styles.focusRing} aria-hidden="true" />
          <span className={styles.buttonTimeLabel}>{TIME_VALUES[time] || '–'}</span>
          <SelectArrow role="presentation" />
        </CardButton>

        <CardButton
          aria-label={starred ? 'Unstar Action' : 'Star Action'}
          onClick={onToggleStarred}
          disabled={readonly}
          selected={starred}
          cardWithColor={cardWithColor}
        >
          {starred && <IconStar role="presentation" />}
          {!starred && <IconStarOutline role="presentation" />}
        </CardButton>

        {!readonly &&
          <>
            <ContextualMenuTrigger
              visible={optionsMenuVisible}
              setVisible={setOptionsMenuVisible}
              menuId={optionsMenuButtonId}
            >
              <CardButton
                ref={optionsMenuButtonRef}
                aria-label="Action tools"
                cardWithColor={cardWithColor}
              >
                <IconMore role="presentation" />
              </CardButton>
            </ContextualMenuTrigger>

            {optionsMenuVisible &&
              <ContextualMenu
                buttonRef={optionsMenuButtonRef}
                contextRef={actionRef}
                mouseCoords={rightClickMenuCoords}
                onClose={() => setOptionsMenuVisible(false)}
                aria-labelledby={optionsMenuButtonId}
                position={MENU_POSITION_HORIZONTAL}
              >
                <ContextualMenuLink onClick={handleLeverageToggle}>
                  {leveragedPersonId ? 'Remove leverage' : 'Leverage to…'}
                </ContextualMenuLink>
                <ContextualMenuLink onClick={(e) => onToggleCompletionButton(e, 'unnecessary')}>
                  Mark {unnecessary ? 'necessary' : 'unnecessary'}
                </ContextualMenuLink>
                {snoozable &&
                  <ContextualMenuLink onClick={handleSnoozeClick}>Snooze Action</ContextualMenuLink>
                }
                {snoozed &&
                  <ContextualMenuLink onClick={handleMakeActiveClick}>Make active</ContextualMenuLink>
                }
                <ContextualMenuLink onClick={handleDelete} negative>Delete Action</ContextualMenuLink>
              </ContextualMenu>
            }
          </>
        }
      </div>

      <Tooltip title={newState === ACTIVE ? 'Mark as active' : 'Mark as completed'}>
        <button
          className={clsx(
            'sortable-ignore',
            styles.completionButton
          )}
          onClick={(e) => onToggleCompletionButton(e, 'state')}
          disabled={readonly}
          aria-label={newState === ACTIVE ? 'Mark as active' : 'Mark as completed'}
        >
          <span
            className={clsx(
              styles.completionButtonRing,
              completed && styles.completionButtonRingCompleted,
            )}
          >
            {unnecessary ?
              <IconUnneccessary role="presentation" /> :
              <IconCheck role="presentation" />
            }
          </span>
        </button>
      </Tooltip>

      {leverageDialogVisible &&
        <LeverageDialog
          onConfirm={handleLeverageDialogConfirm}
          onClose={handleLeverageDialogClose}
        />
      }

      {deleteDialogVisible &&
        <Dialog
          headerTitle="Scheduled Action"
          onClose={() => setDeleteDialogVisible(false)}
          footer={
            <>
              <Button block onClick={() => setDeleteDialogVisible(false)}>Cancel</Button>
              <Button block onClick={performDelete} negative>Delete action</Button>
            </>
          }
        >
          <p>This action is scheduled. Deleting it will also delete those scheduled events. Do you wish to&nbsp;proceed?</p>
        </Dialog>
      }
    </li>
  );
}

export default ActionItem;
