export class NetworkError extends Error {}
export class ApiParameterError extends Error {}
export class ApiError extends Error {
  constructor(response) {
    super('API call replied with errors:\n' + JSON.stringify(response, null, 2));
  }
}

// This provides an environment variable based on the dataset as described in #Environments in the readme
export const getEnvVar = (key) => process.env[`REACT_APP_${process.env.REACT_APP_DATASET}_${key}`];

export async function api(endpoint, body, args = {}) {
  const {
    method = 'POST',
    accessToken,
    contentType = 'application/json',
  } = args;

  const headers = {};

  if (contentType === 'application/json' && method !== 'GET') {
    body = JSON.stringify(body);
  }

  //  For multipart/form-data it's best not to set the Content-Type and allow the browser to infer
  //  the required boundaries
  if (contentType !== 'multipart/form-data') {
    headers['Content-Type'] = contentType;
  }

  if (accessToken) {
    headers.Authorization = `Bearer ${accessToken}`;
  }

  try {
    const response = await fetch(new URL(endpoint, getEnvVar('BACKEND_API_URL')), {
      method,
      headers,
      body,
    });
    return await response.json();
  } catch (e) {
    if (e.message === 'Timeout'
        || e.message === 'Network request failed'
        || e.message === 'Failed to fetch') {
      throw new NetworkError(e.message);
    } else {
      throw e;
    }
  }
}
