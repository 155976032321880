import React from 'react';

import EmptyState from './EmptyState';

import styles from './EmptyState.module.scss';

function EmptyStateActions({
  ...props
}) {
  return (
    <EmptyState
      headerChildren={<>
        <div className={styles.action} role="presentation"/>
        <div className={styles.action} role="presentation"/>
        <div className={styles.action} role="presentation"/>
      </>}
      {...props}
    />
  );
}

export default EmptyStateActions;
