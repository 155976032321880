import React, { useEffect, useState, useContext } from 'react';

import {
  SNOOZED, ALL_CATEGORY_ID, SNOOZED_TO_SOMETIME,
} from '../../../services/DbService/constants';
import { watchCategory } from '../../../services/DbService/categories';

import { DashboardContext } from '../../Dashboard';

import TabPanel from '../../TabPanel';
import LoadingSpinner from '../../LoadingSpinner';
import CreateBlockDialog from '../../CreateDialog/CreateBlockDialog';
import DashboardSidebarList from './DashboardSidebarList';

function DashboardSidebarCategory({
  state,
  categoryId,
  tabs,
  ...props
}) {
  const { setActiveCategoryId } = useContext(DashboardContext);

  const [category, setCategory] = useState(null);
  const [blockDialogVisible, setBlockDialogVisible] = useState(false);

  useEffect(() => {
    return watchCategory(categoryId, category => {
      // Active category checking is handled in DashboardContext for users signing in and registering,
      // but this is needed in the rare event that a user deletes a category on iOS and that category
      // then no longer exists when they reload the page on web. It's a rare edge-case, but this
      // simple condition ensures that the app doesn't crash, and instead shows them the
      // "all categories" view.
      if (category) {
        setCategory(category);
      } else {
        setActiveCategoryId(ALL_CATEGORY_ID);
      }
    });
  }, [categoryId, setActiveCategoryId]);

  if (category === null) {
    return (
      <LoadingSpinner absolute />
    );
  }

  return (
    // This div is needed to ensure that the active item gets the right spacing
    <div {...props}>
      {tabs && tabs.map(tab => (
        <TabPanel key={tab.id} id={tab.id} active={tab.state === state}>
          <DashboardSidebarList
            categoryId={category.id}
            state={tab.state}
            onCreateClick={() => setBlockDialogVisible(true)}
            cardWithColor
          />
        </TabPanel>
      ))}

      {blockDialogVisible &&
        <CreateBlockDialog
          categoryId={categoryId}
          state={state}
          starred={false}
          snoozedToString={state === SNOOZED ? SNOOZED_TO_SOMETIME : null}
          onClose={() => setBlockDialogVisible(false)}
        />
      }
    </div>
  );
}

export default DashboardSidebarCategory;
