import React from 'react';
import clsx from 'clsx';

import { ReactComponent as IconCheck } from '../../assets/icons/16-check.svg';

import styles from './Checkbox.module.scss';

function Checkbox({
  checked,
  label,
  className,
  ...props
}) {
  return (
    <label className={clsx(className, styles.wrapper)}>
      <span className={clsx(styles.tick, checked && styles.checked)} aria-hidden="true">
        <IconCheck />
      </span>
      <span className={styles.label}>{label}</span>

      <input {...props} className={styles.input} type="checkbox" checked={checked} />
      <span className={styles.focusRing} aria-hidden="true" />
    </label>
  );
}

export default Checkbox;
