import React from 'react';
import clsx from 'clsx';

import styles from './Toast.module.scss';

import { ReactComponent as IconErrorOutline } from './../../assets/icons/16-error-outline.svg';

function Toast({
  className,
  error,
  src,
  children,
  tag,
  // When you toggle hidden from true to false and viceversa, the toast slides
  // up/down from the bottom of the screen with a nice animation
  hidden,
  ...props
}) {
  const Tag = props.onClick ? 'button' : 'div';

  return (
    <Tag
      {...props}
      className={clsx(className, styles.toast, error && styles.error)}
      hidden={hidden}
    >
      {error && <IconErrorOutline aria-label="Error"/>}
      {children}
    </Tag>
  );
}

export default Toast;
