import React, { useCallback, useRef } from 'react';

import useUniqueId from '../../../hooks/useUniqueId';

import EventCard from '../EventCard';
import ExternalEventDialog from './ExternalEventDialog';

function ExternalEventCard({
  event,
  allDay = false,
  boxes,
  open,
  onOpen,
  onClose,
}) {
  const {
    id: eventId,
    startDate,
    duration,
    description,
  } = event;

  const elemId = useUniqueId();

  const eventRef = useRef();

  const handleDialogClose = useCallback(() => {
    if (!open) return;

    onClose();
    eventRef.current.focus();
  }, [onClose, open]);

  const handleClick = useCallback(e => {
    if (open) {
      handleDialogClose();
    } else {
      onOpen(eventId);
    }
  }, [eventId, open, onOpen, handleDialogClose]);

  return (
    <>
      <EventCard
        ref={eventRef}
        eventId={eventId}
        allDay={allDay}
        description={description}
        startDate={startDate}
        duration={duration}
        boxes={boxes}
        onClick={handleClick}
        aria-expanded={open}
        id={elemId}
        external
      />

      {open &&
        <ExternalEventDialog
          event={event}
          eventRef={eventRef}
          onClose={handleDialogClose}
          aria-labelledby={elemId}
        />
      }
    </>
  );
}

export default ExternalEventCard;
