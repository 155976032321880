import React from 'react';
import clsx from 'clsx';

import styles from './SidebarScroller.module.scss';

function SidebarScroller({
  children,
  className,
  ...props
}) {
  return (
    <div {...props} className={clsx(className, styles.wrapper)}>
      <div className={styles.scroller}>
        {children}
      </div>
    </div>
  );
}

export default SidebarScroller;
