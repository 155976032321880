import React, { useEffect, useState, useMemo, useCallback, useRef } from 'react';

import { composeUnsubscribers } from '../../../services/DbService/general';
import { ACTIVE, STARRED, UNSTARRED } from '../../../services/DbService/constants';
import { watchCategoryBlocks } from '../../../services/DbService/categories';
import { watchActions } from '../../../services/DbService/actions';
import { createEvent } from '../../../services/DbService/events';

import { useCategories, useGetCategoryColors } from '../../CategoriesContext';
import Button, { BUTTON_COLOR_CATEGORY } from '../../Button';
import DialogFooter from '../../Dialog/DialogFooter';
import NewEventDialogList from './NewEventDialogList';

function NewEventDialogRoot({
  hidden,
  dialogHidden,
  startDate,
  duration,
  onBlockSelected,
  onClose,
  ...props
}) {
  const [selected, setSelected] = useState([]);

  const [categoriesBlocks, setCategoriesBlocks] = useState({});
  const [categoriesActions, setCategoriesActions] = useState({});

  const { activeCategories } = useCategories();
  const getCategoryColors = useGetCategoryColors();

  // Reset selection when the dialog gets closed
  useEffect(() => {
    if (dialogHidden) setSelected([]);
  }, [dialogHidden]);

  // This is stored in a ref rather than a state to leverage the immutability so that
  // the useEffect doesn't call itself
  const childrenUnsubscribers = useRef([]);

  // Watch categories with their active blocks and actions
  // Note: it's required that all the lists maintain the user defined order
  useEffect(() => {
    if (activeCategories === null) return;

    // We are going to watch all the blocks and all the actions inside the
    // active categories

    // First release all the previous blocks/actions watchers (if present)
    childrenUnsubscribers.current.forEach(unsubscribe => unsubscribe());
    childrenUnsubscribers.current = [];

    // Then for each category received install the new watchers
    activeCategories.forEach(category => {
      const categoryId = category.id;

      const blocksWatcher = watchCategoryBlocks(categoryId, ACTIVE, null, categoryBlocks => {
        setCategoriesBlocks(categoriesBlocks => ({
          ...categoriesBlocks,
          [categoryId]: categoryBlocks,
        }));
      });

      const actionsWatcher = watchActions(categoryId, null, ACTIVE, null, categoryActions => {
        setCategoriesActions(categoriesActions => ({
          ...categoriesActions,
          [categoryId]: [
            ...categoryActions[STARRED],
            ...categoryActions[UNSTARRED],
          ],
        }));
      });

      childrenUnsubscribers.current.push(
        blocksWatcher,
        actionsWatcher,
      );
    });

    return composeUnsubscribers(childrenUnsubscribers.current);
  }, [activeCategories]);

  // Prepare the data structure consumed by NewEventDialogList
  // see the component implementation for more info
  const items = useMemo(() => {
    if (activeCategories === null) return [];

    const items = [];
    activeCategories.forEach(category => {
      items.push({category});
      (categoriesBlocks[category.id] || []).forEach(
        block => items.push({block}));
      (categoriesActions[category.id] || []).forEach(
        action => items.push({action}));
    });
    return items;
  }, [activeCategories, categoriesBlocks, categoriesActions]);

  const handleChange = useCallback(selected => setSelected(selected), []);

  const handleScheduleClick = useCallback(() => {
    createEvent(
      startDate,
      duration,
      selected[0].categoryId,
      null,
      selected.map(action => action.id)
    );
    onClose();
  }, [startDate, duration, selected, onClose]);

  const selectedCategoryColors = useMemo(() => {
    return getCategoryColors(selected[0]?.categoryId);
  }, [getCategoryColors, selected]);

  return (
    <div hidden={hidden} {...props}>
      <NewEventDialogList
        items={items}
        emptyMessage="
          You haven't created any blocks or actions.
          Please create some in the panel to the left before scheduling them.
        "
        selectedActions={selected}
        onChange={handleChange}
        onBlockSelected={onBlockSelected}
        parentHidden={hidden}
        dialogHidden={dialogHidden}
      />

      {selected.length > 0 &&
        <DialogFooter style={selectedCategoryColors}>
          <Button
            color={BUTTON_COLOR_CATEGORY}
            onClick={handleScheduleClick}
            disabled={selected.length > 10}
            block
          >
            {selected.length > 10 ?
              'Max 10 actions' :
              `Schedule ${selected.length} action${selected.length !== 1 ? 's' : ''}`}
          </Button>
        </DialogFooter>
      }
    </div>
  );
}

export default NewEventDialogRoot;
