import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';

import { DASHBOARD_URL } from './App';
import { AuthContext } from './AuthContext';

export default function UnauthedRoute({
  children,
  ...props
}) {
  const { user } = useContext(AuthContext);

  return (
    <Route {...props}>
      {user ? <Redirect to={DASHBOARD_URL}/> : children}
    </Route>
  );
}
