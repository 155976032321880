import React, { useCallback, useRef, useState } from 'react';
import clsx from 'clsx';

import { UNCATEGORIZED_ID } from '../../services/DbService/constants';

import useUniqueId from '../../hooks/useUniqueId';

import CardButton from './CardButton';
import { useCategoryIcon } from '../CategoriesContext';
import ContextualMenu, { ContextualMenuTrigger, ContextualMenuCategories } from '../ContextualMenu';
import { BUTTON_COLOR_CATEGORY } from '../Button';

import styles from './Card.module.scss';

function CardCategoryMenu({
  className,
  categoryId,
  cardWithColor,
  onCategoryClick,
}) {
  const menuButtonRef = useRef();
  const menuButtonId = useUniqueId();
  const [menuVisible, setMenuVisible] = useState(null);

  const categoryIcon = useCategoryIcon(categoryId);

  const Icon = categoryIcon?.icon;

  const handleCategoryClick = useCallback(categoryId => {
    setMenuVisible(false);

    onCategoryClick && onCategoryClick(categoryId);
  }, [onCategoryClick]);

  // Uncategorized is shown without the colour regardless of whether it is a coloured
  // card or not
  const activeIconStyle = !cardWithColor && categoryId !== UNCATEGORIZED_ID;

  return (
    <>
      <ContextualMenuTrigger
        visible={menuVisible}
        setVisible={setMenuVisible}
        menuId={menuButtonId}
      >
        <CardButton
          ref={menuButtonRef}
          className={clsx(className, activeIconStyle && styles.buttonActive)}
          color={activeIconStyle ? BUTTON_COLOR_CATEGORY : undefined}
          aria-label="Change category"
        >
          {Icon && <Icon role="presentation" />}
        </CardButton>
      </ContextualMenuTrigger>

      {menuVisible &&
        <ContextualMenu
          buttonRef={menuButtonRef}
          onClose={() => setMenuVisible(false)}
          aria-labelledby={menuButtonId}
        >
          <ContextualMenuCategories onCategoryClick={handleCategoryClick} />
        </ContextualMenu>
      }
    </>
  );
}

export default CardCategoryMenu;
