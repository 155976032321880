import React, { useState } from 'react';
import { useParams, Link, generatePath } from 'react-router-dom';
import clsx from 'clsx';

import usePeople from '../../hooks/usePeople';

import { PEOPLE_URL } from '../App';

import Sidebar from '../Sidebar';
import Heading, { HEADING_LEVEL_4 } from '../Heading';
import ItemIcon from '../ItemIcon';
import CreateButton from '../CreateButton';
import EmptyStateIcons from '../EmptyState/EmptyStateIcons';
import CreatePersonDialog from '../CreateDialog/CreatePersonDialog';
import LoadingSpinner from '../LoadingSpinner';
import Tooltip from '../Tooltip';

import { UNGROUPED_PEOPLE_ID } from '../../services/DbService/constants';

import { ReactComponent as IconCategoryFriends } from '../../assets/icons/16-category-friends.svg';
import { ReactComponent as IconPeople } from '../../assets/icons/16-people.svg';
import { ReactComponent as IconCategoryLove } from '../../assets/icons/16-category-love.svg';
import { ReactComponent as IconCategoryBaby } from '../../assets/icons/16-category-baby.svg';

import styles from './PeopleSidebar.module.scss';

function PeopleSidebar({
  className,
  ...props
}) {
  const { personId } = useParams();

  const [dialogVisible, setDialogVisible] = useState(false);
  const { people } = usePeople();

  return (
    <Sidebar className={clsx(className, styles.main)} {...props}>
      {people === null &&
        <LoadingSpinner absolute />
      }
      {people &&
        <ul className={styles.list}>
          {people.map(person => (
            <li
              {...props}

              key={person.id}
              className={clsx(
                styles.item,
                personId === person.id && styles.active,
              )}
            >
              <Tooltip title={`@${person.nickname}`}>
                <Link to={generatePath(PEOPLE_URL, { personId: person.id })} className={styles.summary}>
                  <ItemIcon
                    className={styles.icon}
                    initials={person.nickname[0]}
                    active
                    image={person.image}
                  />
                  <div className={styles.info}>
                    <Heading className={styles.name} tag="span" level={HEADING_LEVEL_4}>
                      @{person.nickname}
                    </Heading>
                  </div>
                </Link>
              </Tooltip>
            </li>
          ))}
        </ul>
      }

      {people?.length === 0 &&
        <EmptyStateIcons
          icons={[
            IconCategoryFriends,
            IconPeople,
            IconCategoryLove,
            IconCategoryBaby,
          ]}
        >
          You haven’t created any people. Why not create one using the button&nbsp;below?
        </EmptyStateIcons>
      }

      <CreateButton
        className={styles.createButton}
        onClick={() => setDialogVisible(true)}
        aria-label="Create Person"
      />

      {dialogVisible &&
        <CreatePersonDialog
          personGroupId={UNGROUPED_PEOPLE_ID}
          onClose={() => setDialogVisible(false)}
        />
      }
    </Sidebar>
  );
}

export default PeopleSidebar;
