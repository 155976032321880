import React, { useContext } from 'react';
import clsx from 'clsx';

import { UNCATEGORIZED_ID, ACTIVE } from '../../services/DbService/constants';

import Head from '../Head';
import Heading, { HEADING_LEVEL_2 } from '../Heading';
import Sidebar from '../Sidebar';
import ActionsList from '../ActionsList';
import CreateButton from '../CreateButton';

import ActionDialog, {
  ActionDialogContext,
  useShowCreateActionDialog,
} from '../ActionDialog';

import styles from './Page.module.scss';

function Page({
  className,
  children,
  pageTitle,
  captureList = true,
  ...props
}) {
  const showCreateActionDialog = useShowCreateActionDialog();

  const { captureListExpanded } = useContext(ActionDialogContext);

  return (
    <div className={clsx(className, styles.page)}>
      <Head title={pageTitle} />

      {children}

      {captureList &&
        <Sidebar
          className={clsx(styles.captureSidebar, captureListExpanded && styles.expanded)}
          headerChildren={(
            <Heading className={styles.captureHeading} tag="span" level={HEADING_LEVEL_2}>
              Capture List
            </Heading>
          )}
          headerDivider={false}
        >
          <ActionsList
            categoryId={UNCATEGORIZED_ID}
            state={ACTIVE}
            emptyState
          />

          <CreateButton
            onClick={() => showCreateActionDialog(UNCATEGORIZED_ID)}
            aria-label="Capture New Action"
            tooltip
          />
        </Sidebar>
      }

      {captureList &&
        <ActionDialog />
      }
    </div>
  );
}

export default Page;
