import React, { useState, useCallback, useContext } from 'react';
import { Route, Switch, useLocation, useRouteMatch } from 'react-router-dom';
import { CSSTransition, SwitchTransition } from 'react-transition-group';

import clsx from 'clsx';

import { DASHBOARD_URL, BLOCK_DETAIL_URL, CATEGORY_DETAIL_URL } from '../App';

import Page from '../Page';
import FloatingButton from '../FloatingButton';
import BlockDetail from '../DetailPanels/BlockDetail';
import CategoryDetail from '../DetailPanels/CategoryDetail';
import CalendarPanel from '../CalendarPanel/CalendarPanel';
import DashboardSidebar from './DashboardSidebar/DashboardSidebar';

import { DashboardContextProvider } from './DashboardContext';
import { ActionDialogContext } from '../ActionDialog';

import { ReactComponent as IconExpand } from '../../assets/icons/32-expand.svg';
import { ReactComponent as IconCollapse } from '../../assets/icons/32-collapse.svg';

import styles from './Dashboard.module.scss';

const LOCALSTORAGE_EXPANDED_KEY = 'dashboardMainExpanded';

function Dashboard({ ...props }) {
  const location = useLocation();

  const [mainExpanded, setMainExpanded] = useState(() => JSON.parse(localStorage.getItem(LOCALSTORAGE_EXPANDED_KEY)));

  const handleToggleExpanded = useCallback(() => {
    const newValue = !mainExpanded;
    localStorage.setItem(LOCALSTORAGE_EXPANDED_KEY, JSON.stringify(newValue));
    setMainExpanded(newValue);
  }, [mainExpanded]);

  const isCalendarRoute = useRouteMatch(DASHBOARD_URL).isExact;

  const { captureListExpanded } = useContext(ActionDialogContext);

  return (
    <Page
      className={clsx(mainExpanded && styles.expanded)}
      pageTitle="Calendar"
    >
      <main className={styles.mainArea}>
        <CalendarPanel
          className={clsx(
            styles.calendar,
            !isCalendarRoute && styles.calendarHidden,
          )}
        />
        <div
          className={clsx(
            styles.floatingButtonWrapper,
            captureListExpanded && styles.captureListExpanded,
          )}
        >
          <FloatingButton
            iconOnly
            aria-expanded={mainExpanded}
            aria-label={`${!mainExpanded ? 'Collapse' : 'Expand'} sidebars`}
            onClick={handleToggleExpanded}
            tooltip
          >
            {mainExpanded ?
              <IconCollapse role="presentation"/>
              :
              <IconExpand role="presentation"/>
            }
          </FloatingButton>
        </div>
        <SwitchTransition mode="out-in">
          <CSSTransition
            key={location.pathname}
            classNames="slide"
            timeout={+styles.stateTransitionDuration}
          >
            <Switch location={location}>
              <Route path={BLOCK_DETAIL_URL}>
                <BlockDetail className={clsx(styles.card, styles.mainAreaAnimate)} />
              </Route>
              <Route path={CATEGORY_DETAIL_URL}>
                <CategoryDetail className={clsx(styles.card, styles.mainAreaAnimate)}/>
              </Route>
            </Switch>
          </CSSTransition>
        </SwitchTransition>
      </main>

      <DashboardSidebar className={clsx(styles.sidebar, mainExpanded && styles.hidden)} />
    </Page>
  );
}

const DashboardWithContext = (props) => (
  <DashboardContextProvider>
    <Dashboard {...props} />
  </DashboardContextProvider>
);

export default DashboardWithContext;
