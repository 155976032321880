import React from 'react';
import clsx from 'clsx';
import Button from '../Button';
import styles from './LoadMoreButton.module.scss';

function LoadMoreButton({
  className,
  ...props
}) {
  return (
    <Button
      className={clsx(styles.button, className)}
      {...props}
    >
      Load more
    </Button>
  );
}

export default LoadMoreButton;
