import React, { useState } from 'react';

import { useCategories } from '../CategoriesContext';
import Page from '../Page';
import PlannerColumn from './PlannerColumn';
import LoadingSpinner from '../LoadingSpinner';
import Checkbox from '../Checkbox';
import { DialogContextProvider } from '../Dialog';

import styles from './Planner.module.scss';

function Planner({ ...props }) {
  const [showOnlyStarred, setShowOnlyStarred] = useState(false);

  const { activeCategories } = useCategories();

  props.pageTitle = 'Planner';

  if (activeCategories === null) {
    return (
      <Page {...props}>
        <LoadingSpinner absolute />
      </Page>
    )
  }

  return (
    <Page {...props}>
      <div className={styles.wrapper}>
        <Checkbox
          className={styles.showStarred}
          label='Show only starred'
          onChange={e => setShowOnlyStarred(e.target.checked)}
          checked={showOnlyStarred}
        />

        <div className={styles.columnsOuter}>
          <div className={styles.columnsInner}>
            {activeCategories.map(category => (
              <PlannerColumn
                key={category.id}
                className={styles.column}
                title={category.name}
                categoryId={category.id}
                icon={category.icon}
                showOnlyStarred={showOnlyStarred}
              />
            ))}
          </div>
        </div>
      </div>
    </Page>
  );
}

const PlannerWithContext = (props) => (
  <DialogContextProvider>
    <Planner {...props} />
  </DialogContextProvider>
);

export default PlannerWithContext;
