import React, { useEffect, useState, useCallback, useRef, useContext, useMemo } from 'react';
import clsx from 'clsx';

import usePeople from '../../hooks/usePeople';

import { ActionDialogContext, useShowCreateActionDialog } from '.';

import { ACTION_DESCRIPTION_MAX_LENGTH, UNCATEGORIZED_ID } from '../../services/DbService/constants';
import { watchQuickCaptureOptions } from '../../services/DbService/general';
import { createAction, setActionDescription, setActionList } from '../../services/DbService/actions';
import { replaceMentions, getModifierKey } from "../../utils";

import useUniqueId from '../../hooks/useUniqueId';

import Autocomplete from './Autocomplete';
import CreateButton from '../CreateButton';
import { BUTTON_COLOR_FILL } from '../Button';
import CreateDialog from '../CreateDialog';
import Divider from '../Divider';
import Toast from '../Toast';
import { InputLabel } from '../Input';
import CharacterCounter from '../CharacterCounter';
import ActionParentSelect from './ActionParentSelect';

import styles from './ActionDialog.module.scss';

function ActionDialog({
  className,
  children,
  ...props
}) {
  const { people } = usePeople();

  const [visible, setVisible] = useState(false);
  const [parentSelectOpen, setParentSelectOpen] = useState(false);

  const errorToastId = useUniqueId();

  const autocompleteRef = useRef();
  const autocompleteLabelId = useUniqueId();

  const showCreateActionDialog = useShowCreateActionDialog();

  const {
    quickCaptureParent,
    newActionEvent,
    newActionParent, setNewActionParent,
    actionToEdit, setActionToEdit,
  } = useContext(ActionDialogContext);

  const [newActionDescriptionValue, setNewActionDescriptionValue] = useState('');
  const [newActionParentValue, setNewActionParentValue] = useState('');
  // optionsGroups and optionsMap are needed in ActionDialog rather than within ActionParentSelect because
  // the newParentOption is dependant on them for the blockId and categoryId of the new parent. These could
  // be sent back up through the onChange callback, but that requires all instances of newActionParent to be
  // changed to provide the blockId and categoryId so that if a user opens it from a BlockDetail both
  // bits of information are known before the onChange is called (if at all). Finally, it removes the need
  // to pass up some kind of onInit event to handle the latency between mounting ActionDialog and running
  // the watchers in ActionParentSelect to prevent a two stage loading of a simple piece of UI.
  const [optionsGroups, setOptionsGroups] = useState([]);
  const [optionsMap, setOptionsMap] = useState({});

  const newParentOption = useMemo(() => optionsMap?.[newActionParentValue] || {}, [optionsMap, newActionParentValue]);

  const newActionDescriptionLength = useMemo(() => {
    if (!newActionDescriptionValue) return 0;

    const descriptionWithoutMentions = replaceMentions(newActionDescriptionValue, () => '');
    return descriptionWithoutMentions.length;
  }, [newActionDescriptionValue]);

  useEffect(() => {
    return watchQuickCaptureOptions(({ optionsGroups, optionsMap }) => {
      setOptionsGroups(optionsGroups);
      setOptionsMap(optionsMap);
    });
  }, []);

  // Reset dialog (and context) state when it gets hidden
  useEffect(() => {
    if (visible) return;
    setNewActionDescriptionValue('');
    setNewActionParentValue(quickCaptureParent || UNCATEGORIZED_ID);
    // When the dialog gets closed reset also the two "triggers"
    // for "edit" and "create" modes
    setActionToEdit(null);
    setNewActionParent(null);
  }, [visible, quickCaptureParent, setNewActionParent, setActionToEdit]);

  // Show the dialog when newActionParent is set
  useEffect(() => {
    if (!newActionParent) return;
    setNewActionParentValue(newActionParent);
    setVisible(true);
  }, [newActionParent, setVisible]);

  // Show the dialog when actionToEdit is set
  useEffect(() => {
    if (!actionToEdit) return;
    const {description, blockId, categoryId} = actionToEdit;
    setNewActionDescriptionValue(description);
    setNewActionParentValue(blockId || categoryId);
    setVisible(true);
  }, [actionToEdit, setVisible]);

  // Toggle the dialog on CMD+K
  useEffect(() => {
    function handleKeydownAnywhere(e) {
      if (e.key === 'k' && getModifierKey(e)) {
        e.preventDefault();
        showCreateActionDialog(quickCaptureParent || UNCATEGORIZED_ID);
      } else if (e.key === 'Escape') {
        // If the actionParent is selected Escape should handle the closure of the focus rather
        // than the closure of the action dialog
        if (parentSelectOpen) return;
        e.preventDefault();
        showCreateActionDialog(null);
      }
    }
    document.addEventListener('keydown', handleKeydownAnywhere);
    return () => document.removeEventListener('keydown', handleKeydownAnywhere);
  }, [parentSelectOpen, quickCaptureParent, showCreateActionDialog]);

  const handleLabelClick = useCallback((e) => {
    autocompleteRef.current.focus();
  }, []);

  const handleClose = useCallback(e => {
    // If the actionParent is selected Escape should handle the closure of the focus rather
    // than the closure of the action dialog
    if (parentSelectOpen) return;

    setVisible(false);
  }, [parentSelectOpen]);

  const handleSubmit = useCallback((e) => {
    e.preventDefault();

    const newDescription = newActionDescriptionValue.trim();
    if (!newDescription) return;

    let newBlockId = null;
    let newCategoryId = newActionParentValue;

    if (newParentOption.parentId) {
      newBlockId = newActionParentValue;
      newCategoryId = newParentOption.parentId;
    }

    if (actionToEdit) {
      const {
        id,
        blockId,
        categoryId,
        state,
        starred,
      } = actionToEdit;
      setActionDescription(id, newDescription);
      if (newCategoryId !== categoryId || newBlockId !== blockId) {
        setActionList(id, newCategoryId, newBlockId, state, starred);
      }
    } else {
      let newEventStartDate = null;
      let newEventDuration = null;
      if (newActionEvent) {
        newEventStartDate = newActionEvent.startDate;
        newEventDuration = newActionEvent.duration;
      }
      createAction(newDescription, newCategoryId, newBlockId, newEventStartDate, newEventDuration);
    }
    setVisible(false);
  }, [
    newActionDescriptionValue,
    newActionParentValue,
    newParentOption,
    actionToEdit,
    newActionEvent,
    setVisible,
  ]);

  const descriptionErrorMessage = newActionDescriptionLength > ACTION_DESCRIPTION_MAX_LENGTH ?
    'Too many characters' :
    null;

  const emailBody = (actionToEdit?.emailBody || '').trim();

  if (!visible) return null;

  return (
    <CreateDialog onClose={handleClose} wide>
      <form className={styles.form} onSubmit={handleSubmit}>
        <div className={styles.nameAndParent}>
          {/* Autocomplete is a <div contenteditable />, this replicates a label */}
          <div
            id={autocompleteLabelId}
            className={clsx(styles.label)}
          >
            <InputLabel onClick={handleLabelClick} tabIndex="-1" tag="span">
              {actionToEdit ?
                'Edit Action Description' :
                'Capture New Action'
              }
            </InputLabel>
            {/* The max length here is longer to allow a little bit of overflow, but the form will be
                invalid when it is over so won't be submittable. */}
            <Autocomplete
              ref={autocompleteRef}
              aria-labelledby={autocompleteLabelId}
              aria-errormessage={descriptionErrorMessage ? errorToastId : null}
              className={styles.autocomplete}
              placeholder='e.g. Prepare for meeting'
              maxLength={ACTION_DESCRIPTION_MAX_LENGTH + 5}
              value={newActionDescriptionValue}
              onChange={setNewActionDescriptionValue}
              onEnterKeyDown={handleSubmit}
              people={people}
            />
          </div>

          {!!optionsGroups.length &&
            <ActionParentSelect
              className={styles.parentSelect}
              optionsGroups={optionsGroups}
              optionsMap={optionsMap}
              value={newActionParentValue}
              onOpen={() => setParentSelectOpen(true)}
              onClose={() => setParentSelectOpen(false)}
              onChange={setNewActionParentValue}
              onEnterKeyDown={handleSubmit}
            />}
        </div>

        <CharacterCounter
          className={styles.counter}
          count={newActionDescriptionLength}
          max={ACTION_DESCRIPTION_MAX_LENGTH}
        />

        <Divider vertical />

        <CreateButton
          aria-label={actionToEdit ? 'Edit Action' : 'Add Action'}
          disabled={!newActionDescriptionValue || !newActionParentValue || descriptionErrorMessage}
          error={descriptionErrorMessage}
          icon={actionToEdit ? 'complete' : 'add'}
          type="submit"
          color={BUTTON_COLOR_FILL}
        />
      </form>

      {emailBody &&
        <section className={styles.emailBody}>
          <h3 className={styles.emailBodyTitle}>Email Description</h3>
          <p>{emailBody}</p>
        </section>}

      {descriptionErrorMessage &&
        <Toast error role="alert" id={errorToastId}>{descriptionErrorMessage}</Toast>}
    </CreateDialog>
  );
}

export default ActionDialog;
