import React from 'react';

import { ACTIVE, COMPLETED, UNCATEGORIZED_ID } from '../../../services/DbService/constants';

import CreateButton from '../../CreateButton';
import ActionsList from '../../ActionsList';
import BlocksList from '../../BlocksList';

import styles from './DashboardSidebarList.module.scss';

function DashboardSidebarList({
  state,
  onCreateClick,
  categoryId,
  enableEmptyState,
  cardWithColor,
  style,
}) {
  return (
    <>
      <BlocksList
        categoryId={categoryId}
        state={state}
        enableEmptyState={enableEmptyState}
        cardWithColor={cardWithColor}
        style={style}
      />

      {state !== COMPLETED && onCreateClick &&
        <CreateButton
          className={styles.addBlock}
          onClick={onCreateClick}
          aria-label="Create New Block"
          tooltip
        />
      }

      {/* Don't show actions in "Uncategorized" when in "active" state */}
      {categoryId && (categoryId !== UNCATEGORIZED_ID || state !== ACTIVE) &&
        <ActionsList
          className={styles.actions}
          key={`actions-${categoryId}-${state}`}
          state={state}
          categoryId={categoryId}
          style={style}
          cardWithColor={cardWithColor}
        />
      }
    </>
  );
}

export default DashboardSidebarList;
