import { useEffect } from "react";

// Register a global mousedown listener and fires "handler" every time a click
// happens outside the refs specified
export default function useClickOutside(handler, ref1, ref2, ref3) {
  useEffect(() => {
    function handleMouseDown(e) {
      const clickInside =
        [ref1, ref2, ref3].some(ref => ref?.current?.contains(e.target));
      if (clickInside) return;

      handler(e);
    }

    document.addEventListener('mousedown', handleMouseDown, true);
    return () => document.removeEventListener('mousedown', handleMouseDown, true);
  }, [handler, ref1, ref2, ref3]);
}
