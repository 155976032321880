import React, { useRef, useCallback, useState, useEffect } from 'react';
import clsx from 'clsx';

import useUniqueId from '../../../hooks/useUniqueId';

import {
  BLOCK_RESULT_MAX_LENGTH,
  BLOCK_PURPOSE_MAX_LENGTH,
  BLOCK_ROLE_MAX_LENGTH,
  DETAIL_BACKGROUND_IMAGE_MAX_LENGTH,
  DETAIL_BACKGROUND_IMAGE_MAX_FILE_SIZE_MB,
} from '../../../services/DbService/constants';
import { uploadPhoto } from '../../../services/PhotoService';
import { setBlock, setBlockDueDate } from '../../../services/DbService/blocks';

import { DASHBOARD_URL } from '../../App';

import Button from '../../Button';
import { useCategories, useCategoryColors } from '../../CategoriesContext';
import DueDateButton from '../../DueDateButton';
import { BlockItemMenu } from '../../BlockItem';
import { ContextualMenuTrigger } from '../../ContextualMenu';
import Heading, { HEADING_LEVEL_3, HEADING_LEVEL_5 } from '../../Heading';
import Input, { INPUT_SIZE_LARGE } from '../../Input';
import SmartLink, { SMART_LINK_STYLE_PLACEHOLDER } from '../../SmartLink';
import Toast from '../../Toast';
import Tooltip from '../../Tooltip';

import { ReactComponent as IconClose } from '../../../assets/icons/16-close.svg';
import { ReactComponent as IconMediaPhoto } from '../../../assets/icons/16-media-photo.svg';
import { ReactComponent as IconMore } from '../../../assets/icons/16-more.svg';

import styles from './BlockDetail.module.scss';

const BlockDetailHeader = ({
  block,
  category,
  onExportPdf,
  onEditingToggle,
  ...props
}) => {
  const resultRef = useRef();
  const purposeRef = useRef();

  const { activeCategories } = useCategories();

  const [editing, setEditing] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [categories, setCategories] = useState([]);
  const [result, setResult] = useState(block.result || '');
  const [purpose, setPurpose] = useState(block.purpose || '');
  const [role, setRole] = useState(block.role || '');
  const [categoryId, setCategoryId] = useState(block.categoryId || '');
  const [backgroundImage, setBackgroundImage] = useState(block.backgroundImage || null);
  const [imageUploadError, setImageUploadError] = useState(false);
  const [dueDate, setDueDate] = useState(null);

  const categoryColor = useCategoryColors(categoryId);

  const optionsMenuButtonRef = useRef();
  const optionsMenuButtonId = useUniqueId();
  const [optionsMenuVisible, setOptionsMenuVisible] = useState(false);

  useEffect(() => {
    onEditingToggle(!!editing);
  }, [onEditingToggle, editing]);

  useEffect(() => {
    setDueDate(block.dueDate || null);
  }, [block.dueDate]);

  useEffect(() => {
    if (!category || activeCategories === null) return;

    // hidden categories should not appear in the dropdown, but if a block belongs to a
    // hidden category the item should still appear in the dropdown to avoid forcing a
    // change on the user during editing.
    const categoryIsActive = activeCategories.find(({ id }) => id === category.id);

    const newCategories = categoryIsActive ?
      activeCategories :
      [...activeCategories, category];

    setCategories(newCategories);
  }, [category, activeCategories]);

  const handleImageUpload = useCallback(async e => {
    setUploading(true);

    const [file] = e.target.files;

    if (!file) {
      setUploading(false);
      return;
    }

    let newImage;

    try {
      newImage = await uploadPhoto(file, {
        maxSizeMB: DETAIL_BACKGROUND_IMAGE_MAX_FILE_SIZE_MB,
        maxWidthOrHeight: DETAIL_BACKGROUND_IMAGE_MAX_LENGTH,
      });
    } catch(e) {
      console.error('Image upload error', e);
      setImageUploadError(true);
    }

    setBackgroundImage(newImage);
    setUploading(false);
  }, []);

  const handleSubmit = useCallback(async e => {
    e.preventDefault();

    await setBlock(block.id, categoryId, result, purpose, role, backgroundImage);

    setEditing(null);
  }, [block, backgroundImage, result, purpose, role, categoryId]);

  const handleDueDateChange = useCallback(newDate => {
    setDueDate(newDate);
    setBlockDueDate(block.id, newDate);
  }, [block.id]);

  // Calculates a number between 0 and 1 to represent the length of the title compared to the
  // maximum length which is used for dynamic sizing the long headers.
  const titleLengthLimit = 1 - ((BLOCK_RESULT_MAX_LENGTH - result.length) / BLOCK_RESULT_MAX_LENGTH);

  return (
    <header>
      <div
        className={styles.hero}
        style={{
          ...categoryColor,
          '--bg-image': backgroundImage && !uploading && `url('${backgroundImage}')`,
        }}
        aria-hidden={editing}
      >
        {!editing &&
          <>
            <div className={styles.buttons}>
              <DueDateButton
                date={dueDate}
                onChange={handleDueDateChange}
                categoryId={categoryId}
              />

              <ContextualMenuTrigger
                visible={optionsMenuVisible}
                setVisible={setOptionsMenuVisible}
                menuId={optionsMenuButtonId}
              >
                <Button
                  ref={optionsMenuButtonRef}
                  aria-label="Block tools"
                  tooltip
                  iconOnly
                >
                  <IconMore role="presentation" />
                </Button>
              </ContextualMenuTrigger>
              <BlockItemMenu
                block={{
                  id: block.id,
                  state: block.state,
                }}
                onEdit={() => setEditing('result')}
                onExportPdf={onExportPdf}
                menuVisible={optionsMenuVisible}
                buttonRef={optionsMenuButtonRef}
                onClose={() => setOptionsMenuVisible(false)}
                aria-labelledby={optionsMenuButtonId}
              />

              <Button
                linkTo={DASHBOARD_URL}
                iconOnly
                aria-label="Back"
              >
                <IconClose role="presentation" />
              </Button>
            </div>

            <div className={clsx(styles.resultAndPurpose, styles.group)}>
              <div>
                <h1>
                  <Heading level={HEADING_LEVEL_5} tag="span" className={styles.label}>Result</Heading>
                  <Heading level={HEADING_LEVEL_3} tag="span" className={styles.title} style={{'--title-length-limit': titleLengthLimit}}>{result}</Heading>
                </h1>
              </div>

              <div>
                <Heading level={HEADING_LEVEL_5} tag="h2" className={styles.label}>Purpose</Heading>
                {purpose &&
                  <p className={styles.description}>{purpose}</p>
                }
                {!purpose &&
                  <Tooltip title="Add purpose">
                    <SmartLink
                      className={styles.description}
                      onClick={() => setEditing('purpose')}
                      aria-label="Add purpose"
                      linkStyle={SMART_LINK_STYLE_PLACEHOLDER}
                    >
                      Add the purpose for the achievement…
                    </SmartLink>
                  </Tooltip>
                }
              </div>
            </div>
          </>
        }
      </div>

      {editing &&
        <form onSubmit={handleSubmit} className={clsx(styles.form, styles.group)}>
          <Input
            ref={resultRef}
            label="Result"
            tag="textarea"
            value={result}
            autoFocus={editing === 'result'}
            onChange={e => setResult(e.target.value)}
            placeholder="Enter the result you want to achieve…"
            maxLength={BLOCK_RESULT_MAX_LENGTH}
            size={INPUT_SIZE_LARGE}
            maxRows={5}
            minRows={3}
          />

          <Input
            ref={purposeRef}
            label="Purpose"
            value={purpose}
            autoFocus={editing === 'purpose'}
            onChange={e => setPurpose(e.target.value)}
            placeholder="Enter the purpose…"
            maxLength={BLOCK_PURPOSE_MAX_LENGTH}
            tag="textarea"
            maxRows={5}
            minRows={3}
          />

          <Input
            label="Role"
            value={role}
            autoFocus={editing === 'role'}
            onChange={e => setRole(e.target.value)}
            placeholder="Enter your role…"
            maxLength={BLOCK_ROLE_MAX_LENGTH}
          />

          <Input
            label="Category"
            value={categoryId}
            onChange={e => setCategoryId(e.target.value)}
            tag="select"
          >
            {categories.map(option => (
              <option value={option.id} key={option.id}>{option.name}</option>
            ))}
          </Input>

          {/* Editing button – The done button sits at the end of the <form> to ensure that the taborder works as expected */}
          <div className={styles.buttons}>
            <Button iconOnly={true} tabIndex="-1">
              <Input
                aria-label="Upload image"
                type="file"
                accept="image/png,image/jpeg,image/gif"
                onChange={handleImageUpload}
                onFocus={() => setImageUploadError(false)}
                visuallyHidden
              />
              <IconMediaPhoto role="presentation" />
            </Button>
            <Button type="submit" disabled={!result || !categoryId || uploading} loading={uploading}>Done</Button>
          </div>

          {imageUploadError &&
            <Toast error role="alert">Image upload failed. Please try again.</Toast>
          }
        </form>
      }

      {!editing &&
        <div className={styles.header}>
          <div className={clsx(styles.group, styles.role)}>
            <Heading level="h5" tag="h2" className={styles.label}>Your role</Heading>
            {role &&
              <p className={styles.description}>{role}</p>
            }
            {!role &&
              <Tooltip title="Add role">
                <SmartLink
                  className={styles.description}
                  onClick={() => setEditing('role')}
                  aria-label="Add role"
                  linkStyle={SMART_LINK_STYLE_PLACEHOLDER}
                >
                  Add your role…
                </SmartLink>
              </Tooltip>
            }
          </div>
        </div>
      }
    </header>
  );
};

export default BlockDetailHeader;
