import React, { useCallback } from 'react';
import clsx from 'clsx';

import useUniqueId from '../../hooks/useUniqueId';

import { useCategoryColors } from '../CategoriesContext';

import styles from './TabBar.module.scss';

function TabBar({
  className,
  active,
  tabs,
  categoryId,
  onTabClick,
  style = {},
  ...props
}) {
  const tabBarId = useUniqueId();
  const categoryColors = useCategoryColors(categoryId);

  const handleTabClick = useCallback((id) => {
    if (!onTabClick) return;

    onTabClick(id);
  }, [onTabClick]);

  if (!tabs) return null;

  style = {
    ...categoryColors,
    '--tab-count': tabs.length,
    ...style,
  };

  return (
    <div
      className={clsx(
        className,
        styles.tabBar,
        categoryId && styles.categoryColor,
      )}
      style={style}
      {...props}
    >
      <div className={styles.list} role="tablist">
        {tabs.map(tab => (
          <React.Fragment key={tab.id}>
            <input
              className={styles.input}
              type="radio"
              name={tabBarId}
              id={`${tabBarId}-${tab.id}`}
              role="tab"
              aria-controls={tab.id}
              aria-selected={active === tab.id}
              // This tabIndex ensures that when there is only one tab element, that it is skipped for focus
              tabIndex={tabs.length === 1 ? -1 : null}
              onClick={() => handleTabClick(tab.id)}
            />
            <label
              className={clsx(styles.tab, active === tab.id && styles.active)}
              htmlFor={`${tabBarId}-${tab.id}`}
            >
              <span className={styles.labelInner}>
                {tab.icon && <tab.icon />}
                {tab.label && <span>{tab.label}</span>}
              </span>
            </label>
          </React.Fragment>
        ))}
        <span className={styles.focusRing} aria-hidden="true" />
      </div>
    </div>
  );
}

export default TabBar;
