import React, { forwardRef } from 'react';
import clsx from 'clsx';

import Button, { BUTTON_COLOR_FILL, BUTTON_SIZE_LARGE } from '../Button';

import styles from './FloatingButton.module.scss';

const FloatingButton = forwardRef(({
  className,
  children,
  color = BUTTON_COLOR_FILL,
  size = BUTTON_SIZE_LARGE,
  ...props
}, ref) => {
  return (
    <Button
      ref={ref}
      className={clsx(className, styles.button)}
      color={color}
      size={size}
      {...props}
    >
      {children}
    </Button>
  );
});

FloatingButton.displayName = 'FloatingButton';

export default FloatingButton;
