import isElectron from 'is-electron';

import { ReactComponent as IconCategories } from '../../assets/icons/16-categories.svg';

import { ReactComponent as IconCategoryApple } from '../../assets/icons/16-category-apple.svg';
import { ReactComponent as IconCategoryAward } from '../../assets/icons/16-category-award.svg';
import { ReactComponent as IconCategoryBaby } from '../../assets/icons/16-category-baby.svg';
import { ReactComponent as IconCategoryBag } from '../../assets/icons/16-category-bag.svg';
// import { ReactComponent as IconCategoryBolt } from '../../assets/icons/16-category-bolt.svg';
import { ReactComponent as IconCategoryBrain } from '../../assets/icons/16-category-brain.svg';
import { ReactComponent as IconCategoryBulb } from '../../assets/icons/16-category-bulb.svg';
import { ReactComponent as IconCategoryCup } from '../../assets/icons/16-category-cup.svg';
import { ReactComponent as IconCategoryDog } from '../../assets/icons/16-category-dog.svg';
import { ReactComponent as IconCategoryFamily } from '../../assets/icons/16-category-family.svg';
import { ReactComponent as IconCategoryFlag } from '../../assets/icons/16-category-flag.svg';
import { ReactComponent as IconCategoryFlame } from '../../assets/icons/16-category-flame.svg';
import { ReactComponent as IconCategoryFlower } from '../../assets/icons/16-category-flower.svg';
import { ReactComponent as IconCategoryFriends } from '../../assets/icons/16-category-friends.svg';
import { ReactComponent as IconCategoryGoal } from '../../assets/icons/16-category-goal.svg';
import { ReactComponent as IconCategoryGym } from '../../assets/icons/16-category-gym.svg';
import { ReactComponent as IconCategoryHeart } from '../../assets/icons/16-category-heart.svg';
import { ReactComponent as IconCategoryLotus } from '../../assets/icons/16-category-lotus.svg';
// import { ReactComponent as IconCategoryLove } from '../../assets/icons/16-category-love.svg';
import { ReactComponent as IconCategoryMasks } from '../../assets/icons/16-category-masks.svg';
import { ReactComponent as IconCategoryMountain } from '../../assets/icons/16-category-mountain.svg';
import { ReactComponent as IconCategoryPath } from '../../assets/icons/16-category-path.svg';
import { ReactComponent as IconCategoryRun } from '../../assets/icons/16-category-run.svg';
// import { ReactComponent as IconCategorySea } from '../../assets/icons/16-category-sea.svg';
import { ReactComponent as IconCategorySmile } from '../../assets/icons/16-category-smile.svg';
import { ReactComponent as IconCategorySpeed } from '../../assets/icons/16-category-speed.svg';


// Firestore persistance should be enabled only on trusted
// devices, because it stores sensible data lasting even
// after the usage session.
// Better to enable persistance only in Electron and not on
// the web, since the web version is not even PWA enabled.
export const ENABLE_FIRESTORE_PERSISTENCE = isElectron();
export const PAGE_SIZE = 25;

export const ACTIVE = 'active';
export const SNOOZED = 'snoozed';
export const COMPLETED = 'completed';

export const STATE_LABELS = {
  [ACTIVE]: {
    subheading: 'This Week',
    cta: 'Active',
  },
  [SNOOZED]: {
    subheading: 'Upcoming',
    cta: 'Upcoming',
  },
  [COMPLETED]: {
    subheading: 'Done',
    cta: 'Completed',
  },
};

export const STARRED = 'starred';
export const UNSTARRED = 'unstarred';

export const CAPTURE_LIST_ACTIONS = 'Capture List';

export const ACTION_DESCRIPTION_MAX_LENGTH = 75; // (Value from HRZ-7)

export const BLOCK_RESULT_MAX_LENGTH = 240; // (Value from HRZ-671)
export const BLOCK_PURPOSE_MAX_LENGTH = 240; // (Value from HRZ-671)
export const BLOCK_ROLE_MAX_LENGTH = 30; // (Value from HRZ-19)
export const BLOCK_ACTIONS_COMPLETION_THRESHOLD = 0.7; // Value from (HRZ-625)

export const PERSON_NICKNAME_MAX_LENGTH = 25;
export const PERSON_DESCRIPTION_MAX_LENGTH = 100;

export const PEOPLE_GROUP_NAME_MAX_LENGTH = 25;

export const DETAIL_BACKGROUND_IMAGE_MAX_LENGTH = 1920; // (Value from HRZ-33)
export const DETAIL_BACKGROUND_IMAGE_MAX_FILE_SIZE_MB = 1; // (Value from HRZ-33)

export const CATEGORY_NAME_MAX_LENGTH = 30;
export const CATEGORY_FIELD_MAX_LENGTH = 2000;
export const CATEGORY_ACTIVE = 'active';
export const CATEGORY_HIDDEN = 'hidden';

export const ALL_CATEGORY_ID = 'all';
export const UNCATEGORIZED_ID = '00000000-0000-0000-0000-000000000000';
export const UNGROUPED_PEOPLE_ID = '00000000-0000-0000-0000-000000000001';

export const SNOOZED_TO_SOMETIME = 'sometime';
export const SNOOZED_TO_WEEK = 'week';
export const SNOOZED_TO_MONTH = 'month';
export const SNOOZED_TO_NINETY_DAYS = 'ninetyDays';

export const SNOOZED_TO_WEEKS_MAP = {
  [SNOOZED_TO_WEEK]: 0,
  [SNOOZED_TO_MONTH]: 4,
  [SNOOZED_TO_NINETY_DAYS]: 12,
  [SNOOZED_TO_SOMETIME]: null,
};

export const SNOOZED_LABELS = {
  [SNOOZED_TO_WEEK]: {
    subheading: 'Next Week',
    cta: 'for a week',
    emptyStateString: 'No upcoming blocks in the next\xa0week',
  },
  [SNOOZED_TO_MONTH]: {
    subheading: 'Within a\xa0Month',
    cta: 'for a month',
    emptyStateString: 'No upcoming blocks within the next\xa0month',
  },
  [SNOOZED_TO_NINETY_DAYS]: {
    subheading: 'Within 90\xa0days',
    cta: 'for 90 days',
    emptyStateString: 'No upcoming blocks within the next 90\xa0days',
  },
  [SNOOZED_TO_SOMETIME]: {
    subheading: 'Future',
    cta: 'to the future',
    emptyStateString: 'No upcoming blocks in the\xa0future',
  },
};

export const SNOOZED_LIST_ORDER = [
  SNOOZED_TO_WEEK,
  SNOOZED_TO_MONTH,
  SNOOZED_TO_NINETY_DAYS,
  SNOOZED_TO_SOMETIME,
];

// This comes from the category set by the backend when a user registers and is
// used to make a weak check for the first category created
export const DEFAULT_FIRST_CATEGORY_NAME = 'My First Category';

export const CATEGORY_DEFAULT_COLOR = '0';

export const CATEGORY_COLORS = {
  '0': {
    label: 'Grey',
    rgb: {
      default: '117, 116, 137',
      light: '162, 161, 175',
      dark: '113, 112, 130',
    },
  },
  '1': {
    label: 'Red',
    rgb: {
      default: '226, 87, 128',
      light: '227, 143, 169',
      dark: '203, 73, 112',
    },
  },
  '2': {
    label: 'Orange',
    rgb: {
      default: '241, 103, 42',
      light: '236, 153, 120',
      dark: '217, 89, 31',
    },
  },
  '3': {
    label: 'Umber',
    rgb: {
      default: '250, 136, 0',
      light: '241, 173, 95',
      dark: '225, 119, 0',
    },
  },
  '4': {
    label: 'Green',
    rgb: {
      default: '31, 184, 73',
      light: '106, 202, 138',
      dark: '25, 164, 62',
    },
  },
  '5': {
    label: 'Blue',
    rgb: {
      default: '67, 190, 226',
      light: '125, 206, 226',
      dark: '57, 170, 203',
    },
  },
  '6': {
    label: 'Violet',
    rgb: {
      default: '126, 104, 255',
      light: '165, 155, 246',
      dark: '109, 89, 230',
    },
  },
  '7': {
    label: 'Purple',
    rgb: {
      default: '189, 107, 255',
      light: '205, 156, 245',
      dark: '169, 94, 229',
    },
  },
  '8': {
    label: 'Pink',
    rgb: {
      default: '228, 85, 193',
      light: '229, 142, 208',
      dark: '204, 72, 172',
    },
  },
};

export const CATEGORY_DEFAULT_ICON = 'heart';

export const UNCATEGORIZED_ICON = {
  key: 'uncategorized',
  icon: IconCategories,
  label: 'Uncategorized',
};

export const CATEGORY_ICONS = {
  apple: {
    icon: IconCategoryApple,
    label: 'Apple',
  },
  award: {
    icon: IconCategoryAward,
    label: 'Award',
  },
  baby: {
    icon: IconCategoryBaby,
    label: 'Baby',
  },
  bag: {
    icon: IconCategoryBag,
    label: 'Bag',
  },
  // bolt: {
  //   icon: IconCategoryBolt,
  //   label: 'Bolt',
  // },
  brain: {
    icon: IconCategoryBrain,
    label: 'Brain',
  },
  bulb: {
    icon: IconCategoryBulb,
    label: 'Bulb',
  },
  cup: {
    icon: IconCategoryCup,
    label: 'Cup',
  },
  dog: {
    icon: IconCategoryDog,
    label: 'Dog',
  },
  family: {
    icon: IconCategoryFamily,
    label: 'Family',
  },
  flag: {
    icon: IconCategoryFlag,
    label: 'Flag',
  },
  flame: {
    icon: IconCategoryFlame,
    label: 'Flame',
  },
  flower: {
    icon: IconCategoryFlower,
    label: 'Flower',
  },
  friends: {
    icon: IconCategoryFriends,
    label: 'Friends',
  },
  goal: {
    icon: IconCategoryGoal,
    label: 'Goal',
  },
  gym: {
    icon: IconCategoryGym,
    label: 'Gym',
  },
  heart: {
    icon: IconCategoryHeart,
    label: 'Heart',
  },
  lotus: {
    icon: IconCategoryLotus,
    label: 'Lotus',
  },
  // love: {
  //   icon: IconCategoryLove,
  //   label: 'Love',
  // },
  masks: {
    icon: IconCategoryMasks,
    label: 'Masks',
  },
  mountain: {
    icon: IconCategoryMountain,
    label: 'Mountain',
  },
  path: {
    icon: IconCategoryPath,
    label: 'Path',
  },
  run: {
    icon: IconCategoryRun,
    label: 'Run',
  },
  // sea: {
  //   icon: IconCategorySea,
  //   label: 'Sea',
  // },
  smile: {
    icon: IconCategorySmile,
    label: 'Smile',
  },
  speed: {
    icon: IconCategorySpeed,
    label: 'Speed',
  },
};

export const CATEGORY_FIELD_VISION = 'vision';
export const CATEGORY_FIELD_PURPOSE = 'purpose';
export const CATEGORY_FIELD_ROLES = 'roles';
export const CATEGORY_FIELD_THRIVE = 'thrive';
export const CATEGORY_FIELD_RESOURCES = 'resources';
export const CATEGORY_FIELD_YEAR_RESULTS = 'yearResults';

export const CATEGORY_FIELDS_MAP = {
  [CATEGORY_FIELD_VISION]: {
    label: 'Vision',
    placeholder: 'What you ultimately want for this area of your life?',
  },
  [CATEGORY_FIELD_PURPOSE]: {
    label: 'Purpose',
    placeholder: 'Why it\'s an absolute “must” for you?',
  },
  [CATEGORY_FIELD_ROLES]: {
    label: 'Roles',
    placeholder: 'Who you are being in this area of your life?',
  },
  [CATEGORY_FIELD_THRIVE]: {
    label: '3-to-Thrive',
    placeholder: 'Top 3 areas to focus on to have the greatest impact',
  },
  [CATEGORY_FIELD_RESOURCES]: {
    label: 'Resources',
    placeholder: 'Things that can help you: books, podcasts, people you know, personal resources, etc.',
  },
  [CATEGORY_FIELD_YEAR_RESULTS]: {
    label: '1 Year/90 Day Results',
    placeholder: 'Your top goals or outcomes in this area',
  },
};

export const EMPTY_STARRED_ORDERED_IDS = {
  [STARRED]: {
    ordered_ids: [],
  },
  [UNSTARRED]: {
    ordered_ids: [],
  },
};

export const DEFAULT_PEOPLE_GROUPS_ORDER = [
  UNGROUPED_PEOPLE_ID,
];

export const DEFAULT_PEOPLE_GROUPS = [
  {
    id: UNGROUPED_PEOPLE_ID,
    name: 'Ungrouped',
    people_list: {
      force: 0,
      ordered_ids: [],
    },
  },
];
