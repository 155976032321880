import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import FirestoreRollingBatch from "./DbService/FirestoreRollingBatch";
import {
  firestore,
  document,
  composeUnsubscribers,
} from "./DbService/general";
import {
  deleteAccount,
  getAccountAndCalendars, getAccountCalendarEvents,
} from "./KloudlessService";
import { areIntervalsOverlapping, addSeconds, addWeeks, differenceInMilliseconds, differenceInSeconds, endOfWeek, startOfWeek, subWeeks, parseISO } from 'date-fns';


const POLLING_INTERVAL = 15000;
export const WEEKS_AROUND = 3;
const WEEKS_NAVIGATION_DEBOUNCE_SECONDS = 3;
const ENABLED_CALENDARS_DEBOUNCE_SECONDS = 3;

let started = false;
let currentWeekStart = startOfWeek(new Date());
let unsubscribeUser = null;
let enabledCalendarsMap = {};
let currentFetchPollId = -1;
let eventsCache = [];
// Map in the shape { weekHash: true/false }
let weeksLoading = {};
// Map in the shape { weekHash: Date }, where Date is the date of the last fetch
// for that week
let weeksLoaded = {};
let pollTimeout;
let enabledCalendarsTimeout;
let watchers = [];


export class ExternalEvent {
  static fromKloudless(object) {
    const startDate = parseISO(object.start);

    return Object.assign(new ExternalEvent(), {
      // Events from the same calendar but from different accounts have the same
      // id. Example: same holidays calendar added in two separate account.
      // To make ids always unique prefix them with the account id
      id: `${object.account_id}/${object.calendar_id}/${object.id}`,
      description: object.name,
      details: object.description,
      startDate,
      duration: differenceInSeconds(parseISO(object.end), startDate),
      creationDate: new Date(object.created),
    });
  }
}


// This service needs a signed-in user to work, so it starts/stops when the
// Firestore user becomes available/unavailable
firebase.auth().onAuthStateChanged(async firebaseUser => {
  if (firebaseUser) {
    startService();
  } else {
    stopService();
  }
});

function startService() {
  // Utility function to make it easier to test equality of enabled calendars:
  // converts enabledCalendarsMap to a string and returns it
  function getEnabledCalendarsHash() {
    const list = [];
    for (const [token, calendarsIds] of Object.entries(enabledCalendarsMap)) {
      if (!calendarsIds) continue;
      for (const calendarId of calendarsIds) {
        list.push(`${token}|${calendarId}`);
      }
    }
    list.sort();
    return list.join('\n');
  }

  // This handler gets called at startup and every time there's a change in the
  // user's preferences
  unsubscribeUser = document().onSnapshot(userSnapshot => {
    const oldCalendars = getEnabledCalendarsHash();
    enabledCalendarsMap = userSnapshot.get('external_calendars') || {};
    const newCalendars = getEnabledCalendarsHash();

    if (!started) {
      // Call fetchAndPollCurrentWeek() disabling debounce, as we don't want
      // any additional delay for the fetches we do at startup
      fetchAndPollCurrentWeek(false);
      started = true;

    } else if (newCalendars !== oldCalendars) {
      // The list of enabled calendars changed, invalidate the cache and fetch
      // updated data
      weeksLoaded = {};
      weeksLoading = {};
      // This handler gets triggered every time a single calendar is
      // enabled/disabled, better to add some debouncing
      clearTimeout(enabledCalendarsTimeout);
      enabledCalendarsTimeout = setTimeout(fetchAndPollCurrentWeek,
        ENABLED_CALENDARS_DEBOUNCE_SECONDS * 1000);
    }
  });
}

function stopService() {
  started = false;
  // resetting currentFetchPollId ends all the currently running instances of
  // fetchAndPollCurrentWeek()
  currentFetchPollId = -1;
  clearTimeout(pollTimeout);
  clearTimeout(enabledCalendarsTimeout);
  if (unsubscribeUser) {
    unsubscribeUser();
    unsubscribeUser = null;
  }
  // This function gets called when the user signs out, so better removing all
  // the cached events, or they may wrongly reappear if the user signs in with
  // a different account.
  enabledCalendarsMap = {};
  eventsCache = [];
  weeksLoaded = {};
  weeksLoading = {};
}

export function watchExternalEvents(callback) {
  // Immediately give to the watcher whatever we have in cache
  callWatchers(callback);
  // Add it to the list to receive future updates
  watchers.push(callback);
  // Return a function that when called releases the watcher
  return () => {
    watchers = watchers.filter(watcherCallback => watcherCallback !== callback);
  };
}

function callWatchers(watcherCallback = null) {
  // We send all the events in the cache instead of just the current week, so
  // client modules (currently React) can do the filtering themselves and be
  // faster, with one roundtrip (in React: a render) less.

  // Ensure we send a brand new reference to the watchers, so doing signaling
  // to React that there are changes to check
  const events = [...eventsCache];

  if (watcherCallback) {
    watcherCallback(events);
  } else {
    for (const watcherCallback of watchers) {
      watcherCallback(events);
    }
  }
}

export function setCurrentWeek(weekStart) {
  if (weekStart === currentWeekStart) return;
  currentWeekStart = weekStart;
  if (!started) return;
  fetchAndPollCurrentWeek();
}

async function fetchAndPollCurrentWeek(debounce = true) {
  // This async function will be often called several times in a short amount
  // of time, for example when the user is quickly navigating through weeks.
  // When this happens, only the must recent call must survive, while the others
  // must stop as soon as they can, as they are no longer current. To achieve
  // this I generate a unique id for each function call, and store it in the
  // global state. The function periodically checks if its id is the current one,
  // and if not it returns.
  const fetchPollId = currentFetchPollId + 1;
  currentFetchPollId = fetchPollId;

  // Immediately stop any previous poller installed
  clearTimeout(pollTimeout);

  // Quickly fetch the current week
  await fetchIfNeeded(currentWeekStart, endOfWeek(currentWeekStart));

  // It has been considered to cancel the call above if the user quickly moves
  // to another week, but since these calls are quite fast it's better to
  // let them finish and save the results in the cache, so we have more
  // pre-fetched data.

  if (debounce) {
    // Add a debounce timeout to not trigger too many calls while the user is
    // quickly navigating through weeks
    await new Promise(
      resolve => setTimeout(resolve, WEEKS_NAVIGATION_DEBOUNCE_SECONDS * 1000));
  }

  // Check if another watcher has been spawned while this function was "awaiting"
  if (currentFetchPollId !== fetchPollId) return;

  // Looks like the user decided to stop on the current week: prefetch some
  // weeks ahead/behind to improve UX
  const weeksBeforeStart = subWeeks(currentWeekStart, WEEKS_AROUND);
  const weeksAheadEnd = addWeeks(endOfWeek(currentWeekStart), WEEKS_AROUND);
  await fetchIfNeeded(weeksBeforeStart, weeksAheadEnd);

  if (currentFetchPollId !== fetchPollId) return;

  // The user is still on this week after the prefetch, so install a poller to
  // keep the data of the week updated
  async function poll() {
    // If another instance has been spawned, stop running this poller
    if (currentFetchPollId !== fetchPollId) return;

    await fetchIfNeeded(currentWeekStart, endOfWeek(currentWeekStart));

    pollTimeout = setTimeout(poll, POLLING_INTERVAL);
  }

  pollTimeout = setTimeout(poll, POLLING_INTERVAL);
}

async function fetchIfNeeded(weekStart, weekEnd) {
  // Some utility functions

  function weekHash(weekStart) {
    return weekStart.toDateString();
  }

  function canBeSkipped(weekStart) {
    const hash = weekHash(weekStart);
    return (weeksLoading[hash]
      || differenceInMilliseconds(new Date(), weeksLoaded[hash]) < POLLING_INTERVAL);
  }

  // Calls callback for each week in the interval, passing the first day as param
  function loopWeeks(weekStart, weekEnd, callback) {
    while (weekStart < weekEnd) {
      callback(weekStart);
      weekStart = addWeeks(weekStart, 1);
    }
  }

  function setWeeksLoading(weekStart, weekEnd, loading) {
    loopWeeks(weekStart, weekEnd, curWeekStart => {
      weeksLoading[weekHash(curWeekStart)] = loading;
    });
  }

  function setWeeksLoaded(weekStart, weekEnd, lastFetchDate) {
    loopWeeks(weekStart, weekEnd, curWeekStart => {
      weeksLoaded[weekHash(curWeekStart)] = lastFetchDate;
    });
  }

  // Here it begins

  // Skip weeks at the beginning of the interval that are already loading or
  // have been fetched recently
  while (weekStart < weekEnd && canBeSkipped(weekStart)) {
    weekStart = addWeeks(weekStart, 1);
  }

  // If all weeks have been skipped, there's nothing to do
  if (weekStart >= weekEnd) return;

  // Same as above, but for weeks at the end of the interval
  while (canBeSkipped(startOfWeek(weekEnd))) {
    weekEnd = subWeeks(weekEnd, 1);
  }

  setWeeksLoading(weekStart, weekEnd, true);

  // Fetch the events
  let freshEvents;
  try {
    freshEvents = await fetchEvents(weekStart, weekEnd);
  } catch (e) {
    // Log errors but don't do anything else, this service will automatically
    // retry this API call later
    console.error(e);
    return;
  } finally {
    // Whatever happens, remove the loading flag
    setWeeksLoading(weekStart, weekEnd, false);
  }

  // Remove from the cache all the events mathing the interval...
  const eventsCacheTmp = eventsCache.filter(ev => !areIntervalsOverlapping(
      { start: ev.startDate, end: addSeconds(ev.startDate, ev.duration)},
      { start: weekStart, end: weekEnd }
    ));
  // ...and replace them with the fresh new ones
  eventsCacheTmp.push(...freshEvents);
  eventsCache = eventsCacheTmp;

  // Flag the weeks in this interval as updated
  setWeeksLoaded(weekStart, weekEnd, new Date());

  // Notify all the watchers that there's new data
  callWatchers();
}

async function fetchEvents(start, end) {
  const promises = [];
  for (const [token, calendarsIds] of Object.entries(enabledCalendarsMap)) {
    for (const calendarId of calendarsIds) {
      promises.push(getAccountCalendarEvents(token, calendarId, start, end));
    }
  }

  // Launch the promises allowing some of them to fail (can happen if the
  // bearer token of an external service expires)
  const results = await Promise.allSettled(promises);
  // Filter only the successful responses and return them.
  // It's ok to ignore the failed ones here: temporary errors are automatically
  // recovered when the poller tries again later, while permanent errors (like
  // bearer token expired) are notified to the user in the Profile screen.
  const rawEvents = results
    .filter(result => result.status === 'fulfilled')
    .map(result => result.value)
    .flat();
  return rawEvents.map(ExternalEvent.fromKloudless);
}


export function watchExternalCalendars(callback) {
  let disposed = false;
  let enabledCalendars;
  let kloudlessAccountsData;
  let servicesNamesCache;

  function handleChange() {
    if (!enabledCalendars || !kloudlessAccountsData) return;

    const services = [];
    for (const { token, error, account: kAccount, calendars: kCalendars } of kloudlessAccountsData) {
      if (!enabledCalendars[token]) continue;

      if (error) {
        services.push({
          token,
          // Take name from the cache as the API call failed, so we couldn't get it from there
          name: servicesNamesCache[token] || '[Name not available]',
          error,
          calendars: [],
        });
        continue;
      }

      const service = {
        token,
        name: kAccount.account,
        calendars: [],
      }
      for (const kCalendar of kCalendars) {
        service.calendars.push({
          id: kCalendar.id,
          name: kCalendar.name,
          enabled: enabledCalendars[token].includes(kCalendar.id) || false,
        });
      }
      // Sort calendars by name
      service.calendars.sort((a, b) => a.name.localeCompare(b.name));
      services.push(service);
    }
    // Sort services by name
    services.sort((a, b) => a.name.localeCompare(b.name));

    callback(services);
  }

  async function poll() {
    const tokens = Object.keys(enabledCalendars);

    const promises = tokens.map(token => getAccountAndCalendars(token));
    kloudlessAccountsData = await Promise.all(promises);

    // Stop here if the watcher has been released during the await above
    if (disposed) return;

    // Store the names of the services just retrieved in a cache,
    // to be used when there are connection errors and the name can't be
    // obtained by the API.

    // These names are stored in a map separate to the one containing enabled
    // calendars to completely avoid race conditions, for example: a user
    // removes an account, and at the same times this poller runs, so it writes
    // the account back in the same map, causing the account to be shown back
    // again to the user in a broken state (because the bearer token has been
    // removed from Kloudless meanwhile).

    // Better to use a batch to avoid triggering a change event on the user
    // object for each name written.
    const batch = new FirestoreRollingBatch();
    for (const { token, account, error } of kloudlessAccountsData) {
      // Skip accounts whose API call failed
      if (error) continue;

      const serviceName = account.account;
      // Write the name in Firestore only if it changed, because Firestore bills
      // you a write operation even when you just overwrite the same value (and
      // we are inside a poller, so this code gets triggered very frequently)
      if (servicesNamesCache[token] !== serviceName) {
        batch.update(document(), {
          [`external_calendars_cache.${token}`]: serviceName
        });
      }
    }
    batch.commit();

    handleChange();
  }

  const interval = setInterval(poll, POLLING_INTERVAL);

  return composeUnsubscribers(
    document().onSnapshot(userSnapshot => {
      enabledCalendars = userSnapshot.get('external_calendars') || {};
      servicesNamesCache = userSnapshot.get('external_calendars_cache') || {};
      handleChange();
      poll();
    }),
    () => {
      clearInterval(interval);
      disposed = true;
    }
  );
}

export function addExternalCalendarService(token) {
  document().update({
    [`external_calendars.${token}`]: [],
  });
}

export async function deleteExternalCalendarService(token, deleteFromKloudless = true) {
  document().update({
    [`external_calendars.${token}`]: firestore.FieldValue.delete(),
    [`external_calendars_cache.${token}`]: firestore.FieldValue.delete(),
  });
  if (deleteFromKloudless) await deleteAccount(token);
}

export function setExternalCalendarEnabled(token, calendarId, enabled) {
  document().update({
    [`external_calendars.${token}`]: enabled ?
      firestore.FieldValue.arrayUnion(calendarId)
      :
      firestore.FieldValue.arrayRemove(calendarId)
  });
}
