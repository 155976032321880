import React, { useState, useCallback, useEffect, useMemo, useRef } from 'react';
import clsx from 'clsx';

import usePeople from '../../hooks/usePeople';

import { UNGROUPED_PEOPLE_ID } from '../../services/DbService/constants';
import { createPerson } from '../../services/DbService/people';

import { scrollIntoViewIfNeeded } from '../../utils.js';

import styles from './PeopleSuggester.module.scss';

const OPTION_NEW_PERSON = '+';

function PeopleSuggester({
  query,
  onConfirm,
  className,
  hidden,
  ...props
}) {
  const ref = useRef();

  const { people } = usePeople();

  const [selected, setSelected] = useState(null);

  const options = useMemo(() => {
    const sanitizedQuery = (query || '').toLowerCase();
    // Filter people whose name starts with the query
    const res = people.filter(({nickname}) =>
      nickname.toLowerCase().startsWith(sanitizedQuery));
    // Sort them alphabetically
    res.sort((a,b) => a.nickname.toLowerCase().localeCompare(b.nickname.toLowerCase()));
    if (res.length > 0 && res[0].nickname.toLowerCase() === sanitizedQuery) {
      // If the first person in the list matches perfectly the user's
      // input make it selected
      setSelected(0);
    } else if(sanitizedQuery.length > 0) {
      // If no person matches perfectly the user's input, add the option to
      // create a new person...
      res.unshift(OPTION_NEW_PERSON);
      // ...and remove any previous selection, there is no obvious choice
      // we can preselect for the user
      setSelected(null);
    }
    return res;
  }, [query, people]);

  // When the selected option changes, scroll the options list if
  // the selected element is not completely visible
  useEffect(() => {
    if (selected === null) return;
    const selectedElem = ref?.current.children[selected];
    scrollIntoViewIfNeeded(selectedElem, ref.current);
  }, [ref, selected]);

  const handleMouseMove = useCallback(e => {
    const hoveredIndex = parseInt(e.currentTarget.dataset.index);
    setSelected(hoveredIndex);
  }, []);

  const confirm = useCallback(optionIndex => {
    const selectedOption = options[optionIndex];
    if (selectedOption === OPTION_NEW_PERSON) {
      onConfirm(createPerson(query, UNGROUPED_PEOPLE_ID));
    } else {
      onConfirm(selectedOption);
    }
  }, [options, query, onConfirm]);

  const handleClick = useCallback(e => {
    // On touch devices there's no mouseEnter event, so the
    // 'selected' state may be empty. Better to get the
    // index directly from the element clicked
    const clickedIndex = parseInt(e.currentTarget.dataset.index);
    setSelected(clickedIndex);
    confirm(clickedIndex);
  }, [confirm]);

  const handleKeyDown = useCallback(e => {
    // With an empty options list there can be only bugs here
    if (options.length === 0) return;

    switch (e.key) {
      case 'ArrowUp':
        e.preventDefault();
        setSelected(selected => {
          // Circular list: when you reach the top continue from the bottom
          if (selected === null || selected <= 0) return options.length - 1;
          return selected - 1;
        });
        break;

      case 'ArrowDown':
        e.preventDefault();
        // Circular list: when you reach the bottom continue from the top
        setSelected(selected => {
          if (selected === null || selected >= options.length - 1) return 0;
          return selected + 1;
        });
        break;

      case 'Enter':
        // The user must close the PeopleSuggester to be able to send an
        // Enter to the parent, this way its intent can't be misread
        e.preventDefault();
        if (selected !== null) {
          confirm(selected);
        }
        break;

      case ' ':
        // The space key should be used to confirm the exact match or the selected
        // name if the user has interacted with the arrow keys/mouse, but NOT for
        // creating new users.
        if (selected !== null && options[selected] !== OPTION_NEW_PERSON) {
          e.preventDefault();

          confirm(selected);
        }
        break;

      default:
        // Do nothing
    }
  }, [options, selected, confirm]);

  useEffect(() => {
    if (hidden) {
      // Hidden has been set to true: remove selction
      setSelected(null);
      return;
    }
    // Event listeners added only when hidden === false
    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [hidden, handleKeyDown]);

  return (
    <ul
      ref={ref}
      className={clsx(styles.options, className)}
      tabIndex={-1}
      role='listbox'
      hidden={hidden || options.length === 0}
      {...props}
    >
      {options.map((option, index) =>
        <li
          key={index}
          className={clsx(styles.option, selected === index && styles.selected)}
          role='option'
          aria-selected={selected === index}
          data-index={index}
          // Using mousemove instead of mouseenter/mouseover because the latters
          // are triggered not only when the user moves the mouse on the element,
          // but also when the element is moved under the mouse cursor.
          // That becomes a problem when the list becomes scrollable and the
          // user scrolls it using the keyboard.
          onMouseMove={handleMouseMove}
          onClick={handleClick}
        >
          {option === OPTION_NEW_PERSON ?
            <>
              <span className={styles.pic} aria-hidden={true}>{query[0]}</span>
              <span className={styles.createNew}>Create new</span>
              <span className={styles.nickname}>@{query}</span>
            </>
            :
            <>
              <span
                className={clsx(styles.pic, option.background_image && styles.picAvatar)}
                aria-hidden={true}
                style={{
                  backgroundImage: option.background_image ? `url(${option.background_image})` : null,
                }}
              >{option.nickname[0]}</span>
              <span className={styles.nickname}>@{option.nickname}</span>
            </>
          }
        </li>
      )}
    </ul>
  );
}

export default PeopleSuggester;
