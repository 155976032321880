import React, { useEffect, useState, useCallback } from 'react';

import { SNOOZED, COMPLETED, PAGE_SIZE, SNOOZED_LABELS, SNOOZED_LIST_ORDER } from '../../services/DbService/constants';
import { watchCategoryBlocks } from '../../services/DbService/categories';

import LoadingSpinner from '../LoadingSpinner';
import EmptyStateIcons from '../EmptyState/EmptyStateIcons';
import LoadMoreButton from '../LoadMoreButton';
import Heading from '../Heading';
import SortableBlocksList from './SortableBlocksList';

import styles from './BlocksList.module.scss';

function BlocksList({
  state,
  categoryId,
  enableEmptyState = true,
  cardWithColor,
  ...props
}) {
  const [blocks, setBlocks] = useState(null);
  const [limit, setLimit] = useState(PAGE_SIZE);
  const [hasMore, setHasMore] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);

  const setSnoozedBlocks = useCallback((timeBlocks, snoozedToString) => {
    setBlocks(blocks => ({
      ...blocks,
      [snoozedToString]: timeBlocks,
    }));
  }, []);

  useEffect(() => {
    setLimit(PAGE_SIZE);
    setHasMore(false);
    setLoadingMore(false);
    setBlocks(null);
  }, [categoryId]);

  useEffect(() => {
    if (!categoryId) return;

    return watchCategoryBlocks(categoryId, state, limit, (blocks, hasMore) => {
      setHasMore(hasMore);
      setLoadingMore(false);
      setBlocks(blocks);
    });
  }, [categoryId, state, limit]);

  const handleLoadMoreClick = useCallback(() => {
    setLoadingMore(true);
    setLimit(limit => limit + PAGE_SIZE);
  }, []);

  if (blocks === null) {
    return (
      <div {...props} className={styles.blocksLoading}>
        <LoadingSpinner/>
      </div>
    );
  }

  // The empty states are built into the snoozed view.
  if (state !== SNOOZED && blocks.length === 0) {
    if (enableEmptyState === false) return null;

    return (
      <EmptyStateIcons>
        {state === COMPLETED ?
          'You don’t have any completed blocks in this\xa0category.'
          :
          'You don’t have any blocks in this category. Why not create one using the button\xa0below?'
        }
      </EmptyStateIcons>
    );
  }

  const sharedSortableListProps = {
    state,
    categoryId,
    cardWithColor,
    ...props,
  };

  if (state === SNOOZED) {
    return (
      <>
        {SNOOZED_LIST_ORDER.map((snoozedToString) => {
          const { subheading, emptyStateString } = SNOOZED_LABELS[snoozedToString];
          return (
            <React.Fragment key={snoozedToString}>
              <Heading level="h4" className={styles.snoozedSubheading}>{subheading}</Heading>
              <SortableBlocksList
                {...sharedSortableListProps}
                blocks={blocks?.[snoozedToString]}
                snoozedToString={snoozedToString}
                setBlocks={blocks => setSnoozedBlocks(blocks, snoozedToString)}
                emptyStateString={emptyStateString}
              />
            </React.Fragment>
          );
        })}
      </>
    )
  }

  return (
    <>
      <SortableBlocksList
        {...sharedSortableListProps}
        blocks={blocks}
        setBlocks={setBlocks}
      />

      {hasMore &&
        <LoadMoreButton loading={loadingMore} onClick={handleLoadMoreClick} />
      }
    </>
  );
}

export default BlocksList;
