import React from 'react';

import Heading, { HEADING_LEVEL_0 } from '../Heading';
import Page from '../Page';

import styles from './Onboarding.module.scss';

function OnboardingPage({
  children,
  title,
  intro,
  ...props
}) {
  return (
    <Page
      className={styles.page}
      pageTitle={title}
      captureList={false}
      {...props}
    >
      <header className={styles.header}>
        {title &&
          <Heading className={styles.title} level={HEADING_LEVEL_0}>{title}</Heading>
        }
        {intro && <p>{intro}</p>}
      </header>
      <main className={styles.main}>
        {children}
      </main>
    </Page>
  );
}

export default OnboardingPage;
