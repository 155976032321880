import React, { useContext, useState, useEffect, useMemo } from 'react';
import clsx from 'clsx';

import {
  ACTIVE, SNOOZED, COMPLETED, STATE_LABELS, ALL_CATEGORY_ID,
} from '../../../services/DbService/constants';

import useUniqueId from '../../../hooks/useUniqueId';

import { useCategories, useCategoryColors } from '../../CategoriesContext';
import { DashboardContext } from '../../Dashboard';
import Divider from '../../Divider';
import Sidebar from '../../Sidebar';
import TabBar from '../../TabBar';
import DashboardSidebarCategory from './DashboardSidebarCategory';
import DashboardSidebarThisWeek from './DashboardSidebarThisWeek';
import DashboardSidebarHeader from './DashboardSidebarHeader';

import styles from './DashboardSidebar.module.scss';

function DashboardSidebar({
  className,
  ...props
}) {
  const { activeCategoryId: categoryId } = useContext(DashboardContext);

  const { activeCategories, hiddenCategories } = useCategories();
  const categoryColors = useCategoryColors(categoryId);

  // Because the state is hoisted up to the parent using setState, we can’t just use the id that we
  // provide to the tab for the handleClick callback. Combining these two into one array (including
  // `label`) means that the tab bar is populated from the same look up as the handleClick callback.
  const activeId = useUniqueId();
  const snoozedId = useUniqueId();
  const completedId = useUniqueId();

  const stateTabs = useMemo(() => ([
    {
      state: ACTIVE,
      id: activeId,
      label: STATE_LABELS[ACTIVE].subheading,
    },
    {
      state: SNOOZED,
      id: snoozedId,
      label: STATE_LABELS[SNOOZED].subheading,
    },
    {
      state: COMPLETED,
      id: completedId,
      label: STATE_LABELS[COMPLETED].subheading,
    },
  ]), [activeId, snoozedId, completedId]);

  const [state, setState] = useState(ACTIVE);
  const [activeTabId, setActiveTabId] = useState(stateTabs[0].id);

  useEffect(() => {
    const newStateTab = stateTabs.find(tab => tab.id === activeTabId);
    setState(newStateTab.state);
  }, [activeTabId, stateTabs]);

  const isThisWeek = categoryId === ALL_CATEGORY_ID;

  const headerChildren = useMemo(() => {
    if (activeCategories === null || hiddenCategories === null) {
      return null;
    }

    return (
      <>
        <DashboardSidebarHeader
          activeCategories={activeCategories}
          hiddenCategories={hiddenCategories}
        />
        {!isThisWeek &&
          <>
            <Divider />
            <TabBar
              aria-label="Show only"
              active={activeTabId}
              onTabClick={setActiveTabId}
              tabs={stateTabs}
            />
          </>
        }
      </>
    );
  }, [activeCategories, hiddenCategories, isThisWeek, activeTabId, stateTabs]);

  return (
    <Sidebar
      className={clsx(className, !isThisWeek && styles.hasCategory)}
      headerChildren={headerChildren}
      headerDivider={isThisWeek}
      style={categoryColors}
      {...props}
    >
      {isThisWeek &&
        // Hidden categories are excluded from This Week so only the active categories
        // are needed to be passed through here
        <DashboardSidebarThisWeek
          categories={activeCategories}
        />
      }

      {!isThisWeek &&
        <DashboardSidebarCategory
          key={categoryId}
          categoryId={categoryId}
          state={state}
          tabs={stateTabs}
        />
      }
    </Sidebar>
  );
}

export default DashboardSidebar;
