/* global analytics */
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import { ParameterError } from './DbService/general';
import { appDebugLog, appDebugWarn, appDebugError } from './DebugService';
import { watchUser } from './AuthService';

const DEBUG_TYPE_ANALYTICS = 'Analytics (Segment)';
const DEBUG_TYPE_ERROR_TRACKING = 'Error Tracking (Sentry)';

const sentryEnv = process.env.REACT_APP_SENTRY_ENV;
// To avoid bombarding the Sentry rate limit, development errors are disabled by default.
// This condition can be disabled to test anything in particular, but should be reenabled
// when committed.
const errorTrackingEnabled = sentryEnv && sentryEnv !== 'development';

export function initErrorTracking() {
  if (errorTrackingEnabled) {
    Sentry.init({
      dsn: process.env.REACT_APP_SENTRY_DSN,
      environment: process.env.REACT_APP_SENTRY_ENV,
      integrations: [
        new Integrations.BrowserTracing(),
      ],
    });
    appDebugLog(DEBUG_TYPE_ERROR_TRACKING, 'Enabled', '', { environment: sentryEnv });
  } else {
    appDebugWarn(DEBUG_TYPE_ERROR_TRACKING, 'Disabled', '', { environment: sentryEnv });
  }
}

// Allows better debugging to ensure that calls aren't made to services that don't exist
const checkAnalyticsProvider = (taskName) => {
  if (window.analytics) return true;

  appDebugError(DEBUG_TYPE_ANALYTICS, `${taskName} failed`, 'Analytics provider is not loaded');
  return;
};

const GLOBAL_ANALYTICS_PROPERTIES = {
  dataset: process.env.REACT_APP_DATASET,
};

function identifyUser(user) {
  if (user !== null && typeof user !== 'object')
    throw new ParameterError({ user }, 'Must be null or an object.');

  const userId = user?.uid;

  const identificationEvent = userId ? 'Identify User' : 'De-identify User';

  // Sentry identification is called before the analytics for instances where
  // the analytics might be blocked/disabled, but we still want to identify the
  // user for error handling.
  if (errorTrackingEnabled) {
    if (userId) {
      Sentry.setUser({ id: userId });
      appDebugLog(DEBUG_TYPE_ERROR_TRACKING, identificationEvent, userId);
    } else {
      Sentry.configureScope(scope => scope.setUser(null));
      appDebugLog(DEBUG_TYPE_ERROR_TRACKING, identificationEvent);
    }
  }

  if (!checkAnalyticsProvider(identificationEvent)) return;

  if (userId) {
    const {
      email,
      username,
      photoURL: avatar,
      firstName,
      lastName,
    } = user;

    const traits = {
      email,
      username,
      avatar,
      firstName,
      lastName,
      ...GLOBAL_ANALYTICS_PROPERTIES,
    };

    analytics.identify(userId, traits, () => {
      appDebugLog(DEBUG_TYPE_ANALYTICS, identificationEvent, userId, { traits });
    });
  } else {
    analytics.reset();
    appDebugLog(DEBUG_TYPE_ANALYTICS, identificationEvent);
  }
}

watchUser(newUser => identifyUser(newUser));

export function trackPage(location) {
  if (!checkAnalyticsProvider('Track Page')) return;

  const properties = {
    ...GLOBAL_ANALYTICS_PROPERTIES,
  };

  analytics.page(null, properties);

  // analytics.page has no callback so it is just called syncronously
  appDebugLog(DEBUG_TYPE_ANALYTICS, 'Track Page', location, { properties });
}

export function trackEvent(eventName, properties = {}, callback = null) {
  if (!checkAnalyticsProvider('Track Event')) return;

  if (!eventName)
    throw new ParameterError({ eventName }, 'Event name is required.');
  if (typeof properties !== 'object')
    throw new ParameterError({ properties }, 'Must be an object.');
  if (callback !== null && typeof callback !== 'function')
    throw new ParameterError({ callback }, 'Must be null or a function');

  properties = {
    ...properties,
    ...GLOBAL_ANALYTICS_PROPERTIES,
  };

  analytics.track(eventName, properties, () => {
    appDebugLog(DEBUG_TYPE_ANALYTICS, 'Track Event', eventName, { properties });
    if (callback) callback();
  });
}
