import React, { useCallback, useState, useEffect, useMemo, useRef } from 'react';
import { Link as RouterDomLink, generatePath, useRouteMatch } from 'react-router-dom';
import clsx from 'clsx';
import { format } from 'date-fns';

import { BLOCK_DETAIL_URL } from '../App';

import {
  SNOOZED, ACTIVE,
  STARRED, UNSTARRED,
  SNOOZED_LABELS, SNOOZED_LIST_ORDER, SNOOZED_TO_WEEK,
} from '../../services/DbService/constants';
import { setBlockState, setBlockDueDate } from '../../services/DbService/blocks';
import {
  watchBlockScheduled, watchBlockActions,
  setBlockStarred, setBlockCategory,
} from '../../services/DbService/blocks';
import { setActionList } from '../../services/DbService/actions';
import { trackEvent } from '../../services/AnalyticsService';

import useUniqueId from '../../hooks/useUniqueId';

import Button from '../Button';
import { CardButton, CardCategoryMenu } from '../Card';
import ContextualMenu, {
  ContextualMenuLink, ContextualMenuTrigger, MENU_POSITION_HORIZONTAL,
} from '../ContextualMenu';
import Dialog from '../Dialog';
import Dropzone from '../DragAndDrop/Dropzone';
import DueDateButton from '../DueDateButton';
import { ActionsCounters } from '../Counters';
import { BlockItemMenu } from './';
import Tooltip from '../Tooltip';

import { ReactComponent as IconError } from '../../assets/icons/16-error.svg';
import { ReactComponent as IconStar } from '../../assets/icons/16-star.svg';
import { ReactComponent as IconStarOutline } from '../../assets/icons/16-star-outline.svg';
import { ReactComponent as IconSnoozed } from '../../assets/icons/16-snoozed.svg';
import { ReactComponent as IconSnooze } from '../../assets/icons/16-snooze.svg';
import { ReactComponent as IconMore } from '../../assets/icons/16-more.svg';

import styles from './BlockItem.module.scss';

function BlockItem({
  className,
  block,
  enableScheduledBanner = true,
  detailLink = true,
  snoozedToString,
  showCounters = true,
  cardWithColor,
  ...props
}) {
  const blockRef = useRef();

  const {
    id,
    result,
    categoryId,
    state,
    starred,
  } = block;

  const match = useRouteMatch(BLOCK_DETAIL_URL);
  const active = match?.params.blockId === id;

  const [notScheduledDialogVisible, setNotScheduledDialogVisible] = useState(null);

  const stateMenuButtonRef = useRef();
  const stateMenuButtonId = useUniqueId();
  const [stateMenuVisible, setStateMenuVisible] = useState(null);

  const optionsMenuButtonRef = useRef();
  const optionsMenuButtonId = useUniqueId();
  const [optionsMenuVisible, setOptionsMenuVisible] = useState(false);
  const [rightClickMenuCoords, setRightClickMenuCoords] = useState(null);

  const [scheduled, setScheduled] = useState(null);
  const [dueDate, setDueDate] = useState(null);
  const [actions, setActions] = useState([]);

  useEffect(() => {
    if (!id || showCounters === false) return;

    return watchBlockActions(id, setActions);
  }, [id, showCounters]);

  useEffect(() => watchBlockScheduled(id, setScheduled), [id]);

  useEffect(() => {
    setDueDate(block.dueDate || null);
  }, [block.dueDate]);

  const handleRightClick = useCallback((e) => {
    e.preventDefault();

    setRightClickMenuCoords({ x: e.clientX, y: e.clientY });
    setOptionsMenuVisible(visible => !visible);
  }, []);

  // When the menu is closed reset the right click coordinates so that if it is opened
  // using the ellipsis, it uses the buttonRef rather than the mouseCoords
  useEffect(() => {
    if (optionsMenuVisible) return;

    setRightClickMenuCoords(null);
  }, [optionsMenuVisible])

  const handleActionDropped = useCallback(e => {
    let { id: actionId, starred, state } = e.item.dataset;
    starred = starred === 'true'; // when extracted it is a string, convert to bool
    setActionList(actionId, categoryId, id, state, starred);
  }, [categoryId, id]);

  const handleCategoryClick = useCallback((newCategoryId) => {
    if (categoryId === newCategoryId) return;

    setBlockCategory(id, newCategoryId, null, state, snoozedToString);
  }, [categoryId, id, state, snoozedToString]);

  const handleSetActive = useCallback(() => {
     setBlockState(id, ACTIVE);
     setStateMenuVisible(false);
  }, [id]);

  const handleSnooze = useCallback(snoozedToString => {
    setBlockState(id, SNOOZED, null, snoozedToString);
    // handleSnooze is called from a few spots, but none that need the menu to be open
    // after it happens, so it's safe to set this to false whenever the block is snoozed
    setStateMenuVisible(false);
  }, [id]);

  const handleStarClick = useCallback(() => {
    setBlockStarred(id, !starred);
  }, [id, starred]);

  const handleDueDateChange = useCallback(newDate => {
    setDueDate(newDate);
    setBlockDueDate(id, newDate);
  }, [id]);

  const actionsLength = useMemo(() => {
    const actionsLists = block?.orderedActionsIds?.[ACTIVE];
    if (!actionsLists) return null;

    const actionsStarred = actionsLists?.[STARRED] || [];
    const actionsUnstarred = actionsLists?.[UNSTARRED] || [];

    return actionsStarred.length + actionsUnstarred.length;
  }, [block]);

  const notScheduled = enableScheduledBanner && state === ACTIVE && scheduled === false && actionsLength;

  let LabelTag, labelProps;

  if (detailLink) {
    LabelTag = RouterDomLink;
    const blockUrl = generatePath(BLOCK_DETAIL_URL, { blockId: id });

    labelProps = {
      to: blockUrl,
      onClick: () => {
        trackEvent('User clicked a Block Item', {
          id,
          state,
          starred,
        });
      },
    };
  } else {
    LabelTag = 'span';
    labelProps = {};
  }

  // The active state is the reverse of the default, so when the cardWithColor
  // prop is true, the active state is a card without colour and vice versa
  const isCardWithColor = (!active && cardWithColor) || (active && !cardWithColor);

  return (
    <li
      ref={blockRef}
      className={clsx(
        className,
        styles.container,
        notScheduled && styles.notScheduled,
      )}
      data-id={id}
      // You can drag and drop both actions and blocks inside a cateogry header,
      // so this field is needed to recognise which is which
      data-type='block'
      data-category-id={categoryId}
      onContextMenu={handleRightClick}
      {...props}
    >
      <Dropzone
        className={styles.dropzone}
        allowDrop={['actions']}
        onAdd={handleActionDropped}
      />
      <div
        className={clsx(
          styles.card,
          isCardWithColor && styles.cardWithColor,
        )}
      >
        <LabelTag className={styles.label} {...labelProps}>
          {showCounters &&
            <ActionsCounters className={styles.counters} actions={actions} />
          }
          <span className={styles.result}>
            <span>{result}</span>
          </span>
        </LabelTag>

        <div className={clsx(styles.buttons, 'sortable-ignore')}>
          <CardCategoryMenu
            categoryId={categoryId}
            cardWithColor={isCardWithColor}
            onCategoryClick={handleCategoryClick}
          />

          <CardButton
            selected={starred}
            aria-label="Star block"
            onClick={handleStarClick}
            cardWithColor={isCardWithColor}
          >
            {starred && <IconStar role="presentation" />}
            {!starred && <IconStarOutline role="presentation" />}
          </CardButton>

          <ContextualMenuTrigger
            visible={stateMenuVisible}
            setVisible={setStateMenuVisible}
            menuId={stateMenuButtonId}
          >
            <CardButton
              ref={stateMenuButtonRef}
              aria-label="Snooze block"
              cardWithColor={isCardWithColor}
            >
              <IconSnooze role="presentation" />
            </CardButton>
          </ContextualMenuTrigger>

          {stateMenuVisible &&
            <ContextualMenu
              buttonRef={stateMenuButtonRef}
              onClose={() => setStateMenuVisible(false)}
              aria-labelledby={stateMenuButtonId}
            >
              {state !== ACTIVE &&
                <ContextualMenuLink onClick={handleSetActive}>Make Block Active</ContextualMenuLink>
              }
              {SNOOZED_LIST_ORDER.map(snoozeSlug => {
                if (snoozedToString === snoozeSlug) return null;

                return (
                  <ContextualMenuLink key={snoozeSlug} onClick={() => handleSnooze(snoozeSlug)}>
                    Snooze {SNOOZED_LABELS[snoozeSlug].cta}
                  </ContextualMenuLink>
                );
              })}
            </ContextualMenu>
          }

          {dueDate &&
            <DueDateButton
              date={dueDate}
              onChange={handleDueDateChange}
              categoryId={categoryId}
              customInput={(
                <CardButton
                  className={styles.buttonDueDate}
                  aria-label="Set Due Date"
                  iconOnly={false}
                  cardWithColor={isCardWithColor}
                >
                  <IconSnoozed role="presentation" />
                  <span className={styles.buttonDueDateLabel}>{format(dueDate, 'P')}</span>
                </CardButton>
              )}
            />
          }

          <ContextualMenuTrigger
            visible={optionsMenuVisible}
            setVisible={setOptionsMenuVisible}
            menuId={optionsMenuButtonId}
          >
            <CardButton
              ref={optionsMenuButtonRef}
              aria-label="Block tools"
              cardWithColor={isCardWithColor}
            >
              <IconMore role="presentation" />
            </CardButton>
          </ContextualMenuTrigger>
          <BlockItemMenu
            block={block}
            showSnoozeButton={false}
            showActiveButton={false}
            menuVisible={optionsMenuVisible}
            buttonRef={optionsMenuButtonRef}
            contextRef={blockRef}
            mouseCoords={rightClickMenuCoords}
            onClose={() => setOptionsMenuVisible(false)}
            aria-labelledby={optionsMenuButtonId}
            position={MENU_POSITION_HORIZONTAL}
          />
        </div>

        {enableScheduledBanner && state === ACTIVE &&
          <Tooltip title="Not scheduled">
            <button
              className={clsx(styles.notScheduledBanner, 'sortable-ignore')}
              // By checking explicitly for false it stops the banner from showing when
              // the component is created and then removing it when events are found
              aria-hidden={scheduled !== false || !actionsLength}
              disabled={scheduled !== false || !actionsLength}
              aria-label="Not scheduled"
              onClick={() => setNotScheduledDialogVisible(true)}
            >
              <IconError role="presentation" />
            </button>
          </Tooltip>
        }
      </div>

      {notScheduledDialogVisible &&
        <Dialog
          className={styles.snoozeToDialog}
          headerTitle='Block not scheduled'
          onClose={() => setNotScheduledDialogVisible(false)}
          footer={<>
            <Button block onClick={() => setNotScheduledDialogVisible(false)}>Ok</Button>
            <Button block onClick={() => handleSnooze(SNOOZED_TO_WEEK)}>Snooze to Next Week</Button>
          </>}
        >
          <p>This block has no upcoming events. Schedule it, or snooze it to remove the&nbsp;alert.</p>
        </Dialog>
      }
    </li>
  );
}

export default BlockItem;
