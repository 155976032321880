import React, { forwardRef } from 'react';
import clsx from 'clsx';

import { CATEGORY_ICONS } from '../../services/DbService/constants';
import { composeCategoryColors } from '../../services/DbService/categories';

import ItemIcon from '../ItemIcon';
import Tooltip from '../Tooltip';

import styles from './CategoryRadioGroupIcon.module.scss';

const CategoryRadioGroupIcon = forwardRef(({
  className,
  icon,
  setIcon,
  color,
  style = {},
  ...props
}, ref) => {
  return (
    <div
      className={clsx(className, styles.icons)}
      role="radiogroup"
      style={{
        ...style,
        ...composeCategoryColors(color),
      }}
      {...props}
    >
      {Object.keys(CATEGORY_ICONS).map(iconSlug => {
        const { label } = CATEGORY_ICONS[iconSlug];
        const selected = icon === iconSlug;
        return (
          <Tooltip title={label} key={iconSlug}>
            <div className={styles.icon}>
              <ItemIcon
                className={clsx(!selected && styles.unselected)}
                icon={iconSlug}
                active={selected}
              />
              <input
                type="radio"
                value={iconSlug}
                checked={selected}
                onChange={e => setIcon(e.target.value)}
                aria-label={label}
              />
              <span className={styles.focusRing} aria-hidden="true" />
            </div>
          </Tooltip>
        )
      })}
    </div>
  );
});

CategoryRadioGroupIcon.displayName = 'CategoryRadioGroupIcon';

export default CategoryRadioGroupIcon;
