import React, { useState, useCallback } from 'react';

import { PERSON_NICKNAME_MAX_LENGTH } from '../../services/DbService/constants';

import useUniqueId from '../../hooks/useUniqueId';

import CreateDialog from '../CreateDialog';
import PeopleSuggester from '../PeopleSuggester';
import { InputLabel } from '../Input';

import styles from './LeverageDialog.module.scss';

function LeverageDialog({
  onClose,
  onConfirm,
}) {
  const [input, setInput] = useState('');
  const [inputPrefixed, setInputPrefixed] = useState('');
  const optionsId = useUniqueId();

  const handleChange = useCallback(e => {
    let value = e.target.value;
    if (value !== '' && !value.startsWith('@')) value = `@${value}`;
    value = value.toLowerCase().replace(/\s/g, '');
    setInputPrefixed(value);
    setInput(value.substr(1));
  }, []);

  const handleConfirm = useCallback(personId => {
    onConfirm(personId);
    onClose();
  }, [onConfirm, onClose]);

  return (
    <CreateDialog onClose={onClose}>
      <form className={styles.form}>
        <label className={styles.label}>
          <InputLabel tag="span">Leverage Person</InputLabel>
          <input
            className={styles.input}
            type='text'
            autoFocus
            maxLength={PERSON_NICKNAME_MAX_LENGTH}
            placeholder='e.g. @rob'
            value={inputPrefixed}
            onChange={handleChange}
            autoComplete='off'
            spellCheck={false}
            role='combobox'
            aria-owns={optionsId}
            aria-controls={optionsId}
            aria-autocomplete='list'
            aria-expanded={true}
          />
          <span className={styles.focusRing} aria-hidden="true" />
          <PeopleSuggester
            id={optionsId}
            query={input}
            onConfirm={handleConfirm}
            className={styles.suggestions}
          />
        </label>
      </form>
    </CreateDialog>
  );
}

export default LeverageDialog;
