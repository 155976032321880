import React, { useRef, useCallback, useEffect, useState } from 'react';
import { ReactSortable } from "react-sortablejs";
import clsx from 'clsx';

import { ACTIVE, SNOOZED, COMPLETED } from '../../services/DbService/constants';
import { setBlocksOrder, setBlockCategory, setBlockState } from '../../services/DbService/blocks';

import BlockItem from '../BlockItem';

import styles from './BlocksList.module.scss';

const sortBlocks = (array, state) => {
  // completed blocks are ordered from Firestore (by completion date) so the array
  // provided should be respected
  if (state === COMPLETED) return array;

  // All other states should be ordered by result when manual sorting isn't enabled
  return array.sort((a, b) => {
    const aResult = a.result?.toLowerCase();
    const bResult = b.result?.toLowerCase();

    return aResult.localeCompare(bResult);
  });
}

function SortableBlocksList({
  blocks,
  setBlocks,
  categoryId,
  state,
  className,
  emptyStateString,
  snoozedToString,
  enableScheduledBanner,
  sortActive = true,
  detailLink,
  showCounters,
  cardWithColor,
  ...props
}) {
  const sortableRef = useRef();

  const manualSort = sortActive && state === ACTIVE;

  const [list, setList] = useState(blocks);
  useEffect(() => setList(blocks), [blocks]);

  const handleOrderChange = useCallback(() => {
    if (state !== ACTIVE) return;
    const orderedIds = sortableRef.current.sortable.toArray();
    setBlocksOrder(categoryId, state, orderedIds);
  }, [sortableRef, categoryId, state]);

  const handleBlockDrop = useCallback(e => {
    const { id, categoryId: blockCategoryId } = e.item.dataset;
    if (state === SNOOZED) {
      if (blockCategoryId === categoryId) {
        setBlockState(id, SNOOZED, null, snoozedToString);
      } else {
        setBlockCategory(id, categoryId, null, SNOOZED, snoozedToString);
      }
    } else {
      const orderedIds = manualSort ?
        sortableRef.current.sortable.toArray() :
        null;
      setBlockCategory(id, categoryId, orderedIds, state);
    }
  }, [state, snoozedToString, manualSort, categoryId]);

  if (!blocks) return;

  return (
    <div
      className={clsx(styles.sortableWrapper, !!blocks && blocks.length === 0 && styles.empty)}
      data-empty-string={emptyStateString}
    >
      <ReactSortable
        {...props}

        className={clsx(className, styles.sortable, manualSort && styles.manualSort)}
        tag="ul"
        ref={sortableRef}
        list={manualSort ? list : sortBlocks(list, state)}
        setList={setList}
        group={{
          name: 'blocks',
          pull(to, from) {
            return to.el.dataset.clone ? 'clone' : true;
          },
        }}
        filter='.sortable-ignore'
        preventOnFilter={false}
        forceFallback={true}
        animation={300}
        delay={50}
        sort={manualSort}
        onUpdate={handleOrderChange}
        onAdd={handleBlockDrop}
      >
        {list.map((block, index) => (
          <BlockItem
            key={block.id}
            block={block}
            data-id={block.id}
            // You can drag and drop both actions and blocks inside a cateogry header,
            // so this field is needed to recognise which is which
            data-type="block"
            data-category-id={categoryId}
            data-last={index + 1 === blocks.length}
            enableScheduledBanner={enableScheduledBanner}
            detailLink={detailLink}
            snoozedToString={snoozedToString}
            showCounters={showCounters}
            cardWithColor={cardWithColor}
          />
        ))}
      </ReactSortable>
    </div>
  );
};

export default SortableBlocksList;
