import { isPast, isBefore, addDays } from 'date-fns';

import { mapById } from '../../utils';

import FirestoreRollingBatch from './FirestoreRollingBatch';
import {
  STARRED, UNSTARRED,
  ACTIVE, COMPLETED, SNOOZED,
  CATEGORY_ACTIVE, CATEGORY_HIDDEN,
  CATEGORY_NAME_MAX_LENGTH,
  CATEGORY_ICONS, CATEGORY_DEFAULT_ICON, UNCATEGORIZED_ICON,
  CATEGORY_COLORS, CATEGORY_DEFAULT_COLOR,
  UNCATEGORIZED_ID, ALL_CATEGORY_ID,
  SNOOZED_TO_WEEK, SNOOZED_TO_MONTH, SNOOZED_TO_NINETY_DAYS, SNOOZED_TO_SOMETIME,
} from './constants';
import { firestore, collection, document, composeUnsubscribers, getSortedDocuments, ParameterError, generateId, CacheDelayer } from './general';
import { Block, calculateSnoozedToDate } from './blocks';
import { User } from './user';
import { Action } from './actions';
import { trackEvent } from '../AnalyticsService';

const LEGACY_COLORS_MAP = {
  '#00C4D2': '5',
  '#2790FF': '5',
  '#7EB800': '4',
  '#8935FF': '6',
  '#FF44B5': '8',
  '#FF4D4A': '1',
  '#7C7C99': '0',
  '#FF9529': '2',
};

export class Category {
  static fromFirestore(snapshot, options) {
    const data = snapshot.data(options);
    if (!data) return undefined;

    const categoryId = snapshot.id;

    let { color } = data;

    // Legacy colours were stored as hexes, so if no color string is set, we convert the
    // background_color.hex value (if present) using the LEGACY_COLORS_MAP into the
    // closest colour string we have in the palette. If not present, the default
    // colour is used.
    if (!color) {
      const backgroundLegacyDecimal = data.background_color?.hex;
      const backgroundLegacyHex = backgroundLegacyDecimal && `#${backgroundLegacyDecimal.toString(16).padStart(6, '0').toUpperCase()}`;

      color = LEGACY_COLORS_MAP[backgroundLegacyHex] || CATEGORY_DEFAULT_COLOR;
    }

    // CATEGORY_ICONS[data.icon || CATEGORY_DEFAULT_ICON] doesn't work here in instances
    // where old icon names were used. As this change was after the cut off for stable data,
    // we have to safely degrade to using the CATEGORY_DEFAULT_ICON if category.icon is truthy,
    // but invalid (i.e. an old icon slug).
    const icon = categoryId === UNCATEGORIZED_ID ?
      UNCATEGORIZED_ICON.key :
      (CATEGORY_ICONS[data.icon] ? data.icon : CATEGORY_DEFAULT_ICON);

    const orderedActiveStarredActionsIds = data.actions_lists?.[ACTIVE]?.[STARRED]?.ordered_ids || [];
    const orderedActiveUnstarredActionsIds = data.actions_lists?.[ACTIVE]?.[UNSTARRED]?.ordered_ids || [];
    const orderedActiveBlocksIds = data.blocks_lists?.[ACTIVE]?.ordered_ids || [];

    return Object.assign(new Category(), {
      id: categoryId,
      name: data.name,
      icon,
      color,
      editable: data.is_editable,
      // active is set when state is empty to handle legacy categories with no state
      state: data.state || CATEGORY_ACTIVE,
      orderedActionsIds: {
        [ACTIVE]: {
          [STARRED]: orderedActiveStarredActionsIds,
          [UNSTARRED]: orderedActiveUnstarredActionsIds,
        },
      },
      orderedBlocksIds: {
        [ACTIVE]: orderedActiveBlocksIds,
      },
      vision: data.vision || '',
      purpose: data.purpose || '',
      roles: data.roles || '',
      thrive: data.thrive || '',
      resources: data.resources || '',
      yearResults: data.year_results || '',
    });
  }
}

export function watchCategories(state, callback) {
  if (state === CATEGORY_HIDDEN) {
    return collection('categories')
      .where('state', '==', state)
      .onSnapshot(snapshot => {
        const categories = snapshot.docs.map(Category.fromFirestore);
        callback(categories);
      });
  }

  let categoriesById, order;

  function handleChange() {
    if (categoriesById === undefined || order === undefined) return;
    const unorderedCategories = getSortedDocuments(categoriesById, order);

    // Ensures that UNCATEGORIZED_ID is always the last item, with the rest alphabetised, but keeps
    // the ordered_ids (order) for future implementations
    const categories = unorderedCategories.sort((a, b) => {
      if (a.name === b.name) return 0;
      if (a.id === UNCATEGORIZED_ID) return 1;
      if (b.id === UNCATEGORIZED_ID) return -1;
      return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1;
    });

    callback(categories);
  }

  return composeUnsubscribers(
    document().onSnapshot(snapshot => {
      order = User.fromFirestore(snapshot).orderedCategoryIds;
      handleChange();
    }),

    collection('categories').onSnapshot(snapshot => {
      const categories = snapshot.docs.map(Category.fromFirestore);
      categoriesById = mapById(categories);
      handleChange();
    })
  );
}

export function composeCategoryColors(color) {
  const colorSet = CATEGORY_COLORS[color];

  if (!color || !colorSet) return {};

  const rgb = {
    '--category-default-rgb': colorSet.rgb.default,
    '--category-light-rgb': colorSet.rgb.light,
    '--category-dark-rgb': colorSet.rgb.dark,
  };

  return rgb;
}

export function watchCategory(categoryId, callback) {
  return document(`categories/${categoryId}`).onSnapshot(docSnapshot => {
    callback(Category.fromFirestore(docSnapshot));
  });
}

const isBeforeSnoozed = (date, snoozedToString) =>
  isBefore(date, addDays(calculateSnoozedToDate(snoozedToString), 1));

const calculateSnoozedToString = (date) => {
  let string = SNOOZED_TO_SOMETIME;

  if (date === null) {
    // To ensure that null isn't parsed as a date, this condition
    // is required. null is actually equal to SNOOZED_TO_SOMETIME
    // here, but that is already set as a catch all so nothing is
    // needed to be set for date === null.
  } else if (isBeforeSnoozed(date, SNOOZED_TO_WEEK)) {
    string = SNOOZED_TO_WEEK;
  } else if (isBeforeSnoozed(date, SNOOZED_TO_MONTH)) {
    string = SNOOZED_TO_MONTH;
  } else if (isBeforeSnoozed(date, SNOOZED_TO_NINETY_DAYS)) {
    string = SNOOZED_TO_NINETY_DAYS;
  }

  return string;
};

/**
 * Watches all the blocks in a category in a specific state.
 * Important: when fetching completed blocks use the limit param
 * to paginate the results, they could be several thousands.
 * The limit parameter is ignored when watching non-completed blocks.
 */
export function watchCategoryBlocks(categoryId, state, limit, callback) {
  if (state === COMPLETED && !limit)
    // Completed blocks can be many thousands, pagination is required
    throw new ParameterError({limit}, 'must be > 0 when querying completed blocks');

  const blocksQuery = collection('blocks')
    .where('parent_category_id', '==', categoryId)
    .where('state', '==', state);

  if (state === COMPLETED) {
    const cacheDelayer = new CacheDelayer();
    const unsubscribe = blocksQuery
      .orderBy('date_of_completion', 'desc')
      // The extra action is used only to peek above the limit to see if there
      // are more actions
      .limit(limit + 1)
      .onSnapshot(snapshot => {
        const blocks = snapshot.docs.map(Block.fromFirestore);
        let hasMore = false;
        if (blocks.length > limit) {
          hasMore = true;
          // Skip this extra action that we used only to peek above the limit
          blocks.pop();
        }
        cacheDelayer.delayIfCached(() => callback(blocks, hasMore), snapshot);
      });
    return () => {
      unsubscribe();
      cacheDelayer.dispose();
    };

  } else if (state === SNOOZED) {
    // There is no sorting on this query, it should be applied in the end instance for
    // performance in drag and drop situations
    return blocksQuery
      .onSnapshot(snapshot => {
        const batch = new FirestoreRollingBatch();

        const snoozedBlocks = {
          [SNOOZED_TO_WEEK]: [],
          [SNOOZED_TO_MONTH]: [],
          [SNOOZED_TO_NINETY_DAYS]: [],
          [SNOOZED_TO_SOMETIME]: [],
        };

        // Checks need to be made to see if snoozed blocks that have a snoozed_to
        // date in the past exist, and where they do not show them as a snoozed block
        // but instead setting their collective state to be active
        snapshot.docs.forEach(doc => {
          const block = Block.fromFirestore(doc);
          const {
            id: blockId,
            snoozedTo: blockSnoozedTo = null,
            categoryId: blockCategoryId,
          } = block;

          if (blockSnoozedTo !== null && isPast(blockSnoozedTo)) {
            batch.update(document(`blocks/${blockId}`), {
              state: ACTIVE,
              snoozed_to: null,
            });

            batch.update(document(`categories/${blockCategoryId}`), {
              [`blocks_lists.${ACTIVE}.ordered_ids`]: firestore.FieldValue.arrayUnion(blockId),
            });
          } else {
            const snoozedBlockKey = calculateSnoozedToString(block.snoozedTo);
            snoozedBlocks[snoozedBlockKey].push(block);
          }
        });

        batch.commit();

        callback(snoozedBlocks, false);
      })
  } else {

    let blocksById, order;

    function handleChange() {
      if (blocksById === undefined || order === undefined) return;
      callback(getSortedDocuments(blocksById, order), false);
    }

    return composeUnsubscribers(
      document(`categories/${categoryId}`).onSnapshot(snapshot => {
        const category = snapshot.data();
        order = category.blocks_lists?.[state]?.ordered_ids || [];
        handleChange();
      }),

      blocksQuery.onSnapshot(snapshot => {
        const blocks = snapshot.docs.map(Block.fromFirestore);
        blocksById = mapById(blocks);
        handleChange();
      })
    );
  }
}

export function watchCaptureListCount(callback) {
  // This can throw a number larger than the capture list for legacy/development users
  // where some old actions might be hidden. The alternative is to count the length
  // of the STARRED and UNSTARRED lists, but that is more expensive, and this count
  // should be reliable for most users.
  return collection('actions')
    .where('state', '==', ACTIVE)
    .where('parent_category_id', '==', UNCATEGORIZED_ID)
    .onSnapshot(snapshot => {
      callback(snapshot.size);
    });
}

export function watchCategoryActiveActions(categoryId, includeBlocklessActions, callback) {
  let actions, blockIds;

  let actionsQuery = collection('actions').where('state', '==', ACTIVE);
  let blocksQuery = collection('blocks').where('state', '==', ACTIVE);

  // For all categories, the parent filter is excluded to get all actions
  if (categoryId !== ALL_CATEGORY_ID) {
    actionsQuery = actionsQuery.where('parent_category_id', '==', categoryId);
    blocksQuery = blocksQuery.where('parent_category_id', '==', categoryId);
  }

  function handleChange() {
    if (actions === undefined || blockIds === undefined) return;

    const filteredActions = actions.filter(action => {
      if (!action.blockId) {
        if (!includeBlocklessActions) return false;
        // The actions in Uncategorized that don’t belong to any block
        // are the capture list, and must be excluded here
        return action.categoryId !== UNCATEGORIZED_ID;
      }

      return true;
    });

    callback(filteredActions);
  }

  return composeUnsubscribers(
    blocksQuery.onSnapshot(snapshot => {
      blockIds = snapshot.docs.map(blockSnap => {
        return Block.fromFirestore(blockSnap).id;
      });
      handleChange();
    }),
    actionsQuery.onSnapshot(snapshot => {
      actions = snapshot.docs.map(Action.fromFirestore);
      handleChange();
    }),
  );
}

export function watchCategorySnoozedActions(categoryId, includeBlocklessActions, callback) {
  let activeActions, snoozedActions, blockIds;

  // There are currently no instances where ALL_CATEGORY_ID is supported in this function
  // so rather than complicate it unnecessary, it is unsupported. If in the future, this
  // requirement is added, this watcher may need to be duplicated to be a similarly
  // specialised function.
  if (categoryId === ALL_CATEGORY_ID)
    throw new ParameterError({categoryId}, `cannot be "${ALL_CATEGORY_ID}"`);

  // Snoozed actions associated with a category qualify with one of two criteria:
  // 1. The action is ACTIVE and is a child of a block that is SNOOZED
  //    N.B. an action cannot be SNOOZED in a block
  // 2. The action is SNOOZED and is not a child of a block
  // To correctly ascertain the "snoozed" actions of a category we must watch
  // all ACTIVE and SNOOZED actions and then filter them based on the above.

  const activeActionsQuery = collection('actions')
    .where('state', '==', ACTIVE)
    .where('parent_category_id', '==', categoryId);

  const snoozedActionsQuery = collection('actions')
    .where('state', '==', SNOOZED)
    .where('parent_category_id', '==', categoryId);

  const blocksQuery = collection('blocks')
    .where('state', '==', SNOOZED)
    .where('parent_category_id', '==', categoryId);

  function handleChange() {
    if (
      activeActions === undefined ||
      snoozedActions === undefined ||
      blockIds === undefined
    ) return;

    const filteredActiveActions = activeActions.filter(action => {
      // If the action has no parent, but is has the ACTIVE state it should be excluded
      if (!action.blockId) return false;

      // Actions with a parent block are filtered by blocks that are SNOOZED
      return blockIds.includes(action.blockId);
    });

    const filteredActions = [
      ...filteredActiveActions,
      ...snoozedActions,
    ];

    callback(filteredActions);
  }

  const unsubscribers = [
    blocksQuery.onSnapshot(snapshot => {
      blockIds = snapshot.docs.map(blockSnap => {
        return Block.fromFirestore(blockSnap).id;
      });
      handleChange();
    }),
    activeActionsQuery.onSnapshot(snapshot => {
      activeActions = snapshot.docs.map(Action.fromFirestore);
      handleChange();
    }),
  ];

  // If includeBlocklessActions is false we can avoid adding a watcher on
  // snoozed actions altogether – this is good for performance and cost.
  // snoozedActions still needs to be set to an empty array to allow for
  // handleChange to complete.
  if (includeBlocklessActions) {
    unsubscribers.push(
      snoozedActionsQuery.onSnapshot(snapshot => {
        snoozedActions = snapshot.docs.map(Action.fromFirestore);
        handleChange();
      })
    );
  } else {
    snoozedActions = [];
    // Although handleChange() will always be called before the subscribers
    // above and is thus likely redundant, it is kept in here to make clear
    // the query that it replaces in the event that it needs to be replaced
    // with one in the future.
    handleChange();
  }
  return composeUnsubscribers(unsubscribers);
}

/**
 * Create a category
 */

export function createCategory(name, icon, color, onSuccess = null) {
  if (typeof name !== 'string')
    throw new ParameterError({name}, 'not a string');
  const nameTrim = name.trim();
  if (nameTrim === '')
    throw new ParameterError({name}, 'empty after trim');
  if (nameTrim.length > CATEGORY_NAME_MAX_LENGTH)
    throw new ParameterError({name}, 'length must be <= ' + CATEGORY_NAME_MAX_LENGTH);
  if (!Object.keys(CATEGORY_ICONS).includes(icon))
    throw new ParameterError({icon}, 'invalid icon slug. Must be one of CATEGORY_ICONS');
  if (!Object.keys(CATEGORY_COLORS).includes(color))
    throw new ParameterError({color}, 'invalid color slug. Must be one of CATEGORY_COLORS');
  if (onSuccess !== null && typeof(onSuccess) !== 'function')
    throw new ParameterError({onSuccess}, 'must be "null" or a function');

  const id = generateId();

  const newCategory = {
    id,
    name: nameTrim,
    color,
    is_built_in: false,
    is_editable: true,
    state: CATEGORY_ACTIVE,
    background_color: {
      // Old builds of the iOS app require a hex value here otherwise the categories
      // don't show. It was decided that we would add in the default gray colour with
      // no updating or legacy colour mapping on web with the view to revisiting
      // its inclusion once the `color` key was implemented on iOS and there were no
      // remaining users on the older version of the app.
      hex: 8158361,
    },
    icon,
  };

  const batch = firestore().batch();

  batch.set(document(`categories/${id}`), newCategory);
  batch.update(document(), {
    'categories_list.ordered_ids': firestore.FieldValue.arrayUnion(id),
  });

  batch.commit();

  onSuccess && onSuccess(id);

  trackEvent('User created a Category', {
    id,
    nameLength: nameTrim.length,
  });
}

export function setCategory(category) {
  const {
    name,
    id,
    icon,
    color,
    vision,
    purpose,
    roles,
    thrive,
    resources,
    yearResults,
  } = category;

  if (id === UNCATEGORIZED_ID)
    throw new ParameterError({id}, 'Uncategorized is not editable');

  if (typeof name !== 'string')
    throw new ParameterError({name}, 'not a string');
  const nameTrim = name.trim();
  if (nameTrim === '')
    throw new ParameterError({name}, 'empty after trim');
  if (nameTrim.length > CATEGORY_NAME_MAX_LENGTH)
    throw new ParameterError({name}, 'length must be <= ' + CATEGORY_NAME_MAX_LENGTH);

  if (!Object.keys(CATEGORY_ICONS).includes(icon))
    throw new ParameterError({icon}, 'invalid icon slug, it must be one of CATEGORY_ICONS');

  if (!Object.keys(CATEGORY_COLORS).includes(color))
    throw new ParameterError({color}, 'invalid color slug. Must be one of CATEGORY_COLORS');

  if (typeof vision !== 'string')
    throw new ParameterError({vision}, 'must be a string (empty strings are permitted)');
  if (typeof purpose !== 'string')
    throw new ParameterError({purpose}, 'must be a string (empty strings are permitted)');
  if (typeof roles !== 'string')
    throw new ParameterError({roles}, 'must be a string (empty strings are permitted)');
  if (typeof thrive !== 'string')
    throw new ParameterError({thrive}, 'must be a string (empty strings are permitted)');
  if (typeof resources !== 'string')
    throw new ParameterError({resources}, 'must be a string (empty strings are permitted)');
  if (typeof yearResults !== 'string')
    throw new ParameterError({yearResults}, 'must be a string (empty strings are permitted)');

  document(`categories/${id}`).update({
    name: nameTrim,
    icon,
    color,
    vision: vision.trim(),
    purpose: purpose.trim(),
    roles: roles.trim(),
    resources: resources.trim(),
    thrive: thrive.trim(),
    year_results: yearResults,
  });
}

export async function setCategoryState(categoryId, newState) {
  if (newState !== CATEGORY_ACTIVE && newState !== CATEGORY_HIDDEN)
    throw new ParameterError({newState}, `must be "${CATEGORY_ACTIVE}", "${CATEGORY_HIDDEN}"`);

  const { state: oldState } = await getCategory(categoryId);

  if (newState === oldState) return;

  const batch = firestore().batch();

  batch.update(document(`categories/${categoryId}`), {
    state: newState,
  });

  if (newState === CATEGORY_ACTIVE) {
    batch.update(document(), {
      'categories_list.ordered_ids': firestore.FieldValue.arrayUnion(categoryId),
    });
  } else {
    batch.update(document(), {
      'categories_list.ordered_ids': firestore.FieldValue.arrayRemove(categoryId),
    })
  }

  batch.commit();
}

export async function getCategory(categoryId) {
  const docSnapshot = await document(`categories/${categoryId}`).get();
  return Category.fromFirestore(docSnapshot);
}
