import React, { useContext, useState, useEffect } from 'react';
import { generatePath, useRouteMatch, Link } from 'react-router-dom';
import clsx from 'clsx';

import { PRODUCT_NAME } from '../../utils.js';

import {
  PROFILE_URL,
  DASHBOARD_URL,
  PLANNER_URL,
  BLOCK_DETAIL_URL,
  CATEGORY_DETAIL_URL,
  PEOPLE_URL,
  ONBOARDING_GOALS_URL,
  ONBOARDING_CREATE_CATEGORY_URL,
  ONBOARDING_CATEGORY_DETAILS_URL,
} from '../App';

import { AuthContext } from '../AuthContext';
import {
  ActionDialogContext,
  useShowCreateActionDialog,
} from '../ActionDialog';
import Avatar from '../Avatar';
import Button, {
  BUTTON_SIZE_REGULAR,
  BUTTON_BORDER_STYLE_SQUARE,
} from '../Button';
import Divider from '../Divider';
import Tooltip from '../Tooltip';

import { UNCATEGORIZED_ID } from '../../services/DbService/constants';
import { watchCaptureListCount } from '../../services/DbService/categories';

import MarqueSrc from '../../assets/marque.svg';
import { ReactComponent as CaptureIcon } from '../../assets/icons/24-capture.svg';
import { ReactComponent as CaptureListIcon } from '../../assets/icons/24-capture-list.svg';
import { ReactComponent as CaptureListActiveIcon } from '../../assets/icons/24-capture-list-active.svg';
import { ReactComponent as CalendarIcon } from '../../assets/icons/24-calendar.svg';
import { ReactComponent as PlannerIcon } from '../../assets/icons/24-planner.svg';
import { ReactComponent as PeopleIcon } from '../../assets/icons/24-people.svg';

import styles from './Nav.module.scss';

const NAV_DASHBOARD = 'dashboard';
const NAV_PLANNER = 'planner';
const NAV_PEOPLE = 'people';
const NAV_PROFILE = 'profile';
const NAV_ONBOARDING = 'onboarding';

function NavLink({
  children,
  selected,
  ...props
}) {
  return (
    <Button
      {...props}
      borderStyle={BUTTON_BORDER_STYLE_SQUARE}
      size={BUTTON_SIZE_REGULAR}
      iconOnly
      className={styles.link}
    >
      {children}
    </Button>
  );
}

function Nav() {
  const { user } = useContext(AuthContext);
  const {
    toggleCaptureListExpanded,
    captureListExpanded,
    newActionParent,
  } = useContext(ActionDialogContext);

  const showCreateActionDialog = useShowCreateActionDialog();

  const [captureListCount, setCaptureListCount] = useState(null);

  useEffect(() => {
    if (!user) return;

    return watchCaptureListCount(setCaptureListCount);
  }, [user]);

  const routeIsDashboard = useRouteMatch({
    path: [
      DASHBOARD_URL,
      BLOCK_DETAIL_URL,
      CATEGORY_DETAIL_URL,
    ],
    exact: true,
  });
  const routeIsPeople = useRouteMatch(PEOPLE_URL);
  const routeIsPlanner = useRouteMatch(PLANNER_URL);
  const routeIsProfile = useRouteMatch(PROFILE_URL);
  const routeIsOnboarding = useRouteMatch({
    path:[
      ONBOARDING_GOALS_URL,
      ONBOARDING_CREATE_CATEGORY_URL,
      ONBOARDING_CATEGORY_DETAILS_URL,
    ],
    exact: true,
  });

  if (!user) return null;

  const NAV_LINKS_MAP = {
    [NAV_DASHBOARD]: {
      label: 'Calendar',
      icon: CalendarIcon,
      to: DASHBOARD_URL,
    },
    [NAV_PLANNER]: {
      label: 'Planner',
      icon: PlannerIcon,
      to: PLANNER_URL,
    },
    [NAV_PEOPLE]: {
      label: 'People',
      icon: PeopleIcon,
      to: generatePath(PEOPLE_URL),
    },
    [NAV_PROFILE]: {
      label: 'Profile',
      to: PROFILE_URL,
    },
  };

  const NAV_LINKS = [
    NAV_DASHBOARD,
    NAV_PLANNER,
    NAV_PEOPLE,
  ];

  const composeActiveId = () => {
    if (routeIsDashboard) return NAV_DASHBOARD;
    if (routeIsPeople) return NAV_PEOPLE;
    if (routeIsPlanner) return NAV_PLANNER;
    if (routeIsProfile) return NAV_PROFILE;
    if (routeIsOnboarding) return NAV_ONBOARDING;

    return null;
  };

  const selectedId = composeActiveId();

  if (!selectedId) return null;

  const heading = NAV_LINKS_MAP?.[selectedId]?.label;

  return (
    <nav className={styles.nav}>
      <div className={styles.title}>
        {/* TODO: Replace with logo – to be done in a separate ticket that handles all new nav/logo changes */}
        <Tooltip title={NAV_LINKS_MAP[NAV_DASHBOARD].label || 'RPM'}>
          <Link
            className={styles.marque}
            to={NAV_LINKS_MAP[NAV_DASHBOARD].to}
          >
            <span className="sr-only">{PRODUCT_NAME}</span>
            <img src={MarqueSrc} alt="" />
          </Link>
        </Tooltip>
        {heading && <span className={styles.heading}>{heading}</span>}
      </div>

      {routeIsOnboarding &&
        <Button linkTo={DASHBOARD_URL}>Skip for now</Button>
      }

      {!routeIsOnboarding &&
        <>
          <ul className={styles.list}>
            <li className={clsx(styles.listItem, newActionParent && styles.selected)}>
              <Tooltip title="Capture New Action" shortcut="K" shortcutModifier>
                <NavLink
                  onClick={() => showCreateActionDialog(UNCATEGORIZED_ID)}
                  aria-label="Capture New Action"
                >
                  <CaptureIcon role="presentation" />
                </NavLink>
              </Tooltip>
            </li>

            <li className={clsx(styles.listItem, captureListExpanded && styles.selected)}>
              <NavLink
                aria-label={`${captureListExpanded ? 'Hide' : 'Show'} Capture List`}
                onClick={toggleCaptureListExpanded}
                tooltip
              >
                {captureListExpanded ?
                  <CaptureListActiveIcon role="presentation" /> :
                  <CaptureListIcon role="presentation" />
                }

                {!!captureListCount &&
                  <span className={styles.count} aria-label={`${captureListCount} Capture List Actions`}>
                    {captureListCount > 99 ? '99+' : captureListCount}
                  </span>
                }
              </NavLink>
            </li>
          </ul>

          <Divider className={styles.divider} vertical />

          <ul className={styles.list}>
            {NAV_LINKS.map(id => {
              const { label, to, icon: Icon } = NAV_LINKS_MAP[id];
              const selected = id === selectedId;

              return (
                <li key={to} className={clsx(styles.listItem, selected && styles.selected)}>
                  <NavLink aria-label={label} linkTo={to} tooltip>
                    <Icon role="presentation" />
                  </NavLink>
                </li>
              )
            })}

            <li className={clsx(styles.listItem, selectedId === NAV_PROFILE && styles.selected)}>
              <NavLink
                linkTo={PROFILE_URL}
                aria-label={NAV_LINKS_MAP[NAV_PROFILE].label}
                selected={selectedId === NAV_PROFILE}
                tooltip
              >
                <Avatar src={user?.photoURL} className={styles.avatar} />
              </NavLink>
            </li>
          </ul>
        </>
      }
    </nav>
  );
}

export default Nav;
