import React from 'react';

import Accordion from '../Accordion';
import Button, { BUTTON_COLOR_FILL } from '../Button';
import PageNoUi from '../PageNoUi';

import styles from './AppError.module.scss';

function AppError({ error, resetError }) {
  console.error(error);

  return (
    <PageNoUi
      role="alert"
      heading={'Oops, Something Went\xa0Wrong'}
      text={'Please try again and if the issue persists contact your\xa0administrator.'}
    >
      <Button
        color={BUTTON_COLOR_FILL}
        onClick={resetError}
        block
      >
        Try again
      </Button>

      {error.message &&
        <Accordion className={styles.error} heading="Error message">
          <pre>{error.toString()}</pre>
        </Accordion>
      }
    </PageNoUi>
  )
}

export default AppError;
