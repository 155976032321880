import React, { forwardRef } from 'react';
import clsx from 'clsx';

import Button from '../Button';

import { ReactComponent as IconAdd } from '../../assets/icons/16-add.svg';
import { ReactComponent as IconError } from '../../assets/icons/16-error.svg';
import { ReactComponent as IconCheck } from '../../assets/icons/16-check.svg';

import styles from './CreateButton.module.scss';

const ICON_MAP = {
  add: <IconAdd/>,
  complete: <IconCheck/>,
};

const CreateButton = forwardRef(({
  color,
  icon = 'add',
  error,
  disabled,
  className,
  ...props
}, ref) => {
  return (
    <Button
      ref={ref}
      className={clsx(className, styles.button, error && styles.hasError)}
      color={color}
      iconOnly
      disabled={disabled || error}
      {...props}
    >
      {error ? <IconError /> : ICON_MAP[icon]}
    </Button>
  );
});

CreateButton.displayName = 'CreateButton';

export default CreateButton;
