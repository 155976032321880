import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import clsx from 'clsx';

import { PAGE_SIZE } from '../../../services/DbService/constants';
import { watchPerson, watchPersonActions } from '../../../services/DbService/people';

import { useGetCategoryColors } from '../../CategoriesContext';
import Accordion from '../../Accordion';
import Heading from '../../Heading';
import LoadingSpinner from '../../LoadingSpinner';
import ActionItem from '../../ActionItem';
import EmptyStateActions from '../../EmptyState/EmptyStateActions';
import LoadMoreButton from '../../LoadMoreButton';
import Head from '../../Head';
import PersonDetailHeader from './PersonDetailHeader';

import styles from './PersonDetail.module.scss';

function PersonDetail({
  className,
  ...props
}) {
  const { personId } = useParams();
  const [person, setPerson] = useState(null);
  const [editing, setEditing] = useState(null);
  const [showCompleted, setShowCompleted] = useState(false);

  useEffect(() => watchPerson(personId, setPerson), [personId]);

  // These hooks are created to communicate with the PersonActionsLists
  // and figure out if none of them has found an action, so we can show
  // an empty state to the user. Unfortunately I have not found an
  // easier way to achieve this.
  const [listsCounters, setListsCounters] = useState({});
  const handleFetch = useCallback((key, count) => {
    // this handler receives a "key" identifing the list that called it, and the
    // number of actions that the list fetched
    setListsCounters(oldValue => ({...oldValue, [key]: count}));
  }, []);

  const hasActions = useMemo(() => {
    const counters = Object.values(listsCounters);
    // If we don't have the counters of all 4 lists the state is uncertain
    if (counters.length !== 4) return null;
    // Return true if at least one list has more than 0 elements
    return counters.some(count => count > 0);
  }, [listsCounters]);

  if (!person) {
    return (
      <article {...props}>
        <LoadingSpinner absolute />
      </article>
    );
  }

  const totalCompletedActions = listsCounters['true-leveraged'] + listsCounters['true-mentioned'];

  return (
    <main className={clsx(className, styles.main)} {...props}>
      <Head title={`@${person.nickname}`} />
      <PersonDetailHeader person={person} editing={editing} setEditing={setEditing} />

      {!editing &&
        <div className={styles.group}>
          {hasActions === false &&
            <EmptyStateActions className={styles.emptyState}>
              Any actions you mention or leverage to @{person?.nickname} will appear&nbsp;here.
            </EmptyStateActions>
          }

          <PersonActionsList
            personId={personId}
            completed={false}
            type={'leveraged'}
            onFetch={handleFetch}
          />
          <PersonActionsList
            personId={personId}
            completed={false}
            type={'mentioned'}
            onFetch={handleFetch}
          />

          {/* This must be in the DOM to allow handleFetch to be called properly, but is
              hidden from the user with a 0px height, no overflow div to get the same effect. */}
          <div className={clsx((totalCompletedActions === 0 || !hasActions) && styles.completedHidden)}>
            <Accordion
              open={showCompleted}
              onToggle={setShowCompleted}
              heading={`Completed (${totalCompletedActions})`}
            >
              <PersonActionsList
                personId={personId}
                completed={true}
                type='leveraged'
                onFetch={handleFetch}
              />
              <PersonActionsList
                personId={personId}
                completed={true}
                type='mentioned'
                onFetch={handleFetch}
              />
            </Accordion>
          </div>
        </div>
      }
    </main>
  );
}

export default PersonDetail;

function PersonActionsList({
  personId,
  completed,
  type,
  onFetch,
}) {
  const [actions, setActions] = useState([]);
  const [limit, setLimit] = useState(PAGE_SIZE);
  const [hasMore, setHasMore] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);

  // This is used only for the onFetch handler, see parent object for info
  const listKey = `${completed}-${type}`;

  useEffect(() => {
    if (completed) {
      return watchPersonActions(personId, type, true, limit, (actions, hasMore) => {
        setLoadingMore(false);
        setActions(actions);
        setHasMore(hasMore);
        onFetch(listKey, actions.length);
      });
    } else {
      return watchPersonActions(personId, type, false, null, actions => {
        setActions(actions);
        onFetch(listKey, actions.length);
      });
    }
  }, [completed, personId, type, limit, listKey, onFetch]);

  const handleLoadMoreClick = useCallback(() => {
    setLoadingMore(true);
    setLimit(limit => limit + PAGE_SIZE);
  }, []);

  const getCategoryColors = useGetCategoryColors();

  if (actions.length === 0) return null;

  return (<>
    {!completed &&
      <Heading tag="h3" level="h5" className={styles.subheading}>
        {type === 'leveraged' ? 'Leveraged' : 'Mentioned'}
      </Heading>
    }

    <ul>
      {actions.map((action, index) => (
        <ActionItem
          key={`${type}-${action.id}`}
          action={action}
          style={getCategoryColors(action.categoryId)}
        />
      ))}
    </ul>

    {hasMore &&
      <LoadMoreButton loading={loadingMore} onClick={handleLoadMoreClick} />
    }
  </>);
}
