import React from 'react';
import { Helmet } from 'react-helmet-async';

import { PRODUCT_NAME } from '../utils.js';

function Head({
  title = '',
  titleSuffix = PRODUCT_NAME,
  ...props
}) {
  // Must be a string to avoid <Helmet> throwing an error
  const dataSet = process.env.REACT_APP_DATASET !== 'PROD' ? ` [${process.env.REACT_APP_DATASET}]` : '';

  const titleChunks = [];

  if (title) {
    if (title.length > 45) {
      title = `${title.substr(0, 45)}…`;
    }
    titleChunks.push(title);
  }

  titleSuffix && titleChunks.push(titleSuffix);

  return (
    <Helmet>
      <title>{titleChunks.join(' | ')}{dataSet}</title>
    </Helmet>
  )
}

export default Head;
