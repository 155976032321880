import React, { useEffect, useState, createContext, useMemo } from "react";

import { ALL_CATEGORY_ID, UNGROUPED_PEOPLE_ID } from '../../services/DbService/constants';
import { getAuthTokens } from '../../services/AuthService';

const LOCALSTORAGE_LAST_USER_UID = 'dashboardLastUserUid';
const LOCALSTORAGE_ACTIVE_CATEGORY = 'dashboardActiveCategory';
const LOCALSTORAGE_ACTIVE_PEOPLE_GROUP = 'dashboardActivePeopleGroup';

export const DashboardContext = createContext();

function getLocalValue(key, defaultValue = null) {
  const localValue = localStorage.getItem(key) || null;
  return JSON.parse(localValue) || defaultValue;
}

function checkLocalDashboardContextValues() {
  // The last user uid is stored here rather than AuthService as this isn't mounted
  // at the point that watchUser’s subscribers are notified during sign in/sign up.
  const { uid: currentUid } = getAuthTokens();
  const oldUid = localStorage.getItem(LOCALSTORAGE_LAST_USER_UID);

  if (currentUid === oldUid) return;

  localStorage.setItem(LOCALSTORAGE_LAST_USER_UID, currentUid);
  localStorage.removeItem(LOCALSTORAGE_ACTIVE_CATEGORY);
  localStorage.removeItem(LOCALSTORAGE_ACTIVE_PEOPLE_GROUP);
}

export function DashboardContextProvider({ children }) {
  const [activeCategoryId, setActiveCategoryId] = useState(() => {
    return getLocalValue(LOCALSTORAGE_ACTIVE_CATEGORY, ALL_CATEGORY_ID);
  });
  const [activePeopleGroupId, setActivePeopleGroupId] = useState(() => {
    return getLocalValue(LOCALSTORAGE_ACTIVE_PEOPLE_GROUP, UNGROUPED_PEOPLE_ID);
  });

  useEffect(() => {
    localStorage.setItem(LOCALSTORAGE_ACTIVE_CATEGORY, JSON.stringify(activeCategoryId));
  }, [activeCategoryId]);

  useEffect(() => {
    localStorage.setItem(LOCALSTORAGE_ACTIVE_PEOPLE_GROUP, JSON.stringify(activePeopleGroupId));
  }, [activePeopleGroupId]);

  useEffect(() => {
    checkLocalDashboardContextValues();
  }, []);

  const value = useMemo(() => {
    return {
      activeCategoryId, setActiveCategoryId,
      activePeopleGroupId, setActivePeopleGroupId,
    };
  }, [
    activeCategoryId,
    activePeopleGroupId,
  ]);

  return (
    <DashboardContext.Provider value={value}>
      {children}
    </DashboardContext.Provider>
  );
}
