import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import {
  DEFAULT_FIRST_CATEGORY_NAME,
  CATEGORY_NAME_MAX_LENGTH,
  CATEGORY_DEFAULT_ICON,
  CATEGORY_DEFAULT_COLOR,
 } from '../../services/DbService/constants';
import { getCategory, setCategory, createCategory } from '../../services/DbService/categories';

import { ONBOARDING_CATEGORY_DETAILS_URL } from '../App';
import Button, { BUTTON_COLOR_FILL } from '../Button';
import { useCategories } from '../CategoriesContext';
import LoadingSpinner from '../LoadingSpinner';
import Input, { INPUT_SIZE_LARGE } from '../Input';
import OnboardingPage from './OnboardingPage';

import styles from './Onboarding.module.scss';

function CreateCategory() {
  const history = useHistory();

  const newNameRef = useRef();
  const [newName, setNewName] = useState(null);

  const [categoryId, setCategoryId] = useState(null);
  const [loading, setLoading] = useState(false);

  const { activeCategories } = useCategories();

  useEffect(() => {
    if (activeCategories === null) return;

    // Find the first category that isn't the uncategorised category. For new users this is
    // the "My First Category" category. This is a weak comparison, but is sufficient for now
    // as this is an optional onboarding process that supports safe revisiting in the future
    const categoryToEdit = activeCategories
      .find(category => category.name === DEFAULT_FIRST_CATEGORY_NAME);

    // If that category exists check whether it still has the default name, if it does we allow
    // the user to pick a brand new name. If they've returned to this page after having created
    // or manipulated categories we honour the name they already gave in the input.
    if (categoryToEdit) {
      setCategoryId(categoryToEdit.id);
    }

    setNewName('');
  }, [activeCategories]);

  const handleSubmit = useCallback(async (e) => {
    e.preventDefault();

    setLoading(true);

    // The latency with FireStore is so low, that without the timeout it feels like something
    // went wrong. This just delays the user long enough for it to feel deliberate.
    await new Promise(resolve => setTimeout(resolve, 500));

    // If there was no category that matched the search above we create a new category with
    // the default colour and icon
    if (categoryId) {
      const oldCategory = await getCategory(categoryId);
      oldCategory.name = newName;
      setCategory(oldCategory);
      history.push(ONBOARDING_CATEGORY_DETAILS_URL, { categoryId });
    } else {
      const onSuccess = (newId) => {
        history.push(ONBOARDING_CATEGORY_DETAILS_URL, {
          categoryId: newId,
        });
      };

      createCategory(newName, CATEGORY_DEFAULT_ICON, CATEGORY_DEFAULT_COLOR, onSuccess);
    }
  }, [history, newName, categoryId]);

  return (
    <OnboardingPage
      title="Category of Improvement"
      intro="Categories are specific areas of your life that you're committed to constantly focus on and improve. Before choosing your first area ask yourself “What area, if I improved it, would make the biggest difference in the overall quality of my life? What area will give my life more balance?”"
    >
      <form onSubmit={handleSubmit}>
        {newName === null && <LoadingSpinner absolute />}
        {newName !== null &&
          <Input
            ref={newNameRef}
            label="Category Name"
            type="text"
            size={INPUT_SIZE_LARGE}
            autoFocus
            autoComplete="off"
            autoCorrect="off"
            autoCapitalize="off"
            spellCheck="false"
            value={newName}
            onChange={e => setNewName(e.target.value)}
            maxLength={CATEGORY_NAME_MAX_LENGTH}
          />
        }

        <div className={styles.buttons}>
          <Button loading={loading} color={BUTTON_COLOR_FILL} block disabled={!newName}>Next</Button>
        </div>
      </form>
    </OnboardingPage>
  );
}

export default CreateCategory;
