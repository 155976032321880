import React, { useEffect, useState, useCallback } from 'react';

import { ACTIVE, STARRED, UNSTARRED } from '../../../services/DbService/constants';
import { watchActions } from '../../../services/DbService/actions';
import { createEvent } from '../../../services/DbService/events';

import Button, { BUTTON_COLOR_CATEGORY } from '../../Button';
import DialogFooter from '../../Dialog/DialogFooter';
import NewEventDialogList from './NewEventDialogList';

function NewEventDialogBlock({
  hidden,
  dialogHidden,
  block,
  startDate,
  duration,
  onBack,
  onClose,
  ...props
}) {
  const [items, setItems] = useState(null);
  const [selected, setSelected] = useState([]);

  useEffect(() => {
    if (!block) {
       // If block changes to null clean the items list and the selected actions
      setItems(null);
      setSelected([]);
      return;
    }
    return watchActions(block.categoryId, block.id, ACTIVE, null, actions =>
      setItems([
        ...actions[STARRED].map(action => ({action})),
        ...actions[UNSTARRED].map(action => ({action})),
      ]));;
  }, [block]);

  const handleChange = useCallback(selected => setSelected(selected), []);

  const handleScheduleClick = useCallback(() => {
    createEvent(
      startDate,
      duration,
      block.categoryId,
      block.id,
      selected.map(action => action.id)
    );
    onClose();
  }, [startDate, duration, block, selected, onClose]);

  let buttonLabel = 'Schedule Block';
  if (selected.length > 10) {
    buttonLabel = 'Max 10 actions';
  } else if (selected.length) {
    buttonLabel = `Schedule ${selected.length} action${selected.length !== 1 ? 's' : ''}`;
  }

  return (
    <div hidden={hidden} {...props}>
      <NewEventDialogList
        items={items}
        emptyMessage={'You don’t have any actions assigned to this\xa0block.'}
        selectedActions={selected}
        onChange={handleChange}
        parentHidden={hidden}
        dialogHidden={dialogHidden}
      />

      {/* the footer should be removed from the DOM when this section is hidden to avoid
          any focusable items being wrongly identified as such. The benefit of continually
          holding this dialog in the DOM is related to the list above, and isn't affected
          by this being removed/present. */}
      {!hidden &&
        <DialogFooter>
          <Button
            color={BUTTON_COLOR_CATEGORY}
            onClick={handleScheduleClick}
            disabled={selected.length && selected.length > 10}
            block
          >
            {buttonLabel}
          </Button>
        </DialogFooter>
      }
    </div>
  );
}

export default NewEventDialogBlock;
