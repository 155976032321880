import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';

import { ACCESS_URL } from './App';
import { AuthContext } from './AuthContext';

export default function AuthedRoute({
  children,
  ...props
}) {
  const { user } = useContext(AuthContext);

  return (
    <Route {...props}>
      {!user ? <Redirect to={ACCESS_URL}/> : children}
    </Route>
  );
}
