import React, { useState, useEffect, useCallback, useRef } from 'react';
import clsx from 'clsx';
import { createAuthButton } from '@kloudless/authenticator/dist/react';

import { getRpmCalendarUrl } from '../../services/AuthService';
import { getAccount } from '../../services/KloudlessService';
import {
  addExternalCalendarService, deleteExternalCalendarService,
  setExternalCalendarEnabled, watchExternalCalendars,
} from '../../services/ExternalEventsService';

import useUniqueId from '../../hooks/useUniqueId';

import LoadingSpinner from '../LoadingSpinner';
import Heading, { HEADING_LEVEL_5 } from '../Heading';
import Dialog from '../Dialog';
import Button, { BUTTON_SIZE_XSMALL } from '../Button';
import ContextualMenu, { ContextualMenuLink, ContextualMenuTrigger } from '../ContextualMenu';
import Toast from '../Toast';

import { ReactComponent as IconAdd } from '../../assets/icons/16-add.svg';
import { ReactComponent as IconClose } from '../../assets/icons/16-close.svg';
import { ReactComponent as IconMore } from '../../assets/icons/16-more.svg';
import { ReactComponent as IconError } from '../../assets/icons/16-error.svg';
import { ReactComponent as IconVisibilityOn } from '../../assets/icons/16-visibility-on.svg';
import { ReactComponent as IconVisibilityOff } from '../../assets/icons/16-visibility-off.svg';

import styles from './ProfileSidebar.module.scss';

const AddServiceButton = createAuthButton((props) => {
  return (
    <Button {...props}>
      <IconAdd role='presentation'/>
    </Button>
  );
});

const ReconnectButton = createAuthButton((props) => {
  return (
    <Button {...props}>
      Reconnect
    </Button>
  );
});

function ProfileSidebarCalendars() {
  const [externalCalendars, setExternalCalendars] = useState();
  useEffect(() => watchExternalCalendars(setExternalCalendars), []);

  const [dialogVisible, setDialogVisible] = useState(false);

  const calendarOptionsMenuButtonRef = useRef();
  const calendarOptionsMenuButtonId = useUniqueId();
  const [calendarOptionsMenuVisible, setCalendarOptionsMenuVisible] = useState(false);

  const [showLinkCopiedToast, setShowLinkCopiedToast] = useState(false);

  const handleCopyLinkClick = useCallback(async () => {
    let url = null;
    try {
      url = await getRpmCalendarUrl();
    } catch (e) {
      console.error(e);
      // There can be exceptions thrown only if the user has logged in with an
      // old version of the application and now has problem reaching the
      // backend servers (see getRpmCalendarUrl() for more info). Since it's a
      // very rare error destined to completely disappear in future I opted for
      // a very lightweight error reporting, without adding extra complexity to
      // this component.
      alert('Error retrieving the URL of the RPM calendar.\nPlease ensure to be connected to the internet and try again later.');
      return;
    }
    navigator.clipboard.writeText(url);
    setShowLinkCopiedToast(true);
    setCalendarOptionsMenuVisible(false);
    setTimeout(() => setShowLinkCopiedToast(false), 3000);
  }, []);

  return (<>
    <div className={styles.calendarsHeading}>
      <Heading tag="span" level={HEADING_LEVEL_5}>Calendars</Heading>
      <AddServiceButton
        aria-label="Connect external calendar"
        options={{
          client_id: process.env.REACT_APP_KLOUDLESS_CLIENT_ID,
          scope: 'any.calendar',
        }}
        tooltip
        onSuccess={result => addExternalCalendarService(result.access_token)}
        size={BUTTON_SIZE_XSMALL}
        iconOnly
      />
    </div>

    {!externalCalendars &&
      <LoadingSpinner className={styles.calendarsLoading} />
    }
    {externalCalendars &&
      <ul>
        <li>
          <div className={styles.externalService}>
            <span>My RPM Calendar</span>
            <ContextualMenuTrigger
              visible={calendarOptionsMenuVisible}
              setVisible={setCalendarOptionsMenuVisible}
              menuId={calendarOptionsMenuButtonId}
            >
              <Button
                ref={calendarOptionsMenuButtonRef}
                aria-label="Options"
                size={BUTTON_SIZE_XSMALL}
                tooltip
                iconOnly
              >
                <IconMore role='presentation' />
              </Button>
            </ContextualMenuTrigger>

            {calendarOptionsMenuVisible &&
              <ContextualMenu
                onClose={() => setCalendarOptionsMenuVisible(false)}
                buttonRef={calendarOptionsMenuButtonRef}
                aria-labelledby={calendarOptionsMenuButtonId}
              >
                <ContextualMenuLink onClick={handleCopyLinkClick}>Copy link</ContextualMenuLink>
              </ContextualMenu>
            }

            <Toast hidden={!showLinkCopiedToast}>
              Link copied into clipboard!
            </Toast>
          </div>
        </li>
        {externalCalendars.map((service, i) =>
          <li key={service.token}>
            <div className={styles.externalService}>
              <span>{service.name}</span>

              {!service.error && <>
                <Button
                  aria-label={`Disconnect ${service.name}`}
                  onClick={() => deleteExternalCalendarService(service.token)}
                  iconOnly
                  tooltip
                  size={BUTTON_SIZE_XSMALL}
                >
                  <IconClose role='presentation' />
                </Button>
              </>}

              {service.error && <>
                <Button
                  aria-label="Connection expired. Click to resolve."
                  onClick={() => setDialogVisible(true)}
                  negative
                  size={BUTTON_SIZE_XSMALL}
                  tooltip
                  iconOnly
                  style={{
                    '--button-icon-size': '16rem',
                  }}
                >
                  <IconError role='presentation' />
                </Button>
                {dialogVisible &&
                  <Dialog
                    headerTitle={service.error === 'expired' ?
                      'Connection expired'
                      :
                      'Error contacting the service'
                    }
                    onClose={() => setDialogVisible(false)}
                    footer={<>
                      <ReconnectButton
                        options={{
                          client_id: process.env.REACT_APP_KLOUDLESS_CLIENT_ID,
                          scope: 'any.calendar',
                        }}
                        onSuccess={async result => {
                          // When the account is successfully reconnected a new
                          // token is issued, but it can also happen that the
                          // old token starts working again (this in particular
                          // happens if you manually revoke Kloudless access
                          // from your Google account and then reconnect the
                          // same account using this button).
                          // When this happens, tyring to delete the old token
                          // from Kloudless will delete the new one too, leading
                          // to a broken state.
                          // For this reason we have to check if the old token
                          // has returned active, so we can avoid sending the
                          // DELETE call to Kloudless.
                          let oldTokenBroken;
                          try {
                            // Try getting account details with the old token
                            await getAccount(service.token);
                            oldTokenBroken = false;
                          } catch {
                            oldTokenBroken = true;
                          }
                          // Delete the old token from Firestore, and try also
                          // deleting it from Kloudless, but ONLY if it's still
                          // broken.
                          deleteExternalCalendarService(service.token, oldTokenBroken);
                          // Add the new healthy token just obtained
                          addExternalCalendarService(result.access_token);
                          setDialogVisible(false);
                        }}
                      />
                      <Button
                        negative
                        onClick={() => deleteExternalCalendarService(service.token)}
                      >
                        Remove
                      </Button>
                    </>}
                  >
                    {service.error === 'expired' && <>
                      <p>
                        The connection with this external service has expired and
                        must be renewed.
                      </p><p>
                        You can reconnect the service reinserting your credentials
                        or you can remove it from the list.
                      </p>
                    </>}

                    {service.error === 'unknown' && <>
                      <p>
                        RPM is having issues contacting this service to collect
                        external calendar events.
                      </p><p>
                        If the problem persists you can try reconnecting the
                        service reinserting your credentials, or you can remove
                        it from the list.
                      </p>
                    </>}
                  </Dialog>
                }
              </>}

            </div>
            <ul>
              {service.calendars.map((calendar, j) =>
                <li key={calendar.id}>
                  <label
                    className={clsx(
                      styles.externalCalendar,
                      calendar.enabled && styles.enabled,
                    )}
                  >
                    <span>{calendar.name}</span>
                    <div className={styles.externalCalendarInput}>
                      <input
                        type='checkbox'
                        onChange={() => setExternalCalendarEnabled(
                          service.token, calendar.id, !calendar.enabled)}
                        checked={calendar.enabled}
                      />
                      <span className={styles.focusRing} aria-hidden="true" />
                      {calendar.enabled ?
                        <IconVisibilityOn
                          className={styles.visibilityIcon}
                          role='presentation'
                        />
                        :
                        <IconVisibilityOff
                          className={styles.visibilityIcon}
                          role='presentation'
                        />
                      }
                    </div>
                  </label>
                </li>
              )}
            </ul>
          </li>
        )}
      </ul>
    }
  </>);
}

export default ProfileSidebarCalendars;
