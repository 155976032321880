import React, { useRef, useState, useLayoutEffect, useEffect, useCallback, useContext } from 'react';

import { CATEGORY_DEFAULT_COLOR, UNCATEGORIZED_ID } from '../../../services/DbService/constants';

import { useCategoryColors } from '../../CategoriesContext';
import { ActionDialogContext, useShowCreateActionDialog } from '../../ActionDialog';
import Dialog from '../../Dialog';
import EventCard from '../EventCard';
import { getEventDialogCss } from '../EventDialog';
import NewEventDialogRoot from './NewEventDialogRoot';
import NewEventDialogBlock from './NewEventDialogBlock';

import Button, { BUTTON_SIZE_SMALL } from '../../Button';

import { ReactComponent as IconAdd } from '../../../assets/icons/16-add.svg';
import { ReactComponent as IconPrevious } from '../../../assets/icons/16-previous.svg';

import styles from './NewEvent.module.scss';

function NewEvent({
  event,
  boxes,
  onClose,
}) {
  const {
    id: eventId,
    startDate,
    duration,
  } = event || {};

  const hidden = !event;

  const eventRef = useRef();
  const dialogRef = useRef();

  const [dialogCss, setDialogCss] = useState();
  const [selectedBlock, setSelectedBlock] = useState(null);

  const categoryColors = useCategoryColors(selectedBlock?.categoryId);

  const { setNewActionEvent } = useContext(ActionDialogContext);
  const showCreateActionDialog = useShowCreateActionDialog();

  useEffect(() => {
    if (hidden) {
      // Reset dialog state when it gets hidden
      setSelectedBlock(null);
    } else {
      // Because the dialog is always present the dialog focus transfer on opening
      // won't do anything, so it needs to be manually transfered when the dialog
      // is no longer hidden
      dialogRef.current && dialogRef.current.focus();
    }
  }, [hidden]);

  useLayoutEffect(() => {
    if (!eventRef.current) return;

    const css = getEventDialogCss(eventRef);

    setDialogCss(css);
  }, [startDate]);

  const handleNewEvent = useCallback(() => {
    setNewActionEvent({
      startDate,
      duration,
    });
    showCreateActionDialog(UNCATEGORIZED_ID);
  }, [startDate, duration, setNewActionEvent, showCreateActionDialog]);

  let headerButtons, headerTitle;
  const headerButtonProps = {
    iconOnly: true,
    tooltip: true,
    size: BUTTON_SIZE_SMALL,
  };
  if (selectedBlock === null) {
    headerTitle = 'Select actions to schedule';
    headerButtons = (
      <Button {...headerButtonProps} aria-label="New Event" onClick={handleNewEvent}>
        <IconAdd role="presentation" />
      </Button>
    );
  } else {
    headerTitle = selectedBlock?.result;
    headerButtons = (
      <Button {...headerButtonProps} aria-label="Back" onClick={() => setSelectedBlock(null)}>
        <IconPrevious role="presentation" />
      </Button>
    );
  }

  // Preload the components keeping them hidden
  return <>
    {!hidden &&
      <EventCard
        ref={eventRef}
        eventId={eventId}
        startDate={startDate}
        duration={duration}
        boxes={boxes}
        color={CATEGORY_DEFAULT_COLOR}
        resizable={true}
      />
    }
    {/* These dialogs are in portals, so they don't inherit
        the hidden state of the ancestor */}
    <Dialog
      ref={dialogRef}
      hidden={hidden}
      lightbox={false}
      headerTitle={headerTitle}
      headerButtons={headerButtons}
      onClose={onClose}
      customBody
      style={{
        ...dialogCss,
        ...categoryColors,
      }}
    >
      <NewEventDialogRoot
        className={styles.dialogSection}
        hidden={selectedBlock !== null}
        dialogHidden={hidden}
        startDate={startDate}
        duration={duration}
        onBlockSelected={bool => setSelectedBlock(bool)}
        onClose={onClose}
      />
      <NewEventDialogBlock
        className={styles.dialogSection}
        hidden={selectedBlock === null}
        dialogHidden={hidden}
        startDate={startDate}
        duration={duration}
        block={selectedBlock}
        onClose={onClose}
      />
    </Dialog>
  </>;
}

export default NewEvent;
