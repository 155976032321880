import React, { forwardRef } from 'react';
import clsx from 'clsx';

import styles from './Heading.module.scss';

export const HEADING_LEVEL_0 = 'h0';
export const HEADING_LEVEL_1 = 'h1';
export const HEADING_LEVEL_2 = 'h2';
export const HEADING_LEVEL_3 = 'h3';
export const HEADING_LEVEL_4 = 'h4';
export const HEADING_LEVEL_5 = 'h5';

function getTag(tag, level) {
  if (tag) return tag;

  return level === HEADING_LEVEL_0 ? 'h1' : level;
}

const Heading = forwardRef(({
  className,
  children,
  tag,
  level = HEADING_LEVEL_2,
  ...props
}, ref) => {
  const HeadingTag = getTag(tag, level);

  return (
    <HeadingTag
      ref={ref}
      className={clsx(className, styles.heading, styles[level])}
      {...props}
    >
      {children}
    </HeadingTag>
  );
});

Heading.displayName = 'Heading';

export default Heading;
