import React, { useEffect, useState, useCallback } from 'react';

import {
  ACTIVE, SNOOZED, COMPLETED, STATE_LABELS,
  STARRED, UNSTARRED,
} from '../../../services/DbService/constants';
import { watchActions } from '../../../services/DbService/actions';
import { deleteEvent } from '../../../services/DbService/events';

import useUniqueId from '../../../hooks/useUniqueId';
import usePreviousValue from '../../../hooks/usePreviousValue';

import { useGetCategoryColors } from '../../CategoriesContext';
import Button, { BUTTON_SIZE_SMALL } from '../../Button';
import TabBar from '../../TabBar';
import TabPanel from '../../TabPanel';
import ActionItem from '../../ActionItem';
import EventDialog from './../EventDialog';
import EmptyStateActions from '../../EmptyState/EmptyStateActions';
import LoadingSpinner from '../../LoadingSpinner';

import { ReactComponent as IconTrash } from '../../../assets/icons/16-trash.svg';

import styles from './ScheduledEventDialog.module.scss';

const SCHEDULED_EVENT_DIALOG_HEIGHT = 450;

function ScheduledEventDialog({
  eventId,
  category,
  block,
  actions,
  onClose,
  eventRef,
  startDate,
  style = {},
  ...props
}) {
  const getCategoryColors = useGetCategoryColors();

  const [actionsActive, setActionsActive] = useState(null);
  const [actionsCompleted, setActionsCompleted] = useState(null);
  const [activeTab, setActiveTab] = useState(false);

  // When there are actions in the `actions` prop, the full block actions aren't shown.
  useEffect(() => {
    if (!block || actions.length) return;
    return watchActions(block.categoryId, block.id, ACTIVE, null, setActionsActive);
  }, [block, actions]);

  useEffect(() => {
    if (!block || actions.length) return;
    return watchActions(block.categoryId, block.id, COMPLETED, null, setActionsCompleted);
  }, [block, actions]);

  useEffect(() => {
    if (!actions.length) return;

    const orderedActions = {
      [ACTIVE]: {
        [STARRED]: [],
        [UNSTARRED]: [],
      },
      [COMPLETED]: {
        [STARRED]: [],
        [UNSTARRED]: [],
      },
    };

    actions.forEach(action => {
      // Snoozed items are not shown in either list so are ignored from the filtering
      if (action.state === SNOOZED) return;

      const starredKey = action.starred ? STARRED : UNSTARRED;
      orderedActions[action.state][starredKey].push(action);
    });

    setActionsActive(orderedActions[ACTIVE]);
    setActionsCompleted(orderedActions[COMPLETED]);
  }, [actions]);

  // When both actionsActive and actionsCompleted have been set, the initial tab state
  // is determined. If neither have any actions it is left as null to display a singular
  // empty state with no tab bar.
  const prevActiveTab = usePreviousValue(activeTab);

  useEffect(() => {
    // This check should only be set the dialog is first opened
    if (prevActiveTab) return;

    if (actionsActive === null || actionsCompleted === null) return;

    if (actionsActive[STARRED].length || actionsActive[UNSTARRED].length) {
      setActiveTab(ACTIVE);
    } else if (actionsCompleted[STARRED].length || actionsCompleted[UNSTARRED].length) {
      setActiveTab(COMPLETED);
    } else {
      setActiveTab(null);
    }
  }, [prevActiveTab, actionsActive, actionsCompleted]);

  const handleDelete = useCallback(() => {
    deleteEvent(eventId);
    onClose();
  }, [eventId, onClose]);

  const HeaderDeleteButton = () => (
    <Button
      iconOnly
      aria-label="Delete event"
      onClick={handleDelete}
      size={BUTTON_SIZE_SMALL}
    >
      <IconTrash role="presentation" />
    </Button>
  );

  const TABS = [
    {
      state: ACTIVE,
      id: useUniqueId(),
      label: STATE_LABELS[ACTIVE].subheading,
    },
    {
      state: COMPLETED,
      id: useUniqueId(),
      label: STATE_LABELS[COMPLETED].subheading,
    },
  ];

  return (
    <EventDialog
      eventRef={eventRef}
      onClose={onClose}
      categoryId={category?.id}
      startDate={startDate}
      eventName={block?.result || category?.name || ''}
      dialogHeight={SCHEDULED_EVENT_DIALOG_HEIGHT}
      headerButtons={<>
        <HeaderDeleteButton />
      </>}
      preBodyChildren={activeTab &&
        <TabBar
          className={styles.dialogTabBar}
          active={activeTab && TABS.find(tab => tab.state === activeTab).id}
          tabs={TABS}
          onTabClick={tabId => setActiveTab(TABS.find(tab => tab.id === tabId).state)}
          categoryId={category?.id}
        />
      }
      style={{
        ...style,
        '--dialog-header-max-lines': 6,
      }}
      {...props}
    >
      {activeTab === false &&
        <LoadingSpinner absolute />
      }

      {activeTab === null &&
        <EmptyStateActions>There are no actions in this event.</EmptyStateActions>
      }

      {activeTab && TABS.map(tab => {
        const tabActions = tab.state === ACTIVE ? actionsActive : actionsCompleted;
        const tabActionsArray = Object.values(tabActions).flat();

        // Actions in a scheduled event are only ever in the same category
        // so the colour can be applied to the parent
        const style = getCategoryColors(tabActionsArray?.[0]?.categoryId);

        return (
          <TabPanel id="active" active={tab.state === activeTab} key={tab.id}>
            {!tabActionsArray.length &&
              <EmptyStateActions>There are no {tab.state} actions in this&nbsp;event.</EmptyStateActions>
            }

            <ul className={styles.actionsList} style={style}>
              {tabActionsArray.map((action, index) => (
                <ActionItem key={action.id} action={action} />
              ))}
            </ul>
          </TabPanel>
        )
      })}
    </EventDialog>
  );
}

export default ScheduledEventDialog;
