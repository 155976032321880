import kloudless from '@kloudless/kloudless';

export async function getAccountCalendars(token) {
  const account = new kloudless.Account({ token });
  const resourceList = await account.get({ url: 'cal/calendars/' });
  const calendars = [];
  await resourceList.iterate({
    callback: object => { calendars.push(object.data); }
  });
  return calendars;
}

export async function getAccount(token) {
  const account = new kloudless.Account({ token });
  return await account.getDetail();
}

export async function getAccountAndCalendars(token) {
  try {
    const [account, calendars] = await Promise.all(
      [getAccount(token), getAccountCalendars(token)]);
    return { token, account, calendars };
  } catch (e) {
    if ([401, 403].includes(e.statusCode)) {
      // This matches a set of errors related to tokens expired/invalidated.
      // For the full list of errors involved refer to:
      // https://developers.kloudless.com/docs/latest/core#error-handling-error-code-table
      return { token, error: 'expired' };
    } else {
      // TODO: the execution will land here even when the connection drops
      // and the API calls fail because of that. Instead of trying to fix it
      // here, it's better to work on a more centralised solution that
      // completely disables all calendars feature when in offline mode, and
      // notices it to the user in an elegant way.
      console.error(e);
      return { token, error: 'unknown' };
    }
  }
}

export async function deleteAccount(token) {
  const account = new kloudless.Account({ token });
  try {
    await account.delete();
  } catch (e) {
    if (e.message === 'Invalid Bearer Token.') {
      // This error means that the account has been already removed.
      // It gets triggered for example when the user deletes a service whose
      // token has already expired.
      // It's fine then to just suppress the error.
    } else {
      throw e;
    }
  }
}

export async function getAccountCalendarEvents(token, calendarId, start, end) {
  const account = new kloudless.Account({ token });
  const resourceList = await account.get({
    url: `cal/calendars/${calendarId}/events/`,
    params: {
      start: start.toISOString(),
      end: end.toISOString(),
      page_size: 1000,
    }
  });

  const events = [];
  await resourceList.iterate({
    callback: object => { events.push(object.data); }
  });
  return events;
}
