import React, { useState, useEffect, useRef } from 'react';
import { computeBox } from './WeekView';
import styles from './CalendarPanel.module.scss';

// Managing the visibility inside the component makes it possible to
// encapsulate the effect to scroll the view, otherwise it had to be
// addressed on the parent.
function NowIndicator({ visible }) {
  const [now, setNow] = useState(new Date());
  const nowRef = useRef();

  // Update the 'now' state
  useEffect(() => {
    let timeoutId;
    function tick() {
      const now = new Date();
      setNow(now);
      // Run again at the next minute
      timeoutId = setTimeout(tick, (60 - now.getSeconds()) * 1000);
    }
    tick();
    return () => clearTimeout(timeoutId);
  }, []);

  // Scroll the calendar so the "now bar" is visible and
  // positioned haflway (like Google and Apple do)
  useEffect(() => {
    if (!nowRef.current) return;
    nowRef.current.scrollIntoView({block: 'center'});
  }, []);

  if (!visible) return null;

  // Compute coordinates as if this indicator is an event with duration=0
  // and no other simultaneous events
  const { css } = computeBox(now, now, 1, 0);
  delete css.height; // Remove the height prop, we don't need it here

  return (
    <div
      ref={nowRef}
      className={styles.now}
      style={css}
    />
  );
}

export default NowIndicator;
