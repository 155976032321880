import React, { forwardRef } from 'react';
import { addSeconds, format, isPast } from 'date-fns';
import clsx from 'clsx';

import { CATEGORY_DEFAULT_COLOR } from '../../services/DbService/constants';
import { composeCategoryColors } from '../../services/DbService/categories';

import styles from './EventCard.module.scss';

function formatTime(date) {
  return (
    <>{format(date, 'h:mm')}<span className={styles.timeAmPm}>{format(date, 'a')}</span></>
  );
}

const EventCard = forwardRef(({
  eventId,
  description,
  startDate,
  duration,
  boxes,
  color = CATEGORY_DEFAULT_COLOR,
  allDay = false,
  draggable = false,
  dragging = false,
  resizable = false,
  onClick,
  external,
  ...props
}, ref) => {
  if (!startDate || !boxes) return null;

  const endDate = addSeconds(startDate, duration);

  const EventCardTag = onClick ? 'button' : 'div';

  return (
    <EventCardTag
      ref={ref}
      className={styles.event}
      // onClick handler has to stay on this wrapper for accessibility, so it
      // can be triggered by keyboard when the container is focused
      onClick={onClick}
      // These data attrs are used by WeekView
      data-draggable={draggable || undefined}
      data-event-id={eventId}
      style={composeCategoryColors(color)}
      {...props}
    >
      {/* A multi-day event is made of several boxes, one for each day. */}
      {boxes.map((box, i) =>
        <div
          key={i}
          title={`${description}, ${format(startDate, allDay ? 'PP' : 'PPp')}`}
          className={clsx(
            styles.box,
            styles[box.className],
            dragging && styles.dragging,
            isPast(endDate) && styles.past,
            !external && styles.internal,
          )}
          style={box.css}
          // Screen readers need only the first box, hide the others to them
          role={i > 0 ? 'presentation' : undefined}
        >
          <div className={styles.container}>
            <h3 className={styles.description} style={{ '--duration-increments': box.durationIncrements }}>
              <span className={styles.descriptionInner}>{description}</span>
            </h3>
            <div className={styles.time} hidden={allDay}>
              <time className={styles.timeStart} dateTime={startDate.toISOString()}>
                {formatTime(startDate)}
              </time>
              <time className={styles.timeEnd} dateTime={endDate.toISOString()}>
                {/* Hairspaces add a smaller space around the en-dash. It should be noted that
                    the dash is included within the second <time> to enable it to be kept as
                    a single line in the CSS, but the dateTime attribute means that semantically
                    it is still just the end date. */}
                &#8202;&ndash;&#8202;
                {formatTime(endDate)}
              </time>
            </div>
          </div>
          {/* Place the resize handle on the last box only */}
          {resizable && i === boxes.length - 1 &&
            <div
              className={styles.resizeHandle}
              data-resize-handle={true}
            />
          }
        </div>
      )}
    </EventCardTag>
  );
});

EventCard.displayName = 'EventCard';

export default EventCard;
