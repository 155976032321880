import React from 'react';
import clsx from 'clsx';

import { ReactComponent as IconCategoryFriends } from '../../assets/icons/16-category-friends.svg';
import { ReactComponent as IconCategoryGoal } from '../../assets/icons/16-category-goal.svg';
import { ReactComponent as IconCategoryLove } from '../../assets/icons/16-category-love.svg';
import { ReactComponent as IconCategoryLotus } from '../../assets/icons/16-category-lotus.svg';

import EmptyState from './EmptyState';

import styles from './EmptyState.module.scss';

function EmptyStateIcons({
  icons = [
    IconCategoryLotus,
    IconCategoryLove,
    IconCategoryGoal,
    IconCategoryFriends,
  ],
  ...props
}) {
  return (
    <EmptyState
      headerChildren={
        <div className={clsx(styles.icons, icons.length === 1 && styles.iconsSingle)} role='presentation'>
          {icons.map((Icon, i) =>
            <div key={i}><Icon/></div>
          )}
        </div>
      }
      {...props}
    />
  );
}

export default EmptyStateIcons;
