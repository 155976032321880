import React, { useRef, useState, useCallback, useEffect, useContext } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import clsx from 'clsx';

import { replaceMultipleLineBreaks } from '../../../utils';

import {
  CATEGORY_DEFAULT_ICON, CATEGORY_DEFAULT_COLOR,
  CATEGORY_ICONS,
  CATEGORY_NAME_MAX_LENGTH, CATEGORY_FIELD_MAX_LENGTH,
  CATEGORY_ACTIVE, CATEGORY_HIDDEN,
  CATEGORY_FIELDS_MAP,
} from '../../../services/DbService/constants';
import {
  watchCategory,
  setCategory,
  setCategoryState,
  composeCategoryColors
} from '../../../services/DbService/categories';

import useUniqueId from '../../../hooks/useUniqueId';

import { useCategoryColors } from '../../CategoriesContext';
import { DASHBOARD_URL } from '../../App';
import Heading, { HEADING_LEVEL_3 } from '../../Heading';
import CharacterCounter from '../../CharacterCounter';
import Button from '../../Button';
import Head from '../../Head';
import SmartLink, { SMART_LINK_STYLE_PLACEHOLDER } from '../../SmartLink';
import LoadingSpinner from '../../LoadingSpinner';
import ItemIcon from '../../ItemIcon';
import CategoryRadioGroupIcon from '../../CategoryRadioGroup/CategoryRadioGroupIcon';
import CategoryRadioGroupColor from '../../CategoryRadioGroup/CategoryRadioGroupColor';
import Toast from '../../Toast';
import Divider from '../../Divider';
import ContextualMenu, { ContextualMenuLink, ContextualMenuTrigger } from '../../ContextualMenu';
import Dialog from '../../Dialog';
import Input, { InputLabel, INPUT_SIZE_LARGE } from '../../Input';

import { DashboardContext } from '../../Dashboard';

import { ReactComponent as IconClose } from '../../../assets/icons/16-close.svg';
import { ReactComponent as IconMore } from '../../../assets/icons/16-more.svg';

import styles from './CategoryDetail.module.scss';

const CONCAT_DESCRIPTION_LENGTH = 320;

function CategoryDetail({
  className,
  style = {},
  ...props
}) {
  const history = useHistory();
  const { categoryId } = useParams();

  const errorToastId = useUniqueId();
  const iconLabelId = useUniqueId();
  const colorLabelId = useUniqueId();

  const categoryColor = useCategoryColors(categoryId);

  const editButtonRef = useRef();

  const { activeCategoryId } = useContext(DashboardContext);

  const [editing, setEditing] = useState(null);
  const [categoryToEdit, setCategoryToEdit] = useState(null);
  const [newName, setNewName] = useState(null);
  const [newColor, setNewColor] = useState(null);
  const [newIcon, setNewIcon] = useState(null);
  const [newFields, setNewFields] = useState(null);
  const [expandedFields, setExpandedFields] = useState({});
  const [showUnsavedChangesDialog, setShowUnsavedChangesDialog] = useState(false);
  const [initialFields, setInitialFields] = useState(null);

  const optionsMenuButtonRef = useRef();
  const optionsMenuButtonId = useUniqueId();
  const [optionsMenuVisible, setOptionsMenuVisible] = useState(null);

  let nameErrorMessage = '';
  if (!newName || !newName.trim()) {
    nameErrorMessage = 'Name is required';
  } else if (newName.length > CATEGORY_NAME_MAX_LENGTH) {
    nameErrorMessage = 'Category name is too long';
  }

  useEffect(() => {
    if (!categoryId || !activeCategoryId || activeCategoryId === categoryId) return;

    // Wrapped in a set timeout to allow the sidebar to reload separately
    setTimeout(() => {
      history.push(DASHBOARD_URL);
    });
  }, [history, categoryId, activeCategoryId]);

  useEffect(() => watchCategory(categoryId, newCategory => {
    const { name, vision, purpose, roles, thrive, resources, yearResults } = newCategory;

    const newIcon = CATEGORY_ICONS[newCategory?.icon] ? newCategory?.icon : CATEGORY_DEFAULT_ICON;
    const newColor = newCategory?.color || CATEGORY_DEFAULT_COLOR;

    const newFields = {
      vision,
      purpose,
      roles,
      thrive,
      resources,
      yearResults,
    };

    setNewName(name);
    setNewColor(newColor);
    setNewIcon(newIcon);
    setNewFields(newFields);

    setInitialFields(JSON.stringify({
      newName: name,
      newColor,
      newIcon,
      newFields,
    }));

    setCategoryToEdit(newCategory);
  }), [categoryId]);

  const handleFieldChange = useCallback((slug, value) => {
    setNewFields(newFields => ({
      ...newFields,
      [slug]: replaceMultipleLineBreaks(value),
    }));
  }, []);

  const handleFieldExpandToggle = useCallback(slug => {
    setExpandedFields(expandedFields => ({
      ...expandedFields,
      [slug]: !expandedFields[slug],
    }));
  }, []);

  const handleCloseDialog = () => {
    setShowUnsavedChangesDialog(false);
    history.push(DASHBOARD_URL);
  }

  const handleOpenDialog = () => {
    const updatedFields = {
      newName,
      newColor,
      newIcon,
      newFields,
    };

    if (initialFields === JSON.stringify(updatedFields)) {
      handleCloseDialog();
    } else {
      setShowUnsavedChangesDialog(true);
    }
  }

  const handleToggleCategoryState = useCallback(() => {
    if (!categoryToEdit) return;

    const categoryIsActive = categoryToEdit.state !== CATEGORY_HIDDEN;
    const newState = categoryIsActive ? CATEGORY_HIDDEN : CATEGORY_ACTIVE;

    setCategoryState(categoryId, newState);
    setOptionsMenuVisible(false);
    optionsMenuButtonRef.current && optionsMenuButtonRef.current.focus();
  }, [categoryId, categoryToEdit]);

  const handleSubmit = useCallback((e) => {
    e.preventDefault();

    if (nameErrorMessage) return;

    const { vision, purpose, roles, thrive, resources, yearResults } = newFields;

    setCategory({
      id: categoryId,
      name: newName.trim(),
      icon: newIcon,
      color: newColor,
      vision: vision || '',
      purpose: purpose || '',
      roles: roles || '',
      thrive: thrive || '',
      resources: resources || '',
      yearResults: yearResults || '',
    });

    setEditing(false);

    editButtonRef.current && editButtonRef.current.focus();
  }, [nameErrorMessage, categoryId, newName, newIcon, newColor, newFields]);

  if (categoryToEdit === null) {
    return (
      <article className={clsx(className, styles.page)} {...props}>
        <LoadingSpinner absolute />
      </article>
    );
  }

  const colorsStyle = editing ?
    composeCategoryColors(newColor) :
    categoryColor;

  return (
    <article
      className={clsx(className, styles.page)}
      style={{
        ...style,
        ...colorsStyle,
      }}
      {...props}
    >
      <Head title={categoryToEdit?.name} />
      {!editing &&
        <div className={styles.group}>
          <div className={styles.buttons}>
            <Button ref={editButtonRef} onClick={() => setEditing('name')}>Edit</Button>
            <ContextualMenuTrigger
              menuId={optionsMenuButtonId}
              visible={optionsMenuVisible}
              setVisible={setOptionsMenuVisible}
            >
              <Button
                ref={optionsMenuButtonRef}
                iconOnly
                aria-label="Category Options"
                tooltip
              >
                <IconMore role="presentation" />
              </Button>
            </ContextualMenuTrigger>

            {optionsMenuVisible &&
              <ContextualMenu
                buttonRef={optionsMenuButtonRef}
                onClose={() => setOptionsMenuVisible(false)}
                aria-labelledby={optionsMenuButtonId}
              >
                <ContextualMenuLink onClick={handleToggleCategoryState}>
                  {categoryToEdit.state !== CATEGORY_HIDDEN ? 'Hide' : 'Show'} Category
                </ContextualMenuLink>
              </ContextualMenu>
            }

            <Button
              linkTo={DASHBOARD_URL}
              iconOnly
              aria-label="Back"
            >
              <IconClose role="presentation" />
            </Button>
          </div>

          <ItemIcon
            className={styles.icon}
            icon={categoryToEdit?.icon}
            active
          />
          <Heading level="h1" className={styles.title}>{categoryToEdit.name}</Heading>

          <Divider className={styles.divider} />

          {Object.entries(CATEGORY_FIELDS_MAP).map(([slug, field], index) => {
            const fieldText = categoryToEdit[slug];

            return (
              <React.Fragment key={slug}>
                <Heading level={HEADING_LEVEL_3} tag="h2" className={styles.label}>{field.label}</Heading>
                {!fieldText &&
                  <SmartLink
                    className={styles.description}
                    onClick={() => setEditing(slug)}
                    linkStyle={SMART_LINK_STYLE_PLACEHOLDER}
                  >
                    Add value…
                  </SmartLink>
                }
                {fieldText &&
                  <p className={styles.description}>
                  {
                    (fieldText.length <= CONCAT_DESCRIPTION_LENGTH || expandedFields[slug]) ?
                      `${fieldText}\xa0` :
                      `${fieldText.substr(0, CONCAT_DESCRIPTION_LENGTH)}…\xa0`
                  }
                  {fieldText.length > CONCAT_DESCRIPTION_LENGTH &&
                    <button className={styles.expand} onClick={() => handleFieldExpandToggle(slug)}>
                      Show&nbsp;{expandedFields[slug] ? 'Less' : 'More'}
                    </button>
                  }
                </p>
              }
              </React.Fragment>
            );
          })}
        </div>
      }

      {editing &&
        <div className={styles.group}>
          <form className={styles.form} onSubmit={handleSubmit}>
            <Input
              className={styles.nameInput}
              label="Name"
              value={newName}
              aria-errormessage={nameErrorMessage ? errorToastId : null}
              onChange={e => setNewName(e.target.value)}
              autoFocus={editing === 'name'}
              maxLength={CATEGORY_NAME_MAX_LENGTH + 5}
              placeholder='e.g. Health and Vitality'
              size={INPUT_SIZE_LARGE}
              inputWrapperChildren={(
                <CharacterCounter
                  className={styles.counter}
                  count={newName.length}
                  max={CATEGORY_NAME_MAX_LENGTH}
                />
              )}
            />

            <div className={styles.field}>
              <InputLabel tag="span" fauxFilled id={colorLabelId}>Color</InputLabel>
              <CategoryRadioGroupColor
                color={newColor}
                setColor={setNewColor}
                aria-labelledby={colorLabelId}
              />
            </div>

            <div className={styles.field}>
              <InputLabel tag="span" fauxFilled id={iconLabelId}>Icon</InputLabel>
              <CategoryRadioGroupIcon
                icon={newIcon}
                setIcon={setNewIcon}
                color={newColor}
                aria-labelledby={iconLabelId}
              />
            </div>

            {Object.entries(CATEGORY_FIELDS_MAP).map(([slug, field]) => (
              <Input
                key={slug}
                label={field.label}
                value={newFields[slug] || ''}
                onChange={e => handleFieldChange(slug, e.target.value)}
                placeholder={field.placeholder}
                autoFocus={editing === slug}
                maxLength={CATEGORY_FIELD_MAX_LENGTH}
                tag="textarea"
                maxRows={10}
                autoComplete="off"
                autoCorrect="off"
                autoCapitalize="off"
                spellCheck="false"
              />
            ))}

            <div className={styles.buttons}>
              <Button type="submit" disabled={nameErrorMessage}>Save</Button>
              <Button
                iconOnly
                aria-label="Close"
                type="button"
                onClick={() => handleOpenDialog()}
              >
                <IconClose role="presentation" />
              </Button>
            </div>

            {showUnsavedChangesDialog && (
              <Dialog
                headerTitle='Unsaved changes'
                footer={
                  <>
                    <Button block onClick={() => setShowUnsavedChangesDialog(false)}>
                      Cancel
                    </Button>
                    <Button
                      onClick={() => handleCloseDialog()}
                      negative
                      block
                    >
                      Close without saving
                    </Button>
                  </>
                }
              >
                <p>You have made changes to this category, but have not saved&nbsp;them.</p>
              </Dialog>
            )}
          </form>

          {nameErrorMessage &&
            <Toast error role="alert" id={errorToastId}>{nameErrorMessage}</Toast>
          }
        </div>
      }
    </article>
  );
}

export default CategoryDetail;
