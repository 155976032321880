import React from 'react';
import clsx from 'clsx';

import styles from './Divider.module.scss';

function Divider({
  className,
  vertical = false,
  ...props
}) {
  return (
    <hr
      aria-hidden={true}
      className={clsx(className, styles.divider, vertical && styles.vertical)}
      {...props}
    />
  );
}

export default Divider;
