import React, { useRef, useCallback, useState, useEffect, useMemo } from 'react';
import clsx from 'clsx';

import {
  DETAIL_BACKGROUND_IMAGE_MAX_LENGTH,
  DETAIL_BACKGROUND_IMAGE_MAX_FILE_SIZE_MB,
  PERSON_NICKNAME_MAX_LENGTH,
  PERSON_DESCRIPTION_MAX_LENGTH,
} from '../../../services/DbService/constants';
import { updatePerson, watchPersonCounters } from '../../../services/DbService/people';
import { uploadPhoto } from '../../../services/PhotoService';

import useUniqueId from '../../../hooks/useUniqueId';
import usePeople from '../../../hooks/usePeople';

import Toast from '../../Toast';
import Heading, { HEADING_LEVEL_3 } from '../../Heading';
import Input, { INPUT_SIZE_LARGE } from '../../Input';
import ItemIcon from '../../ItemIcon';
import SmartLink, { SMART_LINK_STYLE_PLACEHOLDER } from '../../SmartLink';
import Button from '../../Button';
import { PeopleCounters } from '../../Counters';
import Tooltip from '../../Tooltip';

import styles from './PersonDetail.module.scss';

const PersonDetailHeader = ({
  person: {
    id,
    nickname,
    description,
    image,
  },
  editing,
  setEditing,
}) => {
  const [uploading, setUploading] = useState(false);
  const [nicknameInput, setNicknameInput] = useState();
  const [descriptionInput, setDescriptionInput] = useState();
  const [counters, setCounters] = useState({});

  const descriptionRef = useRef();

  const errorToastId = useUniqueId();
  const { people } = usePeople();

  const matchesExisting = useMemo(() => {
    if (!nickname) return false;
    // Allow a user to edit it to be the same as what it currently is
    if (nickname.toLowerCase() === nicknameInput?.toLowerCase()) return false;
    return people.find(person => person?.nickname.toLowerCase() === nicknameInput?.toLowerCase());
  }, [people, nickname, nicknameInput]);

  useEffect(() => {
    if (!id) return;
    return watchPersonCounters(id, newCounters => setCounters(newCounters));
  }, [id]);

  useEffect(() => {
    if (!editing) {
      setNicknameInput(nickname);
      setDescriptionInput(description);
    }
  }, [editing, nickname, description]);

  const handleImageUpload = useCallback(async e => {
    const file = e.target.files[0];
    if (!file) return;

    setUploading(true);

    let imageUrl;
    try {
      imageUrl = await uploadPhoto(file, {
        maxSizeMB: DETAIL_BACKGROUND_IMAGE_MAX_FILE_SIZE_MB,
        maxWidthOrHeight: DETAIL_BACKGROUND_IMAGE_MAX_LENGTH,
      });
    } catch(e) {
      setUploading(false);
      return;
    }
    updatePerson({id, background_image: imageUrl});
    setUploading(false);
  }, [id]);

  const handleSubmit = useCallback(async e => {
    e.preventDefault();
    updatePerson({
      id,
      nickname: nicknameInput.toLowerCase(),
      description: descriptionInput,
    });
    setEditing(null);
  }, [id, nicknameInput, descriptionInput, setEditing]);

  return (
    <>
      <div
        className={styles.hero}
        style={{
          '--bg-image': image && `url('${image}')`,
        }}
      >
        {!editing &&
          <div className={styles.buttons}>
            <Button onClick={() => setEditing(true)}>Edit</Button>
          </div>
        }
      </div>
      <header>

        {!editing &&
          <div className={styles.header}>
            <div className={styles.group}>
              <ItemIcon
                className={styles.headerIcon}
                initials={(editing ? (nicknameInput || '') : nickname)?.[0] || ' '}
                active
              />
              <Heading className={styles.headerTitle} level={HEADING_LEVEL_3} tag="h1">@{nickname}</Heading>
              <PeopleCounters className={styles.headerCounters} {...counters} />
              {description &&
                <p className={styles.description}>{description}</p>
              }
              {!description &&
                <Tooltip title="Add description">
                  <SmartLink
                    className={styles.description}
                    onClick={() => setEditing('description')}
                    aria-label="Add description"
                    linkStyle={SMART_LINK_STYLE_PLACEHOLDER}
                  >
                    Add a description for @{nickname}…
                  </SmartLink>
                </Tooltip>
              }
            </div>
          </div>
        }

        {editing &&
          <form onSubmit={handleSubmit} className={clsx(styles.group, styles.form)}>
            <Input
              label="Nickname"
              value={nicknameInput}
              onChange={e => setNicknameInput(e.target.value.replace(/[\n\s]/g, ''))}
              placeholder="Enter Nickname…"
              maxLength={PERSON_NICKNAME_MAX_LENGTH}
              autoFocus={editing === 'nickname'}
              size={INPUT_SIZE_LARGE}
            />

            <Input
              label="Description"
              ref={descriptionRef}
              value={descriptionInput || ''}
              autoFocus={editing === 'description'}
              onChange={e => setDescriptionInput(e.target.value)}
              placeholder="Enter Description…"
              maxLength={PERSON_DESCRIPTION_MAX_LENGTH}
              tag="textarea"
            />

            <Input
              label="Profile Picture"
              accept="image/png,image/jpeg,image/gif"
              onChange={handleImageUpload}
              placeholder="Enter Description…"
              maxLength={PERSON_DESCRIPTION_MAX_LENGTH}
              type="file"
            />

            <div className={styles.buttons}>
              <Button
                type="submit"
                disabled={uploading || matchesExisting || !nicknameInput}
                loading={uploading}
              >
                Save
              </Button>
            </div>
          </form>
        }
      </header>
      {matchesExisting &&
        <Toast error role="alert" id={errorToastId}>Person already exists with username @{nicknameInput}</Toast>}
    </>
  );
};

export default PersonDetailHeader;
