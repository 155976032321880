import React, { useRef, useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { signIn, NotAuthorizedError, NotVerifiedError } from '../../services/AuthService';
import { NetworkError } from '../../services/ApiService';
import { DASHBOARD_URL, ACCESS_URL, EMAIL_VERIFICATION_URL, FORGOT_PASSWORD_URL } from '../App';

import Button, { BUTTON_COLOR_FILL } from '../Button';
import Input from '../Input';
import PageNoUi from '../PageNoUi';
import SmartLink from '../SmartLink';
import AuthForm from './AuthForm';

function SignIn() {
  const history = useHistory();

  const { prev } = history.location.state || {};
  const startingEmail = history.location.state?.email;

  const [username, setUsername] = useState(startingEmail || '');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const usernameRef = useRef();
  const pwdRef = useRef();

  // This function is needed to augment the link to pass the users email
  // across to the forgot password form
  const handleForgotPassword = useCallback(e => {
    e.preventDefault();
    history.push(FORGOT_PASSWORD_URL, {
      username,
    });
  }, [history, username]);

  const onSubmit = useCallback(async e => {
    e.preventDefault();
    setLoading(true);

    try {
      await signIn(username, password);
    } catch (e) {
      setLoading(false);
      if (e instanceof NetworkError) {
        setError('Network error, please check your internet connection and try again');
      } else if (e instanceof NotAuthorizedError) {
        setError('Incorrect username or password, please try\xa0again');
      } else if (e instanceof NotVerifiedError) {
        history.push(EMAIL_VERIFICATION_URL, {username, password});
        return;
      } else {
        setError('Unexpected error, please try again');
        console.error(e);
      }
      // Move the focus on username so the user can make corrections or just
      // press Enter to try again
      usernameRef.current.focus();
      return;
    }

    history.push(DASHBOARD_URL);
  }, [history, username, password]);

  let text;
  if (prev === 'access') {
    text = 'You’ve got access and an account is registered with that email. Sign in to access your\xa0account.';
  } else if (prev === 'reset') {
    text = 'Your password was successfully reset. Please sign in with your new\xa0password.';
  }

  return (
    <PageNoUi
      pageTitle="Sign In"
      text={text}
    >
      <AuthForm
        error={error}
        loading={loading}
        buttons={<>
          <Button
            type='submit'
            loading={loading}
            onClick={onSubmit}
            color={BUTTON_COLOR_FILL}
            block
          >
            Sign In
          </Button>
          <Button linkTo={ACCESS_URL} block>Create Account</Button>
        </>}
        bottomText={<>
          Forgot? <SmartLink to={FORGOT_PASSWORD_URL} onClick={handleForgotPassword}>Reset your password</SmartLink>
        </>}
      >
        <Input
          label='Your username/email'
          type='text'
          autoFocus={!startingEmail}
          autoComplete='username'
          autoCorrect="off"
          autoCapitalize="off"
          spellCheck="false"
          ref={usernameRef}
          value={username}
          onChange={e => setUsername(e.target.value)}
        />
        <Input
          label='Your password'
          type='password'
          autoComplete='current-password'
          ref={pwdRef}
          autoFocus={startingEmail}
          value={password}
          onChange={e => setPassword(e.target.value)}
        />
      </AuthForm>
    </PageNoUi>
  );
}

export default SignIn;
