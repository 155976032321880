import React, { useState } from 'react';

import { setBlockState } from '../../../services/DbService/blocks';
import { ACTIVE } from '../../../services/DbService/constants';

import Dialog from '../../Dialog';
import Toast from '../../Toast';
import Button from '../../Button';

import styles from './BlockDetail.module.scss';

function BlockDetailToast({
  blockId,
  icon: Icon,
  title,
  dialogMessage,
  ...props
}) {
  const [dialogVisible, setDialogVisible] = useState(false);

  return (
    <>
      <Toast
        {...props}
        className={styles.toast}
        onClick={() => setDialogVisible(true)}
      >
        <Icon role="presentation"/> {title}
      </Toast>

      {dialogVisible &&
        <Dialog
          headerTitle={title}
          onClose={() => setDialogVisible(false)}
          footer={(
            <Button block onClick={() => setBlockState(blockId, ACTIVE)}>Make Block Active</Button>
          )}
        >
          <p>{dialogMessage}</p>
        </Dialog>
      }
    </>
  );
}

export default BlockDetailToast;
