import React, { useRef, useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';

import {
  signUp,
  AlreadyRegisteredEmailError,
  AlreadyRegisteredUsernameError,
  InvalidEmailError,
  InvalidParameterError,
  InvalidPasswordError,
  UnauthorizedEmailError,
} from '../../services/AuthService';
import { NetworkError } from '../../services/ApiService';

import { DASHBOARD_URL, ACCESS_URL, EMAIL_VERIFICATION_URL, SUBSCRIBE_URL, SIGN_IN_URL } from '../App';

import Button, { BUTTON_COLOR_FILL } from '../Button';
import Input from '../Input';
import PageNoUi from '../PageNoUi';
import SmartLink from '../SmartLink';
import AuthForm from './AuthForm';

function SignUp() {
  const history = useHistory();

  const emailRef = useRef();
  const passwordRef = useRef();
  const passwordCheckRef = useRef();
  const usernameRef = useRef();

  const startingEmail = history.location.state?.email || '';

  const [email, setEmail] = useState(startingEmail);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [passwordCheck, setPasswordCheck] = useState('');

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const handleUsernameChange = useCallback(e => {
    // Usernames are URL safe, so for a better user experience we prevent users from
    // entering spaces as they are likely to do that frequently, but don't limit other
    // illegal characters. These are handle as errors from the backend.
    const value = e.target.value.replace(/\s/g, '');
    setUsername(value);
  }, []);

  const onSubmit = useCallback(async e => {
    e.preventDefault();

    // Check required fields
    if (!email) {
      setError('Email is required');
      emailRef.current.focus();
      return;
    }

    if (!username) {
      setError('Username is required');
      usernameRef.current.focus();
      return;
    }

    if (!password) {
      setError('Password is required');
      passwordRef.current.focus();
      return;
    }

    // Check password
    if (password !== passwordCheckRef.current.value) {
      setError('Those passwords didn\'t match, try again');
      passwordCheckRef.current.value = '';
      passwordRef.current.focus();
      return;
    }

    // Register
    setLoading(true);

    try {
      await signUp(email, password, username);
    } catch (e) {
      setLoading(false);
      if (e instanceof NetworkError) {
        setError('Network error, please check your internet connection and try again');
      } else if (e instanceof AlreadyRegisteredEmailError) {
        setError(<>Email is already registered. <a href={SIGN_IN_URL}>Sign in</a></>);
      } else if (e instanceof AlreadyRegisteredUsernameError) {
        setError(<>Username is taken. <a href={SIGN_IN_URL}>Sign in</a></>);
      } else if (e instanceof InvalidEmailError) {
        setError('Invalid email');
      } else if (e instanceof UnauthorizedEmailError) {
        history.push(SUBSCRIBE_URL);
        return;
      } else if (e instanceof InvalidParameterError) {
        setError(e.message);
      } else if (e instanceof InvalidPasswordError) {
        setError('Password must be at least 6 characters long');
        passwordCheckRef.current.value = '';
        passwordRef.current.focus();
        return;
      } else {
        setError('Unexpected error, please try again');
        console.error(e);
      }
      // Move the focus on email so the user can make corrections or just
      // press Enter to try again
      emailRef.current.focus();
      return;
    }

    history.push(EMAIL_VERIFICATION_URL, {email, username, password});
  }, [history, email, username, password]);

  if (process.env.REACT_APP_DISABLE_REGISTRATIONS === 'true') {
    return (
      <PageNoUi
        pageTitle="Registration disabled"
        text={<>
          Registration is temporarily disabled on <SmartLink to={DASHBOARD_URL}>rpmcalendar.com</SmartLink>. Please download the iOS app and register on there to&nbsp;proceed.
        </>}
      />
    );
  }

  return (
    <PageNoUi
      pageTitle="Sign Up"
      text={'You’ve got access! Please create your account\xa0below.'}
    >
      <AuthForm
        error={error}
        loading={loading}
        buttons={<>
          <Button linkTo={ACCESS_URL} block>Cancel</Button>
          <Button
            type='submit'
            loading={loading}
            onClick={onSubmit}
            color={BUTTON_COLOR_FILL}
            block
          >
            Next
          </Button>
        </>}
      >
        <Input
          label='Your email'
          type='email'
          autoFocus={!startingEmail}
          name='email'
          autoComplete='email'
          autoCorrect="off"
          autoCapitalize="off"
          spellCheck="false"
          ref={emailRef}
          value={email}
          onChange={e => setEmail(e.target.value)}
        />
        <Input
          label='Your username'
          type='text'
          name='username'
          autoFocus={startingEmail}
          autoCorrect="off"
          autoCapitalize="off"
          spellCheck="false"
          ref={usernameRef}
          value={username}
          onChange={handleUsernameChange}
        />
        <Input
          label='Your password'
          type='password'
          name='password'
          autoComplete='new-password'
          ref={passwordRef}
          value={password}
          onChange={e => setPassword(e.target.value)}
        />
        <Input
          label='Confirm your password'
          type='password'
          name='confirm-password'
          autoComplete='new-password'
          ref={passwordCheckRef}
          value={passwordCheck}
          onChange={e => setPasswordCheck(e.target.value)}
        />
      </AuthForm>
    </PageNoUi>
  );
}

export default SignUp;
