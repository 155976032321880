// This is also set in package.json and index.html. When updated, it should be changed there too.
export const PRODUCT_NAME = 'RPM';

export const NBSP = '\xa0';

/**
 * Takes an array of objects and returns a map where the key is
 * the 'id' field of the object, and the value is the object itself
 */
export function mapById(objs) {
  const mapById = {}
  for (const obj of objs) mapById[obj.id] = obj;
  return mapById;
}

export function replaceMentions(actionDescription, mentionReplaceCallback) {
  // The following sanitation ensures that no complete HTML tags can be
  // maliciously injected in actionDescription. It works by replacing, all
  // tag openings with entities, except for '<@' that is the opening of
  // the mention.
  const sanitized = actionDescription.replace(/<(?!@)/g, '&lt;');
  return sanitized.replace(/<@(.+?)>/g,
    (match, personId) => mentionReplaceCallback(personId));
}

export function timeToText(seconds) {
  const SECONDS_IN_MINUTE = 60;
  const SECONDS_IN_HOUR = SECONDS_IN_MINUTE * 60;

  let shortText;
  let longText;
  if (seconds === 0) {
    shortText = '0H';
    longText = '0 hours';
  } else if (seconds >= SECONDS_IN_HOUR) {
    const hours = Math.round(seconds / SECONDS_IN_HOUR);
    shortText = `${hours}H`;
    longText = `${hours} hour`;
    if (hours !== 1) longText += 's'
  } else {
    const minutes = Math.round(seconds / SECONDS_IN_MINUTE);
    shortText = `${minutes}M`;
    longText = `${minutes} minute`;
    if (minutes !== 1) longText += 's'
  }

  return { shortText, longText };
}

export function encode(string) {
  return string.replace(/&/g, '&amp;').replace(/</g, '&lt;');
}

export function decode(string) {
  return string.replace(/&amp;/g, '&').replace(/&lt;/g, '<');
}

export function scrollIntoViewIfNeeded(el, parent) {
  if (!el || !parent) return;

  const elRect = el.getBoundingClientRect();
  const parentRect = parent.getBoundingClientRect();

  if (elRect.top < parentRect.top || elRect.bottom > parentRect.bottom) {
    el.scrollIntoView({
      block: 'nearest'
    });
  }
}

const isMac = navigator.platform.indexOf('Mac') > -1;

export const getModifierKey = (e) => isMac ? e.metaKey : e.ctrlKey;
export const getModifierKeyString = () => isMac ? '⌘' : 'Ctrl';

export function replaceMultipleLineBreaks(string, replaceWith = '\n') {
  if (typeof string !== 'string') return string;
  return string.replace(/[\r\n]+/g, replaceWith);
}
