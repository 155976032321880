import React from 'react';

import { useCategoryColors } from '../CategoriesContext';
import Dialog from '../Dialog';
import Button, { BUTTON_COLOR_CATEGORY } from '../Button';
import Heading, { HEADING_LEVEL_3 } from '../Heading';

import { ReactComponent as Cutout } from './cutout.svg';

import styles from './CelebrationDialog.module.scss';

function CelebrationDialog({
  title,
  categoryId,
  image,
  description,
  onClose,
}) {
  const categoryColors = useCategoryColors(categoryId);

  return (
    <Dialog
      className={styles.dialog}
      style={categoryColors}
      onClose={onClose}
      customBody
    >
      <div
        className={styles.image}

        style={{
          backgroundImage: image && `url(${image})`,
        }}
      >
        <Cutout />
      </div>

      <Heading tag="span" level={HEADING_LEVEL_3}>{title}</Heading>
      {description &&
        <p className={styles.description}>{description}</p>
      }

      <Button color={BUTTON_COLOR_CATEGORY} className={styles.button} onClick={onClose}>
        Got it
      </Button>
    </Dialog>
  );
}

export default CelebrationDialog;
