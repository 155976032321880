import React, { forwardRef, useCallback, useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import clsx from 'clsx';

import DialogHeader from './DialogHeader';
import DialogFooter from './DialogFooter';
import { useIsTopmostDialog } from '../Dialog';

import useClickOutside from '../../hooks/useClickOutside';
import useFocusTrap from '../../hooks/useFocusTrap';

import styles from './Dialog.module.scss';

const Dialog = forwardRef(({
  title,
  content,
  buttons,
  className,
  children,
  onClose,
  headerTitle,
  headerSubtitle,
  headerButtons,
  footer: footerChildren,
  customBody,
  hidden,
  lightbox = true,
  ...props
}, ref) => {
  // Dialog requires a ref for managing the focus transfer etc, so when it is
  // omitted a local ref is used instead which removes the need for a seemingly
  // unused useRef() at the parent level
  const localRef = useRef();
  ref = ref || localRef;

  const isTopmostDialog = useIsTopmostDialog();

  // When the dialog opens, transfer focus to the modal for keyboard users unless the new
  // dialog has an element that takes focus when it is created (like <input autoFocus />).
  useEffect(() => {
    if (ref.current.contains(document.activeElement)) return;

    ref.current.focus();
  }, [ref]);

  const handleClose = useCallback(() => {
    if (!isTopmostDialog || !onClose) return;

    onClose();
  }, [isTopmostDialog, onClose]);

  useClickOutside(handleClose, ref);
  useFocusTrap(ref, {
    onClose: handleClose,
    isTopmostDialog,
  });

  return createPortal(
    <div
      className={clsx(styles.lightbox, !lightbox && styles.noLightbox)}
      hidden={hidden}
    >
      <div
        {...props}
        className={clsx(styles.dialog, className)}
        ref={ref}
        role='dialog'
        aria-modal='true'
        tabIndex="-1"
      >
        {headerTitle &&
          <DialogHeader
            className={styles.dialogHeader}
            title={headerTitle}
            subtitle={headerSubtitle}
            buttons={headerButtons}
            onClose={onClose}
          />
        }

        {children && !customBody &&
          <div className={styles.body}>{children}</div>
        }

        {customBody && children}

        {footerChildren &&
          <DialogFooter className={clsx(children?.length === 0 && styles.noContent)}>
            {footerChildren}
          </DialogFooter>
        }
      </div>
    </div>
  , document.body);
});

Dialog.displayName = 'Dialog';

export default Dialog;
