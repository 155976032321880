import React, { useCallback, useState, useRef } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';

import useUniqueId from '../../hooks/useUniqueId';

import {
  SNOOZED, ACTIVE, COMPLETED,
  SNOOZED_LIST_ORDER, SNOOZED_LABELS,
} from '../../services/DbService/constants';
import {
  setBlockState,
  blockHasIncompleteActions,
  blockHasEvents,
  deleteBlock,
} from '../../services/DbService/blocks';

import { DASHBOARD_URL, BLOCK_DETAIL_URL } from '../App';
import Button from '../Button';
import ContextualMenu, { ContextualMenuLink, ContextualMenuTrigger } from '../ContextualMenu';
import Dialog from '../Dialog';

const DELETE_DIALOG_EVENTS = 'events';
const DELETE_DIALOG_INCOMPLETE = 'incomplete';

export const tryCompleteBlock = async (id, onFail) => {
  const hasIncompleteBlocks = await blockHasIncompleteActions(id);

  if (hasIncompleteBlocks) {
    onFail();
    return;
  }

  // saveActiveActions defaults to captureList to ensure that no uncaught actions
  // are missed – this is an extra safety precaution that is unlikely to be used.
  setBlockState(id, COMPLETED, 'captureList');
};

function BlockItemCompleteDialog({
  onClose,
  blockId,
  ...props
}) {
  const handleSetUnnecessary = useCallback(() => {
    setBlockState(blockId, COMPLETED, 'unnecessary');
    onClose();
  }, [blockId, onClose]);

  const handleToCaptureList = useCallback(() => {
    setBlockState(blockId, COMPLETED, 'captureList');
    onClose();
  }, [blockId, onClose]);

  return (
    <Dialog
      headerTitle='Incomplete actions'
      onClose={onClose}
      footer={
        <>
          <Button block onClick={handleSetUnnecessary}>Mark as Unnecessary</Button>
          <Button block onClick={handleToCaptureList}>Send to Capture List</Button>
        </>
      }
    >
      <p>What would you like to do with the incomplete actions within this block?</p>
    </Dialog>
  );
}

function BlockItemMenu({
  block,
  showCelebrationDialog,
  onEdit,
  onExportPdf,
  showSnoozeButton = true,
  showActiveButton = true,
  menuVisible,
  onClose,
  ...props
}) {
  const { id, state } = block;

  const match = useRouteMatch(BLOCK_DETAIL_URL);
  const active = match?.params.blockId === id;
  const history = useHistory();

  const [completeDialogVisible, setCompleteDialogVisible] = useState(false);
  const [deleteDialogVisible, setDeleteDialogVisible] = useState(false);

  const snoozeMenuButtonRef = useRef();
  const snoozeMenuButtonId = useUniqueId();
  const [snoozeMenuVisible, setSnoozeMenuVisible] = useState(null);

  const handleEdit = useCallback(() => {
    onEdit && onEdit();
    onClose && onClose();
  }, [onEdit, onClose]);

  const handleComplete = useCallback(async () => {
    function onFailCallback() {
      onClose && onClose();
      setCompleteDialogVisible(true);
    }
    await tryCompleteBlock(id, onFailCallback);
  }, [onClose, id]);

  const handleSetActive = useCallback(() => {
    onClose && onClose();
    setBlockState(id, ACTIVE);
  }, [onClose, id]);

  const handleSnooze = useCallback(snoozedToString => {
    setBlockState(id, SNOOZED, null, snoozedToString);
    setSnoozeMenuVisible(false);
    onClose && onClose();
  }, [onClose, id]);

  const handleExportPdf = useCallback(() => {
    onClose && onClose();
    onExportPdf && onExportPdf();
  }, [onClose, onExportPdf]);

  const performDelete = useCallback(saveActiveActions => {
    deleteBlock(id, saveActiveActions);
    setDeleteDialogVisible(false);
    if (active) history.push(DASHBOARD_URL);
  }, [id, active, history]);

  const handleDelete = useCallback(async () => {
    onClose && onClose();
    // This check is ignored if the dialog is already showing the message about
    // scheduled events (and is in this order to avoid blockHasEvents being called
    // unnecessarily).
    if (deleteDialogVisible !== DELETE_DIALOG_EVENTS && await blockHasEvents(id)) {
      setDeleteDialogVisible(DELETE_DIALOG_EVENTS);
      return;
    }

    if (await blockHasIncompleteActions(id)) {
      if (deleteDialogVisible === DELETE_DIALOG_EVENTS) {
        // Because a dialog is already showing, hiding it and popping the other one –
        // even with just a 200ms delay – gives the user the understanding that
        // there is a new dialog to interact with. It's a short enough delay that
        // there should be no ill effects if a user tries to do something before
        // the new modal pops up.
        setDeleteDialogVisible(false);
        await new Promise(resolve => setTimeout(resolve, 200));
      }

      setDeleteDialogVisible(DELETE_DIALOG_INCOMPLETE);
      return;
    }

    performDelete(true);
  }, [id, performDelete, deleteDialogVisible, onClose]);

  return (
    <>
      {menuVisible &&
        <ContextualMenu onClose={onClose} {...props}>
          {onEdit && state !== COMPLETED &&
            <ContextualMenuLink onClick={handleEdit}>Edit Block</ContextualMenuLink>
          }
          {state !== ACTIVE && showActiveButton &&
            <ContextualMenuLink onClick={handleSetActive}>Make Block Active</ContextualMenuLink>
          }
          {/* Because snoozed blocks can have their date changed, this is visible in all states */}
          {showSnoozeButton &&
            <>
              <ContextualMenuTrigger
                visible={snoozeMenuVisible}
                setVisible={setSnoozeMenuVisible}
                menuId={snoozeMenuButtonId}
                submenu
              >
                <ContextualMenuLink ref={snoozeMenuButtonRef} submenu>
                  Snooze Block
                </ContextualMenuLink>
              </ContextualMenuTrigger>

              {snoozeMenuVisible &&
                <ContextualMenu
                  buttonRef={snoozeMenuButtonRef}
                  onClose={() => setSnoozeMenuVisible(false)}
                  submenu
                  aria-labelledby={snoozeMenuButtonId}
                >
                  {SNOOZED_LIST_ORDER.map((snoozedToString) => {
                    const { cta } = SNOOZED_LABELS[snoozedToString];

                    return (
                      <ContextualMenuLink
                        key={snoozedToString}
                        onClick={() => handleSnooze(snoozedToString)}
                        aria-label={`Snooze block ${cta}`}
                      >
                        {cta}
                      </ContextualMenuLink>
                    );
                  })}
                </ContextualMenu>
              }
            </>
          }
          {state !== COMPLETED &&
            <ContextualMenuLink onClick={handleComplete}>Complete Block</ContextualMenuLink>
          }
          {onExportPdf &&
            <ContextualMenuLink onClick={handleExportPdf}>Export PDF</ContextualMenuLink>
          }
          <ContextualMenuLink onClick={handleDelete} negative>Delete Block</ContextualMenuLink>
        </ContextualMenu>
      }

      {deleteDialogVisible === DELETE_DIALOG_EVENTS &&
        <Dialog
          headerTitle='Scheduled Block'
          onClose={() => setDeleteDialogVisible(false)}
          footer={
            <>
              <Button block onClick={() => setDeleteDialogVisible(false)}>Cancel</Button>
              <Button block onClick={handleDelete} negative>Delete block</Button>
            </>
          }
        >
          <p>This block is scheduled. Deleting it will also delete those scheduled events. Do you wish to&nbsp;proceed?</p>
        </Dialog>
      }

      {deleteDialogVisible === DELETE_DIALOG_INCOMPLETE &&
        <Dialog
          headerTitle='Incomplete Actions'
          onClose={() => setDeleteDialogVisible(false)}
          footer={
            <>
              <Button block negative onClick={() => performDelete(false)}>Delete Actions</Button>
              <Button block onClick={() => performDelete(true)}>Send to Capture List</Button>
            </>
          }
        >
          <p>This block has incomplete actions. Do you want to save them to your capture list or delete them?</p>
        </Dialog>
      }

      {completeDialogVisible &&
        <BlockItemCompleteDialog
          blockId={id}
          onClose={() => setCompleteDialogVisible(false)}
        />
      }
    </>
  );
}

BlockItemCompleteDialog.displayName = 'BlockItemCompleteDialog';

export { BlockItemMenu, BlockItemCompleteDialog };
