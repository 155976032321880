import React, { useState, createContext, useEffect, useMemo, useContext } from 'react';

import {
  CATEGORY_ACTIVE, CATEGORY_HIDDEN, CATEGORY_ICONS, UNCATEGORIZED_ICON,
} from '../services/DbService/constants.js';

import { watchCategories, composeCategoryColors } from '../services/DbService/categories.js';

import { AuthContext } from './AuthContext';

const CategoriesContext = createContext();

function computeMetadata(categories, metadata = {}) {
  for (const category of categories) {
    const { id, name, icon, color } = category;
    metadata[id] = {
      name,
      colors: composeCategoryColors(color),
      icon,
    };
  };
  return metadata;
}

export function CategoriesContextProvider({ children }) {
  const { user } = useContext(AuthContext);

  const [activeCategories, setActiveCategories] = useState(null);
  const [hiddenCategories, setHiddenCategories] = useState(null);
  const [metadata, setMetadata] = useState({});

  useEffect(() => {
    if (!user) return;

    return watchCategories(CATEGORY_ACTIVE, newCategories => {
      setActiveCategories(newCategories);
      setMetadata(oldMetadata => computeMetadata(newCategories, oldMetadata));
    });
  }, [user]);

  useEffect(() => {
    if (!user) return;

    return watchCategories(CATEGORY_HIDDEN, newCategories => {
      setHiddenCategories(newCategories);
      setMetadata(oldMetadata => computeMetadata(newCategories, oldMetadata));
    });
  }, [user]);

  const value = useMemo(() => ({
    activeCategories,
    hiddenCategories,
    metadata,
  }), [activeCategories, hiddenCategories, metadata]);

  return (
    <CategoriesContext.Provider value={value}>
      {children}
    </CategoriesContext.Provider>
  );
}

// Exposes a hook for the watched categories lists
export function useCategories() {
  const { activeCategories, hiddenCategories } = useContext(CategoriesContext);

  return { activeCategories, hiddenCategories };
}

// Exposes a functional hook for getting a category's color style object
export function useCategoryColors(categoryId) {
  const { metadata } = useContext(CategoriesContext);

  return metadata[categoryId]?.colors || {};
};

// Exposes a function for getting a category's color style object for instances
// where categoryId is used conditionally or in a loop, for example:
export function useGetCategoryColors() {
  const { metadata } = useContext(CategoriesContext);

  return categoryId => metadata[categoryId]?.colors || {};
}

// Exposes a functional hook for getting a category's icon object
export function useCategoryIcon(categoryId) {
  const { metadata } = useContext(CategoriesContext);

  const icon = metadata[categoryId]?.icon;

  if (icon === UNCATEGORIZED_ICON.key) {
    return UNCATEGORIZED_ICON;
  }

  return CATEGORY_ICONS[icon] || {};
}
