import React from 'react';
import clsx from 'clsx';

import styles from './LoadingSpinner.module.scss';

function LoadingSpinner({
  className,
  absolute = false,
  ...props
}) {
  return (
    <div className={clsx(className, styles.spinner, absolute && styles.absolute)} role='alert' aria-label='loading' {...props}>
      <div className={styles.bounce1}/>
      <div className={styles.bounce2}/>
      <div/>
    </div>
  );
}

export default LoadingSpinner;
