import React from 'react';
import clsx from 'clsx';

import Heading, { HEADING_LEVEL_4 } from '../Heading';

import { INPUT_SIZE_REGULAR } from './';

import styles from './Input.module.scss';

function InputLabel({
  className,
  tag = 'label',
  size = INPUT_SIZE_REGULAR,
  fauxFilled,
  children,
  ...props
}) {
  return (
    <Heading
      className={clsx(
        className,
        styles.label,
        styles[size],
        fauxFilled && styles.fauxFilled,
      )}
      tag={tag}
      level={HEADING_LEVEL_4}
      {...props}
    >
      {children}
    </Heading>
  );
};

export default InputLabel;
