import React, { useContext, useEffect } from 'react';
import { BrowserRouter, Switch, MemoryRouter, useLocation, Route } from 'react-router-dom';
import { ErrorBoundary } from '@sentry/react';
import { HelmetProvider } from 'react-helmet-async';
import isElectron from 'is-electron';

import { trackPage } from '../services/AnalyticsService';

import { TRAPPABLE_KEYS } from '../hooks/useFocusTrap';

import AuthedRoute from './AuthedRoute';
import UnauthedRoute from './UnauthedRoute';
import EventsNotifier from './EventsNotifier';
import Dashboard from './Dashboard';
import Planner from './Planner';
import SignIn from './Auth/SignIn';
import SignUp from './Auth/SignUp';
import EmailVerification from './Auth/EmailVerification';
import Access from './Auth/Access';
import Subscribe from './Auth/Subscribe';
import ForgotPassword from './Auth/ForgotPassword';
import OnboardingGoals from './Onboarding/Goals';
import OnboardingCreateCategory from './Onboarding/CreateCategory';
import OnboardingCategoryDetails from './Onboarding/CategoryDetails';
import Profile from './Profile';
import People from './People';
import NotFound from './NotFound';

import Nav from './Nav';
import AppError from './AppError';
import { LOCALSTORAGE_COLOR_SCHEME } from './Profile/ProfileSidebar';

import { NotificationsContext, NotificationsContextProvider } from './NotificationsContext';
import { AuthContext, AuthContextProvider } from './AuthContext';
import { CategoriesContextProvider } from './CategoriesContext';
import { ActionDialogContextProvider } from './ActionDialog';
import { DialogContextProvider } from './Dialog';

export const DASHBOARD_URL = '/';
export const SIGN_IN_URL = '/sign-in';
export const SIGN_UP_URL = '/sign-up';
export const EMAIL_VERIFICATION_URL = '/verification';
export const ACCESS_URL = '/access';
export const SUBSCRIBE_URL = '/subscribe';
export const ONBOARDING_GOALS_URL = '/onboarding/goals';
export const ONBOARDING_CREATE_CATEGORY_URL = '/onboarding/create-category';
export const ONBOARDING_CATEGORY_DETAILS_URL = '/onboarding/category-details';
export const FORGOT_PASSWORD_URL = '/forgot-password';
export const BLOCK_DETAIL_URL = '/blocks/:blockId';
export const PEOPLE_URL = '/people/:personId?';
export const CATEGORY_DETAIL_URL = '/category/:categoryId';
export const PROFILE_URL = '/profile';
export const PLANNER_URL = '/planner';

// Electron loads the app with the file:// protocol, which in Chrome
// does not allow history.pushState(). Swtiching to HashRouter does
// the trick.
const Router = isElectron() ? MemoryRouter : BrowserRouter;

// useLocation can only be accessed by children of `<Router>` so a simple
// observer component is required to watch for route changes
function LocationObserver() {
  const { pathname } = useLocation();

  useEffect(() => trackPage(pathname), [pathname]);

  return null;
}

function App() {
  const { notifications } = useContext(NotificationsContext);
  const { user, authReady } = useContext(AuthContext);

  useEffect(() => {
    const colorScheme = localStorage.getItem(LOCALSTORAGE_COLOR_SCHEME);
    if (colorScheme) {
      document.documentElement.dataset.colorPreference = colorScheme;
    }
  }, []);

  // Little function to detect when a user switches to tabbing to allow for `:focus-visible`
  // to only be shown for keyboard actions – inputs have this regardless of how it was focused
  useEffect(() => {
    function detectMouse() {
      document.body.classList.remove('js--focus-visible');
    }

    function detectTab({ key }) {
      if (TRAPPABLE_KEYS.includes(key)) {
        document.body.classList.add('js--focus-visible');
      }
    }

    document.body.addEventListener('mousedown', detectMouse);
    document.body.addEventListener('keydown', detectTab);

    return () => {
      document.body.removeEventListener('mousedown', detectMouse);
      document.body.removeEventListener('keydown', detectTab);
    }
  }, []);

  // TODO: design a loading screen
  if (!authReady) return null;

  const showNotifications = user && notifications === 'granted';

  return (
    <ErrorBoundary
      fallback={AppError}
      resetError={() => {
        // Most instances of a catch all error can be handled with a page reload
        // as the HTML isn't cached so any missing files will be rectified at
        // this point.
        window.location.reload();
      }}
    >
      <Router>
        <Nav />
        <Switch>
          {/* Authentication flow routes */}
          <UnauthedRoute path={SIGN_IN_URL}><SignIn/></UnauthedRoute>
          <UnauthedRoute path={SIGN_UP_URL}><SignUp/></UnauthedRoute>
          <UnauthedRoute path={ACCESS_URL}><Access/></UnauthedRoute>
          <UnauthedRoute path={SUBSCRIBE_URL}><Subscribe/></UnauthedRoute>
          <UnauthedRoute path={EMAIL_VERIFICATION_URL}><EmailVerification/></UnauthedRoute>
          <UnauthedRoute path={FORGOT_PASSWORD_URL}><ForgotPassword/></UnauthedRoute>

          {/* Onboarding routes */}
          <AuthedRoute path={ONBOARDING_GOALS_URL}><OnboardingGoals/></AuthedRoute>
          <AuthedRoute path={ONBOARDING_CREATE_CATEGORY_URL}><OnboardingCreateCategory/></AuthedRoute>
          <AuthedRoute path={ONBOARDING_CATEGORY_DETAILS_URL}><OnboardingCategoryDetails/></AuthedRoute>

          {/* Logged in user routes */}
          <AuthedRoute path={PROFILE_URL}><Profile/></AuthedRoute>
          <AuthedRoute path={PLANNER_URL}><Planner/></AuthedRoute>
          <AuthedRoute path={PEOPLE_URL}><People/></AuthedRoute>
          <AuthedRoute path={CATEGORY_DETAIL_URL}><Dashboard/></AuthedRoute>
          <AuthedRoute path={BLOCK_DETAIL_URL}><Dashboard/></AuthedRoute>
          <AuthedRoute path={DASHBOARD_URL} exact><Dashboard/></AuthedRoute>

          {/* Unprotected routes */}
          <Route><NotFound /></Route>
        </Switch>
        <LocationObserver/>
      </Router>
      {showNotifications && <EventsNotifier/>}
    </ErrorBoundary>
  );
}

const AppWithContext = (props) => (
  <HelmetProvider>
    <AuthContextProvider>
      <CategoriesContextProvider>
        <DialogContextProvider>
          <ActionDialogContextProvider>
            <NotificationsContextProvider>
              <App {...props} />
            </NotificationsContextProvider>
          </ActionDialogContextProvider>
        </DialogContextProvider>
      </CategoriesContextProvider>
    </AuthContextProvider>
  </HelmetProvider>
);

export default AppWithContext;
