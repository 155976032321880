import React, { useMemo, forwardRef } from 'react';
import { ReactSortable } from "react-sortablejs";
import clsx from 'clsx';

import styles from './Dropzone.module.scss';

// These are dummy values that take the place of the list and list setter
// that a full ReactSortable list would need. Here we are only using it as a
// dropzone so they are static.
const DUMMY_LIST = [];
const DUMMY_LIST_SETTER = () => {};

function Dropzone({
  className,
  onAdd,
  allowDrop = [],
  clone,
  ...props
}) {
  // group is memoized to reduce ReactSortable rerenders
  const group = useMemo(() => {
    return {
      put: allowDrop
    };
  }, [allowDrop]);

  // This wrapper is required as data-* is not added as
  // an attribute when directly set on <ReactSortable/>
  const DropzoneWrapper = useMemo(() => {
    return forwardRef((props, ref) => (
      <div ref={ref} data-clone={clone} {...props} />
    ));
  }, [clone]);

  return (
    <ReactSortable
      className={clsx(className, styles.dropzone)}
      list={DUMMY_LIST}
      setList={DUMMY_LIST_SETTER}
      group={group}
      forceFallback={true}
      animation={300}
      onAdd={onAdd}
      tag={DropzoneWrapper}
    />
  );
}

export default Dropzone;
