import { COMPLETED, PERSON_NICKNAME_MAX_LENGTH, PERSON_DESCRIPTION_MAX_LENGTH, ACTIVE, SNOOZED } from "./constants";
import { firestore, collection, document, composeUnsubscribers, ParameterError, generateId, fieldArrayPush, CacheDelayer } from "./general";
import { Action } from "./actions";
import { trackEvent } from '../AnalyticsService';

export class Person {
  static fromFirestore(snapshot, options) {
    const data = snapshot.data(options);
    if (!data) return undefined;
    return Object.assign(new Person(), {
      id: snapshot.id,
      description: data.description,
      fullName: data.full_name,
      nickname: data.nickname,
      peopleGroupId: data.parent_id,
      image: data.background_image,
    });
  }
}

export async function getPeople() {
  const snapshot = await collection('people').get();
  return snapshot.docs.map(Person.fromFirestore);
}

export function watchPeople(callback) {
  return collection('people').orderBy('nickname', 'asc').onSnapshot(snapshot => {
    const people = snapshot.docs.map(Person.fromFirestore);
    callback(people);
  });
}

export function watchPerson(personId, callback) {
  return document(`people/${personId}`).onSnapshot(snapshot => {
    callback(Person.fromFirestore(snapshot));
  });
}

export function watchPersonActions(personId, type, completed, limit, callback) {
  if (completed && !limit)
    // Completed actions can be many thousands, must be fetched using pagination
    throw new ParameterError({limit}, 'must be > 0 when querying completed actions');

  let query = collection('actions');

  if (type === 'leveraged') {
    query = query.where('leveraged_to', '==', personId);
  } else if (type === 'mentioned') {
    query = query.where('mentions', 'array-contains', personId);
  } else {
    throw new ParameterError({type}, 'must "leveraged" or "mentioned"');
  }

  if (completed) {
    query = query.where('state', '==', COMPLETED);
  } else {
    query = query.where('state', 'in', [ACTIVE, SNOOZED]);
  }

  query = query
    .orderBy('is_starred', 'desc')
    .orderBy('date_of_completion', 'desc');

  if (limit) {
    // The extra action is used only to peek above the limit to see if there
    // are more actions
    query = query.limit(limit + 1);
  }

  const cacheDelayer = new CacheDelayer();
  const unsubscribe = query.onSnapshot(snapshot => {
    const actions = snapshot.docs.map(Action.fromFirestore);
    let hasMore = false;
    if (limit) {
      hasMore = actions.length > limit;
      // Don't return the extra action we used to peek above the limit
      if (hasMore) actions.pop();
    }
    cacheDelayer.delayIfCached(() => callback(actions, hasMore), snapshot);
  });
  return () => {
    unsubscribe();
    cacheDelayer.dispose();
  }
}

export function watchPersonCounters(personId, callback) {
  let leverages, mentions;

  function handleChange() {
    if (leverages === undefined || mentions === undefined) return;

    callback({leverages, mentions});
  }

  return composeUnsubscribers(
    collection('actions')
      .where('leveraged_to', '==', personId)
      .where('state', '==', ACTIVE)
      .onSnapshot(snapshot => {
        leverages = snapshot.docs.length;
        handleChange();
      }),

    collection('actions')
      .where('mentions', 'array-contains', personId)
      .where('state', '==', ACTIVE)
      .onSnapshot(snapshot => {
        mentions = snapshot.docs.length;
        handleChange();
      }),
  );
}

export function createPerson(nickname, peopleGroupId) {
  if (typeof nickname !== 'string')
    throw new ParameterError({nickname}, 'not a string');
  const nicknameTrim = nickname.trim();
  if (nicknameTrim === '')
    throw new ParameterError({nickname}, 'empty after trim');
  if (nicknameTrim.length > PERSON_NICKNAME_MAX_LENGTH)
    throw new ParameterError({nickname}, 'length must be <= ' + PERSON_NICKNAME_MAX_LENGTH);

  const id = generateId();
  const person = {
    id,
    description: null,
    full_name: null,
    nickname: nicknameTrim,
    parent_id: peopleGroupId,
  };

  const batch = firestore().batch();

  batch.set(document(`people/${id}`), person);
  batch.update(document(`people_groups/${peopleGroupId}`), {
    'people_list.ordered_ids': fieldArrayPush(id),
  });

  batch.commit();

  trackEvent('User created a Person', {
    id,
    nicknameLength: nicknameTrim.length,
    peopleGroupId,
  });

  return person;
}

export function updatePerson(person) {
  let {nickname} = person;
  if (nickname !== undefined) {
    if (typeof nickname !== 'string')
      throw new Error('nickname is not a string');
    nickname = nickname.trim();
    if (nickname === '')
      throw new Error('nickname is empty after trim');
    if (nickname.length > PERSON_NICKNAME_MAX_LENGTH)
      throw new Error('nickname length must be <= ' + PERSON_NICKNAME_MAX_LENGTH);
    person.nickname = nickname;
  }

  let {description} = person;
  if (description !== undefined && description !== null) {
    if (typeof description !== 'string')
      throw new Error('description must be a string or null');
    description = description.trim();
    if (description.length > PERSON_DESCRIPTION_MAX_LENGTH)
      throw new Error('description length must be <= ' + PERSON_DESCRIPTION_MAX_LENGTH);
    if (description === '') description = null;
    person.description = description;
  }

  document(`people/${person.id}`).update(person);
}
