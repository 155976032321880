import React from 'react';
import clsx from 'clsx';

import Tooltip from '../Tooltip';

import styles from './CharacterCounter.module.scss';

function CharacterCounter({
  className,
  count,
  max,
  ...props
}) {
  const getPercent = () => {
    if (count === max) return 100; // Ensures that no rounding issues cause misreported 99.9%
    const currentPercent = (count / max * 100).toFixed(2);
    return Math.min(Math.max(currentPercent, 0), 100);
  };

  const remaining = Math.max(0, max - count);
  const labelSuffix = ' characters remaining';

  // This prevents the tooltip from jumping around while the user is typing
  const tooltipTitle = (
    <span className={styles.tooltip}>
      <span><span className={styles.tooltipCounter}>{remaining}</span>{labelSuffix}</span>
      <span aria-hidden="true"><span className={styles.tooltipCounter}>{max}</span>{labelSuffix}</span>
    </span>
  );

  const getCounterState = () =>  {
    if (count === 0) return 'zero';
    if (count > max) return 'error';
    if (count >= max - 5) return 'warning';
    return null;
  };

  return (
    <Tooltip title={tooltipTitle}>
      <div
        {...props}
        className={clsx(className, styles.wrapper, styles[getCounterState()])}
        style={{
          '--character-percent': getPercent(),
        }}
        aria-label={`${remaining}${labelSuffix}`}
      >
        <svg role="presentation" className={styles.circle} width="32" height="32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
          <circle className={styles.rail} cx="16" cy="16" r="15" fillRule="evenodd"/>
          <circle className={styles.progress} cx="16" cy="16" r="15" fillRule="evenodd"/>
        </svg>
        <span className={styles.counter}>{max - count}</span>
      </div>
    </Tooltip>
  );
}

export default CharacterCounter;
