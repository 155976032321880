import { addWeeks, addHours, startOfWeek, isValid } from 'date-fns';
import { isURL } from 'validator';

import { mapById } from '../../utils';
import FirestoreRollingBatch from './FirestoreRollingBatch';
import {
  STARRED, UNSTARRED,
  ACTIVE, COMPLETED, SNOOZED,
  BLOCK_RESULT_MAX_LENGTH, BLOCK_ACTIONS_COMPLETION_THRESHOLD,
  EMPTY_STARRED_ORDERED_IDS,
  UNCATEGORIZED_ID,
  SNOOZED_TO_SOMETIME, SNOOZED_TO_WEEKS_MAP,
} from './constants';
import {
  firestore,
  collection, document,
  ParameterError,
  generateId,
  fieldArrayPush, fieldArrayRemove,
  createOrderedIdsPath,
  actionParentDocument,
  composeUnsubscribers,
} from './general';
import { tickBlocksCompleted } from './user';
import { Action, deleteActionHelper, setActionStateHelper } from './actions';
import { deleteEvent, Event } from './events';
import { trackEvent } from '../AnalyticsService';

export class Block {
  static fromFirestore(snapshot, options) {
    const data = snapshot.data(options);
    if (!data) return undefined;
    return Object.assign(new Block(), {
      id: snapshot.id,
      categoryId: data.parent_category_id,
      backgroundImage: data.background_image,
      orderedActionsIds: {
        [ACTIVE]: {
          [STARRED]: data.actions_lists?.[ACTIVE]?.[STARRED]?.ordered_ids || [],
          [UNSTARRED]: data.actions_lists?.[ACTIVE]?.[UNSTARRED]?.ordered_ids || [],
        },
        [COMPLETED]: {
          [STARRED]: data.actions_lists?.[COMPLETED]?.[STARRED]?.ordered_ids || [],
          [UNSTARRED]: data.actions_lists?.[COMPLETED]?.[UNSTARRED]?.ordered_ids || [],
        },
      },
      purpose: data.purpose,
      // Handles legacy blocks where name was required, but result was not
      result: data.result || data.name,
      role: data.role,
      state: data.state,
      // Ensures that it is always passed as a boolean
      starred: !!data.is_starred,
      // Handles legacy blocks where snoozed_to was undefined, but now expect null
      snoozedTo:
        data.snoozed_to ? new Date(data.snoozed_to) : null,
      completionDate:
        data.date_of_completion && new Date(data.date_of_completion),
      dueDate:
        data.date_due && new Date(data.date_due),
    });
  }
}

export async function getBlock(blockId) {
  const docSnapshot = await document(`blocks/${blockId}`).get();
  return Block.fromFirestore(docSnapshot);
}

export function watchBlock(blockId, callback) {
  const block = document(`blocks/${blockId}`);

  return block.onSnapshot(snapshot => {
    if (!snapshot.exists) return;

    callback(Block.fromFirestore(snapshot));
  });
}

export function watchBlockActions(blockId, callback) {
  return collection(`actions`)
    .where('parent_block_id', '==', blockId)
    .where('state', '==', ACTIVE)
    .onSnapshot(snapshot => {
      const actions = snapshot.docs.map(Action.fromFirestore);
      callback(actions);
  });
}

/**
 * Returns a boolean for whether a block is scheduled or not.
 * A block is treated as scheduled if any future events are associated with that block.
 * Past events are not valid for treating a block as scheduled.
 */
export function watchBlockScheduled(blockId, callback) {
  const now = new Date();
  return collection('events')
    .where('block_id', '==', blockId)
    .where('start_date', '>=', now.toISOString())
    .limit(1)
    .onSnapshot(snapshot => {
      callback(!snapshot.empty);
    });
}

export async function createBlock(categoryId, state, result, starred, snoozedToString = null) {
  if (state !== ACTIVE && state !== SNOOZED)
    throw new ParameterError({state}, `must be "${ACTIVE}" or "${SNOOZED}"`);
  if (typeof result !== 'string')
    throw new ParameterError({result}, 'not a string');
  const resultTrim = result.trim();
  if (resultTrim === '')
    throw new ParameterError({result}, 'empty after trim');
  if (resultTrim.length > BLOCK_RESULT_MAX_LENGTH)
    throw new ParameterError({result}, 'length must be <= ' + BLOCK_RESULT_MAX_LENGTH);

  if (typeof starred !== 'boolean')
    throw new ParameterError({starred}, 'must be a boolean');

  const snoozedToOptions = Object.keys(SNOOZED_TO_WEEKS_MAP);
  if (!snoozedToOptions.includes(snoozedToString) && snoozedToString !== null)
    throw new ParameterError({snoozedToString}, `must be ${snoozedToOptions.join(', ')} or null`);
  if (snoozedToString && state !== SNOOZED)
    throw new ParameterError({snoozedToString}, `cannot be used with any state other than "${SNOOZED}".`);
  if (state === SNOOZED && snoozedToString === null)
    throw new ParameterError({snoozedToString}, `cannot be null if state is "${SNOOZED}"`)

  const categoryDoc = await document(`categories/${categoryId}`).get();
  if (!categoryDoc.exists)
    throw new ParameterError({categoryId}, `category does not exist`);

  const id = generateId();
  const snoozedTo = state === SNOOZED ?
    calculateSnoozedToDate(snoozedToString, true) :
    null;

  const block = {
    id,
    background_image: null,
    result: resultTrim,
    parent_category_id: categoryId,
    parent_event_ids: [],
    actions_lists: {
      [ACTIVE]: EMPTY_STARRED_ORDERED_IDS,
      [COMPLETED]: EMPTY_STARRED_ORDERED_IDS,
    },
    purpose: '',
    role: '',
    state,
    snoozed_to: snoozedTo,
    is_starred: starred,
    due_date: null,
    date_of_completion: null,
  };

  const batch = firestore().batch();

  batch.set(document(`blocks/${id}`), block);
  batch.update(document(`categories/${categoryId}`), {
    [`blocks_lists.${state}.ordered_ids`]: fieldArrayPush(id),
  });

  batch.commit();

  trackEvent('User created a Block', {
    id,
    categoryId,
    state,
    starred,
    resultLength: resultTrim.length,
  });
}

function activeActionToCaptureList(batch, action) {
  // TODO: refactor setActionList so we can reuse that instead of this function
  const { id, blockId, categoryId, starred, state } = action;

  // For completed actions there is more complexity to manage
  if (state !== ACTIVE)
    throw new ParameterError({action}, `action state must be "${ACTIVE}"`);

  // Move action to capture list
  batch.update(document(`actions/${id}`), {
    parent_category_id: UNCATEGORIZED_ID,
    parent_block_id: null,
  });
  // Add it to the correct ordered list
  batch.update(document(`categories/${UNCATEGORIZED_ID}`), {
    [createOrderedIdsPath(state, starred)]: fieldArrayPush(id),
  });

  batch.update(actionParentDocument(categoryId, blockId), {
    [createOrderedIdsPath(state, starred)]: firestore.FieldValue.arrayRemove(id),
  });
}

export async function deleteBlock(blockId, saveActiveActions) {
  const batch = new FirestoreRollingBatch();

  // Better to process the actions and the events first, and delete the block as
  // last thing,  so if the block is very big (>400 actions) and the app crashes
  // after the first batch the data is still in a coherent state.

  const savedActions = new Set();

  const actionsSnap = await collection('actions')
    .where('parent_block_id', '==', blockId)
    .get();
  for (const actionSnap of actionsSnap.docs) {
    const action = Action.fromFirestore(actionSnap);
    if (saveActiveActions && action.state === ACTIVE) {
      activeActionToCaptureList(batch, action);
      savedActions.add(action.id);
    } else {
      // deleteActionHelper() is called with updateEvents=false because it has
      // no visibility on the other actions that are being deleted in this loop,
      // and so it cannot decide properly what to do with the linked events.
      // The next code block will take care of linked events.
      await deleteActionHelper(batch, action, false);
    }
  }

  const eventsSnap = await collection('events')
    .where('block_id', '==', blockId)
    .get();
  for (const eventSnap of eventsSnap.docs) {
    const { id: eventId, actionsIds } = Event.fromFirestore(eventSnap);
    const eventSavedActionsIds = actionsIds.filter(actionId => savedActions.has(actionId));
    if (eventSavedActionsIds.length > 0) {
      batch.update(eventSnap.ref, {
        category_id: UNCATEGORIZED_ID,
        block_id: null,
        actions_ids: eventSavedActionsIds,
      });
    } else {
      deleteEvent(eventId, batch);
    }
  }

  const { categoryId, state } = await getBlock(blockId);
  batch.delete(document(`blocks/${blockId}`));
  // Delete from the ordered list in the parent
  batch.update(document(`categories/${categoryId}`), {
    [`blocks_lists.${state}.ordered_ids`]: firestore.FieldValue.arrayRemove(blockId),
  });

  batch.commit();
}

async function setBlockCategoryHelper(batch, block, newCategoryId, newOrderedBlocksIds = null, newState = null, newSnoozedToString = null) {
  if (newOrderedBlocksIds !== null && !Array.isArray(newOrderedBlocksIds))
    throw new ParameterError({newOrderedBlocksIds}, 'not an array');

  if (newState === COMPLETED)
    throw new ParameterError({newState}, `cannot be "${COMPLETED}" as this does not handle counter changes.`);

  const {
    id: blockId,
    state: oldState,
    categoryId,
  } = block;

  const blockDoc = document(`blocks/${blockId}`);

  if (!newState) {
    newState = oldState;
  } else {
    batch.update(blockDoc, {
      state: newState,
    });
  }

  if (newSnoozedToString) {
    batch.update(blockDoc, {
      snoozed_to: calculateSnoozedToDate(newSnoozedToString, true),
    });
  }

  batch.update(blockDoc, {
    parent_category_id: newCategoryId,
  });
  batch.update(document(`categories/${categoryId}`), {
    [`blocks_lists.${oldState}.ordered_ids`]: fieldArrayRemove(blockId),
  });
  batch.update(document(`categories/${newCategoryId}`), {
    [`blocks_lists.${newState}.ordered_ids`]: newOrderedBlocksIds || firestore.FieldValue.arrayUnion(blockId),
    [`blocks_lists.${newState}.force`]: firestore.FieldValue.increment(1),
  });

  const childrenActionsSnap = await collection('actions')
    .where('parent_block_id', '==', blockId)
    .get();
  childrenActionsSnap.forEach(actionSnap => {
    batch.update(actionSnap.ref, { parent_category_id: newCategoryId });
  });

  const eventsSnap = await collection('events')
    .where('block_id', '==', blockId)
    .get();
  eventsSnap.docs.forEach(eventSnap => {
    batch.update(eventSnap.ref, { category_id: newCategoryId });
  });
}

export async function setBlock(blockId, categoryId, result, purpose = '', role = '', backgroundImage = null) {
  if (typeof result !== 'string')
    throw new ParameterError({result}, 'not a string');
  const resultTrim = result.trim();
  if (resultTrim === '')
    throw new ParameterError({result}, 'empty after trim');

  if (typeof purpose !== 'string')
    throw new ParameterError({purpose}, 'not a string');

  if (typeof role !== 'string' && role !== null)
    throw new ParameterError({role}, 'not a string');

  if (backgroundImage !== null && !isURL(backgroundImage))
    throw new ParameterError({backgroundImage}, 'not a URL or "null"');

  const block = await getBlock(blockId);
  const { categoryId: oldCategoryId } = block;

  const batch = new FirestoreRollingBatch();

  batch.update(document(`blocks/${blockId}`), {
    result: resultTrim,
    purpose,
    role,
    parent_category_id: categoryId,
    background_image: backgroundImage,
  });

  if (oldCategoryId !== categoryId) {
    await setBlockCategoryHelper(batch, block, categoryId);
  }

  batch.commit();
}

export function setBlockStarred(blockId, newStarred) {
  if (typeof newStarred !== 'boolean')
    throw new ParameterError({newStarred}, 'must be a boolean');

  document(`blocks/${blockId}`).update({ is_starred: newStarred });
};

export function setBlockDueDate(id, dueDate = null) {
  if (dueDate !== null && !isValid(dueDate))
    throw new ParameterError({dueDate}, 'can be only null or a valid date');

  document(`blocks/${id}`).update({
    date_due: dueDate ? dueDate.toISOString() : null,
  });
}

export const calculateSnoozedToDate = (snoozedToString, formatToISO) => {
  const snoozedToWeeks = SNOOZED_TO_WEEKS_MAP[snoozedToString];

  if (snoozedToString === SNOOZED_TO_SOMETIME) return snoozedToWeeks;

  const thisSunday = startOfWeek(new Date(), {
    // startOfWeek is localised by default, this sets the first day to Sunday
    // for everyone as per the requirements of the platform
    weekStartsOn: 0,
  });

  // startOfWeek returns the start of this week, so 1 is added to the number
  // of weeks to add to get offset from the next Sunday.
  let snoozedToDate = addWeeks(thisSunday, snoozedToWeeks + 1);
  // Sets the time to be 7AM on that Sunday
  snoozedToDate = addHours(snoozedToDate, 7);

  if (formatToISO) {
    snoozedToDate = snoozedToDate.toISOString();
  }

  return snoozedToDate;
}

export async function setBlockState(blockId, newState, activeActionsTo = null, newSnoozedTo = null) {
  const CAPTURE_LIST = 'captureList'
  const UNNECESSARY = 'unnecessary';

  if (![CAPTURE_LIST, UNNECESSARY, null].includes(activeActionsTo))
    throw new ParameterError({activeActionsTo}, `must be "${CAPTURE_LIST}", "${UNNECESSARY}" or null`);

  const snoozedToOptions = Object.keys(SNOOZED_TO_WEEKS_MAP);
  if (!snoozedToOptions.includes(newSnoozedTo) && newSnoozedTo !== null)
    throw new ParameterError({newSnoozedTo}, `must be ${snoozedToOptions.join(', ')} or null`);
  if (newSnoozedTo && newState !== SNOOZED)
    throw new ParameterError({newSnoozedTo}, `cannot be used with any state other than "${SNOOZED}".`);
  if (newState === SNOOZED && newSnoozedTo === null)
    throw new ParameterError({newSnoozedTo}, `cannot be null if state is "${SNOOZED}"`)

  const {
    categoryId,
    state: oldState,
    snoozedTo: oldSnoozedTo,
  } = await getBlock(blockId);

  // The state or snoozed_to date may be what is changing. If neither are changing
  // then the function can be returned here. Individual checks for both are made
  // after this to determine which updates to make to FireStore.
  if (oldState === newState && newSnoozedTo === oldSnoozedTo) return;

  const batch = new FirestoreRollingBatch();
  const blockDoc = document(`blocks/${blockId}`);

  if (oldState !== newState) {

    if (activeActionsTo) {
      const actionsSnap = await collection('actions')
        .where('parent_block_id', '==', blockId)
        .where('state', '==', ACTIVE)
        .get();
      actionsSnap.docs.forEach(actionSnap => {
        const action = Action.fromFirestore(actionSnap);
        if (activeActionsTo === CAPTURE_LIST) {
          activeActionToCaptureList(batch, action);
        } else if (activeActionsTo === UNNECESSARY) {
          setActionStateHelper(batch, action, COMPLETED, true);
        }
      });
    }

    batch.update(blockDoc, {
      state: newState,
      date_of_completion: newState === COMPLETED ? new Date().toISOString() : null,
    });

    batch.update(document(`categories/${categoryId}`), {
      [`blocks_lists.${oldState}.ordered_ids`]: firestore.FieldValue.arrayRemove(blockId),
      [`blocks_lists.${newState}.ordered_ids`]: firestore.FieldValue.arrayUnion(blockId),
    });

    if (oldState === COMPLETED || newState === COMPLETED) {
      // The completion state changed, update the global user counter
      tickBlocksCompleted(batch, newState === COMPLETED ? +1 : -1);
    }

    // When changing state from SNOOZED the snoozed_to date should be reset to nothing
    if (oldState === SNOOZED) {
      batch.update(blockDoc, {
        snoozed_to: null,
      });
    }
  }

  if (newState === SNOOZED && newSnoozedTo !== oldSnoozedTo) {
    batch.update(blockDoc, {
      snoozed_to: calculateSnoozedToDate(newSnoozedTo, true),
    });
  }

  batch.commit();
}

export async function blockHasIncompleteActions(blockId) {
  const actionsSnap = await collection('actions')
    .where('parent_block_id', '==', blockId)
    .where('state', '==', ACTIVE)
    .limit(1)
    .get();
  return actionsSnap.size > 0;
}

export async function blockHasEvents(blockId) {
  const eventsSnapshot = await collection('events')
    .where('block_id', '==', blockId)
    .limit(1)
    .get();

  return eventsSnapshot.size > 0;
}

export function setBlocksOrder(categoryId, state, newOrderedBlocksIds) {
  if (!Array.isArray(newOrderedBlocksIds))
    throw new ParameterError({newOrderedBlocksIds}, 'not an array');

  if (state !== ACTIVE && state !== SNOOZED && state !== COMPLETED)
    throw new ParameterError({state}, `must be "${ACTIVE}", "${SNOOZED}" or "${COMPLETED}"`);

  document(`categories/${categoryId}`).update({
    [`blocks_lists.${state}.ordered_ids`]: newOrderedBlocksIds,
  });
}

export async function setBlockCategory(blockId, newCategoryId, newOrderedBlocksIds = null, newState = null, newSnoozedToString) {
  const batch = new FirestoreRollingBatch();
  const block = await getBlock(blockId);
  await setBlockCategoryHelper(batch, block, newCategoryId, newOrderedBlocksIds, newState, newSnoozedToString);
  batch.commit();
}

export async function getBlockActions(blockId, categoryId) {
  const snapshot = await collection('actions')
    .where('parent_block_id' , '==', blockId)
    .where('parent_category_id' , '==', categoryId)
    .get();

  const actions = snapshot.docs.map(Action.fromFirestore);

  return mapById(actions);
}

export function watchBlockCompletionProgress(blockId, blockState, callback) {
  if (blockState !== ACTIVE)
    throw new ParameterError(`watchBlockCompletionProgress is only supported for "${ACTIVE}" blocks.`);

  let completedActions, block;

  function handleChange() {
    if (completedActions === undefined || block === undefined) return;

    const activeActionsLists = block?.orderedActionsIds?.[ACTIVE];

    const starredActiveActions = activeActionsLists?.[STARRED] || [];

    // If any active actions are starred the block is not ready for completion
    if (starredActiveActions.length) {
      callback(null);
      return;
    }

    const starredCompletedActions = completedActions.filter(action => action.starred);

    if (starredCompletedActions.length) {
      // If there are any starred completions the block is ready for completion as
      // we ruled out the presence of any unfinished starred actions above already
      callback('starred');
      return;
    }

    const activeActions = [
      ...starredActiveActions,
      ...activeActionsLists?.[UNSTARRED] || [],
    ];

    const completedActionsLen = completedActions.length;
    const totalActionsLen = completedActions.length + activeActions.length;

    // If there are no starred actions, completed or not then the user should be shown
    // the prompt if they have enough completed actions to meet the threshold for
    // assumed readiness
    const meetsThreshold = completedActionsLen / totalActionsLen >= BLOCK_ACTIONS_COMPLETION_THRESHOLD;

    callback(meetsThreshold ? 'threshold' : null);
  }

  return composeUnsubscribers(
    collection('actions')
      .where('parent_block_id', '==', blockId)
      .where('state', '==', COMPLETED)
      // This limit is required because the Firestore cost of calculating this become
      // prohibitively high with unlimited completed actions. This limit is high enough
      // that it is treated as the absolute number (even if the actual number is higher)
      // against which the threshold can be measured. 100 is an arbitrary number that is
      // high enough
      .limit(100)
      .onSnapshot(snapshot => {
        completedActions = snapshot.docs.map(Action.fromFirestore);
        handleChange();
      }),

    document(`blocks/${blockId}`).onSnapshot(docSnapshot => {
      block = Block.fromFirestore(docSnapshot);
      // Fail-safe to ensure that this is only processed if the block is active which mimics
      // the old ParameterError that was thrown under these conditions, however
      // the addition of the blockState param means that this shouldn't be called anyway (and
      // has been tested under this hypothesis).
      if (block.state === ACTIVE) {
        handleChange();
      }
    }),
  );
}
