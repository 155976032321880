import React, { useRef, useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { signIn, verifyEmail, NotAuthorizedError } from '../../services/AuthService';
import { NetworkError } from '../../services/ApiService';

import { SIGN_IN_URL, ONBOARDING_GOALS_URL } from '../App';
import Input from '../Input';
import Button, { BUTTON_COLOR_FILL } from '../Button';
import SmartLink from '../SmartLink';
import PageNoUi from '../PageNoUi';
import AuthForm from './AuthForm';

function EmailVerification() {
  const history = useHistory();

  const { username, email, password } = history.location.state || {};

  const [code, setCode] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const codeRef = useRef();

  const onSubmit = useCallback(async e => {
    e.preventDefault();

    if (!code) {
      setError('Code is required');
      codeRef.current.focus();
      return;
    }

    setLoading(true);
    try {
      await verifyEmail(username, code);
    } catch (e) {
      setLoading(false);
      if (e instanceof NetworkError) {
        setError('Network error, please check your internet connection and try again');
      } else if (e instanceof NotAuthorizedError) {
        setError('Invalid verification code, please try again');
      } else {
        setError('Unexpected error, please try again');
        console.error(e);
      }
      // Move the focus on the input so the user can make corrections or just
      // press Enter to try again
      codeRef.current.focus();
      return;
    }
    // If execution gets here the validation succeded, proceeed
    // with the login and redirect the user to the onboarding flow
    try {
      await signIn(username, password);
      history.push(ONBOARDING_GOALS_URL);
    } catch (e) {
      console.error(e);
      history.push(SIGN_IN_URL);
    }

  }, [history, username, password, code]);

  return (
    <PageNoUi
      pageTitle="Verify Email"
      text={`We’ve sent your activation code to ${email}. Please enter it below.`}
    >
      <AuthForm
        error={error}
        loading={loading}
        buttons={<>
          <Button linkTo={SIGN_IN_URL}>Cancel</Button>
          <Button
            type='submit'
            loading={loading}
            onClick={onSubmit}
            color={BUTTON_COLOR_FILL}
            block
          >
            Next
          </Button>
        </>}
        bottom={<>
          <SmartLink onClick={() => alert('Not yet enabled. Contact your administrator for support.')}>Resend verification code</SmartLink>
        </>}
      >
        <Input
          label='Activation code'
          type='text'
          autoFocus
          autoComplete='off'
          autoCorrect="off"
          autoCapitalize="off"
          spellCheck="false"
          ref={codeRef}
          value={code}
          onChange={e => setCode(e.target.value)}
        />
      </AuthForm>
    </PageNoUi>
  );
}

export default EmailVerification;
