import React, { useEffect, useState, useCallback } from 'react';
import { useHistory, generatePath } from 'react-router-dom';

import { replaceMultipleLineBreaks } from '../../utils';

import {
  CATEGORY_NAME_MAX_LENGTH, CATEGORY_FIELD_MAX_LENGTH, CATEGORY_FIELDS_MAP,
} from '../../services/DbService/constants';
import { watchCategory, getCategory, setCategory } from '../../services/DbService/categories';

import { CATEGORY_DETAIL_URL, ONBOARDING_CREATE_CATEGORY_URL } from '../App';
import Button, { BUTTON_COLOR_FILL } from '../Button';
import LoadingSpinner from '../LoadingSpinner';
import Input from '../Input';
import OnboardingPage from './OnboardingPage';

import styles from './Onboarding.module.scss';

function CategoryDetails() {
  const history = useHistory();

  const { categoryId } = history.location.state || {};

  const [newFields, setNewFields] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // To ensure that the same category is the one being manipulated here, if the user
    // has got to this page from anywhere but the previous page they are redirected
    // to the creation screen
    if (!categoryId) {
      history.push(ONBOARDING_CREATE_CATEGORY_URL);
      return;
    }

    return watchCategory(categoryId, category => {
      const categoryFields = {};

      for (const slug of Object.keys(CATEGORY_FIELDS_MAP)) {
        categoryFields[slug] = category[slug] || '';
      };

      setNewFields(categoryFields);
    });
  }, [history, categoryId]);

  const handleFieldChange = useCallback((slug, value) => {
    setNewFields(newFields => ({
      ...newFields,
      [slug]: replaceMultipleLineBreaks(value),
    }));
  }, []);

  const handleSubmit = useCallback(async (e) => {
    e.preventDefault();

    setLoading(true);

    // The latency with FireStore is so low, that without the timeout it feels like something
    // went wrong. This just delays the user long enough for it to feel deliberate.
    await new Promise(resolve => setTimeout(resolve, 500));

    const oldCategory = await getCategory(categoryId);

    const newCategory = {
      ...oldCategory,
      ...newFields,
    };

    setCategory(newCategory);

    history.push(generatePath(CATEGORY_DETAIL_URL, { categoryId }));
  }, [history, categoryId, newFields]);

  return (
    <OnboardingPage
      title="Magnificent 7"
      intro="Now it's time to dive deeper into precisely what you want in this Category of Improvement. While it takes some time to complete this, once it's done, you'll come back to this vision for each of your Categories every week when you do your RPM Weekly Plan."
    >
      <form onSubmit={handleSubmit}>
        {newFields === null && <LoadingSpinner absolute />}
        {newFields !== null && Object.entries(CATEGORY_FIELDS_MAP).map(([slug, field], index) => (
          <Input
            key={slug}
            placeholder={field.placeholder}
            label={field.label}
            type="text"
            tag="textarea"
            // Originally autoFocus was decided based on the first unfilled input, but that meant
            // that the page might jump after the inputs had loaded which felt janky, so focusing
            // the first item regardless of filled state was preferred.
            autoFocus={index === 0}
            autoComplete="off"
            autoCorrect="off"
            autoCapitalize="off"
            spellCheck="false"
            value={newFields[slug]}
            onChange={e => handleFieldChange(slug, e.target.value)}
            max={CATEGORY_NAME_MAX_LENGTH}
            minRows={2}
            maxRows={6}
            maxLength={CATEGORY_FIELD_MAX_LENGTH}
          />
        ))}

        <div className={styles.buttons}>
          <Button loading={loading} color={BUTTON_COLOR_FILL} block disabled={newFields === null}>Next</Button>
        </div>
      </form>
    </OnboardingPage>
  );
}

export default CategoryDetails;
