(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("axios"), require("crypto"), require("stream"));
	else if(typeof define === 'function' && define.amd)
		define(["axios", "crypto", "stream"], factory);
	else if(typeof exports === 'object')
		exports["sdk"] = factory(require("axios"), require("crypto"), require("stream"));
	else
		root["Kloudless"] = root["Kloudless"] || {}, root["Kloudless"]["sdk"] = factory(root["axios"], root["crypto"], root["stream"]);
})(typeof self !== 'undefined' ? self : this, function(__WEBPACK_EXTERNAL_MODULE__67__, __WEBPACK_EXTERNAL_MODULE__86__, __WEBPACK_EXTERNAL_MODULE__88__) {
return 