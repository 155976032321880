import React, { useEffect, forwardRef, useState, useRef } from 'react';
import clsx from 'clsx';

import InputLabel from './InputLabel';

import { ReactComponent as SelectArrow } from './select-arrow.svg';

import styles from './Input.module.scss';

export const INPUT_SIZE_REGULAR = 'regular';
export const INPUT_SIZE_SMALL = 'small';
export const INPUT_SIZE_LARGE = 'large';

const Input = forwardRef(({
  className,
  children,
  inputWrapperChildren,
  inlineLabel,
  label,
  value,
  onChange,
  tag,
  size = INPUT_SIZE_REGULAR,
  minRows = 2,
  maxRows = 3,
  style,
  visuallyHidden,
  ...props
}, ref) => {
  // Input requires a ref for managing the height of textareas etc, so when it is
  // omitted a local ref is used instead which removes the need for a seemingly
  // unused useRef() at the parent level
  const localRef = useRef();
  ref = ref || localRef;

  const InputTag = tag || 'input';

  const [filled, setFilled] = useState(false);
  const [mounted, setMounted] = useState(false);

  // Adding a class that stop transitions on .label while the component is mounting
  // and then removing it after one animation frame ensures that the input label doesn't
  // jump if there is a value that would mean .filled is applied.
  useEffect(() => {
    const handleMountAnimFrame = requestAnimationFrame(() => {
      setMounted(true);
    });
    return () => cancelAnimationFrame(handleMountAnimFrame);
  }, []);

  useEffect(() => {
    if (tag === 'textarea' && ref?.current) {
      // Resizes the textarea to allow it to only take up the space it needs
      ref.current.style.height = '';
      ref.current.style.height = `${ref.current.scrollHeight}px`;
    }
    setFilled(!!value);
  }, [ref, tag, value]);

  if (tag === 'textarea') {
    style = {
      '--textarea-min-rows': minRows,
      '--textarea-max-rows': maxRows,
      ...style,
    };
  }

  return (
    <label
      className={clsx(
        className,
        styles.field,
        visuallyHidden && styles.fieldVisuallyHidden,
        inlineLabel ? styles.inlineLabel : styles.regularLabel,
        size && styles[size],
        !inlineLabel && (filled || props.placeholder) && styles.filled,
        !inlineLabel && !mounted && styles.mounting,
      )}
      style={style}
    >
      {label && <InputLabel tag="span" size={size}>{label}</InputLabel>}
      <div className={styles.inputWrapper}>
        <InputTag
          ref={ref}
          value={value}
          onChange={onChange}
          {...props}
          className={styles.inputInput}
        >
          {children}
        </InputTag>
        <span className={styles.focusRing} aria-hidden="true" />

        {tag === 'select' &&
          <SelectArrow aria-hidden="true" className={styles.arrow} />
        }

        {inputWrapperChildren}
      </div>
    </label>
  );
});

Input.displayName = 'Input';

export default Input;
