import React, { useMemo } from 'react';
import Autolinker from 'autolinker';
import sanitizeHtml from 'sanitize-html';

import EventDialog from '../EventDialog';
import EmptyState from '../../EmptyState';

import styles from './ExternalEventDialog.module.scss';

const EXTERNAL_EVENT_DIALOG_HEIGHT = 250;

function ExternalEventDialog({
  event,
  eventRef,
  onClose,
  ...props
}) {
  const detailsHtml = useMemo(() => {
    if (!event.details) return;

    // Important: since the output of this function will be injected as HTML
    // in the DOM, we must ensure that it is robust to malicious tags.
    const sanitizedEventDetails = sanitizeHtml(event.details, {
      // We allow a very limited number of tags that are styled in a basic way,
      // but anything else that isn't potentially malicious is just treated as a text,
      // whereas <style>, <textarea> etc are just removed.
      // See https://www.npmjs.com/package/sanitize-html#discarding-the-entire-contents-of-a-disallowed-tag
      // for more info
      allowedTags: [
        'a',
        'strong', 'em', 'u',
        'br',
        'ul', 'ol', 'li',
        'p',
      ],
      // All attributes other than those listed here are removed to avoid styling and security issues
      allowedAttributes: {
        // These attributes need to be allowed, but are overwritten below in transformTags to set
        // the target to be _blank and the rel to match that.
        a: ['href', 'target', 'rel'],
      },
      transformTags: {
        b: 'strong',
        i: 'em',
        a: sanitizeHtml.simpleTransform('a', {
          target: '_blank',
          rel: 'noopener noreferrer',
        }, true),
      },
      allowProtocolRelative: false,
    });

    // Detect urls that are not already <a> tags and convert them into links. Autolinker
    // adds target="_blank" and the necessary rel attributes by default.
    return Autolinker.link(sanitizedEventDetails, {
      stripPrefix: false,
      stripTrailingSlash: false,
    });
  }, [event.details]);

  return (
    <EventDialog
      eventRef={eventRef}
      onClose={onClose}
      startDate={event.startDate}
      eventName={event.description}
      dialogHeight={EXTERNAL_EVENT_DIALOG_HEIGHT}
      {...props}
    >
      {event.details &&
        <div
          className={styles.content}
          dangerouslySetInnerHTML={{ __html: detailsHtml }}
        />
      }

      {!event.details &&
        <EmptyState className={styles.empty}>No details for this event.</EmptyState>
      }
    </EventDialog>
  );
}

export default ExternalEventDialog;
