import React, { useEffect, useState, useRef, useCallback, useMemo } from 'react';
import clsx from 'clsx';
import { ReactSortable } from "react-sortablejs";

import { watchActions, setActionList } from '../../services/DbService/actions';

import ActionItem from '../ActionItem';
import LoadingSpinner from '../LoadingSpinner';
import EmptyStateIcons from '../EmptyState/EmptyStateIcons';
import Divider from '../Divider';
import { ActionsCounters } from '../Counters';

import { ReactComponent as IconActions } from '../../assets/icons/16-actions.svg';

import { COMPLETED, PAGE_SIZE, STARRED, UNSTARRED } from '../../services/DbService/constants';
import LoadMoreButton from '../LoadMoreButton';

import styles from './ActionsList.module.scss';

function DraggableActionsList({
  actions,
  state,
  categoryId,
  blockId = null,
  manualSort,
  readonly,
  showNumbers,
  cardWithColor,
}) {
  const sortableRef = useRef();

  const [list, setList] = useState(actions);
  useEffect(() => setList(actions), [actions]);

  const calculating = useRef();
  const [itemHeights, setItemHeights] = useState(null);

  useEffect(() => {
    // Bug: changing the "disabled" prop on <ReactSortable> when
    // the component is already rendered does nothing: if it is rendered
    // disabled then it cannot be enabled again, and viceversa.
    // This effect solves the issues bypassing the React wrapper.
    sortableRef.current.sortable.option("disabled", readonly);
  }, [readonly]);

  const {
    starredIds,
    lastStarredActionIndex,
  } = useMemo(() => {
    const starredIds = [];
    let lastStarredActionIndex = -1;

    actions.forEach((action, index) => {
      if (action.starred) {
        lastStarredActionIndex = index;
        starredIds.push(action.id);
      }
    });

    return {
      lastStarredActionIndex,
      starredIds,
    };
  }, [actions]);

  const updateNumberHeights = useCallback((updateReason, delay = false) => {
    if (!showNumbers || calculating.current) return;

    calculating.current = true;

    // A small timeout is required to allow the UI to rerender, this ensures that there is no
    // jumpiness to the numbers. If this timeout isn't long enough (on rare occassions/slow connections),
    // the degraded result is only that the numbers are slightly out of line until the app
    // rerenders or a user drags within the list.
    const delayMs = delay ? 100 : 0;

    setTimeout(() => {

      // SortableJS uses the DOM rather than VDOM to handle the drag and drop interface, so
      // waiting for React to give updated values isn't quick enough. The Node element from
      // sortableRef is stable though, and all of our actions have data-id in them so can
      // be safely targeted as such here too.
      const listEl = sortableRef?.current?.ref?.current;
      // .sortable-drag is the class added to the fallback item created during dragging, so
      // should be ignored from the action items when calculating their heights
      const actionItems = listEl?.querySelectorAll('[data-id]:not(.sortable-drag)') || [];

      // The recalculations for resizing should be ignored when items are dragged from
      // one list to another (i.e. where the actionsItems don't match the list items)
      // to avoid the calculations being made twice – they are already handled by the
      // change callback
      if (
        (updateReason === 'resize' && actionItems.length === list.length) ||
        (updateReason === 'change')
      ) {
        const heights = [];

        actionItems.forEach(item => {
          heights.push(item.clientHeight);
        });

        setItemHeights(heights);
      }

      setTimeout(() => {
        calculating.current = false;
      }, 10);
    }, delayMs);
  }, [showNumbers, sortableRef, list]);

  const onUpdate = useCallback((e) => {
    const sortedIdsArray = sortableRef.current.sortable.toArray();

    const actionId = e.item.dataset.id;
    const oldStarred = e.item.dataset.starred === 'true';

    const starredActions = [];
    const unstarredActions = [];

    // If there are no starred actions to begin with, this is set to false
    // to avoid any unintended starring.
    let iteratingThroughStarred = lastStarredActionIndex > -1;

    sortedIdsArray.forEach((id, index) => {
      const isStarred = starredIds.includes(id);

      // If the item being dragged is starred, the new starred state is
      // set to be true if the index is higher or equal to the lastStarredActionIndex
      // so that if it is dragged to the position of the current last starred item
      // the starred state is retained. This condition is different for unstarred actions
      // so that if an item is dragged to within 1 of the lastStarredActionIndex
      // it is not treated as starred.
      const indexComparison = oldStarred ?
        index >= lastStarredActionIndex :
        index > lastStarredActionIndex;

      if (!iteratingThroughStarred || (!isStarred && indexComparison)) {
        iteratingThroughStarred = false;

        unstarredActions.push(id);
      } else {
        starredActions.push(id);
      }
    });

    const newStarred = starredActions.includes(actionId);
    const newOrderIds = newStarred ? starredActions : unstarredActions;

    setActionList(actionId, categoryId, blockId, state, newStarred, newOrderIds);
  }, [starredIds, state, blockId, categoryId, lastStarredActionIndex]);

  const onAdd = useCallback(e => {
    const actionId = e.item.dataset.id;
    const sortedIdsArray = sortableRef.current.sortable.toArray();
    const starred = e.newIndex <= lastStarredActionIndex;

    const newOrderIds = sortedIdsArray.filter((id, index) => {
      // The starred state of the action being dragged is determined by
      // comparing the index of where it is dragged to versus the index of
      // the last starred item.
      const isStarred = id === actionId ?
        starred :
        starredIds.includes(id);

      // Because action lists are made up of two lists (STARRED and UNSTARRED)
      // only one needs to change when the item is added. The isStarred value
      // is used to decide which to manipulate, so actions that don't match
      // that starred state are ignored.
      return isStarred === starred;
    });

    setActionList(actionId, categoryId, blockId, state, starred, newOrderIds);
  }, [starredIds, categoryId, blockId, state, lastStarredActionIndex]);

  // This handler is called when items are dragged within a list, or from list to list
  const handleChange = useCallback(() => {
    updateNumberHeights('change');
  }, [updateNumberHeights]);

  // The ResizeObserver allows tracking when changes happen to the actions – like
  // leveraging – that affect their overall height. It would be more robust to
  // watch the changes from the ActionItems themselves, but that requires one
  // observer per action which is very unperformant.
  // In reality there are very few (if any) instances where the height would change
  // for multiple actions in such a way that the parent didn't change height at all
  // so this is a safe way of observing that in a more performant fashion.
  useEffect(() => {
    const listEl = sortableRef?.current?.ref?.current;

    let resizeObserver;

    if (!updateNumberHeights || !listEl) {
      resizeObserver && resizeObserver.unobserve(listEl);
      return;
    }

    resizeObserver = new ResizeObserver(entries => {
      updateNumberHeights('resize', true);
    });

    resizeObserver.observe(listEl);
  }, [updateNumberHeights]);

  // When the list changes (i.e. a completed action is marked as active)
  // this useEffect ensures that the number heights are updated accordingly.
  // This is different to the handleChange above as it is most likely
  // when it rerenders, hence the delay to allow the DOM to be ready.
  useEffect(() => {
    updateNumberHeights('change', true);
  }, [list, updateNumberHeights]);

  return (
    <div
      className={clsx(
        styles.draggableWrapper,
        showNumbers && styles.draggableWrapperNumbers,
      )}
    >
      {showNumbers &&
        <ul aria-hidden="true" className={styles.sortableNumbers}>
          {itemHeights?.map((height, index) => (
            <li
              key={'number' + index}
              style={{
                '--number-height': height,
              }}
            >{index + 1}.</li>
          ))}
        </ul>
      }
      <ReactSortable
        className={clsx(styles.sortable, manualSort && styles.manualSort)}

        ref={sortableRef}
        filter=".sortable-ignore"
        preventOnFilter={false}
        list={list}
        setList={setList}
        forceFallback={true}
        group={{
          name: 'actions',
          pull(to, from) {
            return to.el.dataset.clone ? 'clone' : true;
          },
        }}
        animation={300}
        delay={50}
        onUpdate={onUpdate}
        onAdd={onAdd}
        onChange={handleChange}
        sort={manualSort}
        tag="ul"
      >
        {list.map((action, index) => (
          <ActionItem
            key={action.id}
            action={action}
            data-id={action.id}
            // You can drag and drop both actions and blocks inside a cateogry header,
            // so this field is needed to recognise which is which
            data-type="action"
            data-category-id={categoryId}
            data-starred={action.starred}
            data-state={state}
            data-index={index + 1}
            readonly={readonly}
            cardWithColor={cardWithColor}
          />
        ))}
      </ReactSortable>
    </div>
  );
}

function ActionsList({
  className,
  categoryId,
  blockId,
  state,
  readonly = false,
  emptyState,
  showCounter,
  cardWithColor,
  showNumbers,
  onFetch,
  ...props
}) {
  const [actions, setActions] = useState(null);
  const [limit, setLimit] = useState(PAGE_SIZE);
  const [hasMore, setHasMore] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);

  useEffect(() => {
    return watchActions(categoryId, blockId, state, limit, (actions, hasMore) => {
      setLoadingMore(false);
      setActions(actions);
      setHasMore(hasMore);

      if (onFetch) {
        onFetch(actions);
      }
    });
  }, [categoryId, blockId, state, limit, onFetch]);

  const handleLoadMoreClick = useCallback(() => {
    setLoadingMore(true);
    setLimit(limit => limit + PAGE_SIZE);
  }, []);

  const allActions = useMemo(() => {
    return [
      ...actions?.[STARRED] || [],
      ...actions?.[UNSTARRED] || [],
    ];
  }, [actions]);

   // Completed actions cannot be rearranged manually: no sorting for them
  const manualSort = state !== COMPLETED;

  if (actions === null) {
    return (
      <div className={clsx(className, styles.wrapper)} {...props}>
        <LoadingSpinner className={styles.loader} absolute />
      </div>
    );
  }

  return (
    <div className={clsx(className, styles.wrapper)} {...props}>
      {emptyState && !allActions.length &&
        <EmptyStateIcons icons={[IconActions]}>
          You have no actions captured. <br/>Add one via the button below.
        </EmptyStateIcons>
      }

      <DraggableActionsList
        actions={allActions}
        state={state}
        categoryId={categoryId}
        blockId={blockId}
        manualSort={manualSort}
        readonly={readonly}
        showNumbers={showNumbers}
        cardWithColor={cardWithColor}
      />

      {showCounter && !!allActions.length &&
        <div className={styles.dividerWithCounter}>
          <ActionsCounters actions={allActions} />
          {/* Deliberately put after the counter to use the <hr/> role correctly */}
          <Divider />
        </div>
      }

      {hasMore &&
        <LoadMoreButton loading={loadingMore} onClick={handleLoadMoreClick} />
      }
    </div>
  );
}

export default ActionsList;
