import React, { forwardRef, useState, useCallback, useEffect, useMemo } from 'react';
import clsx from 'clsx';
import useUniqueId from '../../hooks/useUniqueId';
import { mapById, replaceMentions, encode } from '../../utils';
import PeopleSuggester from '../PeopleSuggester';
import styles from './Autocomplete.module.scss';

function createMentionTag(personId, nickname) {
  const tag = document.createElement('span');
  tag.textContent = `@${nickname || personId}`;
  tag.dataset.personId = personId;
  tag.className = 'mention';
  // These elements are much easier to manage when they aren't contentEditable
  tag.contentEditable = false;
  return tag;
}

const Autocomplete = forwardRef(({
  value,
  onChange,
  placeholder,
  maxLength,
  onEnterKeyDown,
  // People is asked as a prop so it can be held by the parent
  // and be immediately available when this component instantiates,
  // otherwise the component can't decode mentions from the very
  // first render, creating visible lags (action edit use case).
  people,
  className,
  ...props
}, ref) => {
  const suggesterId = useUniqueId();
  const [suggesterQuery, setSuggesterQuery] = useState(null);
  const [suggesterStyle, setSuggesterStyle] = useState({});
  const suggesterVisible = suggesterQuery !== null;
  const [caretInfo, setCaretInfo] = useState(null);
  const remainingChars = useMemo(() => {
    return maxLength - replaceMentions(value, () => '').length;
  }, [maxLength, value]);

  const peopleMap = useMemo(() => mapById(people), [people]);

  const updateCaretInfo = useCallback(() => {
    const selection = document.getSelection();
    // Set to null if
    // - contentEditable is not the focused element
    // - selection is not 'caret', but is a range of text/elements
    // - caret is not inside a text node
    if (document.activeElement !== ref.current
        || selection.type !== 'Caret'
        || selection.anchorNode.nodeType !== Node.TEXT_NODE) {
      setCaretInfo(null);
      return;
    }

    const range = selection.getRangeAt(0);
    const text = range.startContainer.textContent;
    const wordStart = text.lastIndexOf(' ', range.startOffset - 1) + 1;
    let wordEnd = text.indexOf(' ', range.startOffset);
    if (wordEnd === -1) wordEnd = text.length;
    const word = text.substring(wordStart, wordEnd);
    if (word[0] !== '@') {
      // The word around the caret is not a potential mention,
      // return null
      setCaretInfo(null);
      return;
    }

    // mentionRange is a range including the nickname and the initial "@"
    const mentionRange = document.createRange();
    mentionRange.setStart(range.startContainer, wordStart);
    mentionRange.setEnd(range.startContainer, wordEnd);
    // to get the nickname just skip the "@" char at the beginning
    const nickname = word.substring(1);
    // ending is true if the mention is the last word
    // in the contentEditable
    const ending = (wordEnd === text.length);
    setCaretInfo({mentionRange, nickname, ending});
  }, [ref]);

  // Parses the DOM in the contentEditable and returns the action's
  // description string, with the mentions properly serialized
  const extractCurrentValue = useCallback(() => {
    let value = '';
    ref.current.childNodes.forEach(node => {
      if (node.nodeType === Node.TEXT_NODE) {
        value += encode(node.textContent);
      } else if (node.dataset.personId) {
        value += `<@${node.dataset.personId}>`;
      } else {
        // Only text nodes and mention spans are allowed, execution
        // should never arrive here
      }
    });
    return value;
  }, [ref]);

  // Replaces the contents of the contentEditable with the new
  // action description provided
  const applyNewValue = useCallback(newValue => {
    // This function deals with the DOM, better to save a run if
    // the content is not going to change
    if (newValue === extractCurrentValue()) return;

    const newValueHtml = replaceMentions(newValue, personId => {
      const person = peopleMap?.[personId];
      return createMentionTag(personId, person?.nickname).outerHTML;
    });
    ref.current.innerHTML = newValueHtml;

    // Move caret at the end of the contentEditable
    const range = document.createRange();
    range.selectNodeContents(ref.current);
    range.collapse(false);
    const selection = window.getSelection();
    selection.removeAllRanges();
    selection.addRange(range);
    updateCaretInfo();
  }, [ref, peopleMap, extractCurrentValue, updateCaretInfo]);

  // Apply the new value coming from the props
  useEffect(() => applyNewValue(value), [applyNewValue, value]);

  // Autofocus on component instantiation
  useEffect(() => ref.current.focus(), [ref]);

  // Simulate onChange event on the contentEditable using a MutationObserver
  useEffect(()=>{
    function handleChange() {
      // Perform some cleanup at every content change.
      // - Firefox sometimes adds empty text nodes, that make the backspace
      //   bug even harder to fix: remove them.
      // - Firefox (and Safari too) sometimes add <br> elements: we don't
      //   need them and they mess with our code.
      ref.current.childNodes.forEach(node => {
        if (node.tagName === 'BR'
            || (node.nodeType === Node.TEXT_NODE && node.textContent === '')) {
          node.remove();
        }
      });
      onChange(extractCurrentValue());
    }
    const observer = new MutationObserver(handleChange);
    observer.observe(ref.current, {characterData: true, childList: true, subtree: true});
    return () => observer.disconnect();
  }, [ref, onChange, extractCurrentValue]);

  // Runs every time caretInfo changes and shows/hides the PeopleSuggester
  useEffect(() => {
    if (!caretInfo) {
      // Caret position does not qualify as a potential mention:
      // hide the PeopleSuggester setting the query to null
      setSuggesterQuery(null);
      return;
    }
    // Get the coords of the word under the caret and position the
    // PeopleSuggester
    const {mentionRange, nickname} = caretInfo;
    const mentionRect = mentionRange.getBoundingClientRect();
    setSuggesterStyle({
      top: `${mentionRect.bottom + 10}px`,
      left: `${mentionRect.left}px`
    });
    // Setting the query shows the PeopleSuggester
    setSuggesterQuery(nickname.toLowerCase());
  }, [caretInfo]);

  const handleKeyDown = useCallback(e => {
    switch (e.key) {
      case 'Enter':
        if (suggesterVisible) {
          // The Enter must be processed by the PeopleSuggester
          // and not by this handler: exit
          break;
        }
        e.preventDefault();
        if (onEnterKeyDown) onEnterKeyDown(e);
        break;

      case 'Backspace':
        // Fix backspace bug in Firefox
        // https://bugzilla.mozilla.org/show_bug.cgi?id=685445

        var range = window.getSelection().getRangeAt(0);
        // Fix is needed only when the range is collapsed (caret mode)
        if (!range?.collapsed) break;

        let elementToRemove;
        if (range.startContainer === ref.current && range.startOffset > 0) {
          // The range container is the whole input field and not one
          // of its text nodes, so startOffset is the index of
          // a child, not the index of a character.
          const prev = ref.current.childNodes[range.startOffset - 1];
          if (prev.contentEditable === 'false') {
            elementToRemove = prev;
          }
        } else if (range.startOffset === 0
            && range.startContainer.previousSibling?.contentEditable === 'false') {
          // Caret is at the beginning of a text node, right after
          // a non-contentEditable element
          elementToRemove = range.startContainer.previousSibling;
        }

        if (elementToRemove) {
          e.preventDefault();
          elementToRemove.remove();
        }

        break;

      default:
        // Prevent the user from typing if we already reached the input max size
        if (remainingChars <= 0
            // https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/key
            // "If the pressed key has a printed representation, "key" is a
            // non-empty Unicode character string containing the printable
            // representation of the key."
            // So if length==1 it is a printable character
            && e.key.length === 1
            && !(e.metaKey || e.altKey || e.ctrlKey) // No modifier keys pressed
            ) {
          e.preventDefault();
        }
    }

    // Let the event be normally processed, then afterwards update
    // the caret info
    setTimeout(() => {
      // When user presses ESC in the ActionDialog React unmounts the
      // component before the exectution of this function: check that
      // the component is still mounted before proceeding.
      if (!ref.current) return;
      updateCaretInfo();
    }, 0);
  }, [ref, suggesterVisible, updateCaretInfo, onEnterKeyDown, remainingChars]);

  // Paste only text/plain data, with no newlines and without exceeding
  // the maxLength
  const handlePaste = useCallback(e => {
    e.preventDefault();
    if (remainingChars <= 0) return;
    const sanitized = e.clipboardData.getData('text/plain')
      .replace('\n', '') // Remove newlines chars
      .substring(0, remainingChars); // Ensure we don't get past the max length
    document.execCommand('inserttext', false, sanitized);
    updateCaretInfo();
  }, [remainingChars, updateCaretInfo]);

  const handleBlur = useCallback(e => {
    // Don't call updateCaretInfo() if the blur happened because of a
    // click in the PeopleSuggester, otherwise caretInfo will be set to
    // null before handleSuggesterConfirm can use it to apply the mention
    // (the blur event runs before the click event that caused it 🤷‍♂️)
    if (e.relatedTarget?.closest(`[id='${suggesterId}']`)) return;
    updateCaretInfo();
  }, [suggesterId, updateCaretInfo]);

  const handleSuggesterConfirm = useCallback(person => {
    if (!caretInfo) return;
    const {mentionRange, ending} = caretInfo;

    const range = mentionRange.cloneRange();
    range.deleteContents();

    const mention = createMentionTag(person.id, person.nickname);
    if (ending) {
      const space = document.createTextNode(' ');
      range.insertNode(space);
      range.insertNode(mention);
      range.setStartAfter(space, 0);
    } else {
      range.insertNode(mention);
      range.setStartAfter(mention, 0);
    }

    // The contentEditable loses focus when the user clicks on an
    // option in the PeopleSuggester.
    // This restores the focus with the caret in the right place
    const selection = document.getSelection();
    selection.removeAllRanges();
    selection.addRange(range);

    setSuggesterQuery(null);
    updateCaretInfo();
  }, [caretInfo, updateCaretInfo]);

  return (
    <>
      <div
        contentEditable
        aria-autocomplete='both'
        role='textbox'
        aria-owns={suggesterId}
        spellCheck={true}
        data-placeholder={placeholder}
        onKeyDown={handleKeyDown}
        onPaste={handlePaste}
        onClick={updateCaretInfo}
        onFocus={updateCaretInfo}
        onBlur={handleBlur}
        className={clsx(styles.autocomplete, className)}
        ref={ref}
        {...props}
      />
      <span className={styles.focusRing} aria-hidden="true" />
      <PeopleSuggester
        id={suggesterId}
        hidden={!suggesterVisible}
        query={suggesterQuery}
        onConfirm={handleSuggesterConfirm}
        className={styles.peopleSuggester}
        style={suggesterStyle}
      />
    </>
  );
});

Autocomplete.displayName = 'Autocomplete';

export default Autocomplete;
