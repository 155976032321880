import React, { useEffect, useState, useContext, useMemo, useRef, useCallback } from 'react';
import { generatePath, useRouteMatch, useHistory } from 'react-router-dom';
import clsx from 'clsx';

import { NBSP } from '../../../utils.js';

import {
  ALL_CATEGORY_ID, UNCATEGORIZED_ID,
  ACTIVE, STATE_LABELS,
} from '../../../services/DbService/constants';
import { watchCategoryActiveActions } from '../../../services/DbService/categories';

import useUniqueId from '../../../hooks/useUniqueId';

import { DASHBOARD_URL, CATEGORY_DETAIL_URL } from '../../App';
import Button from '../../Button';
import { ActionsCounters } from '../../Counters';
import ContextualMenu, {
  ContextualMenuTrigger, ContextualMenuLink, ContextualMenuCategories,
} from '../../ContextualMenu';
import CreateCategoryDialog from '../../CreateDialog/CreateCategoryDialog';
import { DashboardContext } from '../../Dashboard';
import Heading, { HEADING_LEVEL_2, HEADING_LEVEL_5 } from '../../Heading';

import { ReactComponent as IconExpand } from '../../../assets/icons/16-expand.svg';
import { ReactComponent as IconNext } from '../../../assets/icons/16-next.svg';
import { ReactComponent as IconClose } from '../../../assets/icons/16-close.svg';

import styles from './DashboardSidebarHeader.module.scss';

const ACTIVE_LABEL = STATE_LABELS[ACTIVE].subheading;

function DashboardCategoryHeader({
  activeCategories,
  hiddenCategories,
}) {
  const history = useHistory();

  const {
    activeCategoryId: categoryId,
    setActiveCategoryId,
  } = useContext(DashboardContext);

  const categoriesMenuButtonRef = useRef();
  const categoriesMenuButtonId = useUniqueId();
  const [categoriesMenuVisible, setCategoriesMenuVisible] = useState(false);

  const [actions, setActions] = useState([]);
  const [categoryDialogVisible, setCategoryDialogVisible] = useState(false);

  useEffect(() => {
    if (!categoryId !== ALL_CATEGORY_ID) return;

    return watchCategoryActiveActions(categoryId, true, setActions);
  }, [categoryId]);

  const isCategoryDetailRoute = useRouteMatch({
    path: generatePath(CATEGORY_DETAIL_URL, { categoryId }),
    exact: true,
  });

  const currentCategory = useMemo(() => {
    const categories = [
      ...activeCategories || [],
      ...hiddenCategories || [],
    ];

    return categories.find(category => categoryId === category.id);
  }, [activeCategories, hiddenCategories, categoryId]);

  const handleCreateNewCategoryClick = useCallback(() => {
    setCategoryDialogVisible(true);
    setCategoriesMenuVisible(false);
  }, []);

  const handleSetActiveCategory = useCallback((id) => {
    setActiveCategoryId(id);
    setCategoriesMenuVisible(false);
    categoriesMenuButtonRef.current && categoriesMenuButtonRef.current.focus();
  }, [setActiveCategoryId]);

  const handleCreateCategoryDialogClose = useCallback(() => {
    setCategoryDialogVisible(false);
    categoriesMenuButtonRef.current && categoriesMenuButtonRef.current.focus();
  }, []);

  const handleCategoryCreated = useCallback((id) => {
    setActiveCategoryId(id);
    history.push(generatePath(CATEGORY_DETAIL_URL, { categoryId: id }));
  }, [setActiveCategoryId, history]);

  const isThisWeek = categoryId === ALL_CATEGORY_ID;

  return (
    <>
      <div className={styles.header}>
        <ContextualMenuTrigger
          setVisible={setCategoriesMenuVisible}
          visible={categoriesMenuVisible}
          menuId={categoriesMenuButtonId}
        >
          <Heading
            ref={categoriesMenuButtonRef}
            className={clsx(styles.title, isThisWeek && styles.titleThisWeek)}
            tag="button"
            level={HEADING_LEVEL_2}
          >
            <IconExpand className={styles.titleArrow} role="presentation" />
            {/* Rather than have complicated styling for the case when the label is hidden
                or shown, the fallback is a non-breaking space with aria-hidden to keep the
                line there without affecting screenreaders. */}
            <Heading
              className={styles.titleLabel}
              level={HEADING_LEVEL_5}
              tag="span"
              aria-hidden={isThisWeek}
            >
              {isThisWeek ? NBSP : 'Category'}
            </Heading>
            <span className={styles.titleText}>{isThisWeek ? ACTIVE_LABEL : currentCategory?.name}</span>
          </Heading>
        </ContextualMenuTrigger>

        {categoriesMenuVisible &&
          <ContextualMenu
            onClose={() => setCategoriesMenuVisible(false)}
            buttonRef={categoriesMenuButtonRef}
            aria-labelledby={categoriesMenuButtonId}
          >
            <ContextualMenuLink
              className={styles.dropdownThisWeek}
              onClick={() => handleSetActiveCategory(ALL_CATEGORY_ID)}
              category
            >
              {ACTIVE_LABEL}
            </ContextualMenuLink>

            <ContextualMenuCategories
              onCategoryClick={handleSetActiveCategory}
              onCreateClick={handleCreateNewCategoryClick}
            />
          </ContextualMenu>
        }

        {categoryDialogVisible &&
          <CreateCategoryDialog
            onClose={handleCreateCategoryDialogClose}
            onCreate={handleCategoryCreated}
          />
        }

        {categoryId !== UNCATEGORIZED_ID && !isThisWeek &&
          <Button
            className={styles.detailsButton}
            iconOnly
            linkTo={isCategoryDetailRoute ?
              DASHBOARD_URL :
              generatePath(CATEGORY_DETAIL_URL, { categoryId })
            }
            tooltip
            aria-label={`${isCategoryDetailRoute ? 'Close' : 'Open'} Category Details`}
          >
            {isCategoryDetailRoute ?
              <IconClose role="presentation" /> :
              <IconNext role="presentation" />
            }
          </Button>
        }
      </div>

      {!isThisWeek &&
        <ActionsCounters className={styles.counters} actions={actions} />
      }
    </>
  );
}

export default DashboardCategoryHeader;
