import * as firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import { v4 as uuidv4 } from 'uuid';
import { mapById } from "../../utils";
import {
  STARRED, UNSTARRED,
  ACTIVE, SNOOZED,
  CAPTURE_LIST_ACTIONS,
  UNCATEGORIZED_ID,
  ENABLE_FIRESTORE_PERSISTENCE,
} from "./constants";
import { Category } from "./categories";
import { Block } from "./blocks";
import { Action } from "./actions";

export class ParameterError extends Error {
  constructor(obj, message) {
    // obj is meant to be a quick way to pass both param name and value, if
    // you use it this way: new ParameterError({param}, 'error!');
    const [paramName, paramValue] = Object.entries(obj)[0];
    super(`Error on parameter \`${paramName}\` with value \`${paramValue}\` (${typeof paramValue}): ${message}`);
  }
}

export const firestore = firebase.firestore;

const getUserUid = () => firebase.auth().currentUser.uid;

// Gets a document or collection providing the relative path from the user
export const document = path => firestore().doc(`/users/${getUserUid()}/${path || ''}`);
export const collection = path => firestore().collection(`/users/${getUserUid()}/${path || ''}`);

export const actionParentDocument = (parentCategoryId, parentBlockId) => {
  const collectionName = parentBlockId ? 'blocks' : 'categories';
  const id = parentBlockId || parentCategoryId;
  return document(`${collectionName}/${id}`);
}

export const fieldArrayPush = (...values) => firebase.firestore.FieldValue.arrayUnion(...values);
export const fieldArrayRemove = (...values) => firebase.firestore.FieldValue.arrayRemove(...values);

// iOS uses uppercase whereas this generator uses lowercase
export const generateId = () => uuidv4().toUpperCase();

/**
 * Returns a function that calls all the unsubscribers passed as parameters.
 * Arrays of unsubscribers are supported too.
 */
export function composeUnsubscribers(...unsubscribers) {
  unsubscribers = unsubscribers.flat();
  return () => {
    for (const unsubscribe of unsubscribers) unsubscribe();
  }
}

export function getSortedDocuments(documentsById, order) {
  const sortedDocuments = [];
  for (const id of order) {
    const document = documentsById[id];
    // Sometimes order contains an id that is not present in documentsById.
    // This happens for example when a new document is added and the new
    // order with the new id arrives before the actual new document.
    // Since the documents and the order are collected using two separate
    // observables these race conditions can happen.
    // I simply skip these instances, they will be automatically fixed
    // when all the data is collected from all the observable.
    if (document !== undefined) sortedDocuments.push(document);
  }
  return sortedDocuments;
}

export const createOrderedIdsPath = (state, starred) => {
  return `actions_lists.${state}.${starred ? STARRED : UNSTARRED}.ordered_ids`;
};

export class CacheDelayer {

  delayIfCached(fn, snapshot) {
    // When you call `onSnapshot()`, Firestore immediately sends to the callback
    // the data it has in cache, so you have already some data while you wait the
    // full updated payload from the server. But when persistence is disabled,
    // the data in the cache is very little. This was creating a bug on paginated
    // lists: there's a list with 25 elements, the user clicks "Load More", the
    // list goes to something like 3 docs (the cached ones), and then eventually
    // goes to 50 when the server replies. This flickers the view and messes up
    // the page scroll. To fix this, the cached docs are delayed on paginated
    // lists when persistence is disabled. We tried to discard the cache data
    // entirely in those instances, but when lists have just a few docs it happens
    // that Firestore uses only the cache without never calling the server.
    clearTimeout(this.timeout);
    if (!ENABLE_FIRESTORE_PERSISTENCE && snapshot.metadata.fromCache) {
      this.timeout = setTimeout(fn, 500);
    } else {
      fn();
    }
  }

  dispose() {
    // It's important to cancel pending calls if the parent observer is released
    clearTimeout(this.timeout);
  }

}

export function watchQuickCaptureOptions(callback) {
  let categories, activeBlocksById, snoozedBlocksById;

  function handleChange() {
    if (categories === undefined ||
      activeBlocksById === undefined ||
      snoozedBlocksById === undefined) return;

    const optionsGroupsUnordered = [];

    const blocksById = {
      ...activeBlocksById,
      ...snoozedBlocksById,
    };

    // Historically each category had its ordered list stored for snoozed blocks.
    // This creates a map of unordered snoozed blocks to be consumed in the same way.
    const snoozedBlocksIdsByCategory = {};

    Object.entries(snoozedBlocksById).forEach(([testId, snoozedBlock]) => {
      if (!snoozedBlocksIdsByCategory[snoozedBlock.categoryId]) {
        snoozedBlocksIdsByCategory[snoozedBlock.categoryId] = [];
      }
      snoozedBlocksIdsByCategory[snoozedBlock.categoryId].push(testId);
    });

    const optionsMap = {
      [UNCATEGORIZED_ID]: {
        name: CAPTURE_LIST_ACTIONS,
        type: 'capture',
      },
    };

    categories.forEach(category => {
      const options = [];

      const {
        id: categoryId,
        name,
        orderedBlocksIds,
        color,
      } = category;

      // Set removes duplicates that are found for some legacy users
      const blocksList = new Set([
        ...orderedBlocksIds[ACTIVE],
        ...snoozedBlocksIdsByCategory[categoryId] || [],
      ]);

      [...blocksList].forEach(blockId => {
        const block = blocksById[blockId];

        if (!block) return;

        options.push({
          name: block.result,
          id: blockId,
          state: block.state,
        });

        optionsMap[blockId] = {
          name: block.result,
          type: 'block',
          parentId: categoryId,
          color,
          state: block.state,
        };
      });

      if (!options.length && categoryId === UNCATEGORIZED_ID) return;

      if (categoryId !== UNCATEGORIZED_ID) {

        optionsMap[categoryId] = {
          name,
          type: 'category',
          color,
        };
      }

      optionsGroupsUnordered.push({
        id: categoryId,
        name,
        color,
        options,
      });
    });

    // Ensures that UNCATEGORIZED_ID is always the last item, with the rest alphabetised, but keeps
    // the ordered_ids (order) for future implementations
    const optionsGroups = optionsGroupsUnordered.sort((a, b) => {
      if (a.id === b.id) return 0;
      if (a.id === UNCATEGORIZED_ID) return 1;
      if (b.id === UNCATEGORIZED_ID) return -1;
      return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1;
    });

    callback({
      optionsGroups,
      optionsMap,
    });
  }

  return composeUnsubscribers(
    collection('categories').onSnapshot(snapshot => {
      categories = snapshot.docs.map(Category.fromFirestore);
      handleChange();
    }),

    collection('blocks').where('state', '==', ACTIVE).onSnapshot(snapshot => {
      const blocks = snapshot.docs.map(Block.fromFirestore);
      activeBlocksById = mapById(blocks);
      handleChange();
    }),

    collection('blocks').where('state', '==', SNOOZED).onSnapshot(snapshot => {
      const blocks = snapshot.docs.map(Block.fromFirestore);
      snoozedBlocksById = mapById(blocks);
      handleChange();
    }),
  );
}

export function watchUserCounters(startDate, callback) {
  // This watcher is very expensive and should be used only for short periods
  // of time. Remember that Firestore downloads all the documents in the
  // resultset of a query, and you are billed for a read operation for each one
  // of them. This is why this function can't be used to compute the all-time
  // counters.

  const startDateISOString = startDate.toISOString();
  let blocksStats, actionsStats;

  function handleChange() {
    // Wait all the counters before calling callback
    if (blocksStats === undefined || actionsStats === undefined) return;
    // Ensure to return a new object every time so React knows that the state
    // has changed
    callback({
      ...blocksStats,
      ...actionsStats,
    });
  }

  return composeUnsubscribers(
    // For these 2 watchers we could add the condition "state == COMPLETED"
    // to add some robustness, but adding it requires creating a custom index on
    // Firestore, and since we are just computing analytics we decided to avoid
    // adding this overhead

    collection('blocks')
      .where('date_of_completion', '>=', startDateISOString)
      .onSnapshot(snapshot => {
        blocksStats = { blocksCompleted: snapshot.size };
        handleChange();
      }),

    collection('actions')
      .where('date_of_completion', '>=', startDateISOString)
      .onSnapshot(snapshot => {
        actionsStats = {
          actionsCompleted: snapshot.size,
          actionsCompletedStarred: 0,
          actionsCompletedLeveraged: 0,
        }
        snapshot.docs.forEach(docSnapshot => {
          const {
            starred,
            leveragedPersonId,
          } = Action.fromFirestore(docSnapshot);
          if (starred) actionsStats.actionsCompletedStarred++;
          if (leveragedPersonId) actionsStats.actionsCompletedLeveraged++;
        });
        handleChange();
      }),
  );
}

export function watchUserAllTimeCounters(callback) {
  // TODO: create a cloud function that regularly checks these counters and
  // rebuilds them if necessary. We could check for example if these all-time
  // counters are always >= of the corresponding last-month counters. If not
  // there is a clear consistency error, and the counters should be rebuilt.
  // Running this function on the client is dangerous because it would download
  // all the completed actions, that can be many thousands.
  return document().onSnapshot(snapshot => {
    const counters = snapshot.get('counters');
    callback({
      blocksCompleted: counters?.blocks_completed || 0,
      actionsCompleted: counters?.actions_completed || 0,
      actionsCompletedStarred: counters?.actions_completed_starred || 0,
      actionsCompletedLeveraged: counters?.actions_completed_leveraged || 0,
    })
  });
}
