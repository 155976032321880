import React, { useEffect, useState, useCallback, useMemo } from 'react';
import clsx from 'clsx';

import { ACTIVE, SNOOZED, STATE_LABELS, SNOOZED_LABELS, SNOOZED_LIST_ORDER } from '../../services/DbService/constants';
import {
  watchCategoryBlocks,
  watchCategoryActiveActions,
  watchCategorySnoozedActions,
} from '../../services/DbService/categories';

import { useCategoryColors } from '../CategoriesContext';
import { ActionsCounters } from '../Counters';
import Heading, { HEADING_LEVEL_5 } from '../Heading';
import LoadingSpinner from '../LoadingSpinner';
import SortableBlocksList from '../BlocksList/SortableBlocksList';
import CreateBlockDialog from '../CreateDialog/CreateBlockDialog';
import CreateButton from '../CreateButton';
import ItemIcon from '../ItemIcon';

import styles from './PlannerColumn.module.scss';

function Section({
  title,
  state,
  snoozedToString,
  blocks,
  actions,
  setBlocks,
  categoryId,
  icon,
  showOnlyStarred,
  ...props
}) {
  const [blockDialogVisible, setBlockDialogVisible] = useState(null);

  const blocksFiltered = useMemo(() => {
    return showOnlyStarred ?
      blocks.filter(block => block.starred) :
      blocks;
  }, [showOnlyStarred, blocks]);

  return (
    <div
      className={clsx(
        styles.section,
        state === ACTIVE && styles.sectionActive,
      )}
    >
      <Heading className={styles.subtitle} tag="h3" level={HEADING_LEVEL_5}>{title}</Heading>
      {!!blocks.length &&
        <ActionsCounters className={styles.counters} actions={actions} />
      }
      <SortableBlocksList
        className={styles.sortableList}
        state={state}
        blocks={blocksFiltered}
        setBlocks={setBlocks}
        snoozedToString={snoozedToString}
        categoryId={categoryId}
        enableScheduledBanner={false}
        sortActive={false}
        detailLink={false}
        showCounters={false}
        cardWithColor
      />
      <CreateButton
        className={styles.createButton}
        aria-label="Create Block"
        tooltip
        onClick={() => setBlockDialogVisible(true)}
      />

      {blockDialogVisible &&
        <CreateBlockDialog
          categoryId={categoryId}
          onClose={() => setBlockDialogVisible(null)}
          state={state}
          snoozedToString={snoozedToString}
          starred={showOnlyStarred}
        />
      }
    </div>
  );
}

function PlannerColumn({
  title,
  icon,
  categoryId,
  className,
  showOnlyStarred,
  ...props
}) {
  const categoryColor = useCategoryColors(categoryId);

  const [activeBlocks, setActiveBlocks] = useState(null);
  const [activeActions, setActiveActions] = useState(null);
  const [snoozedBlocks, _setSnoozedBlocks] = useState(null);
  const [snoozedActions, setSnoozedActions] = useState(null);

  const setSnoozedBlocks = useCallback((timeBlocks, snoozedToString) => {
    _setSnoozedBlocks(blocks => ({
      ...blocks,
      [snoozedToString]: timeBlocks,
    }));
  }, []);

  useEffect(() => {
    return watchCategoryBlocks(categoryId, ACTIVE, null, setActiveBlocks);
  }, [categoryId]);

  useEffect(() => {
    return watchCategoryBlocks(categoryId, SNOOZED, null, _setSnoozedBlocks);
  }, [categoryId]);

  useEffect(() => {
    return watchCategoryActiveActions(categoryId, false, setActiveActions);
  }, [categoryId]);

  useEffect(() => {
    return watchCategorySnoozedActions(categoryId, false, setSnoozedActions);
  }, [categoryId]);

  const snoozedActionsBySnoozedTo = useMemo(() => {
    if (snoozedActions === null || snoozedBlocks === null) return null;

    const actionsByBlock = {};
    snoozedActions.forEach(action => {
      const { blockId } = action;
      if (!actionsByBlock[blockId]) {
        actionsByBlock[blockId] = [];
      }
      actionsByBlock[blockId].push(action);
    });

    const actionsBySnoozedTo = {};

    Object.entries(snoozedBlocks).forEach(([snoozedToKey, blocks]) => {
      blocks.forEach(block => {
        actionsBySnoozedTo[snoozedToKey] = actionsByBlock[block.id];
      });
    });

    return actionsBySnoozedTo;
  }, [snoozedActions, snoozedBlocks]);

  if (activeBlocks === null || snoozedBlocks === null) {
    return (
      <div className={clsx(className, styles.column)}>
        <LoadingSpinner absolute />
      </div>
    );
  }

  return (
    <div
      className={clsx(className, styles.column)}
      style={categoryColor}
    >
      <Heading className={styles.title}>
        <ItemIcon
          className={styles.icon}
          icon={icon}
          active={true}
        />
        <span>{title}</span>
      </Heading>

      <div className={styles.scrollerOuter}>
        <div className={styles.scrollerInner}>
          <Section
            title={STATE_LABELS[ACTIVE].subheading}
            state={ACTIVE}
            blocks={activeBlocks}
            setBlocks={setActiveBlocks}
            categoryId={categoryId}
            showOnlyStarred={showOnlyStarred}
            actions={activeActions}
          />

          {SNOOZED_LIST_ORDER.map((snoozedToString) => (
            <Section
              key={snoozedToString}
              title={SNOOZED_LABELS[snoozedToString].subheading}
              state={SNOOZED}
              blocks={snoozedBlocks?.[snoozedToString]}
              snoozedToString={snoozedToString}
              setBlocks={blocks => setSnoozedBlocks(blocks, snoozedToString)}
              categoryId={categoryId}
              showOnlyStarred={showOnlyStarred}
              actions={snoozedActionsBySnoozedTo?.[snoozedToString]}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default PlannerColumn;
