import React, { useState, createContext, useMemo, useCallback, useContext, useEffect } from "react";

import useUniqueId from '../../hooks/useUniqueId';

const DialogContext = createContext();

export function DialogContextProvider({ children }) {
  const [visibleDialogArray, setVisibleDialogArray] = useState([]);

  const registerDialogOpen = useCallback(id => {
    if (!id) return;

    setVisibleDialogArray(curArray => {
      if (curArray.includes(id)) return curArray;

      return [...curArray, id];
    });
  }, []);

  const registerDialogClosed = useCallback(id => {
    if (!id) return;

    setVisibleDialogArray(curArray => {
      if (!curArray.includes(id)) return curArray;

      return curArray.filter(itemId => itemId !== id);
    });
  }, []);

  const value = useMemo(() => {
    const topVisibleDialogId = visibleDialogArray[visibleDialogArray.length - 1];

    return {
      visibleDialogArray,
      registerDialogOpen,
      registerDialogClosed,
      topVisibleDialogId,
    }
  }, [visibleDialogArray, registerDialogOpen, registerDialogClosed]);

  return (
    <DialogContext.Provider value={value}>
      {children}
    </DialogContext.Provider>
  );
}

/**
 * Exposes an effect that handles the registration and deregistration of a dialog (or dialog-like)
 * component as well as returning whether or not it is the current topmost dialog
 */
export function useIsTopmostDialog() {
  const dialogId = useUniqueId();

  const { registerDialogOpen, registerDialogClosed, topVisibleDialogId } = useContext(DialogContext);

  useEffect(() => {
    registerDialogOpen(dialogId);
    return () => registerDialogClosed(dialogId);
  }, [dialogId, registerDialogOpen, registerDialogClosed]);

  return topVisibleDialogId === dialogId;
}
