import React, { useState, useCallback, useLayoutEffect } from 'react';
import clsx from 'clsx';
import { format } from 'date-fns';

import { useCategoryColors } from '../../CategoriesContext';
import Dialog from '../../Dialog';
import DialogHeader from '../../Dialog/DialogHeader';

import styles from './EventDialog.module.scss';

const DIALOG_WIDTH = 350;
const DIALOG_HEIGHT = 350;
const VIEWPORT_TOP_MARGIN = 60;
const VIEWPORT_MARGIN = 20;
const EVENT_MARGIN = 10;

export function getEventDialogCss(eventRef, dialogHeight = DIALOG_HEIGHT) {
  const boxesRects = [...eventRef.current.children].map(
    box => box.getBoundingClientRect());
  const eventRect = boxesRects.reduce(
    (enclosingRect, intervalRect) => ({
      left: Math.min(enclosingRect.left, intervalRect.left),
      top: Math.min(enclosingRect.top, intervalRect.top),
      right: Math.max(enclosingRect.right, intervalRect.right),
      bottom: Math.max(enclosingRect.bottom, intervalRect.bottom),
    })
  );
  eventRect.height = eventRect.bottom - eventRect.top;

  const viewportWidth = document.documentElement.clientWidth;
  const viewportHeight = document.documentElement.clientHeight;

  let top;
  if (dialogHeight > eventRect.height) {
    top = eventRect.top;
  } else {
    top = eventRect.top + (eventRect.height / 2) - (dialogHeight / 2);
  }
  top = Math.max(top, VIEWPORT_TOP_MARGIN);
  top = Math.min(top, viewportHeight - dialogHeight - VIEWPORT_MARGIN);

  let left = eventRect.right + EVENT_MARGIN;
  if (left + DIALOG_WIDTH > viewportWidth - VIEWPORT_MARGIN) {
    left = eventRect.left - DIALOG_WIDTH - EVENT_MARGIN;
  }

  return {
    top: `${top}rem`,
    left: `${left}rem`,
    position: 'fixed',
    height: `${dialogHeight}rem`,
    width: `${DIALOG_WIDTH}rem`,
  };
}

function EventDialog({
  children,
  className,
  eventRef,
  preBodyChildren,
  hidden,
  onClose,
  eventName,
  startDate,
  subtitle,
  headerButtons,
  categoryId,
  dialogHeight = styles.scheduledEventDialogHeight,
  style = {},
  ...props
}) {
  const categoryColors = useCategoryColors(categoryId);

  const [dialogBodyScrolled, setDialogBodyScrolled] = useState(false);
  const [dialogCss, setDialogCss] = useState();

  useLayoutEffect(() => {
    if (!eventRef?.current) return;

    const css = getEventDialogCss(eventRef, dialogHeight);

    setDialogCss(css);
  }, [dialogHeight, eventRef]);

  // The dialog header is only needed to be open once, it's a one and done
  // animation that doesn't reopen
  const handleDialogScroll = useCallback(e => {
    if (dialogBodyScrolled) return;

    setDialogBodyScrolled(true);
  }, [dialogBodyScrolled]);

  return (
    <Dialog
      onClose={onClose}
      customBody
      lightbox={false}
      style={{
        ...style,
        ...dialogCss,
      }}
      tabIndex={-1}
    >
      <DialogHeader
        className={clsx(categoryId && styles.dialogHeaderCategory)}
        reversed={categoryId}
        title={startDate && format(startDate, 'PPp')}
        subtitle={eventName}
        onClose={onClose}
        buttons={headerButtons}
        style={categoryColors}
        collapsed={dialogBodyScrolled}
      />
      {preBodyChildren}
      <div className={styles.dialogBody} onScroll={handleDialogScroll}>
        {children}
      </div>
    </Dialog>
  );
}

export default EventDialog;
