import React from 'react';
import clsx from 'clsx';

import Divider from '../Divider';
import SidebarScroller from './SidebarScroller';

import styles from './Sidebar.module.scss';

function Sidebar({
  className,
  children,
  headerChildren,
  headerDivider = true,
  footerChildren,
  footerDivider = true,
  fixedSize,
  ...props
}) {
  return (
    <aside
      className={clsx(
        className,
        styles.sidebar,
        fixedSize && styles.sidebarFixed,
      )}
      {...props}
    >
      {headerChildren &&
        <div className={styles.sidebarHeader}>
          {headerChildren}
          {headerDivider && <Divider className={styles.sidebarHeaderDivider} />}
        </div>
      }
      <SidebarScroller>
        {children}
      </SidebarScroller>
      {footerChildren &&
        <div>
          {footerDivider && <Divider className={styles.sidebarFooterDivider} />}
          {footerChildren}
        </div>
      }
    </aside>
  );
}

export default Sidebar;
