import React, { useState, useEffect, useCallback } from 'react';
import { useRouteMatch } from 'react-router-dom';
import clsx from 'clsx';
import { addWeeks, subWeeks, format, startOfWeek } from 'date-fns';

import { getModifierKey } from "../../utils";

import { DASHBOARD_URL } from '../App';
import Button from '../Button';
import Heading, { HEADING_LEVEL_0, HEADING_LEVEL_1 } from '../Heading';
import Tooltip from '../Tooltip';
import WeekView from './WeekView';

import { ReactComponent as IconPrevious } from '../../assets/icons/16-previous.svg';
import { ReactComponent as IconNext } from '../../assets/icons/16-next.svg';
import { ReactComponent as IconAdd } from '../../assets/icons/16-add.svg';

import styles from './CalendarPanel.module.scss';

function CalendarPanelTitlePart({
  chunks = [],
  date,
  ...props
}) {
  if (!chunks.length || !date) return null;

  return (
    <span {...props}>
      {chunks[0] &&
        <span>{format(date, chunks[0])}</span>
      }
      {chunks[1] &&
        <Heading tag="span" level={HEADING_LEVEL_1} className={styles.titleChunkTwo}>{format(date, chunks[1])}</Heading>
      }
    </span>
  );
}

function CalendarPanelTitle({
  className,
  date,
  includeDay,
  style = {},
  ...props
}) {
  // This is a magic number for the short format minimum width
  style.minWidth = includeDay ? '7ch' : '5ch';

  const long = [
    includeDay ? 'E d MMMM' : 'MMMM',
    'yyyy',
  ];
  const short = [
    includeDay ? 'd MMM' : 'MMM',
    'yy',
  ];

  return (
    <Heading className={clsx(className, styles.title)} level={HEADING_LEVEL_0} style={style} {...props}>
      {/* The title is broken down into two parts – one long and one short – that are shown based on the space
          available. */}
      <CalendarPanelTitlePart className={styles.titlePartLong} date={date} chunks={long} />
      <CalendarPanelTitlePart className={styles.titlePartShort} date={date} chunks={short} aria-hidden="true" />
    </Heading>
  );
}

export default function CalendarPanel({
  ...props
}) {
  // This date defines which week will be rendered
  const [weekStart, setWeekStart] = useState(startOfWeek(new Date()));
  const [createEventClicked, setCreateEventClicked] = useState(null);

  const routeIsDashboard = useRouteMatch(DASHBOARD_URL).isExact;

  const handlePreviousWeek = useCallback(() => {
    setWeekStart(weekStart => subWeeks(weekStart, 1));
  }, []);

  const handleNextWeek = useCallback(() => {
    setWeekStart(weekStart => addWeeks(weekStart, 1));
  }, []);

  const handleCreateEvent = useCallback(() => {
    setCreateEventClicked(true);
  }, []);

  // Previous/Next weeks on CMD/CTRL + arrows
  useEffect(() => {
    if (!routeIsDashboard) return;

    function handleKeydownAnywhere(e) {
      if ((e.key !== 'ArrowRight' && e.key !== 'ArrowLeft') || !getModifierKey(e)) return;

      e.preventDefault();

      if (e.key === 'ArrowRight') {
        handleNextWeek();
      } else {
        handlePreviousWeek();
      }
    }
    document.addEventListener('keydown', handleKeydownAnywhere);
    return () => document.removeEventListener('keydown', handleKeydownAnywhere);
  }, [routeIsDashboard, handlePreviousWeek, handleNextWeek]);

  return (
    <div {...props}>
      <div className={styles.container}>
        <div className={styles.header}>
          {/* includeDay will be used for the day view to toggle between hiding/showing the full
              date or just the month */}
          <CalendarPanelTitle date={weekStart} includeDay={false} />

          <div className={styles.buttons}>
            <Button
              className={styles.createEventButton}
              onClick={handleCreateEvent}
              iconOnly
              aria-label="New Event"
              tooltip
            >
              <IconAdd role="presentation" />
            </Button>

            <Tooltip title="Previous Week" shortcut={'\u21E6'} shortcutModifier>
              <Button
                onClick={handlePreviousWeek}
                iconOnly
                aria-label='Previous week'
              >
                <IconPrevious role="presentation" />
              </Button>
            </Tooltip>

            <Tooltip title="Next week" shortcut={'\u21E8'} shortcutModifier>
              <Button
                onClick={handleNextWeek}
                iconOnly
                aria-label='Next week'
              >
                <IconNext role="presentation" />
              </Button>
            </Tooltip>

            <Button
              className={styles.todayButton}
              onClick={() => setWeekStart(startOfWeek(new Date()))}
            >
              Today
            </Button>
          </div>
        </div>

        <WeekView
          weekStart={weekStart}
          createEventClicked={createEventClicked}
          setCreateEventClicked={setCreateEventClicked}
        />
      </div>
    </div>
  );
}
